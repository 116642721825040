import { StatusRows } from "../shared/interfaces";
import { IncidentReasonDataForm } from "./IncidentReason";
import { IncidentStatus } from "./IncidentStatus";

export interface IncidentDataForm {
    id?: number;
    incident_date?: string; 
    incident_hour?: string;
    reason?: string; 
    incident_reason?: IncidentReasonDataForm;
    incident_reason_id?: number;
    solution?: string
    evidence?: File; 
    status?: string;
    status_selected?: IncidentStatus;
    status_choices?: IncidentStatusRequestDataForm[];
    transport_order_id: number;
    created_at?: string;
    description?: string;
}

export const defaultIncidentDataForm: IncidentDataForm = {
    incident_date: '',
    transport_order_id: 0,
};

export interface IncidentFilters {
    page: number;
    per_page: number;
    sort: string;
    order: string;
}


export interface IncidentStatusRequestDataForm {
    id?: number | string;
    name?: number | string;
}
