import { EndPoints } from '../EndPoints';

import useFetch from '../../../hooks/useFetch';
import { useState, useContext } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { UserResponseDetailDataForm, UserResponseDetailFilters } from '../../models/UserResponseDetail';

const useUserResponseDetailService = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetUserResponseDetails, setFetchingGetUserResponseDetails] =
        useState(false);
    const [fetchingGetUserResponseDetailsByUserResponse, setFetchingGetUserResponseDetailsByUserResponse] =
        useState(false);
    const [fetchingStoreUserResponseDetail, setFetchingStoreUserResponseDetail] =
        useState(false);
    const [fetchingEditUserResponseDetail, setFetchingEditUserResponseDetail] =
        useState(false);
    const [fetchingUpdateUserResponseDetail, setFetchingUpdateUserResponseDetail] =
        useState(false);
    const [fetchingDeleteUserResponseDetail, setFetchingDeleteUserResponseDetail] =
        useState(false);
    const [fetchingCreateUserResponseDetail, setFetchingCreateUserResponseDetail] =
        useState(false);
    const [fetchingShowUserResponseDetail, setFetchingShowUserResponseDetail] =
        useState(false);
 
    const getAllUserResponseDetails = (
        filter: UserResponseDetailFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.USER_RESPONSE_DETAILS.GET_USER_RESPONSE_DETAILS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetUserResponseDetails
        });
    };

    // const getAllUserResponseDetailsByUserResponse = (
    //     user_response_id: number,
    //     filter: UserResponseDetailFilters,
    //     events: ServiceEvents = {}
    // ) => {
    //     const queryString = Object.entries(filter)
    //         .map(([key, value]) => `${key}=${value}`)
    //         .join('&');
    //     const url = `${EndPoints.USER_RESPONSE_DETAILS.GET_USER_RESPONSE_DETAILS_BY_USER_RESPONSE}?${queryString}`;
    //     doGet({
    //         ...events,
    //         url: url.replace(':user_response_id', user_response_id.toString()),
    //         setFetching: setFetchingGetUserResponseDetailsByUserResponse
    //     });
    // };

    const getAllUserResponseDetailsByUserResponse = (
        user_response_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.USER_RESPONSE_DETAILS.GET_USER_RESPONSE_DETAILS_BY_USER_RESPONSE.replace(':user_response_id', user_response_id.toString()),
            setFetching: setFetchingGetUserResponseDetailsByUserResponse
        });
    };

    const createUserResponseDetail = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USER_RESPONSE_DETAILS.CREATE_USER_RESPONSE_DETAIL,
            setFetching: setFetchingCreateUserResponseDetail
        });
    };

    const storeUserResponseDetail = (
        userResponseDetailDataForm: UserResponseDetailDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.USER_RESPONSE_DETAILS.STORE_USER_RESPONSE_DETAIL,
            body: userResponseDetailDataForm,
            setFetching: setFetchingStoreUserResponseDetail
        });
    };

    const editUserResponseDetail = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USER_RESPONSE_DETAILS.EDIT_USER_RESPONSE_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingEditUserResponseDetail
        });
    };

    const showUserResponseDetail = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.USER_RESPONSE_DETAILS.SHOW_USER_RESPONSE_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingShowUserResponseDetail
        });
    };

    const updateUserResponseDetail = (
        id: number,
        userResponseDataForm: UserResponseDetailDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.USER_RESPONSE_DETAILS.UPDATE_USER_RESPONSE_DETAIL.replace(':id', id.toString()),
            body: userResponseDataForm,
            setFetching: setFetchingUpdateUserResponseDetail
        });
    };

    const deleteUserResponseDetail = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.USER_RESPONSE_DETAILS.DELETE_USER_RESPONSE_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingDeleteUserResponseDetail
        });
    };

    return {
        getAllUserResponseDetails,
        getAllUserResponseDetailsByUserResponse,
        createUserResponseDetail,
        storeUserResponseDetail,
        editUserResponseDetail,
        updateUserResponseDetail,
        deleteUserResponseDetail,
        showUserResponseDetail,
        fetchingGetUserResponseDetailsByUserResponse,
        fetchingShowUserResponseDetail,
        fetchingGetUserResponseDetails,
        fetchingStoreUserResponseDetail,
        fetchingEditUserResponseDetail,
        fetchingUpdateUserResponseDetail,
        fetchingDeleteUserResponseDetail,
        fetchingCreateUserResponseDetail,
    };
};

export default useUserResponseDetailService;
