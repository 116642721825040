import { Question, QuestionDataForm } from "./Question";
import { Survey } from "./Survey";

export interface Section{
    id?: number;
    title?: string;
    description?: string;
    survey?: Survey;
    survey_id?: number;
    questions?: QuestionDataForm[];
    position?: number;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface SectionDataForm{
    id?: number;
    title?: string;
    description?: string;
    survey?: string;
    survey_id?: number;
    position?: number;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}


export const defaultSectionDataForm: SectionDataForm = {
    title: '',
    description: ''
}


export interface SectionFilters {
    title?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}