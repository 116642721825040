import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import ButtonEditColumns from '../../../components/buttons/ButtonEditColumns';
import { AuthContext } from '../../../contexts/AuthContext';
import useSectionService from '../../../app/services/hooks/useSectionService';
import { SectionDataForm, SectionFilters } from '../../../app/models/Section';
import SectionCreate from './SectionCreate';
import SectionEdit from './SectionEdit';
import QuestionContainer from './QuestionContainer';

interface Props {
    surveyId: number;
    isShow?: boolean;
}

const SectionContainer = ({ surveyId, isShow = false }: Props) => {

    const { auth } = useContext(AuthContext);
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllSectionsBySurvey,
        fetchingGetSectionsBySurvey,
        deleteSection,
        fetchingDeleteSection
   
    } = useSectionService();

    const [showingEdit, setShowingEdit] = useState(false);

    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);
   
    const [sectionIdEdit, setSectionIdEdit] = useState<number>(-1);

    const [sections, setSections] = useState<SectionDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
 
    const [filter, setFilter] = useState<SectionFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc',
    });

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (sectionId: number) => {
        setShowingEdit(true);
        setSectionIdEdit(sectionId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setSectionIdEdit(-1);
    };

 
    useEffect(() => {
        reloadSections();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);


    const destroy = (sectionId: number, position: number) => {
        console.log(sectionId)
        const _text = 'Está a punto de eliminar la sección #' + position;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading)
                            showLoading('loading', 'Eliminando sección...');
                        deleteSection(sectionId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadSections();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La sección no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const reloadSections = () => {

        getAllSectionsBySurvey(surveyId, filter, {

            onSuccess: (response: ServiceResponse) => {
                setSections(response.data.sections);
                setTotalRows(response.data.total_rows);
             
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    
    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12 mb-4">
                        {!isShow && (
                            <ButtonCreate
                                title="Nueva sección"
                                callbackCreate={showCreate}
                            />
                        )}
                    </div>
                </div>
            </DefaultCard>
            
            {/* Iterar secciones*/}
            {sections.map((section, index) => (
                <DefaultCard key={section.id!}>
                    <div className="col-12" key={section.id!}>
                        <QuestionContainer
                            key={section.id!}
                            section={section!}
                            survey_id={section.survey_id!}
                            deleteSection ={destroy}
                        />
                    </div>
                </DefaultCard>
            ))}
            
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Sección"
                    backdrop={true}
                    showFooter={false}
                >
                    <SectionCreate
                        surveyId={surveyId}
                        onSaved={() => {
                            reloadSections();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar sección"
                    backdrop={true}
                    showFooter={false}
                >
                    <SectionEdit
                        sectionId={sectionIdEdit}
                        onSaved={() => {
                            reloadSections();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default SectionContainer;
