import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import LazyLoading from '../../../components/LazyLoading';
import DefaultModal from '../../../components/default/DefaultModal';

import useExpenseService from '../../../app/services/hooks/useExpenseService';
import { ExpenseDataForm, ExpenseFilters } from '../../../app/models/Expense';
import ExpenseDataTable from './tables/ExpenseDataTable';
import ExpenseCreate from './ExpenseCreate';
import ExpenseEdit from './ExpenseEdit';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import { ExpenseHistoryDataForm } from '../../../app/models/ExpenseHistory';
import ExpenseHistoryCreate from './ExpenseHistoryCreate';
import ExpenseHistoryContainer from './ExpenseHistoryContainer';

interface Props {
    transportOrderId: number;
    refreshKey?: boolean
    statusFinished?: boolean;

}

const ExpenseContainer = ({ transportOrderId, refreshKey, statusFinished }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllExpensesByTransportOrder,
        fetchingGetExpensesByTransportOrder,
        deleteExpense,
        fetchingDeleteExpense,
        getUrlDocumentExpenseOnTransportOrder,
        downloadDocument,
        approvedExpense,
        cancelIsApprovedExpense
    } = useExpenseService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingGet, setShowingGet] = useState(false);

    const [expenseIdEdit, setExpenseIdEdit] = useState<number>(-1);

    const [expenses, setExpenses] = useState<ExpenseDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<ExpenseFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const [showingCreateExpenseHistory, setShowingCreateExpenseHistory] = useState(false);
    const [expenseHistoryIdCreate, setExpenseHistoryIdCreate] = useState<number>(-1);

    const [showingGetExpenseHistories, setShowingGetExpenseHistories] = useState(false);
    const [expenseHistoryIdGet, setExpenseHistoryIdGet] = useState<number>(-1);
    const [expenseHistory, setExpenseHistory] = useState<ExpenseHistoryDataForm>({
        comment: '',
        is_approved: true
    });

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        reloadTable();
    }, [refreshKey]);

    const reloadTable = () => {
        getAllExpensesByTransportOrder(filter, transportOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setExpenses(response.data.expenses);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (expenseId: number) => {
        setShowingEdit(true);
        setExpenseIdEdit(expenseId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setExpenseIdEdit(-1);
    };

    const showCreateExpenseHistory = (expenseId: number) => {
        setShowingCreateExpenseHistory(true);
        setExpenseHistoryIdCreate(expenseId);
    };

    const hideCreateExpenseHistory = () => {
        setShowingCreateExpenseHistory(false);
        setExpenseHistoryIdCreate(-1);
        useReactConfirmAlert().infoAlert({
            title: 'Cancelado',
            message: `El gasto no ha cambiado de estado.`
        });
    };

    const hideSuccessCreateExpenseHistory = () => {
        setShowingCreateExpenseHistory(false);
        setExpenseHistoryIdCreate(-1);
    };

    const showGetExpenseHistories = (ExpenseId: number) => {
        setShowingGetExpenseHistories(true);
        setExpenseHistoryIdGet(ExpenseId);
    };

    const hideGetExpenseHistories = () => {
        setShowingGetExpenseHistories(false);
        setExpenseHistoryIdGet(-1);
    };

    const document = (expenseId: number) => {
        if (showLoading) showLoading('download', 'descargando archivo...');
        getUrlDocumentExpenseOnTransportOrder(transportOrderId, expenseId, {
            onSuccess: (response: ServiceResponse) => {
                window.open(response.data.url_file);
                if (changeAnimation)
                    changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) {
                    hideLoading();
                }
                toast.error(response.message);
            }
        });
    };
    const destroy = (expenseId: number) => {
        const _text = 'Está a punto de eliminar el gasto #' + expenseId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando gasto de viaje...');
                        deleteExpense(transportOrderId, expenseId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El gasto de viaje no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const cancel = (expenseId: number, approved: boolean) => {
        const _text = 'Está a punto de pasar el gasto #' + expenseId + ` de ${approved ? 'aprobado' : 'rechazado'} a nulo`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando gasto de viaje...');
                        cancelIsApprovedExpense(expenseId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El gasto de viaje no ha cambiado de estado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const approved = (expenseId: number, value: boolean, message: string) => {
        const _text = `El gasto # ${expenseId} está apunto de ser ${message},${value ? " recuerde rendir el gasto antes de intentar aprobarlo": ""}`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (value) {
                            getApprovedExpense(expenseId, expenseHistory);
                        } else {
                            showCreateExpenseHistory(expenseId);
                        }
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: `El gasto no se ha ${message}.`
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const getApprovedExpense = (expenseId: number, expenseHistory: ExpenseHistoryDataForm) => {
        if (showLoading) showLoading('loading', `Cambiando estado de gasto...`);
        approvedExpense(transportOrderId, expenseId, expenseHistory, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });

                reloadTable();
                hideSuccessCreateExpenseHistory();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <h5>Otros Gastos</h5>
                    <div className="col-12 mb-2 mt-2">
                        {!statusFinished && (
                            <ButtonCreate title="Nuevo gasto" callbackCreate={showCreate} />
                        )}
                    </div>
                    <div className="col-12">
                        <ExpenseDataTable
                            transportOrderId={transportOrderId}
                            loading={fetchingGetExpensesByTransportOrder}
                            expenses={expenses}
                            totalRows={totalRows}
                            edit={showEdit}
                            // show={showGet}
                            destroy={destroy}
                            approved={approved}
                            cancel={cancel}
                            showExpenseHistory={showGetExpenseHistories}
                            filter={filter}
                            setFilter={setFilter}
                            documentExpense={document}
                            callbackSuccess={reloadTable}
                            canBeEdited={true}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear gasto de viaje"
                    backdrop={true}
                    showFooter={false}
                >
                    <ExpenseCreate
                        transportOrderId={transportOrderId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Gasto de viaje"
                    backdrop={true}
                    showFooter={false}
                >
                    <ExpenseEdit
                        expenseId={expenseIdEdit}
                        transportOrderId={transportOrderId}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}

            {showingCreateExpenseHistory ? (
                <DefaultModal
                    show={showingCreateExpenseHistory}
                    handleClose={hideCreateExpenseHistory}
                    title="Añadir información"
                    backdrop={true}
                    showFooter={false}
                >
                    <ExpenseHistoryCreate
                        expenseId={expenseHistoryIdCreate}
                        getApprovedExpense={getApprovedExpense}
                        errorFields={errorFields}
                        onSaved={() => {
                            reloadTable();
                            hideSuccessCreateExpenseHistory();
                        }}
                        onCancel={hideCreateExpenseHistory}
                        onError={hideCreateExpenseHistory}
                    />
                </DefaultModal>
            ) : null}

            {showingGetExpenseHistories ? (
                <DefaultModal
                    show={showingGetExpenseHistories}
                    handleClose={hideGetExpenseHistories}
                    title="Historial gastos"
                    backdrop={true}
                    showFooter={false}
                    size="lg"
                >
                    <ExpenseHistoryContainer
                        expenseId={expenseHistoryIdGet}
                        onCancel={hideGetExpenseHistories}
                        onError={hideGetExpenseHistories}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default ExpenseContainer;
