import React, { useEffect } from 'react';
import { setCleanInputError } from '../../../../utils/utils';
import { SplitSubOrderItem } from '../../../../app/models/TransportationSubOrderItem';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

interface TransportationOrderItemProps {
    canBeEdited: boolean;
    splitData: SplitSubOrderItem[];
    setSplitData: (splitData: SplitSubOrderItem[]) => void;
    errorFields?: any;
}

const TransportationOrderItemSplitForm = ({
    canBeEdited = true,
    splitData,
    setSplitData,
    errorFields
}: TransportationOrderItemProps) => {
    const { fieldErrorMessage, fieldHasError, setErrors, onFocusRemove } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, position: number) => {
        const { name, value } = event.target;

        const updatedSplitData = splitData.map((item, i) => {
            if (position === item.position) {
                return {
                    ...item,
                     [name]: name === 'quantity' ? (!isNaN(parseInt(value)) ? parseInt(value) : 0) : value
                };
            }
            return item;
        });
        
        setSplitData(updatedSplitData);
       
    };

    const removeSplit = (position: number) => {
        
        const newSplitData = splitData.filter((item) => item.position !== position);
        setSplitData(newSplitData);

        setErrors(undefined);
    };

    return (
        <>
            {canBeEdited
                ? splitData.map((split, index) => (
                      <div className="row mb-2" key={index}>
                          <div className="col-md">
                              <label className="label mb-0">Cantidad</label>
                              <input
                                  type="number"
                                  name="quantity"
                                  id="quantity"
                                  onFocus={() => onFocusRemove(`dispatch_program_item.[${index}].quantity`, true)}
                                  className={`form-control ${fieldHasError(
                                    `dispatch_program_item.[${index}].quantity`,
                                    true
                                )}`}
                                  value={split.quantity}
                                  onChange={(e) => handleChange(e, split.position)}
                              />
                              <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage(`dispatch_program_item.[${index}].quantity`, true)}
                              </div>
                          </div>
                          <div className="col-md">
                              <label className="label mb-0">Fecha de Despacho</label>
                              <input
                                  type="date"
                                  className={`form-control ${fieldHasError(
                                    `dispatch_program_item.[${index}].dispatch_date`,
                                    true
                                )}`}
                                  name="dispatch_date"
                                  id="dispatch_date"
                                  value={split.dispatch_date}
                                  onChange={(e) => handleChange(e, split.position)}
                                  placeholder="Ingrese una fecha de despacho"
                                  onFocus={() => onFocusRemove(`dispatch_program_item.[${index}].dispatch_date`, true)}
                              />
                              <div className="invalid-feedback" style={{ display: 'flex' }}>
                                  {fieldErrorMessage(`dispatch_program_item.[${index}].dispatch_date`, true)}
                              </div>
                          </div>
                          <div className="col-auto d-flex">
                              <div className="my-auto">
                                  <ButtonTableAction
                                      callbackFunction={() => removeSplit(split.position)}
                                      classIcon={'mdi mdi-delete'}
                                      colorIcon={'text-danger'}
                                      errorMessage={'No se puede eliminar este registro.'}
                                      title={'Eliminar'}
                                  />
                              </div>
                          </div>
                      </div>
                  ))
                : null}
        </>
    );
};

export default TransportationOrderItemSplitForm;
