import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { WorkOrderDataForm, WorkOrderFilters } from '../../models/WorkOrder';

const useWorkOrderService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetWorkOrders, setFetchingGetWorkOrders] = useState(false);
    const [fetchingDeleteWorkOrder, setFetchingDeleteWorkOrder] = useState(false);
    const [fetchingCreateWorkOrder, setFetchingCreateWorkOrder] = useState(false);
    const [fetchingStoreWorkOrder, setFetchingStoreWorkOrder] = useState(false);

    const [fetchingShowWorkOrder, setFetchingShowWorkOrder] = useState(false);

    const [fetchingEditWorkOrder, setFetchingEditWorkOrder] = useState(false);
    const [fetchingUpdateWorkOrder, setFetchingUpdateWorkOrder] = useState(false);

    const [fetchingActiveWorkOrder, setFetchingActiveWorkOrder] = useState(false);

    const [fetchingChangeStatusWorkOrder, setFetchingChangeStatusWorkOrder] = useState(false);
    const [fetchingHasFinishedStatuses, setFetchingHasFinishedStatuses] = useState(false);

    const getWorkOrders = (workOrderFilters: WorkOrderFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(workOrderFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.WORK_ORDERS.GET_WORK_ORDERS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetWorkOrders
        });
    };

    // const showWorkOrder = (id: number, events: ServiceEvents = {}) => {
    //     doGet({
    //         ...events,
    //         url: EndPointsPegasus.TRUCKS.GET_TRUCK.replace(':id', id.toString()),
    //         setFetching: setFetchingShowWorkOrder
    //     });
    // };

    const createWorkOrder = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.WORK_ORDERS.CREATE_WORK_ORDERS,
            setFetching: setFetchingCreateWorkOrder
        });
    };

    const storeWorkOrder = (workOrder: WorkOrderDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.WORK_ORDERS.STORE_WORK_ORDERS,
            body: workOrder,
            requestType: 'multipart',
            setFetching: setFetchingStoreWorkOrder
        });
    };

    const editWorkOrder = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.WORK_ORDERS.EDIT_WORK_ORDER.replace(':id', id.toString()),
            setFetching: setFetchingEditWorkOrder
        });
    };

    const updateWorkOrder = (
        id: number,
        workOrder: WorkOrderDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.WORK_ORDERS.UPDATE_WORK_ORDER.replace(':id', id.toString()),
            body: workOrder,
            setFetching: setFetchingUpdateWorkOrder
        });
    };

    const deleteWorkOrder = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.WORK_ORDERS.DELETE_WORK_ORDER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteWorkOrder
        });
    };

    const changeStatusWorkOrder = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.WORK_ORDERS.CHANGE_STATUS_TRANSPORT_ORDER.replace(':id', id.toString()),
            body: { status: status },
            setFetching: setFetchingChangeStatusWorkOrder
        });
    };

    const hasAllFinishedStatuses = (id: number, status: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.WORK_ORDERS.HAS_ALL_FINISHED_STATUSES_TRANSPORT_ORDER.replace(
                ':id',
                id.toString()
            ).replace(':work_order_status', status),
            setFetching: setFetchingHasFinishedStatuses
        });
    };

    return {
        fetchingGetWorkOrders,
        fetchingDeleteWorkOrder,
        fetchingCreateWorkOrder,
        fetchingStoreWorkOrder,
        fetchingShowWorkOrder,
        fetchingEditWorkOrder,
        fetchingUpdateWorkOrder,
        fetchingActiveWorkOrder,
        fetchingHasFinishedStatuses,
        fetchingChangeStatusWorkOrder,
        hasAllFinishedStatuses,
        changeStatusWorkOrder,
        getWorkOrders,
        createWorkOrder,
        storeWorkOrder,
        deleteWorkOrder,
        editWorkOrder,
        updateWorkOrder
    };
};

export default useWorkOrderService;
