import React from 'react';
import {  QuestionDataForm, LinkInspectionOption } from '../../../../../../app/models/Question';

type Props = {
    input: QuestionDataForm; 
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean
};

const QuestionMultipleCheckField = ({ input, handleCheckboxChange, disabled=false }: Props) => {

    return (
        <div className="mb-5">
            
            <div>
                {input &&
                    input.options &&
                    input.options.map((option: LinkInspectionOption, index: number) => (
                        <div key={index} className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`${option.id}-${index}`}
                                name={`${option.id}`}
                                checked={
                                    Number(input.options && input.options.find((o: LinkInspectionOption) => o.id === option.id)!.value) == 1
                                } 
                                onChange={handleCheckboxChange}
                                disabled={disabled}
                            />
                            <label className="form-check-label" htmlFor={`${input.id}-${index}`}>
                                {option.label}
                            </label>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default QuestionMultipleCheckField;
