import React, { useEffect, useState } from 'react';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import { toast } from 'react-toastify';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import TransportationSubOrderItemSplitForm from './forms/TransportationSubOrderItemSplitForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { formatNumber } from '../../../utils/utils';
import AlertBootstrap from '../../../components/AlertBootstrap';
import {
    defaultTransportationSubOrderItemDataForm,
    SplitSubOrderItem,
    TransportationSubOrderItemDataForm
} from '../../../app/models/TransportationSubOrderItem';
import useSweetAlert from '../../../hooks/useSweetAlert';

interface Props {
    canBeEdited: boolean;
    transportationSubOrderItemId: number;
    dispatchProgramId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
}

const TransportationSubOrderItemSplit = ({
    canBeEdited,
    transportationSubOrderItemId,
    dispatchProgramId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const {
        fetchingSplitTransportationSubOrderItem,
        splitTransportationSubOrderItem,
        fetchingStoreSplitTransportationSubOrderItem,
        storeSplitTransportationSubOrderItem
    } = useDispatchProgramService();

    const [transportationSubOrderItem, setTransportationSubOrderItem] =
        useState<TransportationSubOrderItemDataForm>(defaultTransportationSubOrderItemDataForm);

    const [errorFields, setErrorFields] = useState<any>();
    // const [errorFields, setErrorFields] = useState<any>({});
    const [splitData, setSplitData] = useState<SplitSubOrderItem[]>([]);
    const [quantityAvailable, setQuantityAvailable] = useState<number>(0);
    const [quantityUsed, setQuantityUsed] = useState<number>(0);

    // useEffect(() => {
    //     console.log(splitData);
    // }, [splitData]);

    useEffect(() => {
        if (transportationSubOrderItemId) {
            edit();
        }
    }, [transportationSubOrderItemId]);

    const edit = () => {
        splitTransportationSubOrderItem(transportationSubOrderItemId, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationSubOrderItem(response.data.transportation_sub_order_item);
            },
            // onError: (response: ServiceResponse) => {
            //     onError && onError(response.message);
            // }
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2500
                });
                if (onError) onError();
            }
        });
    };

    const handleDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setTransportationSubOrderItem({
            ...transportationSubOrderItem,
            dispatch_date: value
        });
    };

    const addSplit = () => {
        

        const maxIndex = splitData
                ? Math.max(...splitData.map((item) => item.position), 0)
                : 0;

        setSplitData([
            ...splitData,
            {
                position: maxIndex + 1,
                quantity: 28,
                dispatch_date: transportationSubOrderItem.dispatch_date ?? ''
            }
        ]);

        setErrorFields(undefined);

    };

    const getQuantityAdded = () => {
        
        return splitData.reduce((total, split) => {
        
            return total + (Number(split.quantity) || 0);
        }, 0);
    };

    const getQuantityAvailable = () => {
        return transportationSubOrderItem.quantity - getQuantityAdded();
    };

    const hasQuantityToSplit = () => {
        return transportationSubOrderItem.quantity > getQuantityAdded();
    };

    const canBeSaved = () => {
        return transportationSubOrderItem.quantity == getQuantityAdded();
    };

    const cancel = () => {
        onCancel && onCancel();
    };

    const storeSplit = () => {
        // if (!canBeSaved()) {
        //     toast.error('Aún quedan cantidades por dividir');
        //     return;
        // }
        setErrorFields(undefined);
        useSweetAlert().spinnerAlert('Dividiendo detalle de pedido de transporte', 500);
        storeSplitTransportationSubOrderItem(
            {
                transportation_order_item_id: transportationSubOrderItemId,
                dispatch_program_id: dispatchProgramId,
                dispatch_program_item: splitData
            },
            {
                onSuccess: (response: ServiceResponse) => {
                    useSweetAlert().closeSpinnerAlert();
                    toast.success(response.message);
                    onSaved && onSaved();
                },
                onError: (response: ServiceResponse) => {
                    useSweetAlert().closeSpinnerAlert();
                    toast.error(response.message);
                    if (onError) onError();
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    useSweetAlert().closeSpinnerAlert();
                    setErrorFields(errorFields.data);
                }
            }
        );
    };

    return (
        <>
            {canBeEdited && (
                <div className="row">
                    <div className="col-12">
                        <table className="table table-sm table-bordered">
                            <thead className="table-header-45">
                                <tr className="table-primary">
                                    <th colSpan={4} className="text-center py-0">
                                        CANTIDADES
                                    </th>
                                </tr>
                                <tr className="table-primary">
                                    {/*<th className="text-center py-0 w-25">TOTAL</th>*/}
                                    {/*<th className="text-center py-0 w-25">DIVIDIDA</th>*/}
                                    {/*<th className="text-center py-0 w-25">RESTANTE</th>*/}
                                    {/*<th className="text-center py-0 w-25">EN DESPACHO</th>*/}
                                    <th className="text-center py-0 w-25">PROGRAMADO</th>
                                    <th className="text-center py-0 w-25">POR PROGRAMAR</th>
                                    <th className="text-center py-0 w-25">RESTANTE</th>
                                    <th className="text-center py-0 w-25">TOTAL</th>
                                </tr>
                            </thead>
                            <tr>
                                {/*<td className="text-center">{formatNumber(getQuantityAdded())}</td>*/}
                                <td className="text-center">
                                    {' '}
                                    {formatNumber(transportationSubOrderItem.quantity_used ?? 0)}
                                </td>
                                <td className="text-center">{formatNumber(getQuantityAdded())}</td>
                                <td className="text-center">
                                    {formatNumber(
                                        transportationSubOrderItem.quantity -
                                            (transportationSubOrderItem.quantity_used ?? 0) -
                                            getQuantityAdded()
                                    )}
                                </td>
                                <td className="text-center">
                                    {formatNumber(transportationSubOrderItem.quantity)}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col-12">
                    {!fetchingSplitTransportationSubOrderItem ? (
                        <>
                            {canBeEdited ? (
                                <>
                                    <div className="row mb-3">
                                        <div className="col-auto align-self-center">
                                            <ButtonCreate callbackCreate={addSplit} />
                                        </div>
                                        <div className="col-auto">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={transportationSubOrderItem.dispatch_date}
                                                onChange={handleDate}
                                                placeholder="Ingrese una fecha de despacho"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-md">
                                    <label className="label mb-0">Fecha para despacho</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={transportationSubOrderItem.dispatch_date}
                                        // onChange={(e) => handleChange(e, index)}
                                        placeholder="Ingrese una fecha de despacho"
                                    />
                                </div> */}
                                    </div>
                                </>
                            ) : (
                                <AlertBootstrap type="warning">
                                    Este pedido ya no puede ser dividido.
                                </AlertBootstrap>
                            )}
                            <TransportationSubOrderItemSplitForm
                                splitData={splitData}
                                setSplitData={setSplitData}
                                canBeEdited={canBeEdited}
                                errorFields={errorFields}
                            />
                        </>
                    ) : (
                        <LazyLoading height="300" />
                    )}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreSplitTransportationSubOrderItem}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={storeSplit}
                        locked={fetchingStoreSplitTransportationSubOrderItem}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationSubOrderItemSplit;
