import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useRut from '../../../../hooks/useRut';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { ResponseType } from '../../../../app/models/ResponseType';
import { QuestionDataForm } from '../../../../app/models/Question';

interface GeneralProps {
    questionForm: QuestionDataForm;
    setQuestionForm: (QuestionForm: QuestionDataForm) => void;
    responseTypes: ResponseType[];
    errorFields?: any;
}

const QuestionForm = ({
    questionForm,
    setQuestionForm,
    responseTypes,
    errorFields
}: GeneralProps) => {
    
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setQuestionForm({ ...questionForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setQuestionForm({ ...questionForm, ...obj });
    };
    
    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        setQuestionForm({ ...questionForm, is_required: checked });
    };

    if (!questionForm) return null;

    return (
        <>
           <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Pregunta</label>

                    <input
                        className={`form-control ${fieldHasError('title')}`}
                        type="text"
                        name="question_text"
                        id="question_text"
                        value={questionForm.question_text}
                        onChange={handleChange}
                        placeholder="Ingrese pregunta a realizar"
                        onFocus={() => onFocusRemove('question_text')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('question_text')}
                    </div>
                </div>
            </div>
            
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tipo de respuesta</label>
                    <SelectTwo
                        name="response_type"
                        id="response_type"
                        inputValue={questionForm.response_type}
                        options={SelectTwoMapperOptions(responseTypes, 'id', ['name'])}
                        hasError={fieldHasError('response_type') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('response_type', value?.value)}
                        placeholder={'Seleccione opción'}
                        onFocus={() => onFocusRemove('response_type')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('response_type')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                
                <div className="col-12">
                <label htmlFor="is_required" className="col-form-label">
                    Respuesta requerida
                </label>
                    <div className="square-switch d-flex  form-check form-switch">
                        <input
                            type="checkbox"
                            name="is_required"
                            id="is_required"
                            className={`form-check-input ${
                                questionForm.is_required ? 'active' : ''
                            }`}
                            checked={questionForm.is_required}
                            onChange={handleCheckChange}
                        />
                        {fieldErrorMessage('is_required')}
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default QuestionForm;
