import React, { useContext, useEffect, useState } from 'react';
import DefaultCard from '../../components/default/DefaultCard';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import DefaultModal from '../../components/default/DefaultModal';
import { IncidentReasonDataForm, IncidentReasonFilters } from '../../app/models/IncidentReason';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import useIncidentReasonService from '../../app/services/hooks/useIncidentReasonService';
import IncidentReasonDataTable from './components/tables/IncidentReasonDataTable';
import IncidentReasonEdit from './components/IncidentReasonEdit';
import IncidentReasonCreate from './components/IncidentReasonCreate';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Motivos de incidentes',
        url: '/incident-reasons',
        isActive: true
    }
];

const IncidentReasons = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetIncidentReasons, getAllIncidentReasons, deleteIncidentReason } = useIncidentReasonService();
    const { navigationPage } = useNavigationPage();

    const [incidentReasonId, setIncidentReasonId] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [incidentReasons, setIncidentReasons] = useState<IncidentReasonDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [incidentReasonFilter, setIncidentReasonFilter] = useState<IncidentReasonFilters>({
        page: 1,
        per_page: 10,
        sort: 'date',
        order: 'asc'
    });

    useEffect(() => {
        reloadTable();
    }, [incidentReasonFilter.page, incidentReasonFilter.per_page, incidentReasonFilter.sort, incidentReasonFilter.order]);

    const getAllHolidays = () => {
        getAllIncidentReasons(incidentReasonFilter, {
            onSuccess: (response: ServiceResponse) => {
                setIncidentReasons(response.data.incident_reasons);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const destroy = (id: number) => {
        const _text = 'Está a punto de eliminar el motivo de incidente #' + id;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando motivo de incidente...');
                        deleteIncidentReason(id, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El feriado no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const reloadTable = () => {
        getAllHolidays();
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (id: number) => {
        setShowingEdit(true);
        setIncidentReasonId(id);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setIncidentReasonId(-1);
    };

    return (
        <>
            <Breadcrumbs pageSection="Motivos de Incidentes" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo Motivo de Incidente" />}
                />
                <IncidentReasonDataTable
                    incidentReasons={incidentReasons}
                    totalRows={totalRows}
                    filter={incidentReasonFilter}
                    setFilter={setIncidentReasonFilter}
                    callbackSuccess={reloadTable}
                    destroy={destroy}
                    edit={showEdit}
                    loading={fetchingGetIncidentReasons}
                    onSearch={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Motivo de Incidente"
                    backdrop={true}
                    showFooter={false}
                >
                    <IncidentReasonCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Motivo de Incidente"
                    backdrop={true}
                    showFooter={false}
                >
                    <IncidentReasonEdit
                        incidentReasonId={incidentReasonId}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default IncidentReasons;