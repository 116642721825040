import React, { useEffect, useState, useContext } from 'react';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Society } from '../../../../app/models/Society';
import { Client } from '../../../../app/models/Client';
import { Currency } from '../../../../app/models/Currency';
import { PaymentCondition } from '../../../../app/models/PaymentCondition';
import { DispatchProgram } from '../../../../app/models/DispatchProgram';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { TransportationOrderDataForm } from '../../../../app/models/TransportationOrder';
import AssignRunnerForm from '../../../TransportationOrders/components/forms/AssignRunnerForm';
import { RunnerDataForm } from '../../../../app/models/Runner';
import { AuthContext } from '../../../../contexts/AuthContext';

interface DispatchProgramFormProps {
    dispatchProgramForm: DispatchProgram;
    setDispatchProgramForm: (dispatchProgram: DispatchProgram) => void;
    societies: Society[];
    transportationOrders: TransportationOrderDataForm[];
    clients: Client[];
    runners: RunnerDataForm[];
    errorFields?: any;
    disabled?: boolean;
    availableUse?: boolean;
}

const DispatchProgramForm = ({
    dispatchProgramForm,
    setDispatchProgramForm,
    societies,
    clients,
    runners,
    transportationOrders,
    errorFields,
    disabled = false,
    availableUse = false
}: DispatchProgramFormProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setDispatchProgramForm({ ...dispatchProgramForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setDispatchProgramForm({ ...dispatchProgramForm, [name]: value });
    };

    const handleChangeSelectTwoClient = (name: string, value: number | string | null) => {
        setDispatchProgramForm({
            ...dispatchProgramForm,
            [name]: value,
            contact_email: clients.find((client) => client.id == value)?.email_contact || ''
        });
    };

    if (!dispatchProgramForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Posición Pedido de transporte </label>
                <div className="col-md-9">
                    <SelectTwo
                        name="transportation_order_item_id"
                        id="transportation_order_item_id"
                        inputValue={dispatchProgramForm.transportation_order_item_id}
                        options={SelectTwoMapperOptions(transportationOrders, 'id', ['name'])}
                        hasError={fieldHasError('transportation_order_item_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('transportation_order_item_id', value.value)
                        }
                        placeholder={'Seleccione número de pedido de transporte posicion'}
                        onFocus={() => onFocusRemove('transportation_order_item_id')}
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('transportation_order_item_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Sociedad</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="society_id"
                        id="society_id"
                        inputValue={dispatchProgramForm.society_id}
                        options={SelectTwoMapperOptions(societies, 'id', ['business_name'])}
                        hasError={fieldHasError('society_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('society_id', value.value)}
                        placeholder={'Seleccione una sociedad'}
                        onFocus={() => onFocusRemove('society_id')}
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Cliente</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="client_id"
                        id="client_id"
                        inputValue={dispatchProgramForm.client_id}
                        options={SelectTwoMapperOptions(clients, 'id', ['business_name'])}
                        hasError={fieldHasError('client_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwoClient('client_id', value.value)
                        }
                        placeholder={'Seleccione un cliente'}
                        onFocus={() => onFocusRemove('client_id')}
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('client_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Contacto</label>
                <div className="col-md-9">
                    <input
                        name="contact_email"
                        id="contact_email"
                        type="email"
                        className={`form-control ${fieldHasError('contact_email')}`}
                        value={dispatchProgramForm.contact_email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('contact_email')}
                        placeholder="Ingrese un correo de contacto"
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('contact_email')}
                    </div>
                </div>
            </div>

            {/* {
                isCreated ?
                (<>
                <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Forma de Pago</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="payment_method_id"
                        id="payment_method_id"
                        inputValue={dispatchProgramForm.payment_method_id}
                        options={SelectTwoMapperOptions(paymentMethods)}
                        hasError={fieldHasError('payment_method_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('payment_method_id', value.value)
                        }
                        onFocus={() => onFocusRemove('payment_method_id')}
                        placeholder={'Seleccione una forma de pago'}
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('payment_method_id')}
                    </div>
                </div>
            </div>
           
                    <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Condición de pago</label>
                    <div className="col-md-9 ">
                        <div className="row g-1">
                            <div className="col-9">
                                <SelectTwo
                                    name="reference"
                                    id="reference"
                                    inputValue={dispatchProgramForm.reference}
                                    hasError={fieldHasError('reference') !== ''}
                                    options={SelectTwoMapperOptions(references)}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('reference', value.value)
                                    }
                                    onFocus={() => onFocusRemove('reference')}
                                    placeholder="Seleccione Referencia"
                                    disabled={disabled}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('reference')}
                                </div>
                            </div>

                            <div className="col-3">
                                <input
                                    name="days_count"
                                    id="days_count"
                                    type="number"
                                    className={`form-control ${fieldHasError('days_count')}`}
                                    value={dispatchProgramForm.days_count}
                                    onChange={handleChange}
                                    onFocus={() => onFocusRemove('days_count')}
                                    placeholder="días"
                                    disabled={disabled}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('days_count')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           
            
                <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Moneda</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="currency_id"
                        id="currency_id"
                        inputValue={dispatchProgramForm.currency_id}
                        hasError={fieldHasError('currency_id') !== ''}
                        options={SelectTwoMapperOptions(currencies)}
                        onChange={(value: any) => handleChangeSelectTwo('currency_id', value.value)}
                        onFocus={() => onFocusRemove('currency_id')}
                        placeholder={'Seleccione una Moneda'}
                        disabled={availableUse}

                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('currency_id')}
                    </div>
                </div>
            </div>
                </>
                
                )
            : null
            } */}

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Runner</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="runner_id"
                        id="runner_id"
                        inputValue={dispatchProgramForm.runner_id}
                        hasError={fieldHasError('runner_id') !== ''}
                        options={SelectTwoMapperOptions(runners, 'id', ['name'])}
                        onChange={(value: any) => handleChangeSelectTwo('runner_id', value?.value)}
                        placeholder={'Seleccione un runner'}
                        disabled={availableUse}
                        onFocus={() => onFocusRemove('runner_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('runner_id')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DispatchProgramForm;
