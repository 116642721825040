import React from 'react';
import { IconProps } from '../types';

export const Provider: React.FC<IconProps> = ({ size = 24, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            width={size}
            height={size}
            viewBox="0 0 256 240"
            enableBackground="new 0 0 256 240"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <path d="M84.635,20.256c18.383,0,33.286,14.903,33.286,33.286s-14.903,33.286-33.286,33.286S51.349,71.925,51.349,53.542 S66.251,20.256,84.635,20.256z M31.002,145.011c0-2.499,1.606-4.194,4.194-4.194s4.194,1.606,4.194,4.194v92.986h91.469v-92.986 c0-2.499,1.606-4.194,4.194-4.194c2.499,0,4.194,1.606,4.194,4.194v92.986h29.092V136.623c0-22.934-18.74-41.585-41.585-41.585 h-8.388l-24.451,38.015l-2.945-28.467l4.016-9.638H76.96l4.016,9.638l-3.123,28.645L53.401,95.038h-9.816 C20.651,95.038,2,113.778,2,136.623v101.375h29.092v-92.986H31.002z M156.681,11.989c-3.648,4.567-11.759,26.332,23.01,61.971 c36.625,37.543,60.258,29.512,64.327,25.275l-24.494-24.494c-3.418,3.418-7.827,0.384-16.287-6.514 c-5.598-4.564-12.038-10.703-17.622-17.622c-3.918-4.855-7.383-9.953-3.804-13.533L156.681,11.989z M252.872,90.468 c1.583-1.583,1.429-4.009-0.017-5.454v-0.023c0,0-19.101-19.079-19.113-19.091c-1.514-1.514-3.936-1.481-5.427,0.011l-6.939,6.939 l24.557,24.557c0,0,6.932-6.911,6.932-6.922L252.872,90.468z M190.645,28.24c1.583-1.583,1.429-4.009-0.017-5.454v-0.023 c0,0-19.652-19.63-19.664-19.641c-1.514-1.514-3.936-1.481-5.427,0.011l-6.939,6.939l25.108,25.108c0,0,6.932-6.91,6.932-6.922 L190.645,28.24z" />
            </g>
        </svg>
    );
};
