import React from 'react';
import {  QuestionDataForm, LinkInspectionOption } from '../../../../../../app/models/Question';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../../../components/SelectTwo';

type Props = {
    input: QuestionDataForm; 
    handleChange: (fieldName: string, value: number | string | null) => void;
    disabled?: boolean
};

const QuestionSelectionField = ({ input, handleChange, disabled=false }: Props) => {
    return (
        <div className="mb-5">
       
            <SelectTwo
                name={`${input.id}`}
                id={`${input.id}`}
                inputValue={input.options?.find((option: LinkInspectionOption) => option.is_response === true)?.id}
                options={SelectTwoMapperOptions(input.options!)}
                onChange={(value: any) => handleChange('options', value?.value)}
                placeholder={'Seleccione opciones'}
                isClearable={false}
                disabled={disabled}
            />
        </div>
    );
};

export default QuestionSelectionField;