import React, { useEffect } from 'react';
import { QuestionDataForm } from '../../../../../../app/models/Question';

type Props = {
    input: QuestionDataForm;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean
};

const QuestionSwitchField = ({ input, handleChange, disabled=false }: Props) => {

    return (
        <>
            <div className="mb-5 text-center">
               
                <div className="row">
                    <div className="col d-flex">
                        <span className="my-auto ms-auto">No</span>
                    </div>
                    <div className="col-auto">
                        <div className="form-check ps-0 form-switch d-flex">
                            <input
                                className="form-check-input mx-auto"
                                type="checkbox"
                                name={input.id?.toString()}
                                checked={input.switch_value}
                                onChange={(e) => handleChange(e)}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className="col d-flex">
                        <span className="my-auto">Si</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuestionSwitchField;
