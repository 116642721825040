import React, { useState, useContext } from 'react';
import MonthlyClosureFormContainer from './MonthlyClosureFormContainer';
import { getCurrentMonth } from '../../../helpers';
import { MonthlyClosureDataForm, defaultMonthlyClosureDataForm } from '../../../app/models/MonthlyClosure';
import { AppContext } from '../../../contexts/AppContext';
import useMonthlyClosureService from '../../../app/services/hooks/useMonthlyClosureService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useNavigationPage from '../../../hooks/useNavigationPage';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const MonthlyClosureCreate = ({ onSaved, onCancel, onError }: Props) => {

    const { navigationPage } = useNavigationPage();

    const { showLoading, hideLoading } = useContext(AppContext);

    const { storeMonthlyClosure, fetchingStoreMonthlyClosures } = useMonthlyClosureService()

    const [errorFields, setErrorFields] = useState<any>();
    const [closure, setClosure] = useState<MonthlyClosureDataForm>({
        ...defaultMonthlyClosureDataForm,
        closing_date: getCurrentMonth()
    });

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando cierre de mes...');
        storeMonthlyClosure(closure, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                navigationPage('/monthly-closures/' + response.data.monthly_closure_id + '/edit');
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };


    return (
        <>
            <MonthlyClosureFormContainer 
                closure={closure} 
                setClosure={setClosure}
                action={store}
                cancel={cancel}
                fetching={fetchingStoreMonthlyClosures}
                errorFields={errorFields}
                setErrorFields={setErrorFields}
            />
        </>
    )
}

export default MonthlyClosureCreate
