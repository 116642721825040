import React from 'react';
import ButtonTableAction from './buttons/ButtonTableAction';
import DataTable from 'react-data-table-component';
import LazyLoading from './LazyLoading';
import { momentParseDate } from '../helpers';
import { DocumentNameFilters } from '../app/models/Document';
import DocumentFilter from './DocumentFilter';

type Props = {
    data: any[];
    create?: (type: string) => void;
    showHistory?: (type: string) => void;
    progressPending?: boolean;
    filter: DocumentNameFilters;
    setFilter: (filter: DocumentNameFilters) => void;
    onSearch: () => void;
};

const DocumentsDataTable = ({ 
    create, 
    data, 
    progressPending, 
    showHistory,
    filter,
    setFilter,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Documento',
            selector: (row: any) => row.name,
            sortable: false,
            sortField: 'documento',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.document_name}</span>
            )
        },
        {
            name: 'Nombre del Archivo',
            selector: (row: any) => row.name,
            cell: (row: any, index: any, column: any) => (
                <a
                    className="fw-bold"
                    href={String(row?.file)}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {row?.name}
                </a>
            )
        },
        {
            name: 'Fecha de Emisión',
            selector: (row: any) => row.issue_date,
            cell: (row: any, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name: 'Fecha de Vencimiento',
            selector: (row: any) => row.expiration_date,
            cell: (row: any, index: any, column: any) => (
                <span className="">{momentParseDate(row?.expiration_date)}</span>
            )
        },
        {
            name: 'Indefinido',
            selector: (row: any) => row.is_expire_date_indefinite,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {row.is_expire_date_indefinite ? <span>Sí</span> : <span>No</span>}
                </div>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {create && (
                        <ButtonTableAction
                            callbackFunction={() => create(row?.id ?? -1)}
                            classIcon={'mdi mdi-file-plus'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Cargar'}
                        />
                    )}
                    {showHistory && (
                        <ButtonTableAction
                            callbackFunction={() => showHistory(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div>
            <div className="row mt-3">
                <div className="col">
                    <DocumentFilter 
                        filter={filter} 
                        setFilter={setFilter} 
                        onSearch={onSearch}
                    />
                </div>
            </div>
            <DataTable
                columns={columns}
                data={data}
                progressPending={progressPending}
                progressComponent={<LazyLoading height={300} />}
                noDataComponent="No hay documentos registrados"
            />
        </div>
    );
};

export default DocumentsDataTable;
