import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useRut from '../../../../hooks/useRut';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { SurveyDataForm } from '../../../../app/models/Survey';
import { EventTypeListItem } from '../../../../app/models/EventType';

interface GeneralProps {
    surveyForm: SurveyDataForm;
    eventTypes: EventTypeListItem[];
    setSurveyForm: (surveyForm: SurveyDataForm) => void;
    errorFields?: any;
}

const SurveyForm = ({
    surveyForm,
    setSurveyForm,
    eventTypes,
    errorFields
}: GeneralProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setSurveyForm({ ...surveyForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setSurveyForm({ ...surveyForm, ...obj });
    };

    const MapperOptions = (items: any[]) => {
        return items.map((item) => ({
            value: item.id,
            label: item.name
        }));
    };

    if (!surveyForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Título</label>

                    <input
                        className={`form-control ${fieldHasError('title')}`}
                        type="text"
                        name="title"
                        id="title"
                        value={surveyForm.title}
                        onChange={handleChange}
                        placeholder="Ingrese título"
                        onFocus={() => onFocusRemove('title')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('title')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                <label className="col-form-label">Descripción</label>
                <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={surveyForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                <label className="col-form-label">Tipos de Eventos</label>
                    <SelectTwo
                        name="eventTypes"
                        id="eventTypes"
                        inputValue={surveyForm.event_types}
                        options={MapperOptions(eventTypes)}
                        hasError={fieldHasError('documents') !== ''}
                        onChange={(value: any) =>
                            setSurveyForm({
                                ...surveyForm,
                                event_types: value
                            })
                        }
                        isSortable={true}
                        isMulti={true}
                        placeholder={'Seleccione los tipos de eventos'}
                        onFocus={() => onFocusRemove('eventTypes')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('event_types')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SurveyForm;
