import React from 'react';
import { Question, QuestionDataForm } from '../../../app/models/Question';
import { toast } from 'react-toastify';
import { InputResponseType } from '../../../app/shared/enums';

type Props = {
    totalQuestions: number;
    currentQuestion: number;
    setCurrentQuestion: (currentQuestion: number) => void;
    confirmForm: () => void;
    storeForm: () => void;
    showResume: boolean;
    setShowConfirm: (showConfirm: boolean) => void;
    showConfirm: boolean;
    setShowResume: (showResume: boolean) => void;
    questionSelected?: QuestionDataForm;
};


const NavQuestions = ({
    totalQuestions,
    currentQuestion,
    setCurrentQuestion,
    showResume,
    setShowResume,
    showConfirm,
    setShowConfirm,
    storeForm,
    confirmForm,
    questionSelected
}: Props) => {

    const handlePrevious = () => {
        if (currentQuestion > 1) {
            if (showResume) {
                setShowResume(false);
            } else if(showConfirm) {
                setShowConfirm(false)
                setCurrentQuestion(currentQuestion - 1);
            }else {
                setCurrentQuestion(currentQuestion - 1);
            }
        }
    };

    const handleNext = () => {
        const isContinue = validationQuestion();

        if (!isContinue) {
            return;
        }

        if (currentQuestion <= totalQuestions) {
            setCurrentQuestion(currentQuestion + 1);
            if (currentQuestion === totalQuestions) setShowConfirm(true);
        } else{
            setShowConfirm(true)
        }
    };

    const handleConfirm = () => {
        const isContinue = validationQuestion();

        if (!isContinue) {
            return;
        }

        confirmForm();
    };

    const validationQuestion = () => {
        let isContinue = true;

        if(questionSelected){

            if(questionSelected.response_type === InputResponseType.FILE){
                //PENDIENTE
            }
            else if(questionSelected.response_type === InputResponseType.CHECKBOX){

                if(questionSelected.is_required && !questionSelected.options?.some(option => option.is_response === true)){
                    isContinue = false;
                    toast.error('Por favor, debe seleccionar al menos una opción.');
                }
            }
            else if(questionSelected.response_type === InputResponseType.SELECT || questionSelected.response_type === InputResponseType.RADIOBUTTON){

                if(questionSelected.is_required && !questionSelected.options?.some(option => option.is_response === true)){
                    isContinue = false;
                    toast.error('Por favor, debe seleccionar una opción.');
                }
            
            }
            else if(questionSelected.response_type === InputResponseType.MULTIPLESELECT){

                if(questionSelected.is_required && !questionSelected.options?.some(option => option.is_response === true)){
                    isContinue = false;
                    toast.error('Por favor, debe seleccionar al menos una opción.');
                }
            
            }
            else if(questionSelected.response_type === InputResponseType.SWITCH){

                if(questionSelected.is_required && !questionSelected.text){
                    isContinue = false;
                    toast.error('Por favor, debe seleccionar al menos una opción.');
                }
            }
            else if (questionSelected.response_type === InputResponseType.DATE) {
                if (questionSelected.is_required && !questionSelected.text) {
                    isContinue = false;
                    toast.error('Por favor, debe seleccionar una fecha.');
                } else if (questionSelected.text && isNaN(Date.parse(questionSelected.text.toString()))) {
                    isContinue = false;
                    toast.error('Por favor, seleccione una fecha válida.');
                }
            }
            else if (questionSelected.response_type === InputResponseType.TIME) {
                if (questionSelected.is_required && !questionSelected.text) {
                    isContinue = false;
                    toast.error('Por favor, debe seleccionar una hora.');
                } else if (questionSelected.text && !/^\d{2}:\d{2}$/.test(questionSelected.text.toString())) {
                    isContinue = false;
                    toast.error('Por favor, seleccione una hora válida.');
                }
            }
            else if (questionSelected.response_type === InputResponseType.DATETIME) {
                if (questionSelected.is_required && !questionSelected.text) {
                    isContinue = false;
                    toast.error('Por favor, debe seleccionar una fecha y hora.');
                } else if (questionSelected.text && isNaN(Date.parse(questionSelected.text.toString()))) {
                    isContinue = false;
                    toast.error('Por favor, seleccione una fecha y hora válidas.');
                }
            }
            else if (questionSelected.response_type === InputResponseType.NUMBER) {
                
                if (questionSelected.is_required && !questionSelected.text) {
                    isContinue = false;
                    toast.error('Por favor, ingrese un número.');
                }
                else if (isNaN(Number(questionSelected.text))) {
                    isContinue = false;
                    toast.error('Por favor, ingrese un número válido.');
                }
            }
            else{
                //ACA VAN LOS TIPOS DE RESPUESTAS QUE SOBRAN, PERO FALTA VALIDAR LOS INPUTS DE TIPO FECHAS HORA
                if(questionSelected.is_required && !questionSelected.text){
                    isContinue = false;
                    toast.error('Por favor, debe seleccionar llenar el campo.');
                }
            }
        }
      
        return isContinue;
    };


    return (
        
        <div className="row justify-content-center align-items-center d-flex" style={{ padding: 0 }}>
            <div className='row mobile-wrapper'>
                <div className="col">
                    {currentQuestion > 1 && (
                        showResume ? (
                            <button
                                type="button"
                                className="btn btn-danger btn-rounded btn-lg w-100 text-uppercase"
                                onClick={handlePrevious}
                            >
                                CANCELAR
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-primary btn-rounded btn-lg w-100 text-uppercase"
                                onClick={handlePrevious}
                            >
                                Anterior
                            </button>
                        )
                    )}
                </div>
                <div className="col d-flex">
                    <span className="m-auto">
                        {currentQuestion <= totalQuestions 
                            ? currentQuestion 
                            : totalQuestions
                        } de {totalQuestions}
                    </span>
                </div>
                <div className="col">
                    {showConfirm ? (
                        showResume ? (
                            <button
                                type="button"
                                className="btn btn-success btn-rounded btn-lg w-100 text-uppercase"
                                onClick={storeForm}
                            >
                                FINALIZAR
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-success btn-rounded btn-lg w-100 text-uppercase"
                                onClick={handleConfirm}
                            >
                                CONFIRMAR
                            </button>
                        )
                    ) : (
                        <button
                            type="button"
                            className="btn btn-primary btn-rounded btn-lg w-100 text-uppercase"
                            onClick={handleNext}
                        >
                            Siguiente
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
    
    
    
}

export default NavQuestions
