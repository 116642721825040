
export interface Column {
    id?: number;
    column_name?: string;
    table_name?: string;
    alias?: string;
    table_url?: string;
}

export interface ColumnGroupDataForm {
    group?: number,
    columns: Column[]
}

export const defaultColumnGroupDataForm: ColumnGroupDataForm = {
    columns: [],
}

export interface UserColumnSetDataForm {
    user_id?: number,
    columns: Column[]
}

export const defaultUserColumnSetDataForm: UserColumnSetDataForm = {
    columns: [],
};