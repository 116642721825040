import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { IncidentDataForm, IncidentFilters } from '../../../app/models/Incident';
import IncidentDataTable from './tables/IncidentDataTable';
import IncidentCreate from './IncidentCreate';
import IncidentEdit from './IncidentEdit';
import useIncidentService from '../../../app/services/hooks/useIncidentService';
import { IncidentStatus } from '../../../app/models/IncidentStatus';
import IncidentShow from './IncidentShow';

interface Props {
    transportOrderId: number;
}

const IncidentContainer = ({ transportOrderId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllIncidentsByTransportOrder,
        deleteIncident,
        getUrlEvidenceOnIncident,
        showIncident,
        fetchingGetIncidentsByTransportOrder,
        fetchingDeleteIncident,
        fetchingShowIncident
    } = useIncidentService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingShow, setShowingShow] = useState(false);

    const [incidentIdEdit, setIncidentIdEdit] = useState<number>(-1);
    const [incidentIdShow, setIncidentIdShow] = useState<number>(-1);

    const [incidents, setIncidents] = useState<IncidentDataForm[]>([]);
    const [statuses, setStatuses] = useState<IncidentStatus[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<IncidentFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getAllIncidentsByTransportOrder(filter, transportOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setIncidents(response.data.incidents);
                setStatuses(response.data.statuses)
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (incidentId: number) => {
        setShowingEdit(true);
        setIncidentIdEdit(incidentId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setIncidentIdEdit(-1);
    };

    const showShow = (incidentId: number) => {
        setIncidentIdShow(incidentId);
        setShowingShow(true);
    };

    const hideShow = () => {
        setShowingShow(false);
        setIncidentIdShow(-1);
    };


    const destroy = (incidentId: number) => {
        const _text = 'Está a punto de eliminar el incidente #' + incidentId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando incidente...');
                        deleteIncident(incidentId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El incidente no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const downloadEvidence = (incidentId: number) => {
        if (showLoading) showLoading('download', 'Descargando archivo...');
        
        getUrlEvidenceOnIncident(incidentId, {
            onSuccess: (response: ServiceResponse) => {
                if (response.data && response.data.url_evidence) {
                    window.open(response.data.url_evidence);
                    if (changeAnimation)
                        changeAnimation('downloadSuccess', 'Archivo recuperado con éxito', true);
                } else {
                    if (hideLoading) hideLoading();
                    toast.error('No se encontró la URL del archivo.');
                }
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <h5>Incidentes</h5>
                    <div className="col-12 mb-2 mt-2">
                        <ButtonCreate title="Nuevo Incidente" callbackCreate={showCreate} />
                    </div>
                    <div className="col-12">
                        <IncidentDataTable
                            loading={fetchingGetIncidentsByTransportOrder}
                            statuses={statuses}
                            incidents={incidents}
                            totalRows={totalRows}
                            callbackSuccess={reloadTable}
                            edit={showEdit}
                            destroy={destroy}
                            filter={filter}
                            setFilter={setFilter}
                            downloadEvidence={downloadEvidence}
                            show={showShow}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Incidente"
                    backdrop={true}
                    showFooter={false}
                >
                    <IncidentCreate
                        transportOrderId={transportOrderId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Incidente"
                    backdrop={true}
                    showFooter={false}
                >
                    <IncidentEdit
                        incidentId={incidentIdEdit}
                        transportOrderId={transportOrderId}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}

            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title="Ver detalle del incidente"
                    backdrop={true}
                    showFooter={false}
                >
                    <IncidentShow
                        incidentId={incidentIdShow}
                        onError={() => {
                            hideShow();
                        }}
                        onCancel={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default IncidentContainer;
