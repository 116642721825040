import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import useTollRateService from '../../../app/services/hooks/useTollRateService';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import TollRateForm from './forms/TollRateForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';

import { Currency } from '../../../app/models/Currency';
import { VehicleType } from '../../../app/models/VehicleType';
import { TollRateDataForm, defaultTollRateDataForm } from '../../../app/models/TollRate';

interface Props {
    tollRateId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TollRateEdit = ({ tollRateId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditTollRate, editTollRate, fetchingUpdateTollRate, updateTollRate } =
        useTollRateService();

    const [tollRate, setTollRate] = useState<TollRateDataForm>(defaultTollRateDataForm);

    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando orden de transporte...');
        editTollRate(tollRateId, {
            onSuccess: (response: ServiceResponse) => {
                setTollRate(response.data.toll_rate);
                setCurrencies(response.data.currencies);
                setVehicleTypes(response.data.vehicle_types);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando tarifa de peaje...');
        updateTollRate(tollRateId, tollRate, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditTollRate ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TollRateForm
                        tollRateForm={tollRate}
                        errorFields={errorFields}
                        vehicleTypes={vehicleTypes}
                        currencies={currencies}
                        setTollRateForm={setTollRate}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateTollRate} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateTollRate} />
                </div>
            </div>
        </>
    );
};

export default TollRateEdit;
