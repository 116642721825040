import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents, ServiceEventsDocuments } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { MonthlyClosureDataForm, MonthlyClosureFilters } from '../../models/MonthlyClosure';
import { MonthlyClosureDetailFilters } from '../../models/MonthlyClosureDetail';

const useMonthlyClosureService = () => {

    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetMonthlyClosures, setFetchingGetMonthlyClosures] =
        useState(false);
    const [fetchingStoreMonthlyClosures, setFetchingStoreMonthlyClosures] =
        useState(false);
    const [fetchingEditMonthlyClosures, setFetchingEditMonthlyClosures] =
        useState(false);

    const getMonthlyClosures = (filter: MonthlyClosureFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.MONTHLY_CLOSURES.ALL_MONTHLY_CLOSURES}?${queryString}`;
    
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetMonthlyClosures
        });
    };

    const storeMonthlyClosure = (closure: MonthlyClosureDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.MONTHLY_CLOSURES.STORE_MONTHLY_CLOSURES,
            body: closure,
            setFetching: setFetchingStoreMonthlyClosures
        });
    };

    const editMonthlyClosure = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.MONTHLY_CLOSURES.EDIT_MONTHLY_CLOSURES.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingEditMonthlyClosures
        });
    };


    return {
        fetchingStoreMonthlyClosures,
        fetchingGetMonthlyClosures,
        fetchingEditMonthlyClosures,
        getMonthlyClosures,
        storeMonthlyClosure,
        editMonthlyClosure
    };
};

export default useMonthlyClosureService;
