export interface PaymentStatusLoad {
    id?: number | undefined;
    dispatch_program_id?: number;
    transportation_order_item_id?: number;
    blockStart?: number;
    progressPercentage?: number;
    all_load?: boolean;
    date_of_issue?: string;
}

export const defaultPaymentStatusLoad: PaymentStatusLoad = {
    id: undefined,
    blockStart: 0,
    progressPercentage: 0,
    all_load: false
};
