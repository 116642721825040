import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { Client, ClientFilters } from '../../models/Client';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useClientService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetClients, setFetchingGetClients] = useState(false);
    const [fetchingShowClient, setFetchingShowClient] = useState(false);
    const [fetchingStoreClient, setFetchingStoreClient] = useState(false);
    const [fetchingEditClient, setFetchingEditClient] = useState(false);
    const [fetchingCreateClient, setFetchingCreateClient] = useState(false);
    const [fetchingUpdateClient, setFetchingUpdateClient] = useState(false);
    const [fetchingDeleteClient, setFetchingDeleteClient] = useState(false);
    const [fetchingActiveClient, setFetchingActiveClient] = useState(false);

    const getClients = (clientFilters: ClientFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(clientFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CLIENTS.GET_CLIENTS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetClients
        });
    };

    const activeClient = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            body: { active: active },
            url: EndPoints.CLIENTS.ACTIVE_CLIENT.replace(':id', id.toString()),
            setFetching: setFetchingActiveClient
        });
    };

    const showClient = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CLIENTS.GET_CLIENT.replace(':id', id.toString()),
            setFetching: setFetchingShowClient
        });
    };

    const storeClient = (client: Client, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CLIENTS.STORE_CLIENT,
            body: client,
            setFetching: setFetchingStoreClient
        });
    };

    const editClient = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CLIENTS.EDIT_CLIENT.replace(':id', id.toString()),
            setFetching: setFetchingEditClient
        });
    };

    const createClient = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CLIENTS.CREATE_CLIENT,
            setFetching: setFetchingCreateClient
        });
    };

    const updateClient = (id: number, client: Client, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.CLIENTS.UPDATE_CLIENT.replace(':id', id.toString()),
            body: client,
            setFetching: setFetchingUpdateClient
        });
    };

    const deleteClient = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CLIENTS.DELETE_CLIENT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteClient
        });
    };

    return {
        fetchingGetClients,
        fetchingShowClient,
        fetchingStoreClient,
        fetchingEditClient,
        fetchingUpdateClient,
        fetchingDeleteClient,
        fetchingCreateClient,
        fetchingActiveClient,
        getClients,
        showClient,
        storeClient,
        createClient,
        editClient,
        updateClient,
        deleteClient,
        activeClient
    };
};

export default useClientService;
