import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { CheckingAccountDataForm } from '../../../app/models/CheckingAccount';
import CheckingAccountForm from './forms/CheckingAccountForm';
import { Bank } from '../../../app/models/Bank';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Carrier } from '../../../app/models/Carrier';
import { Currency } from '../../../app/models/Currency';
import { Expense } from '../../../app/models/Expense';
import { TransferReason } from '../../../app/models/TransferReason';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    checkingAccount: CheckingAccountDataForm;
    firstEditExpenses?: Expense[];
    firstEditCarrierId?: number | undefined;
    setCheckingAccount: (checkingAccount: CheckingAccountDataForm) => void;
    banks: Bank[];
    bankAccountTypes: BankAccountType[];
    transferReasons: TransferReason[];
    carriers: Carrier[];
    currencies: Currency[];
    expenses: Expense[];
    setExpenses: (expenses: Expense[]) => void;
    errorFields?: any;
    loading?: boolean;
    updated: boolean;
}

const CheckingAccountFormContainer = ({
    fetching,
    action,
    transferReasons,
    cancel,
    checkingAccount,
    setCheckingAccount,
    banks,
    bankAccountTypes,
    carriers,
    currencies,
    expenses,
    setExpenses,
    errorFields = null,
    loading,
    updated = false
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CheckingAccountForm
                        checkingAccountDataForm={checkingAccount}
                        setCheckingAccountDataForm={setCheckingAccount}
                        banks={banks}
                        transferReasons={transferReasons}
                        bankAccountTypes={bankAccountTypes}
                        carriers={carriers}
                        currencies={currencies}
                        expensesData={expenses}
                        setExpenses={setExpenses}
                        errorFields={errorFields}
                        loading={loading}
                        updated={updated}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default CheckingAccountFormContainer;
