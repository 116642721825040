import React, { useEffect } from 'react';
import { CarrierDataForm } from '../../../../app/models/Carrier';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useRut from '../../../../hooks/useRut';
import { User } from '../../../../app/models/User';
import { Society } from '../../../../app/models/Society';
import { Provider } from '../../../../app/models/Provider';
import { Brand } from '../../../../app/models/Brand';
import { VehicleModel } from '../../../../app/models/VehicleModel';
import { Truck, TruckDataForm } from '../../../../app/models/Truck';
import { WorkOrderDataForm } from '../../../../app/models/WorkOrder';

interface GeneralWorkOrderProps {
    workOrderForm: WorkOrderDataForm;
    societies: Society[];
    trucks: Truck[];
}

const WorkOrderShowForm = ({ workOrderForm, societies, trucks }: GeneralWorkOrderProps) => {
    if (!workOrderForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Sociedades</label>
                    <SelectTwo
                        name="society_id"
                        disabled={true}
                        id="society_id"
                        inputValue={workOrderForm.society_id}
                        options={SelectTwoMapperOptions(societies, 'id', ['name'])}
                        placeholder={'Seleccione Sociedad'}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tractos</label>
                    <SelectTwo
                        name="truck_id"
                        id="truck_id"
                        disabled={true}
                        inputValue={workOrderForm.truck_id}
                        options={SelectTwoMapperOptions(trucks, 'id', ['name'])}
                        placeholder={'Seleccione tracto'}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Periodo</label>
                    <input
                        name={'period'}
                        disabled={true}
                        id={`period`}
                        className={`form-control`}
                        type="month"
                        value={workOrderForm.period}
                    />
                </div>
            </div>
        </>
    );
};

export default WorkOrderShowForm;
