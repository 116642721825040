import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { TransferReason, TransferReasonFilters } from '../../models/TransferReason';

const useTransferReasonService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetTransferReasons, setFetchingGetTransferReasons] = useState(false);
    const [fetchingShowTransferReason, setFetchingShowTransferReason] = useState(false);
    const [fetchingStoreTransferReason, setFetchingStoreTransferReason] = useState(false);
    const [fetchingEditTransferReason, setFetchingEditTransferReason] = useState(false);
    const [fetchingUpdateTransferReason, setFetchingUpdateTransferReason] = useState(false);
    const [fetchingDeleteTransferReason, setFetchingDeleteTransferReason] = useState(false);
    const [fetchingActiveTransferReason, setFetchingActiveTransferReason] = useState(false);

    const getTransferReasons = (
        transferReasonFilters: TransferReasonFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(transferReasonFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TRANSFER_REASONS.GET_TRANSFER_REASONS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTransferReasons
        });
    };

    const showTransferReason = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSFER_REASONS.SHOW_TRANSFER_REASON.replace(':id', id.toString()),
            setFetching: setFetchingShowTransferReason
        });
    };

    const storeTransferReason = (transferReason: TransferReason, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TRANSFER_REASONS.STORE_TRANSFER_REASON,
            body: transferReason,
            setFetching: setFetchingStoreTransferReason
        });
    };

    const editTransferReason = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSFER_REASONS.EDIT_TRANSFER_REASON.replace(':id', id.toString()),
            setFetching: setFetchingEditTransferReason
        });
    };

    const updateTransferReason = (
        id: number,
        transferReason: TransferReason,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TRANSFER_REASONS.UPDATE_TRANSFER_REASON.replace(':id', id.toString()),
            body: transferReason,
            setFetching: setFetchingUpdateTransferReason
        });
    };

    const deleteTransferReason = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TRANSFER_REASONS.DELETE_TRANSFER_REASON.replace(':id', id.toString()),
            setFetching: setFetchingDeleteTransferReason
        });
    };

    const activeTransferReason = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TRANSFER_REASONS.ACTIVE_TRANSFER_REASON.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveTransferReason
        });
    };

    return {
        fetchingGetTransferReasons,
        fetchingShowTransferReason,
        fetchingStoreTransferReason,
        fetchingEditTransferReason,
        fetchingUpdateTransferReason,
        fetchingDeleteTransferReason,
        fetchingActiveTransferReason,
        getTransferReasons,
        showTransferReason,
        storeTransferReason,
        editTransferReason,
        updateTransferReason,
        deleteTransferReason,
        activeTransferReason
    };
};

export default useTransferReasonService;
