import React, { useEffect } from 'react';
import { QuestionDataForm } from '../../../../../app/models/Question';
import useHandleErrorFields from '../../../../../hooks/useHandleErrorFields';

type Props = {
    questionForm: QuestionDataForm;
    setQuestionForm: (question: QuestionDataForm) => void;
    errorFields?: any;
};

const FormTime = ({ questionForm, setQuestionForm, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();


    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setQuestionForm(
            { 
                ...questionForm, 
                [name]: value,
                text: value
            }
        );
    };


    return (
        <>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label className="col-form-label">Pregunta</label>
                    <input
                        className="form-control"
                        type="text"
                        name="question_text"
                        id="question_text"
                        value={questionForm.question_text}
                        readOnly={true}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <label className="col-form-label">Valor Inicial</label>
                    <input
                        className={`form-control ${fieldHasError('initial_value')}`}
                        type="time"
                        name="initial_value"
                        id="initial_value"
                        value={questionForm.initial_value?.toString()}
                        onChange={handleChange}
                        placeholder="Ingrese valor inicial"
                        onFocus={() => onFocusRemove('initial_value')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('initial_value')}
                    </div>
                </div>
            </div>
          
        </>
    );
};

export default FormTime;