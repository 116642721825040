import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';

import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { ExpenseType, ExpenseTypeFilters } from '../../app/models/ExpenseType';
import ExpenseTypeDataTable from './components/tables/ExpenseTypeDataTable';
import ExpenseTypeCreate from './components/ExpenseTypeCreate';
import ExpenseTypeEdit from './components/ExpenseTypeEdit';
import { useHistory } from 'react-router-dom';
import useExpenseTypeService from '../../app/services/hooks/useExpenseTypeService';
import { Tab, Tabs } from 'react-bootstrap';
import ExpenseTypesContainer from './components/ExpenseTypeContainer';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Tipos de gastos',
        url: '/expense-types',
        isActive: true
    }
];

const ExpenseTypes = () => {
    return (
        <>
            <Breadcrumbs pageSection="Tipos de gastos" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                {/* Tabs */}

                <Tabs defaultActiveKey="general-data" id="expense-type-tabs" className="mb-4">
                    <Tab eventKey="general-data" title="General">
                        {/* <ExpenseTypeDataTable
                        expenseTypes={expenseTypes}
                        edit={showEdit}
                        destroy={destroy}
                        loading={fetchingGetExpenseTypes}
                        filter={expenseTypeFilter}
                        setFilter={setExpenseTypeFilter}
                        totalRows={totalRows}
                        callbackSuccess={reloadTable}
                    /> */}
                        <ExpenseTypesContainer is_system={false} />
                    </Tab>
                    <Tab eventKey="in-system-data" title="En sistema">
                        <ExpenseTypesContainer is_system={true} />
                    </Tab>
                </Tabs>
            </DefaultCard>
        </>
    );
};

export default ExpenseTypes;
