import React, { useEffect } from 'react';
import { CarrierDataForm } from '../../../../app/models/Carrier';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useRut from '../../../../hooks/useRut';
import { User } from '../../../../app/models/User';
import { Society } from '../../../../app/models/Society';
import { Provider } from '../../../../app/models/Provider';
import { Brand } from '../../../../app/models/Brand';
import { VehicleModel } from '../../../../app/models/VehicleModel';
import { Truck, TruckDataForm } from '../../../../app/models/Truck';
import { WorkOrderDataForm } from '../../../../app/models/WorkOrder';
import { MonthlyClosureDetailDataForm } from '../../../../app/models/MonthlyClosureDetail';

interface Props {
    monthlyClosureDetailDataForm: MonthlyClosureDetailDataForm;
    setMonthlyClosureDetailDataForm: (monthlyClosureDetailDataForm: MonthlyClosureDetailDataForm) => void;
    societies: Society[];
    errorFields?: any;
}

const MonthlyClosureDetailForm = ({
    monthlyClosureDetailDataForm,
    setMonthlyClosureDetailDataForm,
    societies,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;

        setMonthlyClosureDetailDataForm({ ...monthlyClosureDetailDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setMonthlyClosureDetailDataForm({ ...monthlyClosureDetailDataForm, ...obj });
    };

    if (!monthlyClosureDetailDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Sociedades</label>
                    <SelectTwo
                        name="society_id"
                        id="society_id"
                        inputValue={monthlyClosureDetailDataForm.society_id}
                        options={SelectTwoMapperOptions(societies, 'id', ['business_name'])}
                        hasError={fieldHasError('society_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('society_id', value.value)}
                        placeholder={'Seleccione Sociedad'}
                        onFocus={() => onFocusRemove('society_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MonthlyClosureDetailForm;
