import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import DefaultCard from '../../../components/default/DefaultCard';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useMonthlyClosureService from '../../../app/services/hooks/useMonthlyClosureService';
import LazyLoading from '../../../components/LazyLoading';
import { defaultMonthlyClosureDataForm, MonthlyClosureDataForm } from '../../../app/models/MonthlyClosure';
import MonthlyClosureShowForm from '../components/forms/MonthlyClosureShowForm';
import MonthlyClosureDetailContainer from '../components/MonthlyClosureDetailContainer';


type RouterParams = {
    id: string;
};

const MonthlyClosureEdit = () => {

    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();
    
    const { 
        editMonthlyClosure,
        fetchingEditMonthlyClosures 
    } = useMonthlyClosureService()

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Detalle de cierre mensual',
            url: `/monthly-closures/${id}/edit`,
            isActive: true
        }
    ];
    
    const [monthlyClosure, setMonthlyClosure] = useState<MonthlyClosureDataForm>(defaultMonthlyClosureDataForm);
    
    useEffect(() => {
        editMonthlyClosure(Number(id), {
            onSuccess: (response: ServiceResponse) => {
                setMonthlyClosure(response.data.monthly_closure);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
            }
        });
    }, []);


    
    return (
        <>
            <Breadcrumbs pageSection="Detalle cierre mensual" breadcrumbs={breadcrumbs} />

            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditMonthlyClosures ? (
                            <>
                                <MonthlyClosureShowForm
                                    monthlyClosureDataForm={monthlyClosure}
                                />
                            </>
                        ) : (
                            // )
                            <LazyLoading height={300}/>
                        )}
                    </DefaultCard>
                </div>
            </div>
            {!fetchingEditMonthlyClosures ? (
                <>
                    <MonthlyClosureDetailContainer
                        monthlyClosureId={parseInt(id)}
                    />
                </>
            ) : null}
          
            
        </>
    )
}

export default MonthlyClosureEdit
