import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ProductDataForm } from '../../../app/models/Product';
import { Unit } from '../../../app/models/Unit';
import ProductForm from './forms/ProductForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    product: ProductDataForm;
    setProduct: (product: ProductDataForm) => void;
    units: Unit[];
    errorFields?: any;
}

const ProductFormContainer = ({
    fetching,
    action,
    cancel,
    product,
    setProduct,
    units,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ProductForm
                        productDataForm={product}
                        setProductDataForm={setProduct}
                        units={units}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ProductFormContainer;
