import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import useWorkOrderService from '../../../app/services/hooks/useWorkOrderService';
import { defaultWorkOrder, WorkOrderDataForm } from '../../../app/models/WorkOrder';
import { Society } from '../../../app/models/Society';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { Truck } from '../../../app/models/Truck';
import DefaultCard from '../../../components/default/DefaultCard';
import WorkOrderForm from '../components/forms/WorkOrderForm';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import TransportOrderContainer from '../components/TransportOrdersContainer';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import WorkOrderShowForm from '../components/forms/WorkOrderShowForm';

const WorkOrderEdit = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Ordenes de trabajo',
            url: '/work-orders',
            isActive: false
        },
        {
            name: 'Ver orden de trabajo #' + id,
            url: '/work-orders/' + id + '/show',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        editWorkOrder,
        fetchingEditWorkOrder,
        fetchingShowWorkOrder,
        fetchingUpdateWorkOrder,
        updateWorkOrder
    } = useWorkOrderService();

    const [workOrderForm, setWorkOrderForm] = useState<WorkOrderDataForm>(defaultWorkOrder);

    const [errorFields, setErrorFields] = useState<any>();

    const [societies, setSocieties] = useState<Society[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [workOrderStatuses, setWorkOrderStatuses] = useState<any[]>([]);

    useEffect(() => {
        reloadModule();
    }, [id]);

    const edit = (id: number) => {
        editWorkOrder(id, {
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setTrucks(response.data.trucks);
                setWorkOrderForm(response.data.work_order);
                setWorkOrderStatuses(response.data.work_order_statuses);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const reloadModule = () => {
        edit(parseInt(id));
    };

    const update = () => {
        if (workOrderForm != null) {
            if (showLoading) showLoading('loading', 'Actualizando...');
            updateWorkOrder(Number(workOrderForm?.id), workOrderForm, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    reloadModule();
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                    toast.error(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    return (
        <>
            <Breadcrumbs pageSection={`Ver Orden de trabajo #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditWorkOrder ? (
                            <>
                                <WorkOrderShowForm
                                    workOrderForm={workOrderForm}
                                    societies={societies}
                                    trucks={trucks}
                                />
                            </>
                        ) : (
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
            </div>

            {!fetchingEditWorkOrder && !fetchingShowWorkOrder ? (
                <>
                    <TransportOrderContainer
                        workOrderId={parseInt(id)}
                        truckId={Number(workOrderForm.truck_id)}
                        isShow={true}
                    />
                </>
            ) : null}
        </>
    );
};

export default WorkOrderEdit;
