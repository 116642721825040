import React, { useEffect } from 'react';
import { CarrierDataForm } from '../../../../app/models/Carrier';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useRut from '../../../../hooks/useRut';
import { User } from '../../../../app/models/User';
import { Society } from '../../../../app/models/Society';
import { Provider } from '../../../../app/models/Provider';
interface GeneralCarrierProps {
    carrierForm: CarrierDataForm;
    setCarrierForm: (carrier: CarrierDataForm) => void;
    users: User[];
    societies: Society[];
    providers: Provider[];
    errorFields?: any;
}

const CarrierForm = ({
    carrierForm,
    setCarrierForm,
    users,
    societies,
    providers,
    errorFields
}: GeneralCarrierProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();
    const [user, setUser] = React.useState<User>();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        setterUserData(Number(carrierForm.user_id));
    }, []);

    const handleCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name } = event.target;

        setCarrierForm({ ...carrierForm, is_internal: !carrierForm.is_internal });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if (name === 'user_id') {
            setterUserData(Number(value));
        }
        setCarrierForm({ ...carrierForm, ...obj });
    };

    if (!carrierForm) return null;

    const setterUserData = (user_id: number) => {
        const user = users.find((user) => user.id === user_id);

        if (user) {
            // user.first_name = user.first_name;
            // user.last_name = user.last_name;
            // user.email = user.email;
            // user.phone = user.phone ?? '';
            // user.phone_code = user.phone_code ?? '';
            // user.second_last_name = user.second_last_name ?? '';
            setUser(user);
        }
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="internal"
                            value="internal"
                            checked={carrierForm.is_internal}
                            id="it_internal"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="it_internal">
                            Interno
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="external"
                            value="external"
                            checked={!carrierForm.is_internal}
                            id="it_external"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="it_external">
                            Externo
                        </label>
                    </div>
                </div>
            </div>

            {carrierForm.is_internal ? (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Sociedades</label>
                        <SelectTwo
                            name="society_id"
                            id="society_id"
                            inputValue={carrierForm.society_id}
                            options={SelectTwoMapperOptions(societies, 'id', ['name'])}
                            hasError={fieldHasError('society_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('society_id', value.value)
                            }
                            placeholder={'Seleccione Sociedad'}
                            onFocus={() => onFocusRemove('society_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('society_id')}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Proveedores</label>
                        <SelectTwo
                            name="provider_id"
                            id="provider_id"
                            inputValue={carrierForm.provider_id}
                            options={SelectTwoMapperOptions(providers, 'id', ['name'])}
                            hasError={fieldHasError('provider_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('provider_id', value.value)
                            }
                            placeholder={'Seleccione Proveedor'}
                            onFocus={() => onFocusRemove('provider_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('provider_id')}
                        </div>
                    </div>
                </div>
            )}

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Usuario</label>
                    <SelectTwo
                        name="user_id"
                        id="user_id"
                        inputValue={carrierForm.user_id}
                        options={SelectTwoMapperOptions(users, 'id', ['name'])}
                        hasError={fieldHasError('user_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('user_id', value?.value)}
                        placeholder={'Seleccione Usuario'}
                        onFocus={() => onFocusRemove('user_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('user_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">RUT</label>
                    <input
                        name="rut"
                        id="rut"
                        type="text"
                        className="form-control"
                        disabled={true}
                        value={user?.rut}
                        onFocus={() => onFocusRemove('rut')}
                        placeholder="Ingrese el RUT"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('rut')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="first_name"
                        id="first_name"
                        type="text"
                        disabled={true}
                        className={`form-control ${fieldHasError('first_name')}`}
                        value={user?.first_name}
                        onFocus={() => onFocusRemove('first_name')}
                        placeholder="Ingrese el nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('first_name')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Email</label>

                    <input
                        name="email"
                        id="email"
                        type="email"
                        disabled={true}
                        className={`form-control ${fieldHasError('email')}`}
                        value={user?.email}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese el email"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-6">
                    <label className="col-form-label">Apellido Paterno</label>
                    <input
                        name="last_name"
                        id="last_name"
                        type="text"
                        disabled={true}
                        className={`form-control ${fieldHasError('last_name')}`}
                        value={user?.last_name}
                        onFocus={() => onFocusRemove('last_name')}
                        placeholder="Ingrese el apellido paterno"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('last_name')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Apellido Materno</label>

                    <input
                        name="second_last_name"
                        id="second_last_name"
                        type="text"
                        disabled={true}
                        className={`form-control ${fieldHasError('second_last_name')}`}
                        value={user?.second_last_name}
                        onFocus={() => onFocusRemove('second_last_name')}
                        placeholder="Ingrese el apellido materno"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('second_last_name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-12 ">
                    <label className=" col-form-label">Teléfono</label>
                    <div className="row">
                        <div className="col-auto">
                            <select
                                className="form-control form-control-custom pl-2"
                                id="phone_code"
                                name="phone_code"
                                disabled={true}
                                value={user?.phone_code}
                                onFocus={() => onFocusRemove('phone_code')}
                            >
                                <option defaultValue={'+56'} value="+56">
                                    +56
                                </option>
                            </select>
                        </div>

                        <div className="col">
                            <input
                                name="phone"
                                id="phone"
                                type="number"
                                disabled={true}
                                className="form-control"
                                value={user?.phone}
                                onFocus={() => onFocusRemove('phone')}
                                placeholder="Teléfono"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarrierForm;
