import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { CheckingAccountHistoryDataForm, CheckingAccountHistoryFilter } from '../../../app/models/CheckingAccountHistory';
import useCheckingAccountHistoryService from '../../../app/services/hooks/useCheckingAccountHistoryService';
import CheckingAccountHistoryDataTable from './tables/CheckingAccountHIstoryDataTable';

interface Props {
    checkingAccountId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    checkingAccountHistories: CheckingAccountHistoryDataForm[];
    totalRowsHistory: number;
    filterHistory: CheckingAccountHistoryFilter;
    setFilterHistory: (filter: CheckingAccountHistoryFilter) => void;
}

const CheckingAccountHistoryContainer = ({ 
    checkingAccountId,
     onSaved, 
     onCancel, 
     onError,
     checkingAccountHistories,
     totalRowsHistory,
     filterHistory,
     setFilterHistory }: Props) => {

    const { getCheckingAccountHistory, fetchingGetCheckingAccountHistory } = useCheckingAccountHistoryService();

    return (
        <DefaultCard>
            <div className="row">
                <div className="col-12">
                    <CheckingAccountHistoryDataTable
                        checkingAccountHistories={checkingAccountHistories}
                        loading={fetchingGetCheckingAccountHistory}
                        totalRows={totalRowsHistory}
                        filter={filterHistory}
                        setFilter={setFilterHistory}
                    />
                </div>
            </div>
        </DefaultCard>
    );
};

export default CheckingAccountHistoryContainer;
