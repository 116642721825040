import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import {
    SocietyDataForm,
    defaultSocietyDataForm,
    defaultSocietyForm
} from '../../../app/models/Society';
import useSocietyService from '../../../app/services/hooks/useSocietyService';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { User } from '../../../app/models/User';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../../components/default/DefaultCard';
import SocietyForm from './forms/SocietyForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { DocumentSet } from '../../../app/models/DocumentSet';
import GeneralDocumentsTransporterTable from '../../../components/GeneralDocumentsTransporterTable';
import useSocietyDocumentService from '../../../app/services/hooks/useSocietyDocumentService';
import DefaultModal from '../../../components/default/DefaultModal';
import SocietyDocumentCreate from './SocietyDocumentCreate';
import DocumentsDataTable from '../../../components/DocumentsDataTable';
import SocietyDocumentHistory from './SocietyDocumentHistory';
import { DocumentNameFilters } from '../../../app/models/Document';

type RouterParams = {
    id: string;
};

const SocietyEdit = () => {
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams<RouterParams>();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditSociety, editSociety, fetchingUpdateSociety, updateSociety } =
        useSocietyService();
    const { getSocietyDocumentsBySociety, fetchingGetSocietyDocuments } =
        useSocietyDocumentService();
    const [name, setName] = useState('');
    const [users, setUsers] = useState<User[]>([]);
    const [documentTypes, setDocumentTypes] = useState<any[]>([]);
    const [typeDocumentSelected, setTypeDocumentSelected] = useState<any>();
    const [showingCreateDocument, setShowingCreateDocument] = useState(false);
    const [showingHistoryDocument, setShowingHistoryDocument] = useState(false);
    const [documentSets, setDocumentSets] = useState<DocumentSet[]>([]);
    const [society, setSociety] = useState<SocietyDataForm>(defaultSocietyForm);
    const [errorFields, setErrorFields] = useState<any>();
    const [filter, setFilter] = useState<DocumentNameFilters>({
        document_name: ''
    })

    useEffect(() => {
        edit(parseInt(id));
    }, [id]);

    useEffect(() => {
        getDocuments(parseInt(id));
    }, []);

    const reloadTable = () => {
        getDocuments(parseInt(id));
    }

    const edit = (id: number) => {
        if (showLoading) showLoading('loading', 'Cargando sociedad...');
        editSociety(id, {
            onSuccess: (response: ServiceResponse) => {
                setName(response.data.society.business_name);
                setUsers(response.data.users);
                setDocumentSets(response.data.document_sets);
                setSociety(response.data.society);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                history.goBack();
            }
        });
        getDocuments(id);
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando sociedad...');
        updateSociety(Number(society?.id), society, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                setErrorFields(undefined);
                edit(parseInt(id));
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Sociedades',
            url: '/societies',
            isActive: false
        },
        {
            name: 'Editar #' + id,
            url: '/societies/' + id + '/edit',
            isActive: true
        }
    ];

    if (fetchingEditSociety) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    const getDocuments = (id: number) => {
        getSocietyDocumentsBySociety(id, filter, {
            onSuccess: (response: ServiceResponse) => {
                setDocumentTypes(response.data.society_documents);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const showCreateDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingCreateDocument(true);
    };

    const hideCreateDocument = () => {
        setShowingCreateDocument(false);
    };

    const showHistoryDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingHistoryDocument(true);
    };

    const hideHistoryDocument = () => {
        setShowingHistoryDocument(false);
    };

    return (
        <>
            <Breadcrumbs pageSection={`Editar - ${name}`} breadcrumbs={breadcrumbs} />

            {/* Tabs */}

            <Tabs defaultActiveKey="general-data" id="quote-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        <div className="col-md-6">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                                {society && (
                                    <SocietyForm
                                        societyForm={society}
                                        setSocietyForm={setSociety}
                                        users={users}
                                        isEdit={true}
                                        documentSets={documentSets}
                                        errorFields={errorFields}
                                        setErrorFields={setErrorFields}
                                    />
                                )}
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateSociety}
                                        />
                                    </div>
                                </div>
                            </DefaultCard>

                            {showingCreateDocument ? (
                                <DefaultModal
                                    show={showingCreateDocument}
                                    handleClose={hideCreateDocument}
                                    title="Agregar Documento"
                                    backdrop={true}
                                    showFooter={false}
                                >
                                    <SocietyDocumentCreate
                                        onSaved={() => {
                                            edit(parseInt(id));
                                            hideCreateDocument();
                                        }}
                                        typeDocument={typeDocumentSelected}
                                        societyId={Number(society?.id)}
                                        onCancel={hideCreateDocument}
                                        onError={hideCreateDocument}
                                    />
                                </DefaultModal>
                            ) : null}

                            {showingHistoryDocument ? (
                                <DefaultModal
                                    show={showingHistoryDocument}
                                    handleClose={hideHistoryDocument}
                                    title={`Historial de documentos de ${
                                        documentTypes?.find(
                                            (document_type: any) =>
                                                document_type.id === typeDocumentSelected
                                        ).document_name
                                    }`}
                                    backdrop={true}
                                    size={'lg'}
                                    showFooter={false}
                                >
                                    <SocietyDocumentHistory
                                        typeDocument={typeDocumentSelected}
                                        societyId={Number(society?.id)}
                                        onCancel={hideHistoryDocument}
                                        onError={hideHistoryDocument}
                                    />
                                </DefaultModal>
                            ) : null}
                        </div>
                        <>
                            {society && (
                                <div className="row">
                                    <div className="col-md-12">
                                        <DefaultCard>
                                            <div className="h3 mt-0 mb-2 card-title">
                                                Documentos
                                            </div>
                                            <DocumentsDataTable
                                                data={documentTypes}
                                                create={showCreateDocument}
                                                showHistory={showHistoryDocument}
                                                progressPending={fetchingGetSocietyDocuments}
                                                filter={filter}
                                                setFilter={setFilter}
                                                onSearch={reloadTable}
                                            />
                                        </DefaultCard>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </Tab>
            </Tabs>
        </>
    );
};

export default SocietyEdit;
