import React from 'react';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';
import { SurveyFilters } from '../../../../app/models/Survey';

type Props = {
    filter: SurveyFilters;
    setFilter: (filter: SurveyFilters) => void;
    onSearch: () => void;
};

const SurveyFilter = ({ filter, setFilter, onSearch }: Props) => {

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="title">
                            Título
                        </label>
                        <input
                            title="Título"
                            type="text"
                            id="title"
                            name="title"
                            className="form-control form-control-sm"
                            value={filter.title}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
             
                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SurveyFilter;
