import React from 'react'
import DefaultDataTable from '../../../../components/default/DefaultDataTable'
import { MonthlyClosureDataForm, MonthlyClosureFilters } from '../../../../app/models/MonthlyClosure';
import { formatDateToMonth } from '../../../../helpers';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import MonthlyClosureFilter from './MonthlyClosureFilter';

interface Props {
    monthlyClosures: MonthlyClosureDataForm[];
    totalRows: number;
    loading?: boolean;
    filter: MonthlyClosureFilters;
    setFilter: (filter: MonthlyClosureFilters) => void;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    onSearch: () => void;
}


const MonthlyClosureDataTable = ({
    monthlyClosures,
    totalRows,
    loading,
    filter,
    setFilter,
    edit,
    show,
    destroy,
    onSearch
}: Props) => {

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: MonthlyClosureDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Mes',
            selector: (row: any) => row.closing_date,
            sortable: true,
            sortField: 'closing_date',
            cell: (row: MonthlyClosureDataForm, index: any, column: any) => 
            <span className="">{formatDateToMonth(row.closing_date)}</span>
        },
        {
            name: 'created_user',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: MonthlyClosureDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.created_user ? row.created_user : '' }}
                ></span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            omit: !show && !edit && !destroy,
            cell: (row: MonthlyClosureDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ]
    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <MonthlyClosureFilter
                        filter={filter} 
                        setFilter={setFilter} 
                        onSearch={onSearch}
                    />
                </div>
            </div>
            <DefaultDataTable
                columns={columns}
                data={monthlyClosures}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    )
}

export default MonthlyClosureDataTable
