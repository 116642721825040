import React from 'react';
import { IconProps } from '../../types';

export const WorkOrder: React.FC<IconProps> = ({ size = 32, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Icons"
            width={size}
            height={size}
            viewBox="0 0 100 100"
            fill="currentColor"
            enableBackground="new 0 0 100 100"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <path d="M39,32h22c1.1,0,2-0.9,2-2v-4c0-3.3-2.7-6-6-6H43c-3.3,0-6,2.7-6,6v4C37,31.1,37.9,32,39,32z" />
                </g>
                <path d="M72,25h-2c-0.6,0-1,0.4-1,1v4c0,4.4-3.6,8-8,8H39c-4.4,0-8-3.6-8-8v-4c0-0.6-0.4-1-1-1h-2c-3.3,0-6,2.7-6,6 v43c0,3.3,2.7,6,6,6h44c3.3,0,6-2.7,6-6V31C78,27.7,75.3,25,72,25z M66.8,49L47.6,68.2c-0.5,0.5-1.1,0.8-1.8,0.8 c-0.7,0-1.4-0.3-1.9-0.8L33.2,57.5c-0.5-0.5-0.5-1.1,0-1.6l2.1-2.1c0.5-0.5,1.1-0.5,1.6,0l8.8,8.8l17.3-17.3c0.5-0.5,1.1-0.5,1.6,0 l2.1,2.1C67.2,47.9,67.2,48.6,66.8,49z" />
            </g>
        </svg>
    );
};
