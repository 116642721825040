import useFetch from '../../../hooks/useFetch';
import { useContext, useState } from 'react';
import { IncidentReasonDataForm, IncidentReasonFilters } from '../../models/IncidentReason';
import { ServiceEvents } from '../shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';

import { EndPoints } from '../EndPoints';

const useIncidentReasonService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [fetchingCreateIncidentReason, setFetchingCreateIncidentReason] = useState(false);
    const [fetchingStoreIncidentReason, setFetchingStoreIncidentReason] = useState(false);
    const [fetchingEditIncidentReason, setFetchingEditIncidentReason] = useState(false);
    const [fetchingUpdateIncidentReason, setFetchingUpdateIncidentReason] = useState(false);
    const [fetchingDeleteIncidentReason, setFetchingDeleteIncidentReason] = useState(false);
    const [fetchingGetIncidentReasons, setFetchingGetIncidentReasons] = useState(false);
    const [fetchingActiveIncidentReason, setFetchingActiveIncidentReason] = useState(false);


    const getAllIncidentReasons = (
            filter: IncidentReasonFilters,
            events: ServiceEvents = {}
        ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
    
        const url = `${EndPoints.INCIDENT_REASONS.GET_INCIDENT_REASONS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetIncidentReasons
        });
    };


    const createIncidentReason = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INCIDENT_REASONS.CREATE_INCIDENT_REASON,
            setFetching: setFetchingCreateIncidentReason
        });
    };

    const storeIncidentReason = (incident: IncidentReasonDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.INCIDENT_REASONS.STORE_INCIDENT_REASON,
            body: incident,
            setFetching: setFetchingStoreIncidentReason
        });
    };

    const editIncidentReason = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INCIDENT_REASONS.EDIT_INCIDENT_REASON.replace(':id', id.toString()),
            setFetching: setFetchingEditIncidentReason
        });
    };

    const updateIncidentReason = (id: number, incident: IncidentReasonDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.INCIDENT_REASONS.UPDATE_INCIDENT_REASON.replace(':id', id.toString()),
            body: incident,
            setFetching: setFetchingUpdateIncidentReason
        });
    };

    const deleteIncidentReason = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.INCIDENT_REASONS.DELETE_INCIDENT_REASON.replace(':id', id.toString()),
            setFetching: setFetchingDeleteIncidentReason
        });
    };


    const activeIncidentReason = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.INCIDENT_REASONS.ACTIVE_INCIDENT_REASON.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveIncidentReason
        });
    };



    return {
        fetchingCreateIncidentReason,
        fetchingStoreIncidentReason,
        fetchingEditIncidentReason,
        fetchingUpdateIncidentReason,
        fetchingDeleteIncidentReason,
        fetchingGetIncidentReasons,
        fetchingActiveIncidentReason,
        getAllIncidentReasons,
        createIncidentReason,
        storeIncidentReason,
        editIncidentReason,
        updateIncidentReason,
        deleteIncidentReason,
        activeIncidentReason
    };
};

export default useIncidentReasonService;
