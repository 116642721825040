import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useMonthlyClosureDetailHistoryService from '../../../app/services/hooks/useMonthlyClosureDetailHistoryService';
import { MonthlyClosure } from '../../../app/models/MonthlyClosure';
import { MonthlyClosureDetailHistory, MonthlyClosureDetailHistoryFilters } from '../../../app/models/MonthlyClosureDetailHistory';
import MonthlyClosureDetailHistoryDataTable from './tables/MonthlyClosureDetailHistoryDataTable';


interface Props {
    monthlyClosureDetailId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const MonthlyClosureDetailHistoryContainer = ({ monthlyClosureDetailId, onSaved, onCancel, onError }: Props) => {

    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { 
        getMonthlyClosureDetailHistoriesByMonthlyClosureDetail, 
        fetchingGetMonthlyClosureDetailHistoriesByMonthlyClosureDetail 
    } = useMonthlyClosureDetailHistoryService();

    const { navigationPage } = useNavigationPage();

    const [histories, setHistories] = useState<MonthlyClosureDetailHistory[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [historyIdEdit, setHistoryIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);

    const [filter, setFilter] = useState<MonthlyClosureDetailHistoryFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const showEdit = (historyId: number) => {
        setShowingEdit(true);
        setHistoryIdEdit(historyId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setHistoryIdEdit(-1);
    };

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getMonthlyClosureDetailHistoriesByMonthlyClosureDetail(monthlyClosureDetailId, filter, {
            onSuccess: (response: ServiceResponse) => {
                setHistories(response.data.monthly_closure_details_histories);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    if (onError) onError();
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <MonthlyClosureDetailHistoryDataTable
                            monthlyClosureDetailHistories={histories}
                            edit={showEdit}
                            loading={fetchingGetMonthlyClosureDetailHistoriesByMonthlyClosureDetail}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </div>
                </div>
            </DefaultCard>

            {/* {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Ver información"
                    backdrop={true}
                    showFooter={false}
                    centered={true}
                >
                    <MonthlyClosureDetailHistoryShow
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                        historyId={historyIdEdit}
                    />
                </DefaultModal>
            ) : null} */}
        </>
    );
};

export default MonthlyClosureDetailHistoryContainer;
