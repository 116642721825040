import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { AuthContext } from '../../../contexts/AuthContext';
import MonthlyClosureDetailDataTable from './tables/MonthlyClosureDetailDataTable';
import { MonthlyClosureDetailDataForm, MonthlyClosureDetailFilters } from '../../../app/models/MonthlyClosureDetail';
import useMonthlyClosureDetailService from '../../../app/services/hooks/useMonthlyClosureDetailService';
import MonthlyClosureDetailHistoryContainer from './MonthlyClosureDetailHistoryContainer';
import MonthlyClosureDetailCreate from './MonthlyClosureDetailCreate';

interface Props {
    monthlyClosureId: number;
    isShow?: boolean;
}

const MonthlyClosureDetailContainer = ({ monthlyClosureId, isShow = false }: Props) => {
    const { auth } = useContext(AuthContext);
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const { 
        getMonthlyClosureDetailsByClosure,
        fetchingGetMonthlyClosureDetailsByClosure
    } = useMonthlyClosureDetailService();
    
    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);

    const [monthlyClosureDetails, setMonthlyClosureDetails] = useState<MonthlyClosureDetailDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [statuses, setStatuses] = useState<any[]>([]);

    const [showingGetHistories, setShowingGetHistories] = useState(false);
    const [historyIdGet, setHistoryIdGet] = useState<number>(-1);

    const [monthlyClosureDetailFilter, setMonthlyClosureDetailFilter] = useState<MonthlyClosureDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [
        monthlyClosureDetailFilter.page, 
        monthlyClosureDetailFilter.per_page, 
        monthlyClosureDetailFilter.sort, 
        monthlyClosureDetailFilter.order
    ]);

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const getAllmonthlyClosureDetails = () => {
        getMonthlyClosureDetailsByClosure(monthlyClosureId, monthlyClosureDetailFilter, {
            onSuccess: (response: ServiceResponse) => {
                setMonthlyClosureDetails(response.data.monthly_closure_details);
                setTotalRows(response.data.total_rows);
                setStatuses(response.data.statuses);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
            }
        });
    };

    const reloadTable = () => {
        getAllmonthlyClosureDetails();
    };

    const showGetHistories = (ExpenseId: number) => {
        setShowingGetHistories(true);
        setHistoryIdGet(ExpenseId);
    };

    const hideGetHistories = () => {
        setShowingGetHistories(false);
        setHistoryIdGet(-1);
    };

    

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12 mb-2">
                        {!isShow && (
                            <ButtonCreate
                                title="Nueva sociedad"
                                callbackCreate={showCreate}
                            />
                        )}
                    </div>
                    <div className="col-12">
                        {isShow ? (
                            <MonthlyClosureDetailDataTable
                                monthlyClosureDetails={monthlyClosureDetails} 
                                totalRows={totalRows} 
                                loading={fetchingGetMonthlyClosureDetailsByClosure}
                                filter={monthlyClosureDetailFilter} 
                                setFilter={setMonthlyClosureDetailFilter} 
                                onSearch={reloadTable}
                                statuses={statuses}
                                showHistory={showGetHistories}
                                callbackSuccess={reloadTable}
                                isShow={isShow}
                            />
                        ) : (
                            <MonthlyClosureDetailDataTable
                                monthlyClosureDetails={monthlyClosureDetails} 
                                totalRows={totalRows} 
                                loading={fetchingGetMonthlyClosureDetailsByClosure}
                                filter={monthlyClosureDetailFilter} 
                                setFilter={setMonthlyClosureDetailFilter} 
                                onSearch={reloadTable}
                                statuses={statuses}
                                showHistory={showGetHistories}
                                callbackSuccess={reloadTable}
                            />
                        )}
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Agregar sociedad"
                    backdrop={true}
                    showFooter={false}
                >
                    <MonthlyClosureDetailCreate
                        monthlyClosureId={monthlyClosureId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingGetHistories ? (
                <DefaultModal
                    show={showingGetHistories}
                    handleClose={hideGetHistories}
                    title="Historial estados"
                    backdrop={true}
                    showFooter={false}
                    size="lg"
                >
                    <MonthlyClosureDetailHistoryContainer
                        monthlyClosureDetailId={historyIdGet}
                        onCancel={hideGetHistories}
                        onError={hideGetHistories}
                    />
                </DefaultModal>
            ) : null}

        </>
    );
};

export default MonthlyClosureDetailContainer;