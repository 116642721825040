import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';

import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { VehicleModelDataForm, VehicleModelFilters } from '../../models/VehicleModel';

const useVehicleModelService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetVehicleModels, setFetchingGetVehicleModels] = useState(false);
    const [fetchingShowVehicleModel, setFetchingShowVehicleModel] = useState(false);
    const [fetchingCreateVehicleModel, setFetchingCreateVehicleModel] = useState(false);
    const [fetchingStoreVehicleModel, setFetchingStoreVehicleModel] = useState(false);
    const [fetchingEditVehicleModel, setFetchingEditVehicleModel] = useState(false);
    const [fetchingUpdateVehicleModel, setFetchingUpdateVehicleModel] = useState(false);
    const [fetchingDeleteVehicleModel, setFetchingDeleteVehicleModel] = useState(false);
    const [fetchingActiveVehicleModel, setFetchingActiveVehicleModel] = useState(false);

    const getVehicleModels = (
        vehicleModelFilter: VehicleModelFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(vehicleModelFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.VEHICLE_MODELS.GET_VEHICLE_MODELS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetVehicleModels
        });
    };

    const createVehicleModel = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.VEHICLE_MODELS.CREATE_VEHICLE_MODEL,
            setFetching: setFetchingCreateVehicleModel
        });
    };

    const showVehicleModel = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.VEHICLE_MODELS.SHOW_VEHICLE_MODEL.replace(':id', id.toString()),
            setFetching: setFetchingShowVehicleModel
        });
    };

    const storeVehicleModel = (vehicleModel: VehicleModelDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.VEHICLE_MODELS.STORE_VEHICLE_MODEL,
            body: vehicleModel,
            setFetching: setFetchingStoreVehicleModel
        });
    };

    const editVehicleModel = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.VEHICLE_MODELS.EDIT_VEHICLE_MODEL.replace(':id', id.toString()),
            setFetching: setFetchingEditVehicleModel
        });
    };

    const updateVehicleModel = (
        id: number,
        vehicleModel: VehicleModelDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.VEHICLE_MODELS.UPDATE_VEHICLE_MODEL.replace(':id', id.toString()),
            body: vehicleModel,
            setFetching: setFetchingUpdateVehicleModel
        });
    };

    const deleteVehicleModel = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.VEHICLE_MODELS.DELETE_VEHICLE_MODEL.replace(':id', id.toString()),
            setFetching: setFetchingDeleteVehicleModel
        });
    };

    const activeVehicleModel = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.VEHICLE_MODELS.ACTIVE_VEHICLE_MODEL.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveVehicleModel
        });
    };

    return {
        fetchingGetVehicleModels,
        fetchingCreateVehicleModel,
        fetchingShowVehicleModel,
        fetchingStoreVehicleModel,
        fetchingEditVehicleModel,
        fetchingUpdateVehicleModel,
        fetchingDeleteVehicleModel,
        fetchingActiveVehicleModel,
        getVehicleModels,
        createVehicleModel,
        showVehicleModel,
        storeVehicleModel,
        editVehicleModel,
        updateVehicleModel,
        deleteVehicleModel,
        activeVehicleModel
    };
};

export default useVehicleModelService;
