import React from 'react';
import { TransportOrderFilters } from '../../../app/models/TransportOrder';
import { useScreenDetector } from '../../../hooks/useScreenDetector';
import MonthYearPicker from '../../../components/inputs/MonthYearPicker';
import { momentParseDate } from '../../../helpers';

type Props = {
    filter: TransportOrderFilters;
    setFilter: (filter: TransportOrderFilters) => void;
};

const TransportOrderChartFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };
    const { isMobile, isTablet, isDesktop } = useScreenDetector();

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="program_date_from">
                            Fecha de programa desde
                        </label>
                        <input
                            title="Fecha de programa desde"
                            type="date"
                            id="program_date_from"
                            name="program_date_from"
                            className="form-control form-control-sm"
                            value={filter.program_date_from ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="program_date_until">
                            Fecha de programa hasta
                        </label>
                        <input
                            title="Fecha de programa hasta"
                            type="date"
                            id="program_date_until"
                            name="program_date_until"
                            className="form-control form-control-sm"
                            value={filter.program_date_until ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransportOrderChartFilter;
