import { User } from "./User";

export interface MonthlyClosure {
    id?: number;
    closing_date?: string;
    created_user?: User;
    created_user_id?: number;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface MonthlyClosureDataForm {
    id?: number;
    closing_date?: string;
    created_user?: string;
    created_user_id?: number;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}


export interface MonthlyClosureFilters {
    id?: number | null;
    closing_date?: string | '';
    created_user?: string | '';
    period_from?: string | '';
    period_until?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultMonthlyClosureDataForm: MonthlyClosureDataForm = {
    closing_date: ''
};