import React from 'react';
import DefaultSpinnerLottie from '../../../components/default/DefaultSpinnerLottie';

import { LoadingData } from '../../../contexts/Shared/interfaces';

interface Props {
    loadingData: LoadingData;
    hideLoading: () => void;
}
const FullScreenLoading = ({ loadingData, hideLoading }: Props) => {
    return (
        <div className="loading-all">
            <div className="m-auto px-3 text-center w-75">
                <div className="text-primary" role="status">
                    {/* <span className="sr-only">Loading...</span> */}
                    <div className="spinner-container">
                        <div className="spinner-body">
                            <div className="spinner-loading">
                                <DefaultSpinnerLottie
                                    loading={true}
                                    loadingData={loadingData}
                                    hideLoading={hideLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <p className="pt-3">{text ? text : 'Cargando...'}</p> */}
            </div>
        </div>
    );
};

export default FullScreenLoading;
