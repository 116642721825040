import {
    HIDE_LOADING,
    HIDE_MAIN_MENU,
    SHOW_LOADING,
    SHOW_MAIN_MENU,
    CHANGE_ANIMATION,
    CHANGE_MAIN_MENU,
    CHANGE_EVENT_CLICK
} from './types';
import { AppContextStateInterface } from './interfaces';
import { ReducerActionInterface } from '../Shared/interfaces';

const reducerApp = (state: AppContextStateInterface, action: ReducerActionInterface) => {
    switch (action.type) {
        case SHOW_LOADING:
            return {
                ...state,
                showingLoading: true,
                loadingData: {
                    nameLoading: action.payload.name,
                    messageLoading: action.payload.message,
                    finishAnimation: false
                }
            };
        case CHANGE_ANIMATION:
            return {
                ...state,
                loadingData: {
                    nameLoading: action.payload.name,
                    messageLoading: action.payload.message,
                    finishAnimation: action.payload.finishAnimation
                }
            };

        case CHANGE_EVENT_CLICK:
            return {
                ...state,
                eventClick: action.payload.eventClick
            };
        case HIDE_LOADING:
            return {
                ...state,
                showingLoading: false,
                loadingData: {
                    nameLoading: '',
                    messageLoading: 'Cargando...',
                    finishAnimation: true
                }
            };
        case SHOW_MAIN_MENU:
            return {
                ...state,
                showingMainMenu: true
            };
        case HIDE_MAIN_MENU:
            return {
                ...state,
                showingMainMenu: false
            };
        case CHANGE_MAIN_MENU:
            return {
                ...state,
                showingMainMenu: !state.showingMainMenu
            };

        default:
            return state;
    }
};
export default reducerApp;
