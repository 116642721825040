import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { ColumnGroupDataForm, UserColumnSetDataForm } from '../../models/Column';

const useColumnService = () => {

    const { doGet, doPost } = useFetch();

    const [fetchingGetColumnsByTable, setFetchingGetColumnsByTable] = useState(false);
    const [fetchingGetColumnsByTableAndRole, setFetchingGetColumnsByTableAndRole] = useState(false);
    const [fetchingStoreColumnGroup, setFetchingStoreColumnGroup] = useState(false);
    const [fetchingCreateColumnGroup, setFetchingColumnGroup] = useState(false);
    const [fetchingGetColumnsByTableAndUser, setFetchingGetColumnsByTableAndUser] = useState(false);
    const [fetchingStoreUserColumnSet, setFetchingStoreUserColumnSet] = useState(false);
    const [fetchingStoreColumnSet, setFetchingStoreColumnSet] = useState(false);
    const [fetchingCreateUserColumnSet, setFetchingCreateUserColumnSet] = useState(false);
    const [fetchingEditColumnSetByTableAndUser, setFetchingEditColumnSetByTableAndUser] = useState(false);

    const getColumnsByTable = (tableName: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COLUMNS.GET_COLUMNS_BY_TABLE.replace(':table-name', tableName),
            setFetching: setFetchingGetColumnsByTable
        })
    };

    const createColumnGroup = (tableName: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COLUMNS.CREATE_COLUMN_GROUP.replace(':table-name', tableName),
            setFetching: setFetchingColumnGroup
        });
    };

    const storeColumnGroup = (columnGroup: ColumnGroupDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.COLUMNS.STORE_COLUMN_GROUP,
            body: columnGroup,
            setFetching: setFetchingStoreColumnGroup
        });
    };

    const getColumnsByTableAndRole = (tableName: string, rolId: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COLUMNS.GET_COLUMNS_BY_TABLE_AND_ROLE.replace(':table-name', tableName).replace(':rol-id', rolId.toString()),
            setFetching: setFetchingGetColumnsByTableAndRole
        })
    };

    const createUserColumnSet = (tableName: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COLUMNS.CREATE_USER_COLUMN_SET.replace(':table-name', tableName),
            setFetching: setFetchingCreateUserColumnSet
        });
    };

    const storeUserColumnSet = (userColumnSet: UserColumnSetDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.COLUMNS.STORE_USER_COLUMN_SET,
            body: userColumnSet,
            setFetching: setFetchingStoreUserColumnSet
        });
    };

    const getColumnsByTableAndUser = (tableName: string, userId: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COLUMNS.GET_COLUMNS_BY_TABLE_AND_USER.replace(':table-name', tableName).replace(':user-id', userId.toString()),
            setFetching: setFetchingGetColumnsByTableAndUser
        })
    };

    const editColumnSetByTableAndUser = (tableName: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COLUMNS.EDIT_COLUMN_SET_BY_TABLE_AND_USER.replace(':table-name', tableName),
            setFetching: setFetchingEditColumnSetByTableAndUser
        })
    };

    const storeColumnSet = (userColumnSet: UserColumnSetDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.COLUMNS.STORE_COLUMN_SET,
            body: userColumnSet,
            setFetching: setFetchingStoreColumnSet
        });
    };

    return {
        fetchingGetColumnsByTable,
        fetchingGetColumnsByTableAndRole,
        fetchingStoreColumnGroup,
        fetchingCreateColumnGroup,
        fetchingGetColumnsByTableAndUser,
        fetchingStoreUserColumnSet,
        fetchingStoreColumnSet,
        fetchingCreateUserColumnSet,
        fetchingEditColumnSetByTableAndUser,
        getColumnsByTable,
        getColumnsByTableAndRole,
        storeColumnGroup,
        createColumnGroup,
        createUserColumnSet,
        storeUserColumnSet,
        storeColumnSet,
        getColumnsByTableAndUser,
        editColumnSetByTableAndUser
    };
};

export default useColumnService;

