import React, { createContext, useEffect, useReducer } from 'react';
import { AuthContextStateInterface } from './interfaces';
import reducerAuth from './reducer';
import { LOADING, LOGIN, LOGOUT, UPDATE_AUTH, UPDATE_AUTH_TOKEN } from './types';
import { LOCAL_STORAGE } from '../../config';
import { Auth } from '../../app/models/Auth';

const initialState: AuthContextStateInterface = {
    auth: null,
    authToken: null,
    logged: undefined,
    loading: true
};

export const AuthContext = createContext<AuthContextStateInterface>(initialState);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducerAuth, initialState);

    useEffect(() => {
        const localStoreAuth = localStorage.getItem(LOCAL_STORAGE.AUTH);
        const localStoreAuthToken = localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN);

        if (localStoreAuth && localStoreAuthToken) {
            dispatch({
                type: LOGIN,
                payload: localStoreAuth ? JSON.parse(localStoreAuth) : null
            });
        }

        dispatch({
            type: LOADING,
            payload: false
        });
    }, []);

    const login = (auth: Auth) => {
        if (auth) {
            localStorage.setItem(LOCAL_STORAGE.AUTH, JSON.stringify(auth));
            localStorage.setItem(LOCAL_STORAGE.AUTH_TOKEN, auth.authToken ?? '');

            dispatch({
                type: LOGIN,
                payload: auth
            });
        }
    };

    const updateAuth = (auth: Auth): void => {
        localStorage.setItem(LOCAL_STORAGE.AUTH, JSON.stringify(auth));
        dispatch({
            type: UPDATE_AUTH,
            payload: auth
        });
    };

    const logout = (): void => {
        localStorage.removeItem(LOCAL_STORAGE.AUTH);
        localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN);
        dispatch({
            type: LOGOUT
        });
        window.location.href = '/login';
    };

    const updateAuthToken = (authToken: string): void => {
        localStorage.setItem(LOCAL_STORAGE.AUTH_TOKEN, authToken);
        dispatch({
            type: UPDATE_AUTH_TOKEN,
            payload: authToken
        });
    };

    return (
        <AuthContext.Provider
            value={{
                auth: state.auth,
                authToken: state.authToken,
                logged: state.logged,
                loading: state.loading,
                login,
                logout,
                updateAuth,
                updateAuthToken
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
