import React from 'react';
import { IconProps } from '../types';

export const Pruchase: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Capa_1"
            width={size}
            height={size}
            viewBox="0 0 28.968 28.969"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <path d="M22.943,12.601c-2.426-0.913-3.426-1.513-3.426-2.454c0-0.8,0.602-1.599,2.455-1.599c2.057,0,3.367,0.656,4.11,0.972 l0.827-3.227c-0.941-0.456-2.228-0.855-4.139-0.941V2.841h-2.798v2.71c-1.079,0.212-1.989,0.603-2.728,1.121V1.5 c0-0.829-0.67-1.5-1.5-1.5C7.759,0,1.261,6.498,1.261,14.484s6.498,14.484,14.484,14.484c0.83,0,1.5-0.67,1.5-1.5v-4.426 c0.802,0.195,1.687,0.334,2.586,0.375v2.709h2.796v-2.91c3.28-0.57,5.08-2.738,5.08-5.277C27.709,15.369,26.34,13.8,22.943,12.601z M11.242,3.921c-2.045,2.366-3.345,5.576-3.64,9.064H4.371C4.908,8.904,7.584,5.487,11.242,3.921z M4.373,15.984h3.231 c0.294,3.488,1.594,6.699,3.639,9.064C7.583,23.482,4.908,20.066,4.373,15.984z M14.248,23.871 c-2.011-1.854-3.329-4.706-3.647-7.887h3.647V23.871z M14.248,12.985H10.6c0.318-3.18,1.637-6.032,3.647-7.886V12.985z M20.574,20.107c-1.217,0-2.346-0.271-3.325-0.633v-5.052c0.827,0.571,1.856,1.035,3.042,1.433 c2.111,0.713,3.023,1.398,3.023,2.482C23.314,19.479,22.201,20.107,20.574,20.107z" />
                </g>
            </g>
        </svg>
    );
};
