import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { Worker, WorkerFilters } from '../../models/Workers';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useWorkerService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetWorkers, setFetchingGetWorkers] = useState(false);
    const [fetchingShowWorker, setFetchingShowWorker] = useState(false);
    const [fetchingStoreWorker, setFetchingStoreWorker] = useState(false);
    const [fetchingEditWorker, setFetchingEditWorker] = useState(false);
    const [fetchingCreateWorker, setFetchingCreateWorker] = useState(false);
    const [fetchingUpdateWorker, setFetchingUpdateWorker] = useState(false);
    const [fetchingDeleteWorker, setFetchingDeleteWorker] = useState(false);
    const [fetchingActiveWorker, setFetchingActiveWorker] = useState(false);
    const [fetchingFindUser, setFetchingFindUser] = useState(false);
    const [fetchingGetWorkerCalendar, setFetchingGetWorkerCalendar] = useState(false);

    const getWorkers = (workerFilters: WorkerFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(workerFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.WORKERS.GET_WORKERS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetWorkers
        });
    };

    const activeWorker = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            body: { active: active },
            url: EndPoints.WORKERS.ACTIVE_WORKER.replace(':id', id.toString()),
            setFetching: setFetchingActiveWorker
        });
    };

    const showWorker = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.WORKERS.SHOW_WORKER.replace(':id', id.toString()),
            setFetching: setFetchingShowWorker
        });
    };

    const storeWorker = (worker: Worker, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            requestType: 'multipart',
            url: EndPoints.WORKERS.STORE_WORKER,
            body: worker,
            setFetching: setFetchingStoreWorker
        });
    };

    const editWorker = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.WORKERS.EDIT_WORKER.replace(':id', id.toString()),
            setFetching: setFetchingEditWorker
        });
    };

    const createWorker = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.WORKERS.CREATE_WORKER,
            setFetching: setFetchingCreateWorker
        });
    };

    const updateWorker = (id: number, worker: Worker, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            requestType: 'multipart',
            url: EndPoints.WORKERS.UPDATE_WORKER.replace(':id', id.toString()),
            body: worker,
            setFetching: setFetchingUpdateWorker
        });
    };

    const deleteWorker = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.WORKERS.DELETE_WORKER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteWorker
        });
    };

    const findUser = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.WORKERS.FIND_USER.replace(':id', id.toString()),
            setFetching: setFetchingFindUser
        });
    };

    const getWorkerCalendar = (
        id: number,
        date: string,
        view: string,
        events: ServiceEvents = {}
    ) => {
        const url = `${EndPoints.WORKERS.GET_WORKER_CALENDAR.replace(
            ':id',
            id.toString()
        )}?date=${date}&view=${view}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetWorkerCalendar
        });
    };

    return {
        fetchingGetWorkers,
        fetchingShowWorker,
        fetchingStoreWorker,
        fetchingEditWorker,
        fetchingUpdateWorker,
        fetchingDeleteWorker,
        fetchingCreateWorker,
        fetchingActiveWorker,
        fetchingFindUser,
        fetchingGetWorkerCalendar,
        getWorkers,
        showWorker,
        storeWorker,
        createWorker,
        editWorker,
        updateWorker,
        deleteWorker,
        activeWorker,
        findUser,
        getWorkerCalendar
    };
};

export default useWorkerService;
