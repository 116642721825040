import React from 'react';
import { IconProps } from '../types';

export const CheckingAccount: React.FC<IconProps> = ({ size = 32, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            width={size}
            height={size}
            viewBox="-2.5 0 19 19"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <path d="M13.014 7.026v5.142a.476.476 0 0 1-.475.475H1.461a.476.476 0 0 1-.475-.475V7.026a.476.476 0 0 1 .475-.475h11.078a.476.476 0 0 1 .475.475zm-3.582 2.57a2.424 2.424 0 1 0-.191.947 2.417 2.417 0 0 0 .19-.946zm-.235-4.581a.554.554 0 0 1-.783 0l-.86-.86v1a.554.554 0 1 1-1.108 0v-1l-.86.86a.554.554 0 0 1-.783-.784l1.805-1.805a.554.554 0 0 1 .784 0L9.197 4.23a.554.554 0 0 1 0 .784zm0 9.947-1.805 1.806a.554.554 0 0 1-.784 0l-1.805-1.806a.554.554 0 0 1 .783-.783l.86.86v-1a.554.554 0 0 1 1.108 0v1l.86-.86a.554.554 0 1 1 .783.783zm-1.05-4.729a.83.83 0 0 1-.416.716 1.326 1.326 0 0 1-.413.173v.266a.317.317 0 0 1-.633 0v-.262a1.449 1.449 0 0 1-.268-.084.943.943 0 0 1-.362-.265.317.317 0 0 1 .479-.415.32.32 0 0 0 .118.092.842.842 0 0 0 .162.052 1.248 1.248 0 0 0 .176.022.765.765 0 0 0 .397-.11c.127-.082.127-.152.127-.185a.218.218 0 0 0-.053-.14.504.504 0 0 0-.132-.106.668.668 0 0 0-.163-.058.765.765 0 0 0-.16-.017 1.642 1.642 0 0 1-.273-.021 1.248 1.248 0 0 1-.352-.114 1.018 1.018 0 0 1-.335-.269.864.864 0 0 1-.198-.542.876.876 0 0 1 .435-.74 1.314 1.314 0 0 1 .402-.165v-.255a.317.317 0 0 1 .633 0v.264a1.513 1.513 0 0 1 .273.095 1.085 1.085 0 0 1 .318.218.317.317 0 0 1-.448.449.454.454 0 0 0-.13-.09.887.887 0 0 0-.167-.058l-.017-.004a.842.842 0 0 0-.137-.023.768.768 0 0 0-.389.104.249.249 0 0 0-.14.205.242.242 0 0 0 .057.143.394.394 0 0 0 .126.101.627.627 0 0 0 .173.056 1.01 1.01 0 0 0 .169.013 1.4 1.4 0 0 1 .295.03 1.305 1.305 0 0 1 .323.117 1.13 1.13 0 0 1 .314.25.848.848 0 0 1 .21.557z" />
            </g>
        </svg>
    );
};
