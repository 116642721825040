import React, { useContext, useEffect, useState } from 'react';

import { Place } from '../../app/models/Place';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';

import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { toast } from 'react-toastify';

import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';

import { useHistory } from 'react-router-dom';
import useCheckingAccountService from '../../app/services/hooks/useCheckingAccount';
import { CheckingAccountDataForm, CheckingAccountFilters } from '../../app/models/CheckingAccount';
import CheckingAccountDataTable from './components/tables/CheckingAccountDataTable';
import CheckingAccountCreate from './components/CheckingAccountCreate';
import CheckingAccountEdit from './components/CheckingAccountEdit';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Transferencias',
        url: '/checking-account',
        isActive: true
    }
];

const CheckingAccount = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingGetCheckingAccounts,
        getCheckingAccounts,
        deleteCheckingAccount,
        activeCheckingAccount
    } = useCheckingAccountService();
    const [checkingAccounts, setCheckingAccounts] = useState<CheckingAccountDataForm[]>([]);
    const { navigationPage } = useNavigationPage();

    const [checkingAccountIdEdit, setCheckingAccountIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [checkingAccountFilter, setCheckingAccountFilter] = useState<CheckingAccountFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [checkingAccountFilter.page, checkingAccountFilter.per_page, checkingAccountFilter.sort, checkingAccountFilter.order]);

    const getAllCheckingAccounts = () => {
        getCheckingAccounts(checkingAccountFilter, {
            onSuccess: (response: ServiceResponse) => {
                setCheckingAccounts(response.data.checking_accounts);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (checkingAccountId: number) => {
        setShowingEdit(true);
        setCheckingAccountIdEdit(checkingAccountId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setCheckingAccountIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllCheckingAccounts();
    };

    const destroy = (checkingAccountId: number) => {
        const _text = 'Está a punto de eliminar la transferencia #' + checkingAccountId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando transferencia...');
                        deleteCheckingAccount(checkingAccountId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La transferencia no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const active = (CheckingAccountId: number, active: boolean) => {
        activeCheckingAccount(CheckingAccountId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Transferencias" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nueva transferencia" />}
                />
                <CheckingAccountDataTable
                    checkingAccounts={checkingAccounts}
                    edit={showEdit}
                    destroy={destroy}
                    active={active}
                    loading={fetchingGetCheckingAccounts}
                    filter={checkingAccountFilter}
                    setFilter={setCheckingAccountFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                    onSearch={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear transferencia"
                    backdrop={true}
                    showFooter={false}
                >
                    <CheckingAccountCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar transferencia"
                    backdrop={true}
                    showFooter={false}
                >
                    <CheckingAccountEdit
                        checkingAccountId={checkingAccountIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default CheckingAccount;
