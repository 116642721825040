import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
interface MonthYearPickerProps {
    onMonthYearChange: (date: Date) => void;
    className?: string;
}

const MonthYearPicker: React.FC<MonthYearPickerProps> = ({ onMonthYearChange, className }) => {
    const [startDate, setStartDate] = useState<Date>();
    registerLocale('es', es);

    const handleChange = (date: Date) => {
        setStartDate(date);
        onMonthYearChange(date);
    };

    return (
        <div style={{ width: 200 }}>
            <DatePicker
                locale="es"
                selected={startDate}
                onChange={handleChange}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className={className}
            />
        </div>
    );
};

export default MonthYearPicker;
