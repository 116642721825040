import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { NotificationContextStateInterface } from './interfaces';
import reducerAuth from './reducer';
import { NOTIFICATIONS } from './types';
import { channel } from '../../App';
import useNotificationService from '../../app/services/hooks/useNotificationService';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import { AuthContext } from '../AuthContext';

const initialState: NotificationContextStateInterface = {
    notifications: []
};

export const NotificationContext = createContext<NotificationContextStateInterface>(initialState);

const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducerAuth, initialState);

    const { getNotificationsNotViewed } = useNotificationService();

    const { auth } = useContext(AuthContext);

    useEffect(() => {
        if (auth) {
            getNotifications();
            channel.bind('notify', function (data: any) {
                getNotifications();
            });
        }
    }, [auth]);

    const getNotifications = () =>
        getNotificationsNotViewed({
            onSuccess: (response: ServiceResponse) => {
                dispatch({
                    type: NOTIFICATIONS,
                    payload: response.data.notifications
                });
            }
        });

    return (
        <NotificationContext.Provider
            value={{
                notifications: state.notifications
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;