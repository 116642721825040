import { ResponseType } from "./ResponseType";
import { Section } from "./Section";
import { UserResponseOption } from "./UserResponseOption";

export interface Question{
    id?: string;
    question_text?: string;
    initial_value?: string;
    position?: number;
    section?: Section;
    section_id?: number;
    options?: LinkInspectionOption[];
    response_type?: ResponseType;
    is_required?: boolean;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;

}

export interface QuestionDataForm{
    id?: number;
    question_text?: string;
    initial_value?: string | boolean;
    text?: string; //Es la respuesta en caso de ser un input simple(sin opciones)
    switch_value?: boolean;
    position?: number;
    section?: string;
    section_id?: number;
    response_type?: string;
    is_required?: boolean;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    options?: LinkInspectionOption[];
    
    images?: string[];
    user_response_option?: UserResponseOption[];

}

export const defaultQuestionDataForm: QuestionDataForm = {
    id : 0
}

export interface LinkInspectionOption {
    id?: number | string;
    value: number | string | boolean;
    name?: string;
    label: string;
    depend?: string;
    next_question_id?: number | undefined;
    next_question?: string;
    position: number;
    is_response?: boolean;
    index?: number;
}

export interface QuestionFilters {
    question_text?: string | '';
    response_type?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}


export interface InputQuestion {
    uuid?: string;
    label: string;
    position: number;
    minRange?: number;
    maxRange?: number;
    initialValue?: string;
    inputValue?: string;
    inputValues?: string[];
    images?: string[];
    minAlert?: number;
    maxAlert?: number;
    unit?: string;
    step?: number;
    property?: string;

    isRequired: boolean;
    type:
        | 'select'
        | 'range'
        | 'radio'
        | 'text'
        | 'textarea'
        | 'checkbox'
        | 'switch'
        | 'number'
        | 'images';
    questionUuid?: string;
    disabled?: boolean;
}