import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useProductService from '../../../app/services/hooks/useProductService';
import { ProductDataForm } from '../../../app/models/Product';
import { Unit } from '../../../app/models/Unit';
import ProductFormContainer from './ProductFormContainer';

interface Props {
    productId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProductEdit = ({ productId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditProduct, editProduct, fetchingUpdateProduct, updateProduct } =
        useProductService();

    const [product, setProduct] = useState<ProductDataForm>({ name: '' });
    const [units, setUnits] = useState<Unit[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando producto...');
        editProduct(productId, {
            onSuccess: (response: ServiceResponse) => {
                setProduct(response.data.product);
                setUnits(response.data.units);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando producto...');
        updateProduct(productId, product, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditProduct ? (
        <LazyLoading height="300" />
    ) : (
        <ProductFormContainer
            fetching={fetchingUpdateProduct}
            action={update}
            cancel={cancel}
            product={product}
            setProduct={setProduct}
            units={units}
            errorFields={errorFields}
        />
    );
};

export default ProductEdit;
