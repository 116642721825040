import React from 'react'
import MonthlyClosureForm from './forms/MonthlyClosureForm'
import { MonthlyClosureDataForm } from '../../../app/models/MonthlyClosure';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';

interface Props {
    closure: MonthlyClosureDataForm;
    setClosure: (closure: MonthlyClosureDataForm) => void;
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const MonthlyClosureFormContainer = ({
    closure,
    setClosure,
    fetching,
    action,
    cancel,
    errorFields = null,
    setErrorFields
}: Props) => {

    return (
        <>
            <MonthlyClosureForm 
                closure={closure} 
                setClosure={setClosure}
                errorFields={errorFields}
                setErrorFields={setErrorFields}
            />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    )
}

export default MonthlyClosureFormContainer
