import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { CarrierDocument } from '../../models/Carrier';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useCarrierDocumentService = () => {
    const { doGet, doPost } = useFetch();

    const [
        fetchingGetCarrierDocumentsByCarrierAndDocumentType,
        setFetchingGetCarrierDocumentsByCarrierAndDocumentType
    ] = useState(false);
    const [fetchingStoreCarrierDocument, setFetchingStoreCarrierDocument] = useState(false);

    const getCarrierDocumentByCarrierAndDocumentType = (
        type: string,
        carrier_id: number,
        events: ServiceEvents = {}
    ) => {
        const url = `${EndPoints.CARRIER_DOCUMENT.ALL_CARRIER_DOCUMENTS_BY_CARRIER_TYPE}`;
        doGet({
            ...events,
            url: url.replaceAll(':carrier_id', carrier_id.toString()).replaceAll(':type', type),
            setFetching: setFetchingGetCarrierDocumentsByCarrierAndDocumentType
        });
    };

    const storeCarrierDocument = (carrierDocument: CarrierDocument, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CARRIER_DOCUMENT.STORE_CARRIER_DOCUMENT,
            requestType: 'multipart',
            body: carrierDocument,
            setFetching: setFetchingStoreCarrierDocument
        });
    };

    return {
        fetchingGetCarrierDocumentsByCarrierAndDocumentType,
        storeCarrierDocument,
        getCarrierDocumentByCarrierAndDocumentType,
        fetchingStoreCarrierDocument
    };
};

export default useCarrierDocumentService;
