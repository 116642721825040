import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';

import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useGroupService from '../../../app/services/hooks/useGroupService';
import GroupForm from './forms/GroupForm';
import { GroupDataForm } from '../../../app/models/Group';
import { ContentType } from '../../../app/models/ContentType';
import GroupFormContainer from './GroupFormContainer';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const GroupCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [group, setGroup] = useState<GroupDataForm>({ permissions: [], name: '' });
    const [contentTypes, setContentTypes] = useState<ContentType[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingStoreGroup, storeGroup, createGroup, fetchingCreateGroup } = useGroupService();

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createGroup({
            onSuccess: (response: ServiceResponse) => {
                setContentTypes(response.data.content_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando rol...');
        storeGroup(group, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return fetchingCreateGroup ? (
        <LazyLoading height="300" />
    ) : (
        <GroupFormContainer
            fetching={fetchingStoreGroup}
            contentTypes={contentTypes}
            action={store}
            cancel={cancel}
            group={group}
            setGroup={setGroup}
            errorFields={errorFields}
        />
    );
};

export default GroupCreate;
