import moment from 'moment';

export const formatDateToInputDate = (date: Date | string) => {
    if (typeof date === 'string') {
        return date;
    }
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    return date.getFullYear() + '-' + month + '-' + day;
};

export const momentParseDate = (date: string | any, format = 'DD-MM-YYYY') => {
    if (!date) {
        return '';
    }
    return moment(date).format(format);
};

export const momentParseDateToDate = (date: string | any, format = 'DD-MM-YYYY') => {
    if (!date) {
        return null;
    }
    const parsedDate = moment(date, format, true);
    if (parsedDate.isValid()) {
        return parsedDate.toDate();
    } else {
        const alternativeFormat = 'YYYY-MM-DD';
        const alternativeParsedDate = moment(date, alternativeFormat, true);
        if (alternativeParsedDate.isValid()) {
            return alternativeParsedDate.toDate();
        }
        return null;
    }
};

export const momentParseDateToInput = (date: string | any, format = 'YYYY-MM-DD') => {
    if (!date) {
        return '';
    }
    return moment(date).format(format);
};

export const momentParseDateTime = (date: string | any) => {
    console.log(date)
    if (!date) {
        return '';
    }
    return moment(date).format('DD-MM-YYYY HH:mm');
};

export const momentParseDateWithDayOfWeek = (date: string | any, format = 'DD-MM-YYYY') => {
    if (!date) {
        return '';
    }
    const formattedDate = moment(date).format(format);
    const dayOfWeek = moment(date).format('dddd');
    const capitalizedDay = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
    return `${capitalizedDay}, ${formattedDate}`;
};

export const getTimeFromDateTime = (dateTime: string): string => {
    const date = new Date(dateTime);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
};

export const formatNumberWithDots = (x: number | string): string => {
    return x
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatNumberWithDecimals = (value: number | string): string => {
    if (value.toString().includes('.')) {
        let [integerPart, decimalPart] = value.toString().split('.');
        integerPart = formatNumberWithDots(integerPart);

        if (decimalPart) {
            decimalPart = decimalPart.replace(/\D/g, '').substring(0, 2);
        }

        return decimalPart ? `${integerPart},${decimalPart}` : `${integerPart},`;
    }

    return formatNumberWithDots(value);
};

export const formatFloattNumber = (value: number | string): string => {
    if (value.toString().includes('.')) {
        let [integerPart, decimalPart] = value.toString().split('.');
        integerPart = formatNumberWithDots(integerPart);

        if (decimalPart) {
            decimalPart = decimalPart.replace(/\D/g, '');
        }

        return decimalPart ? `${integerPart},${decimalPart}` : `${integerPart},`;
    }

    return formatNumberWithDots(value);
};

export const getCurrentMonth = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    return `${year}-${month}`;
};

export const formatDateToInputMonth = (date: string | any) => {
    if (!date) {
        return '';
    }
    return moment(date).format('YYYY-MM');
};

export const formatDateToMonth = (date: string | any) => {
    if (!date) {
        return '';
    }
    return moment(date).format('MM-YYYY');
};

