import { EndPoints } from '../EndPoints';
import {
    SectionDataForm,
    SectionFilters
} from '../../models/Section';
import useFetch from '../../../hooks/useFetch';
import { useState, useContext } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';

const useSectionService = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetSections, setFetchingGetSections] =
        useState(false);
    const [fetchingGetSectionsBySurvey, setFetchingGetSectionsBySurvey] =
        useState(false);
    const [fetchingStoreSection, setFetchingStoreSection] =
        useState(false);
    const [fetchingEditSection, setFetchingEditSection] =
        useState(false);
    const [fetchingUpdateSection, setFetchingUpdateSection] =
        useState(false);
    const [fetchingDeleteSection, setFetchingDeleteSection] =
        useState(false);
    const [fetchingCreateSection, setFetchingCreateSection] =
        useState(false);
    const [fetchingShowSection, setFetchingShowSection] =
        useState(false);
    const [fetchingActiveSection, setFetchingActiveSection] =
        useState(false);


    const getAllSections = (
        filter: SectionFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.SECTIONS.GET_SECTIONS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetSections
        });
    };

    const getAllSectionsBySurvey = (
        survey_id: number,
        filter: SectionFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.SECTIONS.GET_SECTIONS_BY_SURVEY}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':survey_id', survey_id.toString()),
            setFetching: setFetchingGetSectionsBySurvey
        });
    };

    const createSection = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SECTIONS.CREATE_SECTION,
            setFetching: setFetchingCreateSection
        });
    };

    const storeSection = (
        sectionDataForm: SectionDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.SECTIONS.STORE_SECTION,
            body: sectionDataForm,
            setFetching: setFetchingStoreSection
        });
    };

    const editSection = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SECTIONS.EDIT_SECTION.replace(':id', id.toString()),
            setFetching: setFetchingEditSection
        });
    };

    const showSection = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SECTIONS.SHOW_SECTION.replace(':id', id.toString()),
            setFetching: setFetchingShowSection
        });
    };

    const updateSection = (
        id: number,
        sectionDataForm: SectionDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.SECTIONS.UPDATE_SECTION.replace(':id', id.toString()),
            body: sectionDataForm,
            setFetching: setFetchingUpdateSection
        });
    };

    const deleteSection = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SECTIONS.DELETE_SECTION.replace(':id', id.toString()),
            setFetching: setFetchingDeleteSection
        });
    };

    const activeSection = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SECTIONS.ACTIVE_SECTION.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveSection
        });
    };


    return {
        getAllSections,
        getAllSectionsBySurvey,
        createSection,
        storeSection,
        editSection,
        updateSection,
        deleteSection,
        showSection,
        activeSection,
        fetchingGetSectionsBySurvey,
        fetchingShowSection,
        fetchingGetSections,
        fetchingStoreSection,
        fetchingEditSection,
        fetchingUpdateSection,
        fetchingDeleteSection,
        fetchingCreateSection,
        fetchingActiveSection
    };
};

export default useSectionService;
