import React from 'react';
import DataTable from 'react-data-table-component';
import LazyLoading from '../../../../components/LazyLoading';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

type Props = {
    data: any[];
    destroy?: (id: number) => void;
    edit?: (id: number) => void;
    progressPending?: boolean;
};

const BankAccountsDataTable = ({ 
    data, 
    destroy,
    edit,
    progressPending, 
}: Props) => {
    const columns = [
        {
            name: 'Motivo de Cuenta',
            selector: (row: any) => row.bank_account_reason
            ,
            sortable: false,
            sortField: 'motivo',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.bank_account_reason.name}</span>
            )
        },
        {
            name: 'Banco',
            selector: (row: any) => row.bank,
            sortable: false,
            sortField: 'banco',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.bank.name}</span>
            )
        },
        {
            name: 'Número de Cuenta',
            selector: (row: any) => row.account_number,
            sortable: false,
            sortField: 'numero',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.account_number}</span>
            )
        },
        {
            name: 'Tipo de Cuenta',
            selector: (row: any) => row.account_type,
            sortable: false,
            sortField: 'numero',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.account_type.name}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ]
    return (
        <div>
            <DataTable
                columns={columns}
                data={data}
                progressPending={progressPending}
                progressComponent={<LazyLoading height={300} />}
                noDataComponent="No hay cuentas bancarias registradas"
            />
        </div>
    )
}

export default BankAccountsDataTable
