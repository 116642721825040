import ButtonCancelForm from "../../../components/buttons/ButtonCancelForm";
import ButtonSaveForm from "../../../components/buttons/ButtonSaveForm";
import useColumnService from "../../../app/services/hooks/useColumnService";
import { useEffect, useContext, useState } from "react";
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import { Column, UserColumnSetDataForm, defaultUserColumnSetDataForm } from "../../../app/models/Column";
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ColumnSetForm from "../forms/ColumnSetForm";


interface Props {
    tableName: string
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ColumnSetCreate = ({ tableName, onSaved, onCancel, onError }: Props) => {

    const { showLoading, hideLoading } = useContext(AppContext);
    const { editColumnSetByTableAndUser, 
        storeColumnSet, 
        fetchingEditColumnSetByTableAndUser,
        fetchingStoreColumnSet 
    } = useColumnService();
    const [ errorFields, setErrorFields ] = useState<any>();
    const [ userColumnSet, setUserColumnSet ] = useState<UserColumnSetDataForm>(defaultUserColumnSetDataForm);
    const [ columns, setColumns ] = useState<Column[]>([]);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        editColumnSetByTableAndUser(tableName, {
            onSuccess: (response: ServiceResponse) => {
                setColumns(response.data.columns);
                setUserColumnSet({
                    ...userColumnSet,
                    columns: response.data.user_columns.map((item: any) => ({
                        value: item.id,
                        label: item.alias
                    }))
                });
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando grupo de columnas...');
        storeColumnSet(userColumnSet, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditColumnSetByTableAndUser ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ColumnSetForm 
                        userColumnSetForm={userColumnSet} 
                        setUserColumnSetForm={setUserColumnSet} 
                        columns={columns} 
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreColumnSet} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreColumnSet} />
                </div>
            </div>
        </>
    );
};

export default ColumnSetCreate;
