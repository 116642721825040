import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { MonthlyClosureDataForm } from '../../../../app/models/MonthlyClosure';



interface Props {
    closure: MonthlyClosureDataForm;
    setClosure: (closure: MonthlyClosureDataForm) => void;
    is_edit?: boolean;
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const MonthlyClosureForm = ({
    closure,
    setClosure,
    is_edit,
    errorFields,
    setErrorFields
}: Props) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        
        const obj: { [key: string]: any } = {
            [name]: value
        };

        setClosure({ ...closure, ...obj });

        if(closure.closing_date !== value){
            if (setErrorFields) setErrorFields('')
        }
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-2">
                    <label className="col-md-4 col-form-label">Periodo</label>
                </div>
                <div className="col-md-10">
                    <div className="col-md-12">
                        <input
                            type="month"
                            className={`form-control ${fieldHasError('closing_date')}`}
                            name="closing_date"
                            id="closing_date"
                            value={closure.closing_date}
                            onChange={handleChange}
                            disabled={is_edit}
                            placeholder="Ingrese una fecha"
                            onFocus={() => onFocusRemove('closing_date')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('closing_date')}
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default MonthlyClosureForm
