import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { BrandDataForm } from '../../../app/models/Brand';
import { VehicleType } from '../../../app/models/VehicleType';
import BrandForm from './forms/BrandForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    brand: BrandDataForm;
    setBrand: (brand: BrandDataForm) => void;
    vehicleTypes: VehicleType[];
    errorFields?: any;
}

const BrandFormContainer = ({
    fetching,
    action,
    cancel,
    brand,
    setBrand,
    vehicleTypes,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BrandForm
                        brandDataForm={brand}
                        setBrandDataForm={setBrand}
                        vehicleTypes={vehicleTypes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default BrandFormContainer;
