import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ProductDataForm, ProductFilters } from '../../models/Product';

const useProductService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetProducts, setFetchingGetProducts] = useState(false);
    const [fetchingCreateProduct, setFetchingCreateProduct] = useState(false);
    const [fetchingShowProduct, setFetchingShowProduct] = useState(false);
    const [fetchingStoreProduct, setFetchingStoreProduct] = useState(false);
    const [fetchingEditProduct, setFetchingEditProduct] = useState(false);
    const [fetchingUpdateProduct, setFetchingUpdateProduct] = useState(false);
    const [fetchingDeleteProduct, setFetchingDeleteProduct] = useState(false);
    const [fetchingActiveProduct, setFetchingActiveProduct] = useState(false);

    const getProducts = (ProductFilters: ProductFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(ProductFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PRODUCTS.GET_PRODUCTS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetProducts
        });
    };

    const createProduct = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PRODUCTS.CREATE_PRODUCT,
            setFetching: setFetchingCreateProduct
        });
    };

    const showProduct = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PRODUCTS.SHOW_PRODUCT.replace(':id', id.toString()),
            setFetching: setFetchingShowProduct
        });
    };

    const storeProduct = (product: ProductDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PRODUCTS.STORE_PRODUCT,
            body: product,
            setFetching: setFetchingStoreProduct
        });
    };

    const editProduct = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PRODUCTS.EDIT_PRODUCT.replace(':id', id.toString()),
            setFetching: setFetchingEditProduct
        });
    };

    const updateProduct = (id: number, products: ProductDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PRODUCTS.UPDATE_PRODUCT.replace(':id', id.toString()),
            body: products,
            setFetching: setFetchingUpdateProduct
        });
    };

    const deleteProduct = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PRODUCTS.DELETE_PRODUCT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteProduct
        });
    };

    const activeProduct = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PRODUCTS.ACTIVE_PRODUCT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveProduct
        });
    };

    return {
        fetchingGetProducts,
        fetchingCreateProduct,
        fetchingShowProduct,
        fetchingStoreProduct,
        fetchingEditProduct,
        fetchingUpdateProduct,
        fetchingDeleteProduct,
        fetchingActiveProduct,
        getProducts,
        createProduct,
        showProduct,
        storeProduct,
        editProduct,
        updateProduct,
        deleteProduct,
        activeProduct
    };
};

export default useProductService;
