import { EndPoints } from '../EndPoints';
import {
    QuestionDataForm,
    QuestionFilters
} from '../../models/Question';
import useFetch from '../../../hooks/useFetch';
import { useState, useContext } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';

const useQuestionService = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetQuestions, setFetchingGetQuestions] =
        useState(false);
    const [fetchingGetQuestionsBySection, setFetchingGetQuestionsBySection] =
        useState(false);
    const [fetchingStoreQuestion, setFetchingStoreQuestion] =
        useState(false);
    const [fetchingEditQuestion, setFetchingEditQuestion] =
        useState(false);
    const [fetchingUpdateQuestion, setFetchingUpdateQuestion] =
        useState(false);
    const [fetchingDeleteQuestion, setFetchingDeleteQuestion] =
        useState(false);
    const [fetchingCreateQuestion, setFetchingCreateQuestion] =
        useState(false);
    const [fetchingShowQuestion, setFetchingShowQuestion] =
        useState(false);
    const [fetchingActiveQuestion, setFetchingActiveQuestion] =
        useState(false);
    const [fetchingRequiredQuestion, setFetchingRequiredQuestion] =
        useState(false);


    const getAllQuestions = (
        filter: QuestionFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.QUESTIONS.GET_QUESTIONS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetQuestions
        });
    };

    const getAllQuestionsBySection = (
        section_id: number,
        filter: QuestionFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.QUESTIONS.GET_QUESTIONS_BY_SECTION}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':section_id', section_id.toString()),
            setFetching: setFetchingGetQuestionsBySection
        });
    };

    const createQuestion = (section_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.QUESTIONS.CREATE_QUESTION.replace(':section_id', section_id.toString()),
            setFetching: setFetchingCreateQuestion
        });
    };

    const storeQuestion = (
        questionDataForm: QuestionDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.QUESTIONS.STORE_QUESTION,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: questionDataForm,
            requestType: 'json',
            setFetching: setFetchingStoreQuestion
        });
    };

    const editQuestion = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.QUESTIONS.EDIT_QUESTION.replace(':id', id.toString()),
            setFetching: setFetchingEditQuestion
        });
    };

    const showQuestion = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.QUESTIONS.SHOW_QUESTION.replace(':id', id.toString()),
            setFetching: setFetchingShowQuestion
        });
    };

    const updateQuestion = (
        id: number,
        QuestionDataForm: QuestionDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.QUESTIONS.UPDATE_QUESTION.replace(':id', id.toString()),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: QuestionDataForm,
            requestType: 'json',
            setFetching: setFetchingUpdateQuestion
        });
    };

    const deleteQuestion = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.QUESTIONS.DELETE_QUESTION.replace(':id', id.toString()),
            setFetching: setFetchingDeleteQuestion
        });
    };

    const activeQuestion = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.QUESTIONS.ACTIVE_QUESTION.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveQuestion
        });
    };

    const requiredQuestion = (id: number, required: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.QUESTIONS.REQUIRED_QUESTION.replace(':id', id.toString()),
            body: { required: required },
            setFetching: setFetchingRequiredQuestion
        });
    };


    return {
        getAllQuestions,
        getAllQuestionsBySection,
        createQuestion,
        storeQuestion,
        editQuestion,
        updateQuestion,
        deleteQuestion,
        showQuestion,
        activeQuestion,
        requiredQuestion,
        fetchingGetQuestionsBySection,
        fetchingShowQuestion,
        fetchingGetQuestions,
        fetchingStoreQuestion,
        fetchingEditQuestion,
        fetchingUpdateQuestion,
        fetchingDeleteQuestion,
        fetchingCreateQuestion,
        fetchingActiveQuestion,
        fetchingRequiredQuestion
    };
};

export default useQuestionService;
