import { useHistory } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { SurveyDataForm, SurveyFilters } from '../../app/models/Survey';

import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { ServiceResponse } from '../../app/services/shared/interfaces';
import useSurveyService from '../../app/services/hooks/useSurveyService';
import DefaultModal from '../../components/default/DefaultModal';
import SurveyCreate from './components/SurveyCreate';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';

import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';
import SurveyDataTable from './components/tables/SurveyDataTable';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Encuentas',
        url: '/surveys',
        isActive: true
    }
];

const Surveys = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetSurveys, getSurveys, deleteSurvey } = useSurveyService();

    const { auth } = useContext(AuthContext);
    const { navigationPage } = useNavigationPage();

    const [surveys, setSurveys] = useState<SurveyDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);

    const [showingCreate, setShowingCreate] = useState(false);

    const [filter, setFilter] = useState<SurveyFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
    });

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const reloadTable = () => {
        getSurveys(filter, {
            onSuccess: (response: ServiceResponse) => {
                setSurveys(response.data.surveys);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (surveyId: number) => {
        navigationPage('/surveys/' + surveyId + '/edit');
    };

    const showResponses = (surveyId: number) => {
        navigationPage('/surveys/' + surveyId + '/responses');
    };

    const destroy = (surveyId: number) => {
        const _text = 'Está a punto de eliminar la encuesta #' + surveyId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando encuesta...');
                        deleteSurvey(surveyId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                          
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                          
                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La encuesta no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    
    return (
        <>
            <Breadcrumbs pageSection="Encuestas" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate callbackCreate={showCreate} title="Nueva encuesta" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                    
                        <SurveyDataTable
                            surveys={surveys}
                            totalRows={totalRows}
                            loading={fetchingGetSurveys}
                            destroy={destroy}
                            edit={showEdit}
                            showResponses={showResponses}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Encuesta"
                    backdrop={true}
                    showFooter={false}
                >
                    <SurveyCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Surveys;
