import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Afp, AfpFilters } from '../../models/Afp';

const useAfpService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetAfps, setFetchingGetAfps] = useState(false);
    const [fetchingShowAfp, setFetchingShowAfp] = useState(false);
    const [fetchingStoreAfp, setFetchingStoreAfp] = useState(false);
    const [fetchingEditAfp, setFetchingEditAfp] = useState(false);
    const [fetchingUpdateAfp, setFetchingUpdateAfp] = useState(false);
    const [fetchingDeleteAfp, setFetchingDeleteAfp] = useState(false);
    const [fetchingActiveAfp, setFetchingActiveAfp] = useState(false);

    const getAfps = (afpFilters: AfpFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(afpFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.AFPS.GET_AFPS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetAfps
        });
    };

    const showAfp = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.AFPS.SHOW_AFP.replace(':id', id.toString()),
            setFetching: setFetchingShowAfp
        });
    };

    const storeAfp = (afp: Afp, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.AFPS.STORE_AFP,
            body: afp,
            setFetching: setFetchingStoreAfp
        });
    };

    const editAfp = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.AFPS.EDIT_AFP.replace(':id', id.toString()),
            setFetching: setFetchingEditAfp
        });
    };

    const updateAfp = (id: number, afp: Afp, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.AFPS.UPDATE_AFP.replace(':id', id.toString()),
            body: afp,
            setFetching: setFetchingUpdateAfp
        });
    };

    const deleteAfp = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.AFPS.DELETE_AFP.replace(':id', id.toString()),
            setFetching: setFetchingDeleteAfp
        });
    };

    const activeAfp = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.AFPS.ACTIVE_AFP.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveAfp
        });
    };

    return {
        fetchingGetAfps,
        fetchingShowAfp,
        fetchingStoreAfp,
        fetchingEditAfp,
        fetchingUpdateAfp,
        fetchingDeleteAfp,
        fetchingActiveAfp,
        getAfps,
        showAfp,
        storeAfp,
        editAfp,
        updateAfp,
        deleteAfp,
        activeAfp
    };
};

export default useAfpService;
