import React, { useContext, useEffect, useRef, useState } from 'react';
import { Sidebar, Menu, MenuItem, MenuItemStyles, menuClasses, SubMenu } from 'react-pro-sidebar';
import { Typography } from './Sidebar/components/Typography';
import { Book } from './Sidebar/icons/Book';
import { Badge } from 'react-bootstrap';
import { ShoppingCart } from './Sidebar/icons/ShoppingCart';
import { SidebarHeader } from './Sidebar/components/SidebarHeader';
import { Global } from './Sidebar/icons/Global';
import { InkBottle } from './Sidebar/icons/InkBottle';
import { Service } from './Sidebar/icons/Service';
import StickyBox from 'react-sticky-box';
import { Diamond } from './Sidebar/icons/Diamond';
import { Switch } from './Sidebar/components/Switch';
import { AppContext } from '../../../contexts/AppContext';
import Footer from './Footer';
import { Link, useHistory } from 'react-router-dom';
import { getMenuItemStyles, hexToRgba, themes } from './Sidebar/utils/getTheme';
import MenuTop from './MenuTop';
import {
    ITEMS,
    MENU_ITEMS,
    PERMISSIONS_APP,
    verify_permission,
    verify_path
} from './Sidebar/utils/getPermissions';
import { AuthContext } from '../../../contexts/AuthContext';
import LazyLoading from '../../../components/LazyLoading';
import { useLocation } from 'react-router-dom';
import SubMenuComponent from './Sidebar/components/SubMenuComponent';
import { Client } from './Sidebar/icons/sales/Client';
import { Dashboard } from './Sidebar/icons/Dashboard';
import { Sale } from './Sidebar/icons/sales/Sales';
import { Logistic } from './Sidebar/icons/logistics/Logistic';
import { TransportOrder } from './Sidebar/icons/logistics/transportOrder/TransportOrder';
import { Expense } from './Sidebar/icons/logistics/transportOrder/Expense';
import { Guide } from './Sidebar/icons/logistics/transportOrder/Guide';
import { LoadTicket } from './Sidebar/icons/logistics/transportOrder/LoadTicket';
import { MonthlyReconciliation } from './Sidebar/icons/logistics/transportOrder/MonthlyReconciliation';
import { WorkOrder } from './Sidebar/icons/logistics/workOrder/WorkOrder';
import { Toll } from './Sidebar/icons/logistics/routes/Toll';
import { RouteSegment } from './Sidebar/icons/logistics/routes/RouteSegment';
import { Place } from './Sidebar/icons/logistics/routes/Place';
import { Carrier } from './Sidebar/icons/logistics/Carrier';
import { Truck } from './Sidebar/icons/logistics/Truck';
import { Hopper } from './Sidebar/icons/logistics/Hopper';
import { Provider } from './Sidebar/icons/purchases/Provider';
import { ProviderType } from './Sidebar/icons/purchases/ProviderType';
import { Pruchase } from './Sidebar/icons/purchases/Purchase';
import { Product } from './Sidebar/icons/inventary/Product';
import { Inventary } from './Sidebar/icons/inventary/Inventary';
import { FuelConsumption } from './Sidebar/icons/sales/reports/FuelConsumption';
import { Report } from './Sidebar/icons/sales/reports/Report';
import { User } from './Sidebar/icons/configurations/User';
import { Society } from './Sidebar/icons/configurations/Society';
import { Afp } from './Sidebar/icons/configurations/Afp';
import { Configuration } from './Sidebar/icons/configurations/Configuration';
import { Provision } from './Sidebar/icons/configurations/Provision';
import { CheckingAccount } from './Sidebar/icons/configurations/CheckingAccount';
import { Bank } from './Sidebar/icons/configurations/Bank';
import { AccountType } from './Sidebar/icons/configurations/AccountType';
import { Unit } from './Sidebar/icons/configurations/Unit';
import { Currency } from './Sidebar/icons/configurations/Currency';
import { Brand } from './Sidebar/icons/configurations/Brand';
import { VehicleModel } from './Sidebar/icons/configurations/VehicleModel';
import { EventTypes } from './Sidebar/icons/RRHH/EventTypes';
import { Holiday } from './Sidebar/icons/RRHH/Holiday';
import { Rrhh } from './Sidebar/icons/RRHH/Rrhh';
import { Calendar } from './Sidebar/icons/RRHH/Calendar';
import { Group } from './Sidebar/icons/configurations/Group';
import { Quote } from './Sidebar/icons/sales/Quote';
import { Orders } from '../../../template/MainTheme/layouts/Sidebar/icons/sales/Orders';
import { ShiftWorks } from './Sidebar/icons/RRHH/ShiftWork';
import { Worker } from './Sidebar/icons/RRHH/Worker';
import { JobTitles } from './Sidebar/icons/RRHH/JobTitles';
import { Request } from './Sidebar/icons/RRHH/Request';
import { TravelExpenses } from './Sidebar/icons/RRHH/TravelExpenses';
import { Documents } from './Sidebar/icons/RRHH/Documents';
import { DocumentSets } from './Sidebar/icons/RRHH/DocumentSets';
import { Contract } from './Sidebar/icons/RRHH/Contract';
import { Bonus } from './Sidebar/icons/RRHH/Bonus';
import { Deduction } from './Sidebar/icons/RRHH/Deduction';
import { Liquidation } from './Sidebar/icons/RRHH/Liquidation';

type Theme = 'light' | 'dark';

const SidebarPro = () => {
    const location = useLocation();
    const { showingMainMenu, showMainMenu, hideMainMenu, changeMainMenu } = useContext(AppContext);
    const [collapsed, setCollapsed] = React.useState(false);
    const [menuItemStyles, setMenuItemStyles] = React.useState<MenuItemStyles>();
    const [broken, setBroken] = React.useState(false);
    const [rtl, setRtl] = React.useState(false);
    const [theme, setTheme] = React.useState<Theme>('dark');
    const { auth } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setMenuItemStyles(getMenuItemStyles(theme, collapsed));
    }, [theme, collapsed]);

    const onBackdropClick = () => {
        console.log('click atras para cerrar');
        if (hideMainMenu) hideMainMenu();
    };

    useEffect(() => {
        if (broken) {
            onBackdropClick();
            setCollapsed(false);
        } else {
            if (showMainMenu) showMainMenu();
        }
    }, [broken]);

    useEffect(() => {
        const body = document.body;
        // Toggle 'vertical-collpsed' based on the value of 'collapsed'
        body.classList.toggle('vertical-collpsed', collapsed);

        // Toggle 'sidebar-enable' based on the negation of 'collapsed'
        body.classList.toggle('sidebar-enable', !collapsed);
    }, [collapsed]);

    // handle on RTL change event
    const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRtl(e.target.checked);
    };

    // handle on theme change event
    const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTheme(e.target.checked ? 'dark' : 'light');
    };

    // handle on image change event
    //   const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setHasImage(e.target.checked);
    //   };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
        console.log('collapsed');
    }, [collapsed]);

    // useEffect(() => {
    //   setSubMenuOpen(verify_path(PERMISSIONS_APP['logistics'], location.pathname));
    //   console.log("adasd")
    // }, [subMenuOpen]);

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    // height: '100vh',
                    top: 70,
                    bottom: 0,
                    zIndex: 1000
                }}
            >
                <MenuTop />
                <Sidebar
                    collapsed={collapsed}
                    toggled={showingMainMenu}
                    onBackdropClick={hideMainMenu}
                    onBreakPoint={setBroken}
                    image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
                    rtl={rtl}
                    breakPoint="md"
                    backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
                    rootStyles={{
                        color: themes[theme].sidebar.color
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {/*<SidebarHeader rtl={rtl} style={{ marginBottom: '24px', marginTop: '16px' }} />*/}
                        <div style={{ flex: 1, marginBottom: '32px' }}>
                            {loading ? (
                                <LazyLoading height="300" />
                            ) : (
                                <>
                                    {!broken && (
                                        <div
                                            style={{
                                                padding: '0 18px',
                                                marginBottom: '0px',
                                                marginTop: '0px',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <button
                                                type="button"
                                                style={{ height: '50px' }}
                                                onClick={() => {
                                                    setCollapsed(!collapsed);
                                                }}
                                                className="btn btn-sm font-size-16 header-item "
                                                id="vertical-menu-btn"
                                            >
                                                <i className="fa fa-fw fa-bars text-white" />
                                            </button>
                                        </div>
                                    )}
                                    <Menu menuItemStyles={menuItemStyles}>
                                        {(auth?.permissions && auth?.permissions?.length > 0) ||
                                        auth?.hasAllPermissions ? (
                                            <>
                                                {(verify_permission(
                                                    [ITEMS.dashboard],
                                                    auth?.permissions ?? []
                                                ) ||
                                                    auth.hasAllPermissions) && (
                                                    <MenuItem
                                                        active={
                                                            location.pathname === ITEMS.dashboard.to
                                                        }
                                                        icon={<Dashboard />}
                                                        component={<Link to={ITEMS.dashboard.to} />}
                                                    >
                                                        Dashboard
                                                    </MenuItem>
                                                )}
                                                {(verify_permission(
                                                    [ITEMS.calendar_me],
                                                    auth?.permissions ?? []
                                                ) ||
                                                    auth.hasAllPermissions) && (
                                                    <MenuItem
                                                        active={
                                                            location.pathname === ITEMS.calendar_me.to
                                                        }
                                                        icon={<Calendar />}
                                                        component={<Link to={ITEMS.calendar_me.to} />}
                                                    >
                                                        Calendario
                                                    </MenuItem>
                                                )}
                                                {/* Ventas */}
                                                {(verify_permission(
                                                    PERMISSIONS_APP.sales,
                                                    auth?.permissions ?? []
                                                ) ||
                                                    auth.hasAllPermissions) && (
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.sales_single_items.paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        label="Ventas"
                                                        icon={<Sale />}
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.client],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.client.to
                                                                    }
                                                                    icon={<Client />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.client.to}
                                                                        />
                                                                    }
                                                                >
                                                                    Clientes
                                                                </MenuItem>
                                                            )}

                                                            {(verify_permission(
                                                                [ITEMS.quotes],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.quotes.to
                                                                    }
                                                                    icon={<Quote />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.quotes.to}
                                                                        />
                                                                    }
                                                                >
                                                                    Oferta
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.quotes],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.transportation_orders
                                                                            .to
                                                                    }
                                                                    icon={<Orders />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS
                                                                                    .transportation_orders
                                                                                    .to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Pedidos
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.quotes],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.dispatch_programs.to
                                                                    }
                                                                    icon={<Expense />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS
                                                                                    .dispatch_programs
                                                                                    .to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Despachos
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                    </SubMenuComponent>
                                                )}
                                                {/* Logística */}
                                                {(verify_permission(
                                                    PERMISSIONS_APP.logistics,
                                                    auth?.permissions ?? []
                                                ) ||
                                                    auth.hasAllPermissions) && (
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.work_order_submenu_items
                                                                .paths,
                                                            ...MENU_ITEMS
                                                                .transport_order_submenu_items
                                                                .paths,
                                                            ...MENU_ITEMS.routes_submenu_items
                                                                .paths,
                                                            ...MENU_ITEMS.logistics_single_items
                                                                .paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        label="Logística"
                                                        icon={<Logistic />}
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            {(verify_permission(
                                                                [
                                                                    MENU_ITEMS.work_order_submenu_items
                                                                ],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <SubMenuComponent
                                                                    pathList={[
                                                                        ...MENU_ITEMS
                                                                            .work_order_submenu_items
                                                                            .paths
                                                                    ]}
                                                                    pathName={location.pathname}
                                                                    label="Ordenes de trabajo"
                                                                    collapsed={collapsed}
                                                                    showingMainMenu={
                                                                        showingMainMenu
                                                                    }
                                                                >
                                                                    <>
                                                                        {(verify_permission(
                                                                            [ITEMS.work_order],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                        ITEMS
                                                                                            .work_order
                                                                                            .to ||
                                                                                    location.pathname.includes(
                                                                                        ITEMS
                                                                                            .work_order
                                                                                            .to
                                                                                    )
                                                                                }
                                                                                icon={<WorkOrder />}
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .work_order
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Ordenes
                                                                            </MenuItem>
                                                                        )}
                                                                    </>
                                                                </SubMenuComponent>
                                                            )}

                                                            {(verify_permission(
                                                                [
                                                                    MENU_ITEMS.transport_order_submenu_items
                                                                ],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <SubMenuComponent
                                                                    pathList={[
                                                                        ...MENU_ITEMS
                                                                            .transport_order_submenu_items
                                                                            .paths
                                                                    ]}
                                                                    pathName={location.pathname}
                                                                    label="Ordenes de transporte"
                                                                    collapsed={collapsed}
                                                                    showingMainMenu={
                                                                        showingMainMenu
                                                                    }
                                                                >
                                                                    <>
                                                                        {(verify_permission(
                                                                            [ITEMS.transport_order],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                        ITEMS
                                                                                            .transport_order
                                                                                            .to ||
                                                                                    location.pathname.includes(
                                                                                        ITEMS
                                                                                            .transport_order
                                                                                            .to
                                                                                    )
                                                                                }
                                                                                icon={
                                                                                    <TransportOrder />
                                                                                }
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .transport_order
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Ordenes
                                                                            </MenuItem>
                                                                        )}

                                                                        {(verify_permission(
                                                                            [ITEMS.transport_order],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS
                                                                                        .daily_carriers
                                                                                        .to
                                                                                }
                                                                                icon={<Expense />}
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .daily_carriers
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Transportista
                                                                            </MenuItem>
                                                                        )}
                                                                        {(verify_permission(
                                                                            [ITEMS.expense],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS.expense.to
                                                                                }
                                                                                icon={<Expense />}
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .expense
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Gastos
                                                                            </MenuItem>
                                                                        )}
                                                                        {(verify_permission(
                                                                            [ITEMS.guide],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS.guide.to
                                                                                }
                                                                                icon={<Guide />}
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .guide
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Guias
                                                                            </MenuItem>
                                                                        )}

                                                                        {(verify_permission(
                                                                            [ITEMS.load_ticket],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS
                                                                                        .load_ticket
                                                                                        .to
                                                                                }
                                                                                icon={
                                                                                    <LoadTicket />
                                                                                }
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .load_ticket
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                                title="tickets de descarga"
                                                                            >
                                                                                Ticket de descarga
                                                                            </MenuItem>
                                                                        )}
                                                                        {(verify_permission(
                                                                            [
                                                                                ITEMS.monthly_reconciliation
                                                                            ],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS
                                                                                        .monthly_reconciliation
                                                                                        .to
                                                                                }
                                                                                icon={
                                                                                    <MonthlyReconciliation />
                                                                                }
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .monthly_reconciliation
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Cuadre mensual
                                                                            </MenuItem>
                                                                        )}
                                                                    </>
                                                                </SubMenuComponent>
                                                            )}

                                                            {(verify_permission(
                                                                [MENU_ITEMS.routes_submenu_items],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <SubMenuComponent
                                                                    pathList={[
                                                                        ...MENU_ITEMS
                                                                            .routes_submenu_items
                                                                            .paths
                                                                    ]}
                                                                    pathName={location.pathname}
                                                                    label="Rutas"
                                                                    collapsed={collapsed}
                                                                    showingMainMenu={
                                                                        showingMainMenu
                                                                    }
                                                                >
                                                                    <>
                                                                        {(verify_permission(
                                                                            [ITEMS.toll],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                        ITEMS.toll
                                                                                            .to ||
                                                                                    location.pathname.includes(
                                                                                        ITEMS.toll
                                                                                            .to
                                                                                    )
                                                                                }
                                                                                icon={<Toll />}
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .toll
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Peajes
                                                                            </MenuItem>
                                                                        )}
                                                                        {(verify_permission(
                                                                            [ITEMS.place],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS.place.to
                                                                                }
                                                                                icon={<Place />}
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .place
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Origenes/Destinos
                                                                            </MenuItem>
                                                                        )}
                                                                        {(verify_permission(
                                                                            [ITEMS.route_segment],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS
                                                                                        .route_segment
                                                                                        .to
                                                                                }
                                                                                icon={
                                                                                    <RouteSegment />
                                                                                }
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .route_segment
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Tramos
                                                                            </MenuItem>
                                                                        )}
                                                                    </>
                                                                </SubMenuComponent>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.carrier],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.carrier.to
                                                                    }
                                                                    icon={<Carrier />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.carrier.to}
                                                                        />
                                                                    }
                                                                >
                                                                    Conductores
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.truck],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.truck.to
                                                                    }
                                                                    icon={<Truck />}
                                                                    component={
                                                                        <Link to={ITEMS.truck.to} />
                                                                    }
                                                                >
                                                                    Tractos
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.hopper],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.hopper.to
                                                                    }
                                                                    icon={<Hopper />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.hopper.to}
                                                                        />
                                                                    }
                                                                >
                                                                    Semi remolques
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                    </SubMenuComponent>
                                                )}

                                                {/* Compras */}
                                                {(verify_permission(
                                                    PERMISSIONS_APP.purchases,
                                                    auth?.permissions ?? []
                                                ) ||
                                                    auth.hasAllPermissions) && (
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.reports_submenu_items
                                                                .paths,
                                                            ...MENU_ITEMS.purchases_single_items
                                                                .paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        label="Compras"
                                                        icon={<Pruchase />}
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.provider],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.provider.to
                                                                    }
                                                                    icon={<Provider />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.provider.to}
                                                                        />
                                                                    }
                                                                >
                                                                    Proveedores
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.provider_type],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.provider_type.to
                                                                    }
                                                                    icon={<ProviderType />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS.provider_type
                                                                                    .to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Tipos de proveedor
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [MENU_ITEMS.reports_submenu_items],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <SubMenuComponent
                                                                    pathList={[
                                                                        ...MENU_ITEMS
                                                                            .reports_submenu_items
                                                                            .paths
                                                                    ]}
                                                                    pathName={location.pathname}
                                                                    label="Reportes"
                                                                    icon={<Report />}
                                                                    collapsed={collapsed}
                                                                    showingMainMenu={
                                                                        showingMainMenu
                                                                    }
                                                                >
                                                                    <>
                                                                        {(verify_permission(
                                                                            [
                                                                                ITEMS.fuel_consumption
                                                                            ],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS
                                                                                        .fuel_consumption
                                                                                        .to
                                                                                }
                                                                                icon={
                                                                                    <FuelConsumption />
                                                                                }
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .fuel_consumption
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                                title="Consum de combustible"
                                                                            >
                                                                                Consumo combustible
                                                                            </MenuItem>
                                                                        )}
                                                                    </>
                                                                </SubMenuComponent>
                                                            )}
                                                        </>
                                                    </SubMenuComponent>
                                                )}
                                                {/* Inventario */}
                                                {(verify_permission(
                                                    PERMISSIONS_APP.inventary,
                                                    auth?.permissions ?? []
                                                ) ||
                                                    auth.hasAllPermissions) && (
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.inventary_single_items
                                                                .paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        label="Inventario"
                                                        icon={<Inventary />}
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.product],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.product.to
                                                                    }
                                                                    icon={<Product />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.product.to}
                                                                        />
                                                                    }
                                                                >
                                                                    Productos
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                    </SubMenuComponent>
                                                )}
                                                {/*Cuentas corrientes*/}
                                                {(verify_permission(
                                                    PERMISSIONS_APP.checking_account,
                                                    auth?.permissions ?? []
                                                ) ||
                                                    auth.hasAllPermissions) && (
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS
                                                                .checking_account_single_items.paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        label="Cuentas Corrientes"
                                                        icon={<CheckingAccount />}
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.checking_account],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        '/checking-accounts'
                                                                    }
                                                                    icon={<CheckingAccount />}
                                                                    component={
                                                                        <Link to="/checking-accounts" />
                                                                    }
                                                                >
                                                                    Transferencias
                                                                </MenuItem>
                                                            )}

                                                            {(verify_permission(
                                                                [ITEMS.internal_settlement],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.internal_settlement.to
                                                                    }
                                                                    icon={<CheckingAccount />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS
                                                                                    .internal_settlement
                                                                                    .to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Liquidación interna
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                    </SubMenuComponent>
                                                )}

                                                {/*Gestion Documento */}
                                                {(verify_permission(
                                                    PERMISSIONS_APP.management,
                                                    auth?.permissions ?? []
                                                ) ||
                                                    auth.hasAllPermissions) && (
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.management_items
                                                                .paths,
                                                        ]}
                                                        pathName={location.pathname}
                                                        label="Gestion"
                                                        icon={<Rrhh />}
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.document],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.document.to
                                                                    }
                                                                    icon={<Documents />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.document.to}
                                                                        />
                                                                    }
                                                                >
                                                                    Documentos
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.document_set],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.document_set.to
                                                                    }
                                                                    icon={<DocumentSets />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS.document_set
                                                                                    .to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Set de Documentos
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.monthly_closure],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                            ITEMS
                                                                                .monthly_closure
                                                                                .to ||
                                                                        location.pathname.includes(
                                                                            ITEMS
                                                                                .monthly_closure
                                                                                .to
                                                                        ) 
                                                                    }
                                                                    icon={<WorkOrder />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS
                                                                                    .monthly_closure
                                                                                    .to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Cierre Mensual
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.ai_tolls],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={location.pathname === ITEMS.ai_tolls.to}
                                                                    icon={<i className="mdi mdi-rocket" style={{ fontSize: '24px' }}></i>}
                                                                    component={<Link to={ITEMS.ai_tolls.to} />}
                                                                >
                                                                    APIs
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                    </SubMenuComponent>
                                                )}

                                                {(verify_permission(
                                                    PERMISSIONS_APP.human_resources,
                                                    auth?.permissions ?? []
                                                ) ||
                                                    auth.hasAllPermissions) && (
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.human_resources_items
                                                                .paths,
                                                            ...MENU_ITEMS
                                                                .human_resources_submenu_items.paths,
                                                                ...MENU_ITEMS.liquidation_human_resources_submenu_items.paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        label="RRHH"
                                                        icon={<Rrhh />}
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.worker],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.worker.to
                                                                    }
                                                                    icon={<Worker />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.worker.to}
                                                                        />
                                                                    }
                                                                >
                                                                    Trabajadores
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.worker],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.contract.to
                                                                    }
                                                                    icon={<Contract />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.contract.to}
                                                                        />
                                                                    }
                                                                >
                                                                    Contratos
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.worker],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.liquidation.to
                                                                    }
                                                                    icon={<Liquidation />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS.liquidation.to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Liquidaciones
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.shift],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.shift.to
                                                                    }
                                                                    icon={<ShiftWorks />}
                                                                    component={
                                                                        <Link to={ITEMS.shift.to} />
                                                                    }
                                                                >
                                                                    Jornadas
                                                                </MenuItem>
                                                            )}
                                                        </>

                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.calendar_request],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.calendar_request.to
                                                                    }
                                                                    icon={<Request />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS
                                                                                    .calendar_request
                                                                                    .to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Solicitudes
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                        <>
                                                            {(verify_permission(
                                                                [
                                                                    MENU_ITEMS.human_resources_submenu_items,
                                                                    MENU_ITEMS.liquidation_human_resources_submenu_items
                                                                ],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <SubMenuComponent
                                                                    pathList={[
                                                                        ...MENU_ITEMS
                                                                            .human_resources_submenu_items
                                                                            .paths,
                                                                        ...MENU_ITEMS.liquidation_human_resources_submenu_items.paths
                                                                    ]}
                                                                    pathName={location.pathname}
                                                                    label="Configuraciones"
                                                                    collapsed={collapsed}
                                                                    showingMainMenu={
                                                                        showingMainMenu
                                                                    }
                                                                >
                                                                    <>
                                                                        {(verify_permission(
                                                                            [
                                                                                MENU_ITEMS.human_resources_submenu_items
                                                                            ],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <SubMenuComponent
                                                                                pathList={[
                                                                                    ...MENU_ITEMS
                                                                                        .liquidation_human_resources_submenu_items
                                                                                        .paths
                                                                                ]}
                                                                                pathName={
                                                                                    location.pathname
                                                                                }
                                                                                label="Liquidaciones"
                                                                                collapsed={
                                                                                    collapsed
                                                                                }
                                                                                showingMainMenu={
                                                                                    showingMainMenu
                                                                                }
                                                                            >
                                                                                <>
                                                                                    {(verify_permission(
                                                                                        [
                                                                                            ITEMS.worker
                                                                                        ],
                                                                                        auth?.permissions ??
                                                                                            []
                                                                                    ) ||
                                                                                        auth.hasAllPermissions) && (
                                                                                        <MenuItem
                                                                                            active={
                                                                                                location.pathname ===
                                                                                                ITEMS
                                                                                                    .bonus
                                                                                                    .to
                                                                                            }
                                                                                            icon={
                                                                                                <Bonus />
                                                                                            }
                                                                                            component={
                                                                                                <Link
                                                                                                    to={
                                                                                                        ITEMS
                                                                                                            .bonus
                                                                                                            .to
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            Bonificaciones
                                                                                        </MenuItem>
                                                                                    )}
                                                                                </>
                                                                                <>
                                                                                    {(verify_permission(
                                                                                        [
                                                                                            ITEMS.worker
                                                                                        ],
                                                                                        auth?.permissions ??
                                                                                            []
                                                                                    ) ||
                                                                                        auth.hasAllPermissions) && (
                                                                                        <MenuItem
                                                                                            active={
                                                                                                location.pathname ===
                                                                                                ITEMS
                                                                                                    .deductions
                                                                                                    .to
                                                                                            }
                                                                                            icon={
                                                                                                <Deduction />
                                                                                            }
                                                                                            component={
                                                                                                <Link
                                                                                                    to={
                                                                                                        ITEMS
                                                                                                            .deductions
                                                                                                            .to
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            Deducciones
                                                                                        </MenuItem>
                                                                                    )}
                                                                                </>

                                                                                <>
                                                                                    {(verify_permission(
                                                                                        [
                                                                                            ITEMS.worker
                                                                                        ],
                                                                                        auth?.permissions ??
                                                                                            []
                                                                                    ) ||
                                                                                        auth.hasAllPermissions) && (
                                                                                        <MenuItem
                                                                                            active={
                                                                                                location.pathname ===
                                                                                                ITEMS
                                                                                                    .job_title_liquidation_set
                                                                                                    .to
                                                                                            }
                                                                                            icon={
                                                                                                <Deduction />
                                                                                            }
                                                                                            component={
                                                                                                <Link
                                                                                                    to={
                                                                                                        ITEMS
                                                                                                            .job_title_liquidation_set
                                                                                                            .to
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            Set de
                                                                                            liquidaciones
                                                                                        </MenuItem>
                                                                                    )}
                                                                                </>
                                                                            </SubMenuComponent>
                                                                        )}
                                                                    </>
                                                                    <>
                                                                        {(verify_permission(
                                                                            [ITEMS.travel_expense],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS
                                                                                        .travel_expense
                                                                                        .to
                                                                                }
                                                                                icon={
                                                                                    <TravelExpenses />
                                                                                }
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .travel_expense
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Viáticos
                                                                            </MenuItem>
                                                                        )}
                                                                    </>
                                                                    <>
                                                                        {(verify_permission(
                                                                            [ITEMS.event_types],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS
                                                                                        .event_types
                                                                                        .to
                                                                                }
                                                                                icon={
                                                                                    <EventTypes />
                                                                                }
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .event_types
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Tipos de Eventos
                                                                            </MenuItem>
                                                                        )}
                                                                    </>
                                                                    <>
                                                                        {(verify_permission(
                                                                            [ITEMS.holiday],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS.holiday.to
                                                                                }
                                                                                icon={<Holiday />}
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .holiday
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Feriados
                                                                            </MenuItem>
                                                                        )}
                                                                    </>
                                                                    <>
                                                                        {(verify_permission(
                                                                            [ITEMS.job_titles],
                                                                            auth?.permissions ?? []
                                                                        ) ||
                                                                            auth.hasAllPermissions) && (
                                                                            <MenuItem
                                                                                active={
                                                                                    location.pathname ===
                                                                                    ITEMS.job_titles
                                                                                        .to
                                                                                }
                                                                                icon={<JobTitles />}
                                                                                component={
                                                                                    <Link
                                                                                        to={
                                                                                            ITEMS
                                                                                                .job_titles
                                                                                                .to
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                Puestos de trabajo
                                                                            </MenuItem>
                                                                        )}
                                                                    </>
                                                                </SubMenuComponent>
                                                            )}
                                                        </>
                                                    </SubMenuComponent>
                                                )}

                                                {/* Configuraciones */}
                                                {(verify_permission(
                                                    PERMISSIONS_APP.configuration,
                                                    auth?.permissions ?? []
                                                ) ||
                                                    auth.hasAllPermissions) && (
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.configuration_single_items
                                                                .paths,
                                                            ...MENU_ITEMS
                                                                .organization_and_societies_items
                                                                .paths,
                                                            ...MENU_ITEMS.finance_and_accounts_items
                                                                .paths,
                                                            ...MENU_ITEMS.expenses_and_purchase
                                                                .paths,
                                                            ...MENU_ITEMS.vehicles.paths,
                                                            ...MENU_ITEMS.measurement_units.paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        label="Configuración"
                                                        icon={<Configuration />}
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            {(verify_permission(
                                                                [ITEMS.user],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.user.to
                                                                    }
                                                                    icon={<User />}
                                                                    component={
                                                                        <Link to={ITEMS.user.to} />
                                                                    }
                                                                >
                                                                    Usuarios
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.group],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        '/roles'
                                                                    }
                                                                    icon={<Group />}
                                                                    component={<Link to="/roles" />}
                                                                >
                                                                    Roles
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.status_history],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.status_history.to
                                                                    }
                                                                    icon={<Group />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS.status_history
                                                                                    .to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Bitácora estados
                                                                </MenuItem>
                                                            )}
                                                            {(verify_permission(
                                                                [ITEMS.survey],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                            ITEMS
                                                                                .survey
                                                                                .to ||
                                                                        location.pathname.includes(
                                                                            ITEMS
                                                                                .survey
                                                                                .to
                                                                        ) ||
                                                                        location.pathname.includes(
                                                                            ITEMS
                                                                                .question
                                                                                .to
                                                                        )
                                                                    }
                                                                    icon={<WorkOrder />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS
                                                                                    .survey
                                                                                    .to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Encuestas
                                                                </MenuItem>
                                                            )}

                                                            {(verify_permission(
                                                                [ITEMS.incident_reasons],
                                                                auth?.permissions ?? []
                                                            ) ||
                                                                auth.hasAllPermissions) && (
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                            ITEMS
                                                                                .incident_reasons
                                                                                .to 
                                                                    }
                                                                    icon={<WorkOrder />}
                                                                    component={
                                                                        <Link
                                                                            to={
                                                                                ITEMS
                                                                                    .incident_reasons
                                                                                    .to
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Motivos de Incidencias
                                                                </MenuItem>
                                                            )}

                                                        </>
                                                        <SubMenuComponent
                                                            pathList={[
                                                                ...MENU_ITEMS
                                                                    .organization_and_societies_items
                                                                    .paths
                                                            ]}
                                                            pathName={location.pathname}
                                                            label="Organizaciones y Sociedades"
                                                            collapsed={collapsed}
                                                            showingMainMenu={showingMainMenu}
                                                        >
                                                            <>
                                                                {(verify_permission(
                                                                    [ITEMS.society],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/societies'
                                                                        }
                                                                        icon={<Society />}
                                                                        component={
                                                                            <Link to="/societies" />
                                                                        }
                                                                    >
                                                                        Sociedades
                                                                    </MenuItem>
                                                                )}
                                                                {(verify_permission(
                                                                    [ITEMS.society],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/societies-signatures'
                                                                        }
                                                                        icon={<Society />}
                                                                        component={
                                                                            <Link to="/societies-signatures" />
                                                                        }
                                                                    >
                                                                        Firmas de Sociedades
                                                                    </MenuItem>
                                                                )}
                                                                {(verify_permission(
                                                                    [ITEMS.afp],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/afps'
                                                                        }
                                                                        icon={<Afp />}
                                                                        component={
                                                                            <Link to="/afps" />
                                                                        }
                                                                    >
                                                                        Afps
                                                                    </MenuItem>
                                                                )}
                                                                {(verify_permission(
                                                                    [ITEMS.provision],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/provisions'
                                                                        }
                                                                        icon={<Provision />}
                                                                        component={
                                                                            <Link to="/provisions" />
                                                                        }
                                                                    >
                                                                        Previsiones
                                                                    </MenuItem>
                                                                )}
                                                            </>
                                                        </SubMenuComponent>

                                                        <SubMenuComponent
                                                            pathList={[
                                                                ...MENU_ITEMS
                                                                    .finance_and_accounts_items
                                                                    .paths
                                                            ]}
                                                            pathName={location.pathname}
                                                            label="Finanzas y Cuentas"
                                                            collapsed={collapsed}
                                                            showingMainMenu={showingMainMenu}
                                                        >
                                                            <>
                                                                {(verify_permission(
                                                                    [ITEMS.bank],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/banks'
                                                                        }
                                                                        icon={<Bank />}
                                                                        component={
                                                                            <Link to="/banks" />
                                                                        }
                                                                    >
                                                                        Bancos
                                                                    </MenuItem>
                                                                )}
                                                                {(verify_permission(
                                                                    [ITEMS.transfer_reason],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/transfer_reasons'
                                                                        }
                                                                        icon={<Bank />}
                                                                        component={
                                                                            <Link to="/transfer_reasons" />
                                                                        }
                                                                    >
                                                                        Motivo de transferencia
                                                                    </MenuItem>
                                                                )}
                                                                {(verify_permission(
                                                                    [ITEMS.account_type],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/bank-account-types'
                                                                        }
                                                                        icon={<AccountType />}
                                                                        component={
                                                                            <Link to="/bank-account-types" />
                                                                        }
                                                                    >
                                                                        Tipo de cuentas
                                                                    </MenuItem>
                                                                )}

                                                                {(verify_permission(
                                                                    [ITEMS.account],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/accounts'
                                                                        }
                                                                        icon={<AccountType />}
                                                                        component={
                                                                            <Link to="/accounts" />
                                                                        }
                                                                    >
                                                                        Cuentas Contables
                                                                    </MenuItem>
                                                                )}
                                                                {(verify_permission(
                                                                    [ITEMS.clasifications],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/clasifications'
                                                                        }
                                                                        icon={<AccountType />}
                                                                        component={
                                                                            <Link to="/clasifications" />
                                                                        }
                                                                    >
                                                                        Clasificaciones
                                                                    </MenuItem>
                                                                )}
                                                                {(verify_permission(
                                                                    [ITEMS.currency],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/currencies'
                                                                        }
                                                                        icon={<Currency />}
                                                                        component={
                                                                            <Link to="/currencies" />
                                                                        }
                                                                    >
                                                                        Monedas
                                                                    </MenuItem>
                                                                )}
                                                                {(verify_permission(
                                                                    [ITEMS.foreign_exchanges],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            "/foreign-exchanges"
                                                                        }
                                                                        icon={<Currency />}
                                                                        component={
                                                                            <Link to="/foreign-exchanges"/>
                                                                        }
                                                                    >
                                                                        Divisos
                                                                    </MenuItem>
                                                                )}
                                                            </>
                                                        </SubMenuComponent>
                                                        <SubMenuComponent
                                                            pathList={[
                                                                ...MENU_ITEMS.expenses_and_purchase
                                                                    .paths
                                                            ]}
                                                            pathName={location.pathname}
                                                            label="Gastos y Compras"
                                                            collapsed={collapsed}
                                                            showingMainMenu={showingMainMenu}
                                                        >
                                                            <>
                                                                {(verify_permission(
                                                                    [ITEMS.expense_type],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/expense-types'
                                                                        }
                                                                        icon={<AccountType />}
                                                                        component={
                                                                            <Link to="/expense-types" />
                                                                        }
                                                                    >
                                                                        Tipo de gastos
                                                                    </MenuItem>
                                                                )}
                                                            </>
                                                        </SubMenuComponent>

                                                        <SubMenuComponent
                                                            pathList={[
                                                                ...MENU_ITEMS.vehicles.paths
                                                            ]}
                                                            pathName={location.pathname}
                                                            label="Vehículos"
                                                            collapsed={collapsed}
                                                            showingMainMenu={showingMainMenu}
                                                        >
                                                            <>
                                                                {(verify_permission(
                                                                    [ITEMS.brand],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/brands'
                                                                        }
                                                                        icon={<Brand />}
                                                                        component={
                                                                            <Link to="/brands" />
                                                                        }
                                                                    >
                                                                        Marcas
                                                                    </MenuItem>
                                                                )}
                                                                {(verify_permission(
                                                                    [ITEMS.vehicle_model],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/vehicle-models'
                                                                        }
                                                                        icon={<VehicleModel />}
                                                                        component={
                                                                            <Link to="/vehicle-models" />
                                                                        }
                                                                    >
                                                                        Modelo vehículos
                                                                    </MenuItem>
                                                                )}
                                                            </>
                                                        </SubMenuComponent>

                                                        <SubMenuComponent
                                                            pathList={[
                                                                ...MENU_ITEMS.measurement_units
                                                                    .paths
                                                            ]}
                                                            pathName={location.pathname}
                                                            label="Unidades de medida"
                                                            collapsed={collapsed}
                                                            showingMainMenu={showingMainMenu}
                                                        >
                                                            <>
                                                                {(verify_permission(
                                                                    [ITEMS.unit],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/units'
                                                                        }
                                                                        icon={<Unit />}
                                                                        component={
                                                                            <Link to="/units" />
                                                                        }
                                                                    >
                                                                        Unidades
                                                                    </MenuItem>
                                                                )}
                                                                {(verify_permission(
                                                                    [ITEMS.unit],
                                                                    auth?.permissions ?? []
                                                                ) ||
                                                                    auth.hasAllPermissions) && (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            '/unit-equivalence'
                                                                        }
                                                                        icon={<Unit />}
                                                                        component={
                                                                            <Link to="/unit-equivalence" />
                                                                        }
                                                                    >
                                                                        Equivalencia de unidades
                                                                    </MenuItem>
                                                                )}
                                                            </>
                                                        </SubMenuComponent>
                                                    </SubMenuComponent>
                                                )}
                                            </>
                                        ) : null}
                                    </Menu>
                                </>
                            )}
                        </div>
                    </div>
                </Sidebar>
            </div>
            {/* <div className='main-content w-100' style={{marginLeft: '0'}}>


      <div className="page-content">

        {children}

        </div>

      </div> */}
        </>
    );
};

export default SidebarPro;
