import React, { useContext } from 'react';
import { ExpenseFilters } from '../../../../app/models/Expense';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { AuthContext } from '../../../../contexts/AuthContext';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: ExpenseFilters;
    showTransportOrder: boolean;
    setFilter: (filter: ExpenseFilters) => void;
    onSearch: () => void;
};

const CalendarExpenseFilter = ({ filter, setFilter, showTransportOrder, onSearch }: Props) => {
    const { auth } = useContext(AuthContext);
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="expense_type">
                            Tipo de gasto
                        </label>
                        <input
                            title="Tipo de gasto"
                            type="text"
                            id="expense_type"
                            name="expense_type"
                            className="form-control form-control-sm"
                            value={filter.expense_type ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="expense_document_type">
                            Tipo de documento
                        </label>
                        <select
                            id="expense_document_type"
                            name="expense_document_type"
                            className="form-control form-select-sm"
                            value={filter.expense_document_type ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'BOLETA'} value="BOLETA">
                                Boleta
                            </option>
                            <option key={'FACTURA'} value="FACTURA">
                                Factura
                            </option>
                        </select>
                    </div>
                </div>
                {showTransportOrder && (
                    <>
                        <div className="col-md-auto">
                            <div className="mb-3">
                                <label className="font-size-10 mb-1" htmlFor="issue_date_first">
                                    Fecha de emisión (desde)
                                </label>
                                <input
                                    title="Fecha de emisión"
                                    type="date"
                                    id="issue_date_first"
                                    name="issue_date_first"
                                    className="form-control form-control-sm"
                                    value={filter.issue_date_first}
                                    onChange={(e) => handlerInputFilter(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div className="mb-3">
                                <label className="font-size-10 mb-1" htmlFor="issue_date_last">
                                    Fecha de emisión (hasta)
                                </label>
                                <input
                                    title="Fecha de emisión"
                                    type="date"
                                    id="issue_date_last"
                                    name="issue_date_last"
                                    className="form-control form-control-sm"
                                    value={filter.issue_date_last}
                                    onChange={(e) => handlerInputFilter(e)}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalendarExpenseFilter;
