import React, { useContext, useEffect, useState } from 'react';
import useIncidentService from '../../../app/services/hooks/useIncidentService';
import { IncidentDataForm } from '../../../app/models/Incident';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import IncidentShowForm from './forms/IncidentShowForm';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';

interface Props {
    incidentId: number;
    onCancel?: () => void;
    onError?: (message?: string) => void;
}

const IncidentShow = ({ incidentId, onCancel, onError }: Props) => {

    const { fetchingShowIncident, showIncident } = useIncidentService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [incidentDataForm, setIncidentDataForm] = useState<IncidentDataForm>();

    useEffect(() => {
        show();
    }, []);

    const show = () => {
        if (showLoading) showLoading('loading', 'Cargando incidente...');
        showIncident(incidentId, {
            onSuccess: (response: ServiceResponse) => {
                setIncidentDataForm(response.data.incident);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if(onError)onError();
            }
        });
    };

    return fetchingShowIncident || !incidentDataForm ? (
        <LazyLoading height={300} />
    ) : (
         <IncidentShowForm incidentDataForm={incidentDataForm} />
    );
};

export default IncidentShow;
