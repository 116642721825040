import React from 'react';
import { IconProps } from '../types';

export const Quote: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M3 18C2.44772 18 2 18.4477 2 19C2 19.5523 2.44772 20 3 20H10C10.2652 20 10.5196 19.8946 10.7071 19.7071L18 12.4142V22C18 23.1046 17.1046 24 16 24H2C0.89543 24 0 23.1046 0 22V6H5C5.55228 6 6 5.55228 6 5V0H16C17.1046 0 18 0.89543 18 2V7.58579L16.7071 6.29289C16.3166 5.90237 15.6834 5.90237 15.2929 6.29289L6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16V18H3zM4 0.341411V4H0.34141C0.94398 2.29517 2.29517 0.943981 4 0.341411zM9.5858 18H8V16.4142L16 8.41421L17.5858 10L9.5858 18z"
                fill="#758CA3"
            />
        </svg>
    );
};
