import React, { useContext, useEffect, useState } from 'react'
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm'
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm'
import useBankAccountService from '../../../app/services/hooks/useBankAccount';
import { AppContext } from '../../../contexts/AppContext';
import { Bank } from '../../../app/models/Bank';
import { BankAccountDataForm, BankAccountType, defaultBankAccountDataForm } from '../../../app/models/BankAccountType';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import BankAccountForm from './forms/BankAccountForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';


interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    workerId: number;
}

const WorkerBankAccountCreate = ({ onSaved, onCancel, onError, workerId }: Props) => {
    
    const { createWorkerBankAccount, storeBankAccount, fetchingCreateBankAccount, fetchingStoreBankAccount} = useBankAccountService()
    const { showLoading, hideLoading } = useContext(AppContext);

    const [banks, setBanks] = useState<Bank[]>([]);
    const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>([]);
    const [accountReasons, setAccountReasons] = useState<[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const [bankAccount, setBankAccount] = useState<BankAccountDataForm>({
        ...defaultBankAccountDataForm,
        worker_id: workerId
    })

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createWorkerBankAccount(workerId, {
            onSuccess: (response: ServiceResponse) => {
                setBanks(response.data.banks);
                setBankAccountTypes(response.data.account_types);
                setAccountReasons(response.data.bank_account_reasons);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando conductor...');
        storeBankAccount(bankAccount, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BankAccountForm 
                        workerBankAccountForm={bankAccount} 
                        setWorkerBankAccountForm={setBankAccount} 
                        banks={banks} 
                        bankAccountTypes={bankAccountTypes} 
                        accountReasons={accountReasons}
                        errorFields={errorFields}
                        fetchingBankAccounts={fetchingCreateBankAccount}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreBankAccount} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreBankAccount} />
                </div>
            </div>
        </>
    )
}

export default WorkerBankAccountCreate
