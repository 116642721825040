import React, { useEffect } from 'react';
import { Afp } from '../../../../app/models/Afp';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { TransferReason } from '../../../../app/models/TransferReason';

type Props = {
    transferReasonDataForm: TransferReason;
    setTransferReasonDataForm: (transferReason: TransferReason) => void;
    errorFields?: any;
};

const TransferReasonForm = ({
    transferReasonDataForm,
    setTransferReasonDataForm,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setTransferReasonDataForm({ ...transferReasonDataForm, [name]: value });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={transferReasonDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre de banco"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransferReasonForm;
