import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useMonthlyClosureDetailService from '../../../app/services/hooks/useMonthlyClosureDetailService';
import { MonthlyClosureDetailDataForm } from '../../../app/models/MonthlyClosureDetail';
import { Society } from '../../../app/models/Society';
import MonthlyClosureDetailForm from './forms/MonthlyClosureDetailForm';
import useNavigationPage from '../../../hooks/useNavigationPage';

interface Props {
    monthlyClosureId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const MonthlyClosureDetailCreate = ({ onSaved, onCancel, onError, monthlyClosureId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    
    const history = useHistory();

    const { 
        createMonthlyClosureDetail,
        storeMonthlyClosureDetail,
        fetchingCreateMonthlyClosureDetail,
        fetchingStoreMonthlyClosureDetail 
    } = useMonthlyClosureDetailService();

  
    const [monthlyClosureDetail, setMonthlyClosureDetail] = useState<MonthlyClosureDetailDataForm>(
        {
            monthly_closure_id: monthlyClosureId,
            society_id: 0
        }
    );

    const [societies, setSocieties] = useState<Society[]>([]);

    const [errorFields, setErrorFields] = useState<any>();
   
    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createMonthlyClosureDetail(monthlyClosureId, {
            onSuccess: (response: ServiceResponse) => {
               
                setSocieties(response.data.societies);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando sociedad...');
        storeMonthlyClosureDetail(
            monthlyClosureDetail,
            {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    
                    if (onSaved) onSaved();
                    
                },
                onError: (response: ServiceResponse) => {
                 
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
             
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            }
        );
    };


    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateMonthlyClosureDetail ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <MonthlyClosureDetailForm
                        monthlyClosureDetailDataForm={monthlyClosureDetail}
                        setMonthlyClosureDetailDataForm={setMonthlyClosureDetail}
                        errorFields={errorFields}
                        societies={societies}
                        
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreMonthlyClosureDetail}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreMonthlyClosureDetail} />
                </div>
            </div>
        </>
    );
};

export default MonthlyClosureDetailCreate;
