import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { Hopper, HopperDataForm, HopperFilters } from '../../models/Hopper';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useHopperService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetHoppers, setFetchingGetHoppers] = useState(false);
    const [fetchingShowHopper, setFetchingShowHopper] = useState(false);
    const [fetchingCreateHopper, setFetchingCreateHopper] = useState(false);
    const [fetchingStoreHopper, setFetchingStoreHopper] = useState(false);
    const [fetchingEditHopper, setFetchingEditHopper] = useState(false);
    const [fetchingUpdateHopper, setFetchingUpdateHopper] = useState(false);
    const [fetchingDeleteHopper, setFetchingDeleteHopper] = useState(false);
    const [fetchingActiveHopper, setFetchingActiveHopper] = useState(false);

    const getHoppers = (hopperFilters: HopperFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(hopperFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.HOPPERS.GET_HOPPERS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetHoppers
        });
    };

    const showHopper = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.HOPPERS.GET_HOPPER.replace(':id', id.toString()),
            setFetching: setFetchingShowHopper
        });
    };

    const createHopper = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.HOPPERS.CREATE_HOPPER,
            setFetching: setFetchingCreateHopper
        });
    };

    const storeHopper = (hopper: HopperDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.HOPPERS.STORE_HOPPER,
            body: hopper,
            setFetching: setFetchingStoreHopper
        });
    };

    const editHopper = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.HOPPERS.EDIT_HOPPER.replace(':id', id.toString()),
            setFetching: setFetchingEditHopper
        });
    };

    const updateHopper = (id: number, hopper: HopperDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.HOPPERS.UPDATE_HOPPER.replace(':id', id.toString()),
            body: hopper,
            setFetching: setFetchingUpdateHopper
        });
    };

    const deleteHopper = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.HOPPERS.DELETE_HOPPER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteHopper
        });
    };

    const activeHopper = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.HOPPERS.ACTIVE_HOPPER.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveHopper
        });
    };

    return {
        fetchingGetHoppers,
        fetchingShowHopper,
        fetchingCreateHopper,
        fetchingStoreHopper,
        fetchingEditHopper,
        fetchingUpdateHopper,
        fetchingDeleteHopper,
        fetchingActiveHopper,
        getHoppers,
        showHopper,
        createHopper,
        storeHopper,
        editHopper,
        updateHopper,
        deleteHopper,
        activeHopper
    };
};

export default useHopperService;
