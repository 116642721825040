import React, { useEffect, useState } from 'react'
import DefaultCard from '../../../../components/default/DefaultCard'
import DefaultModal from '../../../../components/default/DefaultModal';
import useSurveyService from '../../../../app/services/hooks/useSurveyService';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { SurveyDataForm, SurveyFilters } from '../../../../app/models/Survey';
import SurveyDataTable from '../../../Surveys/components/tables/SurveyDataTable';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import useNavigationPage from '../../../../hooks/useNavigationPage';


interface Props {
    eventTypeId?: number;
}

const CalendarChecklistContainer = ({ eventTypeId }: Props) => {

    const { getSurveyByEventTypeId, fetchingGetSurveyByEventType } = useSurveyService();
    const { navigationPage } = useNavigationPage();

    const [showingChecklist, setShowingChecklist] = useState(false);
    const [surveys, setSurveys] = useState<SurveyDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [filter, setFilter] = useState<SurveyFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
    });

    useEffect(()=>{
        reloadTable()
    },[filter.page, filter.per_page, filter.sort, filter.order, eventTypeId])

    const reloadTable = () => {
        if(eventTypeId){
            getSurveyByEventTypeId(eventTypeId, filter, {
                onSuccess: (response: ServiceResponse) => {
                    setSurveys(response.data.surveys);
                    setTotalRows(response.data.total_rows);
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                }
            });
        }
    }

    // const showChecklist = () => {
    //     setShowingChecklist(true);
    // };

    // const hideChecklist = () => {
    //     setShowingChecklist(false);
    // };
    
    const respondSurvey = (surveyId: number) => {
        const _text = 'Está a punto de responder la encuesta #' + surveyId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        navigationPage('/surveys/' + surveyId + '/respond');
                        //if (showLoading) showLoading('loading', 'Eliminando gasto de viaje...');
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La encuesta no se ha contestado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <h5>Encuestas</h5>
                    {/* <div className="col-12 mb-2 mt-2">
                        <button
                            type="button"
                            className="btn btn-outline-primary btn-sm "
                            onClick={showChecklist}
                        >
                            <i className="mdi mdi-clipboard-list me-1"/>
                            Contestar Encuesta
                        </button>
                    </div> */}
                    <div className="col-12">
                        <SurveyDataTable
                            surveys={surveys}
                            totalRows={totalRows}
                            loading={fetchingGetSurveyByEventType}
                            hideActive={true}
                            edit={respondSurvey}
                            // showResponses={showResponses}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {/* {showingChecklist ? (
                <DefaultModal
                    show={showingChecklist}
                    handleClose={hideChecklist}
                    title="Contestar Encuesta"
                    backdrop={true}
                    showFooter={false}
                >
                    <h1>ñalsdk</h1>
                </DefaultModal>
            ) : null} */}
        </>
    )
}

export default CalendarChecklistContainer
