import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { QuestionDataForm, QuestionFilters } from '../../../../app/models/Question';
import QuestionFilter from './QuestionFilter';
import QuestionSwitchChange from '../QuestionSwitchChange';
import { SectionDataForm } from '../../../../app/models/Section';


interface Props {
    section: SectionDataForm;
    questions: QuestionDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    required?: (id: number, value: boolean, setChecked: (value:boolean) => void) => void;
    active?: (id: number, value: boolean, setChecked: (value:boolean) => void) => void;
    filter: QuestionFilters;
    setFilter: (filter: QuestionFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const QuestionDataTable = ({
    section,
    questions,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    required,
    active,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: QuestionDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Posición',
            selector: (row: any) => row.position,
            sortable: true,
            sortField: 'position',
            cell: (row: QuestionDataForm, index: any, column: any) => (
                <span className="">{row.position}</span>
            )
        },
        {
            name: 'Pregunta',
            selector: (row: any) => row.question_text,
            sortable: true,
            sortField: 'question_text',
            cell: (row: QuestionDataForm, index: any, column: any) => (
                <span className="">{row.question_text}</span>
            )
        },
        {
            name: 'Tipo',
            selector: (row: any) => row.response_type,
            sortable: true,
            sortField: 'response_type',
            cell: (row: QuestionDataForm, index: any, column: any) => (
                <span className="">{row.response_type}</span>
            )
        },
        {
            name: 'Requerido',
            selector: (row: any) => row?.is_required,
            sortable: true,
            sortField: 'is_required',
            cell: (row: QuestionDataForm, index: any, column: any) => (
                required ? (
                    <QuestionSwitchChange
                      checkValue={row.is_required ?? true}
                      id={row.id!}
                      useFunction={required}
                    />
                  ) : (
                    row.is_required ? 'Sí' : 'No'
                  )
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row?.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: QuestionDataForm, index: any, column: any) => (
                active ? (
                    <QuestionSwitchChange
                      checkValue={row.is_active ?? true}
                      id={row.id!}
                      useFunction={active}
                    />
                  ) : (
                    row.is_active ? 'Sí' : 'No'
                  )
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: QuestionDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <QuestionFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                title={section.title}
                columns={columns}
                data={questions}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default QuestionDataTable;
