import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';

import { AppContext } from '../../../contexts/AppContext';
import { CarrierDocument } from '../../../app/models/Carrier';

import useCarrierDocumentService from '../../../app/services/hooks/useCarrierDocumentService';
import GeneralDocumentsTransporterHistoryByTypeTable from '../../../components/GeneralDocumentsTransporterHistoryByTypeTable';

interface Props {
    onCancel?: () => void;
    onError?: () => void;
    carrierId: number;

    typeDocument: string;
}

const CarrierHistoryDocument = ({ onCancel, onError, carrierId, typeDocument }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [carrierDocuments, setCarrierDocuments] = useState<CarrierDocument[]>();

    const {
        fetchingGetCarrierDocumentsByCarrierAndDocumentType,
        getCarrierDocumentByCarrierAndDocumentType
    } = useCarrierDocumentService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        getCarrierDocumentByCarrierAndDocumentType(typeDocument, carrierId, {
            onSuccess: (response: ServiceResponse) => {
                setCarrierDocuments(response.data.carrier_documents);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingGetCarrierDocumentsByCarrierAndDocumentType ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    {carrierDocuments && (
                        // <GeneralDocumentsTransporterHistoryByTypeTable
                        //     documents={carrierDocuments}
                        // />
                        <h1>hola</h1>
                    )}
                </div>
            </div>
        </>
    );
};

export default CarrierHistoryDocument;
