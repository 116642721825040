import React, { useContext, useEffect, useState } from 'react';
import { defaultProvider, Provider } from '../../../app/models/Provider';
import useProviderService from '../../../app/services/hooks/useProviderService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import ProviderFormContainer from './ProviderFormContainer';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { ProviderType } from '../../../app/models/ProviderType';
import { Society } from '../../../app/models/Society';
import { Activity } from '../../../app/models/Activity';
import { User } from '../../../app/models/User';
import useUserService from '../../../app/services/hooks/useUserService';
import useSocietyService from '../../../app/services/hooks/useSocietyService';

interface Props {
    providerId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProviderEdit = ({ providerId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditProvider, editProvider, fetchingUpdateProvider, updateProvider } =
        useProviderService();
    const { getSociety, fetchingGetSociety } = useSocietyService();
    const { getUser, fetchingGetUser } = useUserService();
    const [provider, setProvider] = useState<Provider>(defaultProvider);
    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [activities, setActivities] = useState<Activity[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando proveedor...');
        editProvider(providerId, {
            onSuccess: (response: ServiceResponse) => {
                setProvider(response.data.provider);
                setSocieties(response.data.societies);
                setActivities(response.data.activities);
                setUsers(response.data.users);
                setProviderTypes(response.data.provider_types);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (provider.society_id) {
            getSociety(provider.society_id, {
                onSuccess: (response: ServiceResponse) => {
                    setProvider({
                        ...provider,
                        rut: response.data.society.rut,
                        business_name: response.data.society.business_name,
                        address: response.data.society.address,
                        country: response.data.society.country
                    });

                    setErrorFields({
                        ...errorFields,
                        rut: '',
                        business_name: '',
                        address: '',
                        country: ''
                    });
                }
            });
        }
    }, [provider.society_id]);

    useEffect(() => {
        if (provider.user_id) {
            getUser(provider.user_id, {
                onSuccess: (response: ServiceResponse) => {
                    console.log(response.data.user);
                    setProvider({
                        ...provider,
                        rut_contact: response.data.user.rut,
                        first_name_contact: response.data.user.first_name,
                        last_name_contact: response.data.user.last_name,
                        second_last_name_contact: response.data.user.second_last_name,
                        email_contact: response.data.user.email,
                        phone_code_contact: response.data.user.phone_code,
                        phone_contact: response.data.user.phone
                    });

                    setErrorFields({
                        ...errorFields,
                        rut_contact: '',
                        first_name_contact: '',
                        last_name_contact: '',
                        second_last_name_contact: '',
                        email_contact: '',
                        phone_code_contact: '',
                        phone_contact: ''
                    });
                }
            });
        }
    }, [provider.user_id]);

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando proveedor...');
        updateProvider(providerId, provider, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditProvider ? (
        <LazyLoading height="300" />
    ) : (
        <ProviderFormContainer
            fetching={fetchingUpdateProvider}
            action={update}
            cancel={cancel}
            providerTypes={providerTypes}
            societies={societies}
            users={users}
            provider={provider}
            setProvider={setProvider}
            errorFields={errorFields}
        />
    );
};

export default ProviderEdit;
