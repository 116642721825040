import { Bank } from './Bank';

export interface BankAccountType {
    id?: number;
    name: string;
    is_active?: boolean;

    bank?: Bank[];
}

export interface BankAccount {
    id?: number;
    bank_id: number;
    account_type_id: number;
    account_number: string;
    bank_account_reason_id: number;
}

export interface BankAccountDataForm {
    worker_id?: number;
    bank_id?: number;
    account_type_id?: number;
    account_number?: string;
    bank_account_reason_id?: number;
}

export const defaultBankAccountDataForm: BankAccountDataForm = {
    worker_id: 0
};

export interface BankAccountReason {
    id?: number;
    name: string;
    reason: string;
}

export interface BankAccountTypeFilters {
    id?: number | null;
    name?: string | '';
    is_active?: boolean | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
