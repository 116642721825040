import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { IncidentReasonDataForm, IncidentReasonFilters } from '../../../../app/models/IncidentReason';
import IncidentReasonSwitchChange from '../IncidentReasonSwitchChange';
import IncidentReasonFilter from './IncidentReasonFilter';


interface Props {
    incidentReasons: IncidentReasonDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    required?: (id: number, value: boolean, setChecked: (value:boolean) => void) => void;
    active?: (id: number, value: boolean, setChecked: (value:boolean) => void) => void;
    filter: IncidentReasonFilters;
    setFilter: (filter: IncidentReasonFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const IncidentReasonDataTable = ({
    incidentReasons,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    required,
    active,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: IncidentReasonDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Nombre',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: IncidentReasonDataForm, index: any, column: any) => (
                <span className="">{row.name}</span>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row?.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: IncidentReasonDataForm, index: any, column: any) => (
                    <IncidentReasonSwitchChange
                        checkValue={row.is_active ?? true}
                        id={row.id!}
                        callbackSuccess={callbackSuccess!}                   
                    />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: IncidentReasonDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <IncidentReasonFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={incidentReasons}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default IncidentReasonDataTable;
