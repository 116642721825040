import React, { useEffect, useRef, useState } from 'react';

import Lottie from 'lottie-react';
import { getAnimationIcon } from '../../utils/utils';
import { LoadingData } from '../../contexts/Shared/interfaces';

interface Props {
    loading: boolean;
    loadingData: LoadingData;
    hideLoading: () => void;
}

interface SpinnerData {
    animation?: any;
    message?: string;
}

const DefaultSpinnerLottie = ({
    loading,
    loadingData = { nameLoading: 'loading', messageLoading: 'Cargando...', finishAnimation: false },
    hideLoading
}: Props) => {
    const [animationData, setAnimationData] = useState<SpinnerData>({});

    useEffect(() => {
        setAnimationData({
            animation: getAnimationIcon(loadingData.nameLoading),
            message: loadingData.messageLoading
        });
    }, [loadingData.nameLoading]);

    const handleAnimationComplete = () => {
        let timeout = 0;
        if (loadingData.finishAnimation) {
            if (loadingData.nameLoading == 'error' || loadingData.nameLoading == 'success') {
                timeout = 1500;
            }
            setTimeout(() => {
                hideLoading();
            }, timeout);
        }
    };

    if (!loading) {
        return null;
    }

    return (
        <>
            {animationData.animation && (
                <>
                    <Lottie
                        animationData={animationData.animation}
                        loop={true}
                        style={{ width: '250px', height: '150px' }}
                        onLoopComplete={handleAnimationComplete}
                    />
                    <div style={{ fontSize: '16px', overflowWrap: 'break-word' }}>
                        {animationData.message}
                    </div>
                </>
            )}
        </>
    );
};

export default DefaultSpinnerLottie;
