import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { IncidentReasonDataForm, defaultIncidentReasonDataForm } from '../../../app/models/IncidentReason';
import useIncidentReasonService from '../../../app/services/hooks/useIncidentReasonService';
import IncidentReasonForm from './forms/IncidentReasonForm';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const IncidentReasonCreate = ({ onSaved, onCancel, onError }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();

    const { fetchingCreateIncidentReason, fetchingStoreIncidentReason ,createIncidentReason, storeIncidentReason } = useIncidentReasonService();

    const [incidentReason, setIncidentReason] = useState<IncidentReasonDataForm>(defaultIncidentReasonDataForm);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createIncidentReason({
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
            }
        });
    }, []);


    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando encuesta...');

        storeIncidentReason(incidentReason, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                // navigationPage('/surveys/' + response.data.survey_id + '/edit');

                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateIncidentReason ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <IncidentReasonForm
                        incidentReasonForm={incidentReason}
                        setIncidentReasonForm={setIncidentReason}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreIncidentReason} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreIncidentReason} />
                </div>
            </div>
        </>
    )
}

export default IncidentReasonCreate
