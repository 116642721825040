import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { CheckQuoteDocumentRoutes, QuoteDataForm, QuoteFilters } from '../../models/Quote';
import { ServiceEvents } from '../shared/interfaces';
import { useContext, useState } from 'react';
import { fetchDefaultHeaders } from '../shared/vars';
import { AppContext } from '../../../contexts/AppContext';

const useQuoteService = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetQuotes, setFetchingGetQuotes] = useState(false);
    const [fetchingShowQuote, setFetchingShowQuote] = useState(false);
    const [fetchingStoreQuote, setFetchingStoreQuote] = useState(false);
    const [fetchingEditQuote, setFetchingEditQuote] = useState(false);
    const [fetchingUpdateQuote, setFetchingUpdateQuote] = useState(false);
    const [fetchingDeleteQuote, setFetchingDeleteQuote] = useState(false);
    const [fetchingGetExcelQuotes, setFetchingGetExcelQuotes] = useState(false);
    const [fetchingCreateQuote, setFetchingCreateQuote] = useState(false);
    const [fetchingRepeatQuote, setFetchingRepeatQuote] = useState(false);
    const [fetchingChangeStatusQuote, setFetchingChangeStatusQuote] = useState(false);
    const [fetchingChangeDocumentCheckRoutes, setFetchingChangeDocumentCheckRoutes] =
        useState(false);

    const getAllQuotes = (quoteFilter: QuoteFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(quoteFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.QUOTES.GET_QUOTES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetQuotes
        });
    };

    const getExcelAllQuotes = (quoteFilters: QuoteFilters, events: ServiceEvents = {}) => {
        if (showLoading) showLoading('download', 'descargando archivo...');

        let url = new URL(EndPoints.QUOTES.GET_EXCEL_QUOTES);
        url = getUrlQuotesFilters(
            url,
            quoteFilters.quote_status,
            quoteFilters.start_date,
            quoteFilters.end_date,
            quoteFilters.quote_id
        );

        fetch(url.href, { headers: { ...fetchDefaultHeaders } })
            .then((response) => {
                response
                    .blob()
                    .then((blob) => {
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = 'Oferta.xlsx';
                        a.click();
                        window.URL.revokeObjectURL(url);
                        if (changeAnimation)
                            changeAnimation('downloadSuccess', 'descarga exitosa', true);
                    })
                    .catch((error) => {
                        if (changeAnimation) changeAnimation('error', 'descarga fallida', true);
                    });
            })
            .catch((error) => {
                if (changeAnimation) changeAnimation('error', 'descarga fallida', true);
            });
    };

    const getDocumentQuote = (id: number, events: ServiceEvents = {}) => {
        fetch(EndPoints.QUOTES.DOCUMENT_QUOTE.replace(':id', id.toString()), {
            headers: { ...fetchDefaultHeaders }
        }).then((response) => {
            response.blob().then((blob) => {
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `Oferta n°${id}.docx`;
                a.click();
                window.URL.revokeObjectURL(url);
            });
        });
    };

    const changeDocumentCheckRoutes = (
        id: number,
        checkValue: boolean,
        events: ServiceEvents = {}
    ) => {
        const data: CheckQuoteDocumentRoutes = { is_route_segment_document: checkValue };

        doPut({
            ...events,
            url: EndPoints.QUOTES.CHANGE_CHECK_ROUTES_DOCUMENT_QUOTE.replace(':id', id.toString()),
            body: data,
            setFetching: setFetchingChangeDocumentCheckRoutes
        });
    };

    const createQuote = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.QUOTES.CREATE_QUOTE,
            setFetching: setFetchingCreateQuote
        });
    };

    const storeQuote = (quoteDataForm: QuoteDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.QUOTES.STORE_QUOTE,
            body: quoteDataForm,
            setFetching: setFetchingStoreQuote
        });
    };

    const repeatQuote = (id: number, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.QUOTES.REPEAT_QUOTE,
            body: { quote_id: id },
            setFetching: setFetchingRepeatQuote
        });
    };

    const editQuote = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.QUOTES.EDIT_QUOTE.replace(':id', id.toString()),
            setFetching: setFetchingEditQuote
        });
    };

    const showQuote = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.QUOTES.SHOW_QUOTE.replace(':id', id.toString()),
            setFetching: setFetchingShowQuote
        });
    };

    const updateQuote = (id: number, quoteDataFrom: QuoteDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.QUOTES.UPDATE_QUOTE.replace(':id', id.toString()),
            body: quoteDataFrom,
            setFetching: setFetchingUpdateQuote
        });
    };

    const deleteQuote = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.QUOTES.DELETE_QUOTE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteQuote
        });
    };

    const confirmQuote = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.QUOTES.CONFIRM_QUOTE.replace(':id', id.toString()),
            setFetching: setFetchingChangeStatusQuote
        });
    };

    const rejectQuote = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.QUOTES.REJECT_QUOTE.replace(':id', id.toString()),
            setFetching: setFetchingChangeStatusQuote
        });
    };

    const clientReviewQuote = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.QUOTES.CLIENT_REVIEW_QUOTE.replace(':id', id.toString()),
            setFetching: setFetchingChangeStatusQuote
        });
    };

    const operationReviewQuote = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.QUOTES.OPERATION_REVIEW_QUOTE.replace(':id', id.toString()),
            setFetching: setFetchingChangeStatusQuote
        });
    };

    const preparationQuote = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.QUOTES.PREPARATION_QUOTE.replace(':id', id.toString()),
            setFetching: setFetchingChangeStatusQuote
        });
    };

    const getUrlQuotesFilters = (
        url: URL,
        quote_status: string | null = null,
        start_date: string | null = null,
        end_date: string | null = null,
        quote_id: number | null = null,
        client: string | null = null
    ) => {
        if (quote_status && quote_status !== 'TODOS') {
            url.searchParams.append('status', quote_status);
        }
        if (start_date) {
            url.searchParams.append('start_date', start_date);
        }
        if (end_date) {
            url.searchParams.append('end_date', end_date);
        }
        if (quote_id) {
            url.searchParams.append('id', String(quote_id));
        }
        if (client) {
            url.searchParams.append('client', String(client));
        }
        return url;
    };

    return {
        getAllQuotes,
        getExcelAllQuotes,
        createQuote,
        storeQuote,
        editQuote,
        showQuote,
        repeatQuote,
        updateQuote,
        deleteQuote,
        confirmQuote,
        rejectQuote,
        operationReviewQuote,
        clientReviewQuote,
        preparationQuote,
        getDocumentQuote,
        changeDocumentCheckRoutes,
        fetchingGetQuotes,
        fetchingShowQuote,
        fetchingStoreQuote,
        fetchingEditQuote,
        fetchingUpdateQuote,
        fetchingDeleteQuote,
        fetchingGetExcelQuotes,
        fetchingCreateQuote,
        fetchingRepeatQuote,
        fetchingChangeStatusQuote,
        fetchingChangeDocumentCheckRoutes
    };
};

export default useQuoteService;
