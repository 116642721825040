import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// Define las props y sus tipos
interface PreviewImageTableProps {
    image: string
    close: () => void;
}

const PreviewImageTable: React.FC<PreviewImageTableProps> = ({ image, close }) => {

    return (
        <>                <div
                    className="col-md-4 order-3 d-none d-md-block text-center"
                    style={
                        {
                            // height: '500px',
                            // maxHeight: '500px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            padding: '10px',
                            borderRadius: '8px',
                            overflow: 'hidden'
                        }
                    }
                >
                    <img
                        src={image}
                        className="img-fluid"
                        style={
                            {
                                maxWidth: '100%',
                                maxHeight: '100%',
                                height: 'auto',
                                objectFit: 'contain'
                            }
                        }
                    />
                    <div
                        style={
                            {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '10px'
                            }
                        }
                    >
                        <button className="btn btn-danger" onClick={close}>Cerrar</button>
                    </div>
                </div>
</>
    );
};

export default PreviewImageTable;

