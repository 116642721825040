import moment from 'moment';
import { TransportOrder } from './TransportOrder';
import { Unit } from './Unit';

export interface LoadTicket {
    id?: number;
    issue_date?: string;
    quantity?: number;
    unit_id?: number;
    unit?: Unit;
    file?: File;
    transport_order_id?: number;
    transport_order?: TransportOrder;
}

export interface LoadTicketDataForm {
    id?: number;
    issue_date?: string;
    quantity?: number;
    unit_id?: number;
    unit?: string;
    file?: File;
    transport_order_id?: number;
    is_approved?: boolean;
}

export interface LoadTicketFilters {
    load_ticket_id?: number | null;
    issue_date?: string | '';
    quantity?: string | null;
    unit?: string | null;
    transport_order_id?: string | null;

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultLoadTicketDataForm: LoadTicketDataForm = {
    quantity: 0,
    unit_id: 0
};
