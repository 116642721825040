import React from 'react';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { momentParseDate } from '../../../../helpers';
import { CheckingAccountHistoryDataForm, CheckingAccountHistoryFilter } from '../../../../app/models/CheckingAccountHistory';

interface Props {
    checkingAccountHistories: CheckingAccountHistoryDataForm[];
    totalRows: number;
    loading?: boolean;
    filter: CheckingAccountHistoryFilter;
    setFilter: (filter: CheckingAccountHistoryFilter) => void;
    paginationRowsPerPageOptions?: any[];
}

const CheckingAccountHistoryDataTable = ({
    checkingAccountHistories,
    totalRows,
    loading = false,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'Fecha',
            selector: (row: any) => row.created_at,
            sortable: true,
            sortField: 'created_at',
            cell: (row: CheckingAccountHistoryDataForm) => (
                <span className="">{momentParseDate(row.created_at)}</span>
            )
        },
        // {
        //     name: 'Rol',
        //     selector: (row: any) => row.role,
        //     sortable: true,
        //     sortField: 'role',
        //     cell: (row: CheckingAccountHistoryDataForm) => (
        //         <span className="">{row.role}</span>
        //     )
        // },
        {
            name: 'Usuario',
            selector: (row: any) => row.created_user,
            sortable: true,
            sortField: 'created_user',
            cell: (row: CheckingAccountHistoryDataForm, index: any, column: any) => (
                <span className="">
                <div
                    dangerouslySetInnerHTML={{
                    __html: row.created_user ? String(row.created_user) : ''               

                }}
                />
            </span>
            )
        },

        {
            name: 'Rol',
            selector: (row: any) => row.group_name,
            sortable: true,
            sortField: 'group_name',
            cell: (row: CheckingAccountHistoryDataForm, index: any, column: any) => (
                <span className="">
                <div
                    dangerouslySetInnerHTML={{
                    __html: row.group_name ? String(row.group_name) : ''               

                }}
                />
            </span>
            )
        },
        {
            name: 'Acción',
            selector: (row: any) => row.action,
            sortable: true,
            sortField: 'action',
            cell: (row: CheckingAccountHistoryDataForm) => (
                <span className="">{row.action}</span>
            )
        }
    ];

    return (
        <>
            <DefaultDataTable
                columns={columns}
                data={checkingAccountHistories}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default CheckingAccountHistoryDataTable;
