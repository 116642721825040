import React from 'react';
import { DailyCarrier, DailyCarrierFilters } from '../../../app/models/DailyCarrier';
import ButtonTableAction from '../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../components/default/DefaultDataTable';

interface Props {
    dailyCarriers: DailyCarrier[];
    totalRows: number;
    loading?: boolean;
    edit?: (dailyCarrierId: number) => void;
    show?: (dailyCarrierId: number) => void;
    destroy?: (dailyCarrierId: number) => void;
    filter: DailyCarrierFilters;
    setFilter: (filter: DailyCarrierFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const DailyCarrierDataTable = ({
    dailyCarriers,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: DailyCarrier, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.id}
                </span>
            )
        },
        // {
        //     name: 'Proveedor',
        //     selector: (row: any) => row.provider,
        //     sortable: true,
        //     sortField: 'provider',
        //     cell: (row: any, index: any, column: any) => (
        //         <span className="">
        //             {' '}
        //             {/* Agrega la clase de Bootstrap que desees */}
        //             <div dangerouslySetInnerHTML={{ __html: row.provider }} />
        //         </span>
        //     )
        // },
        {
            name: 'Conductor',
            selector: (row: any) => row.carrier,
            sortable: true,
            sortField: 'carrier',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    <div dangerouslySetInnerHTML={{ __html: row.carrier }} />
                </span>
            )
        },
        {
            name: 'Camión',
            selector: (row: any) => row.truck,
            sortable: true,
            sortField: 'truck',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    <div dangerouslySetInnerHTML={{ __html: row.truck }} />
                </span>
            )
        },
        {
            name: 'Tolvas',
            selector: (row: any) => row.hopper,
            sortable: true,
            sortField: 'hopper',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    <div dangerouslySetInnerHTML={{ __html: row.hopper }} />
                </span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];
    return (
        <DefaultDataTable
            columns={columns}
            data={dailyCarriers}
            progressPending={loading}
            paginationTotalRows={totalRows}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

export default DailyCarrierDataTable;
