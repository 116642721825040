import React, { useEffect } from 'react';
import { Afp } from '../../../../app/models/Afp';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Bank } from '../../../../app/models/Bank';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { ProductDataForm } from '../../../../app/models/Product';
import { Unit } from '../../../../app/models/Unit';

type Props = {
    productDataForm: ProductDataForm;
    setProductDataForm: (productDataForm: ProductDataForm) => void;
    units: Unit[];
    errorFields?: any;
};

const ProductForm = ({ productDataForm, setProductDataForm, units, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setProductDataForm({ ...productDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setProductDataForm({ ...productDataForm, ...obj });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={productDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre de producto"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Unidad</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="unit_id"
                        id="unit_id"
                        inputValue={productDataForm.unit_id}
                        options={SelectTwoMapperOptions(units, 'id', ['name'])}
                        hasError={fieldHasError('unit_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('unit_id', value.value)}
                        placeholder={'Seleccione unidad'}
                        onFocus={() => onFocusRemove('unit_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('unit_id')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductForm;
