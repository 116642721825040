import React, { useEffect, useState } from 'react';
import useUserResponseDetailService from '../../../app/services/hooks/useUserResponseDetail';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { UserResponseDetail } from '../../../app/models/UserResponseDetail';
import { UserResponseOption } from '../../../app/models/UserResponseOption';
import { UserResponseFile } from '../../../app/models/UserResponseFile';
import { InputResponseType } from '../../../app/shared/enums';
import { momentParseDate, momentParseDateTime } from '../../../helpers';
import { formatNumber } from '../../../utils/utils';

interface Props {
    userResponseId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UserResponseDetailContainer = ({ userResponseId, onSaved, onCancel, onError }: Props) => {

    const { getAllUserResponseDetailsByUserResponse, fetchingGetUserResponseDetailsByUserResponse } = useUserResponseDetailService();

    const [ responseDetail, setResponseDetail ] = useState<UserResponseDetail[]>([])
    const [ responseOptions, setResponseOptions ] = useState<UserResponseOption[]>([])
    const [ responseFiles, setResponseFiles ] = useState<UserResponseFile[]>([])
    
    useEffect(() => {
        getAllUserResponseDetailsByUserResponse(userResponseId, {
            onSuccess: (response: ServiceResponse) => {
                setResponseDetail(response.data.response_detail);
                setResponseOptions(response.data.response_options);
                setResponseFiles(response.data.response_files);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
            }
        })
    },[ userResponseId ]);

    const getOptionsForResponseDetail = (detailId: number) => {
        return responseOptions.filter(option => option.user_response_detail_id === detailId);
    };

    return (
        <>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Pregunta</th>
                            <th scope="col">Respuesta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {responseDetail.map((detail, index) => (
                            <tr key={detail.id}>
                                <th scope="row" className="align-top">{index + 1}</th>
                                <td className="align-top">{detail.question_text}</td>
                                <td className="align-top">
                                    {detail.question_value ? (
                                        <p>
                                            {detail.response_type === InputResponseType.DATETIME
                                            ? momentParseDateTime(detail.question_value)
                                            : detail.response_type === InputResponseType.DATE
                                            ? momentParseDate(detail.question_value)
                                            : detail.response_type === InputResponseType.NUMBER
                                            ? !isNaN(Number(detail.question_value))
                                                ? formatNumber(Number(detail.question_value))
                                                : ''
                                            : detail.question_value
                                            }
                                        
                                        </p>
                                    ) : (
                                        <p>
                                            {getOptionsForResponseDetail(detail.id!)
                                                .filter(option => option.is_response) 
                                                .map(option => option.text) 
                                                .join(', ')
                                            }
                                        </p>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default UserResponseDetailContainer;
