import React from 'react';
import { IconProps } from '../types';

export const VehicleModel: React.FC<IconProps> = ({ size = 26, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Icons"
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <path d="M29.9,17.7C29.9,17.6,29.9,17.6,29.9,17.7c-0.1-0.1-0.1-0.2-0.1-0.2l-4-6C25.6,11.2,25.3,11,25,11h-4V9.8L26.2,9 C26.6,8.9,27,8.5,27,8V6c0-0.6-0.4-1-1-1h-9c-0.4,0-0.7,0.2-0.9,0.6L13.4,11H3c-0.6,0-1,0.4-1,1v5c0,0.3,0.1,0.5,0.3,0.7L5,20.4 C3.2,21.2,2,23,2,25c0,2.8,2.2,5,5,5s5-2.2,5-5c0-0.3,0-0.7-0.1-1h4.2C16,24.3,16,24.7,16,25c0,2.8,2.2,5,5,5s5-2.2,5-5h3 c0.6,0,1-0.4,1-1v-6C30,17.9,30,17.8,29.9,17.7z M21,13h3.5l2.7,4h-0.9c-1,0-1.9-0.6-2.3-1.4C23.7,15.2,23.4,15,23,15h-2V13z M7,28 c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S8.7,28,7,28z M17,18H7c-0.6,0-1-0.4-1-1s0.4-1,1-1h10c0.6,0,1,0.4,1,1S17.6,18,17,18z M21,28 c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S22.7,28,21,28z" />
            </g>
        </svg>
    );
};
