import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Provision } from '../../../app/models/Provision';
import ProvisionForm from './forms/ProvisionForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    provision: Provision;
    setProvision: (provision: Provision) => void;
    errorFields?: any;
}

const ProvisionFormContainer = ({
    fetching,
    action,
    cancel,
    provision,
    setProvision,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ProvisionForm
                        provisionDataForm={provision}
                        setProvisionDataForm={setProvision}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ProvisionFormContainer;
