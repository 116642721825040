import React from 'react';
import { QuestionDataForm } from '../../../../../../app/models/Question';

type Props = {
    input: QuestionDataForm;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean
};

const QuestionTimeField = ({ input, handleChange, disabled=false }: Props) => {
    return (
        <>
            <div className="mb-5">
                <input
                    name={`${input.id}`}
                    type="time"
                    className="form-control"
                    value={`${input.text}`}
                    onChange={(e) => handleChange(e)}
                    disabled={disabled}
                />
            </div>
        </>
    );
};

export default QuestionTimeField;
