import React from 'react';
import { IconProps } from '../types';

export const Bank: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.2"
            id="bank_icon_by_Adioma"
            baseProfile="tiny"
            width={size}
            height={size}
            viewBox="0 0 256 256"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <path d="M244.5,49L126.7,0.6L10,50.1v34.4h234.5V49z M131.8,66.9v8.2h-7.9v-7.7c-4.5-0.2-8.7-1.3-10.9-2.5l2-8.2 c2.6,1.3,6.7,2.7,11,2.7c4.7,0,6.9-2,6.9-4.9s-2.2-4-7.5-6c-7.4-2.6-12.1-6.3-12.1-13c0-5.9,3.8-10.5,10.5-12.3v-8.2h7.9v7.7 c4,0.2,6.8,1,9,2.2l-2.2,8c-1.8-0.7-4.9-2-8.9-2c-4.1,0-6.3,2-6.3,4.1c0,2.7,2.6,3.9,8.2,6.1c7.6,2.7,11.2,6.7,11.2,13 C142.6,60.2,139.1,64.9,131.8,66.9z M245.8,230.9v-19.7H236v-96.7h9.8V94.9H9.9v19.7h10.1v96.7H9.8v19.6H0v24.8h255.6v-24.8 L245.8,230.9z M39.7,211.4v-96.7h19.7v96.7H39.7z M78.9,211.4v-96.7h19.7v96.7H78.9z M118.3,211.4v-96.7h19.7v96.7H118.3z M157.5,211.4v-96.7h19.7v96.7H157.5z M196.8,211.4v-96.7h19.7v96.7H196.8z" />
            </g>
        </svg>
    );
};
