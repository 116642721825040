import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ActiveControl from '../../../../components/ActiveControl';
import { SurveyDataForm, SurveyFilters } from '../../../../app/models/Survey';
import SurveySwitchChange from '../SurveySwitchChange';
import SurveyFilter from './SurveyFilter';

interface Props {
    surveys: SurveyDataForm[];
    totalRows: number;
    loading?: boolean;
    hideActive?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    showResponses?: (id: number) => void;
    filter: SurveyFilters;
    setFilter: (filter: SurveyFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const SurveyDataTable = ({
    surveys,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    hideActive = false,
    showResponses,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: SurveyDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Título',
            selector: (row: any) => row.title,
            sortable: true,
            sortField: 'title',
            cell: (row: SurveyDataForm, index: any, column: any) => (
                <span className="">{row.title}</span>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row?.is_active,
            sortable: true,
            sortField: 'is_active',
            omit: hideActive,
            cell: (row: SurveyDataForm, index: any, column: any) => (
                <SurveySwitchChange
                    checkValue={row.is_active ?? true}
                    surveyId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: SurveyDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {showResponses && (
                        <ButtonTableAction
                            callbackFunction={() => showResponses(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver Respuestas'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];
    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <SurveyFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={surveys}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default SurveyDataTable;
