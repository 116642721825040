import useFetch from '../../../hooks/useFetch';
import { useContext, useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { DispatchProgram, DispatchProgramFilters } from '../../models/DispatchProgram';
import { SplitDataForm } from '../../models/TransportationSubOrderItem';
import { QuoteFilters } from '../../models/Quote';
import { fetchDefaultHeaders } from '../shared/vars';
import { AppContext } from '../../../contexts/AppContext';

const useDispatchProgramService = () => {
    const { doPost, doGet, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [fetchingGetDispatchPrograms, setFetchingGetDispatchPrograms] = useState(false);
    const [fetchingCreateDispatchProgram, setFetchingCreateDispatchProgram] = useState(false);
    const [fetchingStoreDispatchProgram, setFetchingStoreDispatchProgram] = useState(false);
    const [fetchingEditDispatchProgram, setFetchingEditDispatchProgram] = useState(false);
    const [fetchingUpdateDispatchProgram, setFetchingUpdateDispatchProgram] = useState(false);
    const [fetchingDeleteDispatchProgram, setFetchingDeleteDispatchProgram] = useState(false);
    const [fetchingShowDispatchProgram, setFetchingShowDispatchProgram] = useState(false);
    const [
        fetchingStoreSplitTransportationSubOrderItem,
        setFetchingStoreSplitTransportationSubOrderItem
    ] = useState(false);

    const [
        fetchingShowTransportationOrderDispatchProgram,
        setFetchingShowTransportationOrderDispatchProgram
    ] = useState(false);

    const [
        fetchingGetTransportationSubOrderItemsDispatchProgram,
        setFetchingGetTransportationSubOrderItemsDispatchProgram
    ] = useState(false);

    const [fetchingSplitTransportationSubOrderItem, setFetchingSplitTransportationSubOrderItem] =
        useState(false);

    const getDispatchPrograms = (
        dispatchProgramFilters: DispatchProgramFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(dispatchProgramFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.DISPATCH_PROGRAMS.GET_DISPATCH_PROGRAMS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetDispatchPrograms
        });
    };

    const createDispatchProgram = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.CREATE_DISPATCH_PROGRAM,
            setFetching: setFetchingCreateDispatchProgram
        });
    };

    const storeDispatchProgram = (quoteDataForm: DispatchProgram, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.STORE_DISPATCH_PROGRAM,
            body: quoteDataForm,
            setFetching: setFetchingStoreDispatchProgram
        });
    };

    const editDispatchProgram = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.EDIT_DISPATCH_PROGRAM.replace(':id', id.toString()),
            setFetching: setFetchingEditDispatchProgram
        });
    };

    const updateDispatchProgram = (
        id: number,
        dispatchProgram: DispatchProgram,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.UPDATE_DISPATCH_PROGRAM.replace(':id', id.toString()),
            body: dispatchProgram,
            setFetching: setFetchingUpdateDispatchProgram
        });
    };

    const showDispatchProgram = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.SHOW_DISPATCH_PROGRAM.replace(':id', id.toString()),
            setFetching: setFetchingShowDispatchProgram
        });
    };

    const deleteDispatchProgram = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.DELETE_DISPATCH_PROGRAM.replace(':id', id.toString()),
            setFetching: setFetchingDeleteDispatchProgram
        });
    };

    const showTransportationOrderDispatchProgram = (
        transportation_order_item_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.SHOW_TRANSPORTATION_ORDER_DISPATCH_PROGRAM.replace(
                ':id',
                transportation_order_item_id.toString()
            ),
            setFetching: setFetchingShowTransportationOrderDispatchProgram
        });
    };

    const getTransportationSubOrderItemsDispatchProgram = (
        id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.GET_TRANSPORTATION_SUB_ORDER_ITEMS_DISPATCH_PROGRAM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingGetTransportationSubOrderItemsDispatchProgram
        });
    };

    const splitTransportationSubOrderItem = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.SPLIT_TRANSPORTATION_SUB_ORDER_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingSplitTransportationSubOrderItem
        });
    };

    const storeSplitTransportationSubOrderItem = (
        splitDataForm: SplitDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.DISPATCH_PROGRAMS.STORE_SPLIT_TRANSPORTATION_SUB_ORDER_ITEM,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: splitDataForm,
            requestType: 'json',
            setFetching: setFetchingStoreSplitTransportationSubOrderItem
        });
    };

    const getExcelAllDispatchPrograms = (
        dispatchProgramFilters: DispatchProgramFilters,
        events: ServiceEvents = {}
    ) => {
        if (showLoading) showLoading('download', 'descargando archivo...');

        let url = new URL(EndPoints.DISPATCH_PROGRAMS.GET_EXCEL_DISPATCH_PROGRAMS);
        url = getUrlDispatchProgramFilters(
            url,
            dispatchProgramFilters.dispatch_program_status,
            dispatchProgramFilters.start_date,
            dispatchProgramFilters.end_date,
            dispatchProgramFilters.dispatch_program_id,
            dispatchProgramFilters.transportation_order_id,
            dispatchProgramFilters.quote_id,
            dispatchProgramFilters.client
        );

        fetch(url.href, { headers: { ...fetchDefaultHeaders } })
            .then((response) => {
                response
                    .blob()
                    .then((blob) => {
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = 'despachos.xlsx';
                        a.click();
                        window.URL.revokeObjectURL(url);
                        if (changeAnimation)
                            changeAnimation('downloadSuccess', 'descarga exitosa', true);
                    })
                    .catch((error) => {
                        if (changeAnimation) changeAnimation('error', 'descarga fallida', true);
                    });
            })
            .catch((error) => {
                if (changeAnimation) changeAnimation('error', 'descarga fallida', true);
            });
    };

    const getUrlDispatchProgramFilters = (
        url: URL,
        transportation_order_status: string | null = null,
        start_date: string | null = null,
        end_date: string | null = null,
        dispatch_program_id: number | null = null,
        transportation_order_id: number | null = null,
        quote_id: number | null = null,
        client: string | null = null
    ) => {
        if (transportation_order_status && transportation_order_status !== 'TODOS') {
            url.searchParams.append('status', transportation_order_status);
        }
        if (start_date) {
            url.searchParams.append('start_date', start_date);
        }
        if (end_date) {
            url.searchParams.append('end_date', end_date);
        }
        if (dispatch_program_id) {
            url.searchParams.append('id', String(dispatch_program_id));
        }
        if (transportation_order_id) {
            url.searchParams.append('transportation_order', String(transportation_order_id));
        }
        if (quote_id) {
            url.searchParams.append('quote', String(quote_id));
        }
        if (client) {
            url.searchParams.append('client', String(client));
        }
        return url;
    };

    return {
        fetchingGetDispatchPrograms,
        fetchingDeleteDispatchProgram,
        fetchingCreateDispatchProgram,
        fetchingStoreDispatchProgram,
        fetchingEditDispatchProgram,
        fetchingUpdateDispatchProgram,
        fetchingShowDispatchProgram,
        fetchingShowTransportationOrderDispatchProgram,
        fetchingGetTransportationSubOrderItemsDispatchProgram,
        fetchingSplitTransportationSubOrderItem,
        fetchingStoreSplitTransportationSubOrderItem,
        getDispatchPrograms,
        createDispatchProgram,
        storeDispatchProgram,
        editDispatchProgram,
        updateDispatchProgram,
        deleteDispatchProgram,
        showDispatchProgram,
        showTransportationOrderDispatchProgram,
        getTransportationSubOrderItemsDispatchProgram,
        splitTransportationSubOrderItem,
        storeSplitTransportationSubOrderItem,
        getExcelAllDispatchPrograms
    };
};

export default useDispatchProgramService;
