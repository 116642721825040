import React from 'react';
import { IconProps } from '../../types';

export const Guide: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            id="Capa_1"
            viewBox="0 0 60 60"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path d="M54,12H42V7h9c0.6,0,1-0.4,1-1c0-3.4-2.6-6-6-6H6C1.3,0,0,3.9,0,6v53c0,0.6,0.4,1,1,1h8h32h8c0.6,0,1-0.4,1-1V19h9 c0.6,0,1-0.4,1-1C60,14.6,57.4,12,54,12z M49.9,5h-7.8c0.1-0.4,0.3-0.8,0.5-1.2l0.1-0.1c0.1-0.2,0.2-0.3,0.4-0.4 c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.4-0.3c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.1-0.1,0.2-0.1 c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0,0.1,0,0.2,0c0.2,0,0.5-0.1,0.8-0.1C47.9,2,49.5,3.3,49.9,5z M2,6c0-0.2,0-4,4-4h35.5 C40.6,3,40,4.4,40,6v6H14c-4.7,0-6,3.9-6,6v40H2V6z M48,58h-7H10V18c0-0.2,0-4,4-4h35.5c-0.9,1-1.5,2.4-1.5,4V58z M50.1,17 c0.1-0.4,0.3-0.8,0.5-1.2l0.1-0.1c0.1-0.2,0.2-0.3,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.4-0.3 c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0,0.1,0,0.2,0 c0.2,0,0.5-0.1,0.8-0.1c1.9,0,3.5,1.3,3.9,3h-7.9V17z" />
                        <path d="M44,35h-5v2h4v8H31c-0.6,0-1,0.4-1,1v7h-3v-5c0-0.6-0.4-1-1-1H15v-2h-2v9c0,0.6,0.4,1,1,1h30c0.6,0,1-0.4,1-1V36 C45,35.4,44.6,35,44,35z M15,49h10v4H15V49z M32,53v-6h11v6H32z" />
                        <path d="M15,37h18v-2H14c-0.6,0-1,0.4-1,1v3h2V37z" />
                        <path d="M37,42V31.4l1.3,1.3l1.4-1.4l-3-3c-0.4-0.4-1-0.4-1.4,0l-3,3l1.4,1.4l1.3-1.3V41H12v2h24C36.6,43,37,42.6,37,42z" />
                        <rect x="34" y="49" width="2" height="2" />
                        <rect x="39" y="49" width="2" height="2" />
                        <rect x="14" y="18" width="6" height="2" />
                        <rect x="14" y="22" width="16" height="2" />
                        <rect x="28" y="4" width="8" height="2" />
                        <rect x="24" y="8" width="12" height="2" />
                        <rect x="39" y="18" width="2" height="2" />
                        <rect x="43" y="18" width="2" height="2" />
                        <rect x="39" y="22" width="2" height="2" />
                        <rect x="43" y="22" width="2" height="2" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
