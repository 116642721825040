import React from 'react'
import DefaultDataTable from '../../../../components/default/DefaultDataTable'
import { MonthlyClosureDetailDataForm, MonthlyClosureDetailFilters } from '../../../../app/models/MonthlyClosureDetail';
import { formatDateToMonth, momentParseDateTime } from '../../../../helpers';
import { DefaultDParams } from '../../../../app/shared/interfaces';
import MonthlyClosureDetailStatusChange from '../MonthlyClosureDetailStatusChange';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import MonthlyClosureDetailFilter from './MonthlyClosureDetailFilter';

interface Props {
    monthlyClosureDetails: MonthlyClosureDetailDataForm[];
    totalRows: number;
    loading?: boolean;
    filter: MonthlyClosureDetailFilters;
    setFilter: (filter: MonthlyClosureDetailFilters) => void;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    showHistory?: (id: number) => void;
    onSearch: () => void;
    statuses: DefaultDParams[];
    callbackSuccess?: () => void;
    isShow?: boolean;
}


const MonthlyClosureDetailDataTable = ({
    monthlyClosureDetails,
    totalRows,
    loading,
    filter,
    setFilter,
    showHistory,
    statuses,
    callbackSuccess,
    onSearch,
    isShow
}: Props) => {

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: MonthlyClosureDetailDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Sociedad',
            selector: (row: any) => row.society,
            sortable: true,
            sortField: 'society',
            cell: (row: MonthlyClosureDetailDataForm, index: any, column: any) => <span className="">{row.society}</span>
        },
        {
            name: 'Creado en',
            selector: (row: any) => row.created_at,
            sortable: true,
            sortField: 'created_at',
            cell: (row: MonthlyClosureDetailDataForm, index: any, column: any) => 
            <span className="">{momentParseDateTime(row.created_at)}</span>
        },
        {
            name: 'Creado por',
            selector: (row: any) => row?.created_user,
            sortable: true,
            sortField: 'created_user',
            cell: (row: MonthlyClosureDetailDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.created_user ? row.created_user : '' }}
                ></span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: MonthlyClosureDetailDataForm, index: any, column: any) => (

                (
                    !isShow ?
                        <MonthlyClosureDetailStatusChange
                            detail={row} 
                            statuses={row.statuses!} 
                            callbackSuccess={callbackSuccess}
                        />
                    :
                    (
                        statuses.find(item => item.id === row.status) ? statuses.find(item => item.id === row.status)?.name : ''
                    )
                
                )
           
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: MonthlyClosureDetailDataForm, index: any, column: any) => (
                <div className="">
                    {showHistory &&
                        
                        <ButtonTableAction
                            callbackFunction={() => showHistory(row?.id ?? -1)}
                            classIcon={'mdi mdi-history'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar el historial de este registro.'}
                            title={'Mostrar historial'}
                        />
                    }
                </div>
            )
        }
    ]
    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <MonthlyClosureDetailFilter
                        filter={filter} 
                        setFilter={setFilter} 
                        onSearch={onSearch}
                    />
                </div>
            </div>
            <DefaultDataTable
                columns={columns}
                data={monthlyClosureDetails}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    )
}

export default MonthlyClosureDetailDataTable
