import { useState, useContext } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { CheckingAccountHistoryFilter } from '../../models/CheckingAccountHistory';

const useCheckingAccountHistoryService = () => {
    const { doGet } = useFetch();
    const { showLoading, hideLoading } = useContext(AppContext);
    const [fetchingGetCheckingAccountHistory, setFetchingGetCheckingAccountHistory] = useState(false);

    const getCheckingAccountHistory = (
        checkingAccountId: number,
        historyFilter: CheckingAccountHistoryFilter,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(historyFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CHECKING_ACCOUNTS.GET_CHECKING_ACCOUNT_HISTORY.replace(':id', checkingAccountId.toString())}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetCheckingAccountHistory
        });
    };

    return {
        fetchingGetCheckingAccountHistory,
        getCheckingAccountHistory
    };
};

export default useCheckingAccountHistoryService;
