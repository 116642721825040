import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import useUserResponseService from '../../../app/services/hooks/useUserResponseService';
import { UserResponse, UserResponseFilters } from '../../../app/models/UserResponse';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useNavigationPage from '../../../hooks/useNavigationPage';
import UserResponseDataTable from '../components/tables/UserResponseDataTable';
import moment from 'moment';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import DefaultModal from '../../../components/default/DefaultModal';
import UserResponseDetailContainer from '../components/UserResponseDetailContainer';

type RouterParams = {
    id: string;
};

const SurveyResponses = () => {
    const { id } = useParams<RouterParams>();
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { getAllUserResponsesBySurvey, fetchingGetUserResponsesBySurvey, deleteUserResponse } = useUserResponseService();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Encuesta',
            url: '/surveys',
            isActive: false
        },
        {
            name: 'Respuestas #' + id,
            url: '/surveys/' + id + '/responses',
            isActive: true
        }
    ];

    const [ userResponses, setUserResponses ] = useState<UserResponse[]>([]);
    const [ totalRows, setTotalRows ] = useState(0);
    const [ showingDetails, setShowingDetails ] = useState<boolean>(false);
    const [ userResponseId, setUserResponseId ] = useState(0);
    const [ filter, setFilter ] = useState<UserResponseFilters>({
        complete_date_from: moment().date(1).format('YYYY-MM-DD'),
        complete_date_until: moment().format('YYYY-MM-DD'),
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
    });

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);
    
    const reloadTable = () => {
        getAllUserResponsesBySurvey(Number(id), filter, {
            onSuccess: (response: ServiceResponse) => {
                setUserResponses(response.data.user_responses);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        })
    };

    const destroy = (userResponseId: number) => {
        const _text = 'Está a punto de eliminar la encuesta respondida #' + userResponseId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando encuesta respondida...');
                        deleteUserResponse(userResponseId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La encuesta no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const showDetail = (userResponseId: number) => {
        setShowingDetails(true);
        setUserResponseId(userResponseId)
    };

    const hideDetail = () => {
        setShowingDetails(false);
    }

    return (
        <>
            <Breadcrumbs pageSection={`Encuestas resueltas #${id}`} breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="h3 mt-0 mb-2 card-title">Respuestas</div>
                            <UserResponseDataTable
                                userResponses={userResponses}
                                totalRows={totalRows}
                                loading={fetchingGetUserResponsesBySurvey}
                                destroy={destroy}
                                show={showDetail}
                                filter={filter}
                                setFilter={setFilter}
                                callbackSuccess={reloadTable}
                                onSearch={reloadTable}
                            />
                        </div>
                </div>
            </DefaultCard>
            {showingDetails ? (
                <DefaultModal
                    show={showingDetails}
                    handleClose={hideDetail}
                    title="Detalle de respuestas"
                    backdrop={true}
                    showFooter={false}
                >
                    <UserResponseDetailContainer
                        userResponseId={userResponseId}
                    />
                </DefaultModal>
            ) : null}
        </>
    )
}

export default SurveyResponses
