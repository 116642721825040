import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { toast } from 'react-toastify';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useQuestionService from '../../../app/services/hooks/useQuestionService';
import { QuestionDataForm } from '../../../app/models/Question';
import { ResponseType } from '../../../app/models/ResponseType';
import { SectionDataForm } from '../../../app/models/Section';
import QuestionForm from '../components/forms/QuestionForm';
import InputCard from '../components/InputCard';
import QuestionCard from '../components/QuestionCard';
import { InputResponseType } from '../../../app/shared/enums';
import moment from 'moment';


type RouterParams = {
    id: string;
    section_id: string;
    question_id: string;
    
};

const QuestionEdit = () => {

    const { id } = useParams<RouterParams>();
    const { section_id } = useParams<RouterParams>();
    const { question_id } = useParams<RouterParams>();
    

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Encuesta',
            url: '/surveys',
            isActive: false
        },
        {
            name: 'Editar encuesta #' + id,
            url: '/surveys/' + id + '/edit',
            isActive: true
        },
        {
            name: 'Editar pregunta',
            url: '/surveys/' + id + '/sections/' + section_id + '/questions' + question_id + '/edit',
            isActive: true
        }
    ];

    const history = useHistory();
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    

    const [question, setQuestion] = useState<QuestionDataForm>(
        {   
            question_text:'',
            initial_value: undefined,
            options: []
        }
    );
    const [copyQuestion, setCopyQuestion] = useState<QuestionDataForm>(
        {   
            question_text:'',
            initial_value: undefined,
            options: []
        }
    );

    const [responseTypes, setResponseTypes] = useState<ResponseType[]>([]);
   
    const [errorFields, setErrorFields] = useState<any>();
    const { editQuestion, updateQuestion, fetchingEditQuestion, fetchingUpdateQuestion } =
        useQuestionService();
    const [section, setSection] = useState<SectionDataForm>({});
    const [otherSectionQuestions, setOtherSectionQuestions] = useState<QuestionDataForm[]>([]);
    const responseTypeSelected = useRef<string | undefined>('');

    useEffect(() => {
        edit(parseInt(question_id));
    }, []);

    //El campo text realmente es para cuando el usuario va respondiendo cada pregunta, pero acá se está usando para que cambie de forma reactiva
    //al momento de cambia el valor inicial segun el tipo de respuestas(no todos los tipos tienen valor inicial, aun así se está reseteando el campo text)
    useEffect(() => {

        if (question.response_type == undefined) {
            responseTypeSelected.current = undefined;
            setQuestion(
                {
                    ...question,
                    initial_value: undefined,
                    options: [],
                    text: undefined
                }
            )
        }
        if (question.response_type && question.response_type != responseTypeSelected.current) {

            responseTypeSelected.current = question.response_type ?? '';

            if(question.response_type === copyQuestion.response_type){

                setQuestion(
                    {
                        ...question,
                        question_text: copyQuestion.question_text,
                        initial_value: copyQuestion.initial_value,
                        is_required: copyQuestion.is_required,
                        options: copyQuestion.options
                    }
                )

            }
            else{
                if(
                    question.response_type === InputResponseType.TEXT ||
                    question.response_type === InputResponseType.TEXTAREA
                ){
        
                    setQuestion(
                        {
                            ...question,
                            options: [],
                            text: '',
                            initial_value: ''
                        }
                    )
        
                }
                else if(question.response_type === InputResponseType.NUMBER){
        
                    setQuestion(
                        {
                            ...question,
                            options: [],
                            text: '0',
                            initial_value: '0'
                        }
                    )
        
                }
                else if(question.response_type === InputResponseType.DATE){
                    
                    setQuestion(
                        {
                            ...question,
                            options: [],
                            text: moment().format('YYYY-MM-DD'),
                            initial_value: moment().format('YYYY-MM-DD')
                        }
                    )
        
                }
                else if(question.response_type === InputResponseType.TIME){
                    
                    setQuestion(
                        {
                            ...question,
                            options: [],
                            text: moment().format('HH:mm'),
                            initial_value: moment().format('HH:mm')
                        }
                    )
        
                }
                else{
                    console.log("otro tipo")
                    setQuestion(
                        {
                            ...question,
                            options: [],
                            text: undefined,
                            initial_value: undefined
                        }
                    )
        
                }
            }
        }
    }, [question.response_type]);

    const edit = (questionId: number) => {

        editQuestion(questionId, {
            onSuccess: (response: ServiceResponse) => {
                setResponseTypes(response.data.response_types);
                setSection(response.data.question.section);
                setOtherSectionQuestions(response.data.other_section_questions);
                responseTypeSelected.current = response.data.question.response_type;
                setQuestion(response.data.question);
                setCopyQuestion(response.data.question);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/surveys/' + id + '/edit');
                }
            }
        });
    };

    const update = () => {
        if (question) {
            
            if (showLoading) showLoading('loading', 'Actualizando pregunta...');

            updateQuestion(Number(question?.id), question, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    setErrorFields(undefined);
                    navigationPage('/surveys/'+ response.data.survey_id + '/edit');
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    

    return (
        <>
            <Breadcrumbs pageSection={`Editar Pregunta ${section?.position ? `sección ${section.position}` : ""}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-9">
                    <DefaultCard>
                        {!fetchingEditQuestion ? (
                            <>
                                <QuestionForm
                                    questionForm={question}
                                    setQuestionForm={setQuestion}
                                    errorFields={errorFields}
                                    responseTypes={responseTypes}
                                />
                                
                            </>
                        ) : (
                            
                            <LazyLoading />
                        )}
                     
                    </DefaultCard>
                </div>
                <div className={'col-md-3'}>
                    <div className={'card mb-3'}>
                        <div className={'card-body'}>
                            <div className={'card-title'}> Vista Previa </div>
                            <div className={'border p-3'} style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                <QuestionCard currentQuestion={question} setCurrentQuestion={setQuestion}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row mt-2">
                <div className="col-md-9">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos respuesta</div>
                            <InputCard
                                questionForm={question}
                                setQuestionForm={setQuestion}
                                nextQuestionOptions={otherSectionQuestions}
                                errorFields={errorFields}
                            />
                        <div className="row justify-content-end">
                            
                            <div className="col-auto">
                                <ButtonSaveForm
                                    callbackSave={update}
                                    locked={fetchingUpdateQuestion}
                                />
                            </div>
                        </div>
                    </DefaultCard>
                </div>
            </div>
        </>
    );
};

export default QuestionEdit;
