import { TruckDataForm, TruckFilters } from '../../../../app/models/Truck';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ActiveControl from '../../../../components/ActiveControl';
import { HopperDataForm } from '../../../../app/models/Hopper';

import { momentParseDate } from '../../../../helpers';
import { AuthContext } from '../../../../contexts/AuthContext';

import { formatMoney, formatNumber } from '../../../../utils/utils';
import { LoadTicketDataForm, LoadTicketFilters } from '../../../../app/models/LoadTicket';
import { Link } from 'react-router-dom';
import LoadTicketFilter from './LoadTicketFilter';
import { useContext, useState } from 'react';
import circleGrey from '../../../../assets/images/icons/circle-color-grey.svg';
import circleRed from '../../../../assets/images/icons/circle-color-red.svg';
import circleGreen from '../../../../assets/images/icons/circle-color-green.svg';
import PreviewImageTable from '../../../../components/PreviewImageTable';

interface Props {
    loadTickets: LoadTicketDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    approved?: (id: number, value: boolean, message: string) => void;
    cancel?: (id: number, value: boolean) => void;
    filter: LoadTicketFilters;
    setFilter: (filter: LoadTicketFilters) => void;
    paginationRowsPerPageOptions?: any[];
    documentLoadTicket: (id: number) => void;
    loadTicketPage?: boolean;
    showLoadTicketHistory?: (loadTicketId: number) => void;
    onSearch: () => void;
}

const LoadTicketDataTable = ({
    loadTickets,
    totalRows,
    loading = false,
    edit,
    destroy,
    approved,
    cancel,
    show,
    filter,
    setFilter,
    loadTicketPage = false,
    documentLoadTicket,
    showLoadTicketHistory,
    onSearch
}: Props) => {
    const { auth } = useContext(AuthContext);
    const [loadTicketUrlFile, setLoadTicketUrlFile] = useState<string | undefined>(undefined);

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: LoadTicketDataForm, index: any, column: any) => (
                <span className="">
                    <img
                        src={
                            row.is_approved == null
                                ? circleGrey
                                : !row.is_approved
                                ? circleRed
                                : circleGreen
                        }
                        alt="circle"
                        style={{
                            width: '25px',
                            height: '25px',
                            marginRight: '5px',
                            marginBottom: '3px'
                        }}
                    />
                    {row.id}
                </span>
            )
        },
        {
            name: 'Orden de transporte',
            selector: (row: any) => row?.transport_order_id,
            sortable: true,
            sortField: 'transport_order_id',
            omit: !loadTicketPage,
            cell: (row: LoadTicketDataForm, index: any, column: any) => (
                <span className="">
                    {row.transport_order_id ? (
                        loadTicketPage ? (
                            <Link to={`/transport-orders/${row.transport_order_id}/edit`}>
                                #{row.transport_order_id}
                            </Link>
                        ) : (
                            row.transport_order_id
                        )
                    ) : (
                        ''
                    )}
                </span>
            )
        },
        {
            name: 'Cantidad',
            selector: (row: any) => row?.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: LoadTicketDataForm, index: any, column: any) => (
                <span className="">
                    {row.quantity ? formatNumber(row.quantity) : 0} {row.unit}
                </span>
            )
        },
        {
            name: 'Fecha de emisión',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'issue_date',
            cell: (row: LoadTicketDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },

        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: LoadTicketDataForm, index: any, column: any) => (
                <div className="">
                    {documentLoadTicket && (
                        <ButtonTableAction
                            callbackFunction={() => documentLoadTicket(row?.id ?? -1)}
                            classIcon={'mdi mdi-download'}
                            colorIcon={row.file ? 'text-success' : 'text-secondary'}
                            locked={!row.file}
                            errorMessage={'No se puede descargar este documento.'}
                            title={'Descargar documento'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (row.is_approved === false || row.is_approved === null) && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                    {approved &&
                        (row.is_approved === false || row.is_approved === null) &&
                        auth &&
                        auth?.roles &&
                        !auth?.roles.includes('Conductor') && (
                            <>
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, true, 'aprobado')
                                    }
                                    classIcon={'mdi mdi-checkbox-marked-circle'}
                                    colorIcon={'text-info'}
                                    errorMessage={'No se puede aprobar este registro.'}
                                    title={'Aprobar'}
                                />
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, false, 'rechazado')
                                    }
                                    classIcon={'mdi mdi-close-circle'}
                                    colorIcon={'text-danger'}
                                    errorMessage={'No se puede rechazar este registro.'}
                                    title={'Rechazar'}
                                />
                            </>
                        )}
                    {cancel && (row.is_approved === false || row.is_approved === true) && (
                        <ButtonTableAction
                            callbackFunction={() => cancel(row?.id ?? -1, row.is_approved ?? false)}
                            classIcon={'fa fa-ban'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede anular este registro.'}
                            title={'Anular'}
                        />
                    )}
                    {showLoadTicketHistory &&
                        auth &&
                        auth?.roles &&
                        !auth?.roles.includes('Conductor') && (
                            <ButtonTableAction
                                callbackFunction={() => showLoadTicketHistory(row?.id ?? -1)}
                                classIcon={'mdi mdi-history'}
                                colorIcon={'text-info'}
                                errorMessage={'No se puede mostrar el historial de este registro.'}
                                title={'Mostrar historial'}
                            />
                        )}
                </div>
            )
        }
    ];
        const rowClick = (row: any) => {
        setLoadTicketUrlFile(row.file);
    }

    const conditionalRowStylesClick = [
       {
          when: (row : any ) => row.file === loadTicketUrlFile,  // Condición para resaltar la fila
          style: {
            backgroundColor: '#13395E',  // Estilo de fondo para la fila seleccionada
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
    ];
    const removeImage = () => {
        setLoadTicketUrlFile(undefined); // Limpiar el estado de la imagen

    };

    return (
        <>
            <div className="row mt-3">
                {loadTicketPage && (
                    <div className="col">
                        <LoadTicketFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                    </div>
                )}
            </div>
            <div className="row mt-3">

                {
                    loadTicketUrlFile &&
                    (<PreviewImageTable image={loadTicketUrlFile} close={removeImage} />)


                }
                <div className={`col-12 col-md-${loadTicketUrlFile?8:12} order-2 order-md-1`}>
            <DefaultDataTable
                columns={columns}
                data={loadTickets}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                isMobileStacked={true}
                setFilter={setFilter}
                                onRowClick={rowClick}
                conditionalRowStyles={conditionalRowStylesClick}
            />
                                                        </div>
                </div>
        </>
    );
};

export default LoadTicketDataTable;
