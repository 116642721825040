import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useRut from '../../../../hooks/useRut';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { SectionDataForm } from '../../../../app/models/Section';

interface GeneralProps {
    sectionForm: SectionDataForm;
    setSectionForm: (sectionForm: SectionDataForm) => void;
    errorFields?: any;
}

const SectionForm = ({
    sectionForm,
    setSectionForm,
    errorFields
}: GeneralProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setSectionForm({ ...sectionForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setSectionForm({ ...sectionForm, ...obj });
    };

    if (!sectionForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Título</label>

                    <input
                        className={`form-control ${fieldHasError('title')}`}
                        type="text"
                        name="title"
                        id="title"
                        value={sectionForm.title}
                        onChange={handleChange}
                        placeholder="Ingrese título"
                        onFocus={() => onFocusRemove('title')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('title')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                <label className="col-form-label">Descripción</label>
                <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={sectionForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default SectionForm;
