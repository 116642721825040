import React from 'react';
import MonthYearPicker from '../../../../components/inputs/MonthYearPicker';
import { momentParseDate } from '../../../../helpers';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';
import { MonthlyClosureFilters } from '../../../../app/models/MonthlyClosure';

type Props = {
    filter: MonthlyClosureFilters;
    setFilter: (filter: MonthlyClosureFilters) => void;
    onSearch: () => void;
};

const MonthlyClosureFilter = ({ filter, setFilter, onSearch }: Props) => {
    
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                
                <div className="col-auto mb-3">
                    <label className="font-size-10 mb-1" htmlFor="period_from">
                        Período desde
                    </label>
                    <MonthYearPicker
                        className="form-control form-control-sm input-datepicker"
                        onMonthYearChange={(date) =>
                            handlerInputFilter({
                                target: {
                                    name: 'period_from',
                                    value: String(momentParseDate(date, 'YYYY-MM-DD'))
                                }
                            })
                        }
                    />
                </div>
                
               
                <div className="col-auto mb-3">
                    <label className="font-size-10 mb-1" htmlFor="period_until">
                        Período hasta
                    </label>
                    <MonthYearPicker
                        className="form-control form-control-sm input-datepicker"
                        onMonthYearChange={(date) =>
                            handlerInputFilter({
                                target: {
                                    name: 'period_until',
                                    value: String(momentParseDate(date, 'YYYY-MM-DD'))
                                }
                            })
                        }
                    />
                </div>
            </div>
            <div className='col-md-auto'>
                <div style={{marginTop: '22px'}}>
                    <ButtonSearchFilter callbackSearch={onSearch}/>
                </div>
            </div>
        </div>
    );
};

export default MonthlyClosureFilter;
