import React from 'react';
import { IconProps } from '../../types';

export const TransportOrder: React.FC<IconProps> = ({ size = 24, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Icons"
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                {' '}
                <path d="M23,28c-1.1,0-2.1-0.7-2.5-1.8c0-0.1,0-0.2-0.1-0.2h-8.9c0,0.1,0,0.2-0.1,0.2C11.1,27.3,10.1,28,9,28c-0.6,0-1,0.4-1,1 s0.4,1,1,1h14c0.6,0,1-0.4,1-1S23.6,28,23,28z" />{' '}
                <path d="M27,3H5C3.3,3,2,4.3,2,6v8h3h2c0.6,0,1-0.4,1-1s-0.4-1-1-1H5H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h1h3c0.6,0,1-0.4,1-1S8.6,8,8,8H5 V7c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1v2h4c0.3,0,0.6,0.1,0.8,0.4l3,4c0.1,0.2,0.2,0.4,0.2,0.6v4c0,0.6-0.4,1-1,1h-1.2 c-0.4,1.2-1.5,2-2.8,2s-2.4-0.8-2.8-2H18h-4.2c-0.4,1.2-1.5,2-2.8,2s-2.4-0.8-2.8-2H6c-0.6,0-1-0.4-1-1v-2H2v5c0,1.7,1.3,3,3,3h6.9 h8.1H27c1.7,0,3-1.3,3-3V6C30,4.3,28.7,3,27,3z" />{' '}
            </g>
        </svg>
    );
};
