import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useTransportOrderService from '../../../app/services/hooks/useTransportOrderService';
import { TransportationOrderStatus } from '../../../app/models/TransportationOrderStatus';
import { TransportOrderDataForm, TransportOrderFilters } from '../../../app/models/TransportOrder';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import TransportOrderEdit from './TransportOrderEdit';
import TransportOrderCreate from './TransportOrderCreate';
import { useHistory } from 'react-router-dom';
import useTransportOrderStatusHistoryService from '../../../app/services/hooks/useTransportOrderStatusHistoryService';
import TransportOrdersDataTable from './tables/TransportOrderDataTable';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import ColumnSetCreate from '../../Columns/subcomponents/ColumnSetCreate';
import ButtonEditColumns from '../../../components/buttons/ButtonEditColumns';
import { AuthContext } from '../../../contexts/AuthContext';

interface Props {
    workOrderId: number;

    truckId: number;

    isShow?: boolean;
}

const TransportOrderContainer = ({ workOrderId, truckId, isShow = false }: Props) => {
    const { auth } = useContext(AuthContext);
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllTransportOrdersByWorkOrder,
        fetchingGetTransportOrdersByWorkOrder,
        deleteTransportOrder,
        fetchingDeleteTransportOrder,
        fetchingGetExcelTransportOrdersByWorkOrder,
        getExcelAllTransportOrdersByWorkOrder
    } = useTransportOrderService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingGuide, setShowingGuide] = useState(false);
    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);
    const [editColumns, setEditColumns] = useState(false);

    const [transportOrderIdEdit, setTransportOrderIdEdit] = useState<number>(-1);

    const [transportOrderIdGuide, setTransportOrderIdGuide] = useState<number>(-1);
    const [statuses, setStatuses] = useState<TransportationOrderStatus[]>([]);

    const [transportOrders, setTransportOrders] = useState<TransportOrderDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [columnAlias, setColumnAlias] = useState<string[]>([]);

    const [filter, setFilter] = useState<TransportOrderFilters>({
        page: 1,
        per_page: 10,
        sort: 'program_date',
        order: 'desc',
        table_name: 'transport_order_by_work_order'
    });

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (transportOrderId: number) => {
        setShowingEdit(true);
        setTransportOrderIdEdit(transportOrderId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setTransportOrderIdEdit(-1);
    };

    const showGuide = (transportOrderId: number) => {
        setShowingGuide(true);
        setTransportOrderIdGuide(transportOrderId);
    };

    const hideGuide = () => {
        setShowingGuide(false);
        setTransportOrderIdGuide(-1);
    };

    const showExpense = (transportOrderId: number) => {
        navigationPage('/transport-orders/' + transportOrderId + '/edit');
    };

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const destroy = (transportOrderId: number) => {
        const _text = 'Está a punto de eliminar la orden de transporte #' + transportOrderId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading)
                            showLoading('loading', 'Eliminando orden de transporte...');
                        deleteTransportOrder(transportOrderId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La orden de transporte no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const reloadTable = () => {
        getAllTransportOrdersByWorkOrder(filter, workOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setTransportOrders(response.data.transport_orders);
                setTotalRows(response.data.total_rows);
                setStatuses(response.data.statuses);
                setColumnAlias(response.data.alias_list)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const downloadExcel = () => {
        if (showLoading) showLoading('download', 'Recuperando documento...');
        getExcelAllTransportOrdersByWorkOrder(workOrderId, filter, {
            onSuccess: (message: string) => {
                if (changeAnimation) changeAnimation('downloadSuccess', message, true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const showEditColumns = () => {
        setEditColumns(true);
    };

    const hideEditColumns = () => {
        setEditColumns(false);
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12 mb-2">
                        {!isShow && (
                            <ButtonCreate
                                title="Nueva orden de transporte"
                                callbackCreate={showCreate}
                            />
                        )}
                        {auth &&
                        auth?.roles &&
                        auth?.roles.includes('Administrador') &&
                            (<div className="col d-md-flex justify-content-end d-none">
                                <ButtonEditColumns
                                    callbackCreate={showEditColumns}
                                    title="Editar Columnas"
                                />
                            </div>)
                        }
                    </div>
                    <div className="col-12">
                        {isShow ? (
                            <TransportOrdersDataTable
                                transportOrders={transportOrders}
                                loading={fetchingGetTransportOrdersByWorkOrder}
                                totalRows={totalRows}
                                downloadExcel={downloadExcel}
                                filter={filter}
                                statuses={statuses}
                                setFilter={setFilter}
                                onSearch={reloadTable}
                                columnDict={columnAlias}
                            />
                        ) : (
                            <TransportOrdersDataTable
                                transportOrders={transportOrders}
                                edit={showEdit}
                                destroy={destroy}
                                statuses={statuses}
                                updateExpenses={showExpense}
                                loading={fetchingGetTransportOrdersByWorkOrder}
                                totalRows={totalRows}
                                downloadExcel={downloadExcel}
                                filter={filter}
                                setFilter={setFilter}
                                onSearch={reloadTable}
                                columnDict={columnAlias}
                            />
                        )}
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Orden de Transporte"
                    backdrop={true}
                    showFooter={false}
                >
                    <TransportOrderCreate
                        workOrderId={workOrderId}
                        truckId={truckId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Orden de transporte"
                    backdrop={true}
                    showFooter={false}
                >
                    <TransportOrderEdit
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        truckId={truckId}
                        onCancel={hideEdit}
                        onError={hideEdit}
                        transportOrderId={transportOrderIdEdit}
                    />
                </DefaultModal>
            ) : null}

            {editColumns ? (
                <DefaultModal
                    show={editColumns}
                    handleClose={hideEditColumns}
                    title="Editar columnas de la tabla"
                    backdrop={true}
                    showFooter={false}
                >
                    <ColumnSetCreate
                        tableName={filter.table_name!}
                        onSaved={() => {
                            reloadTable();
                            hideEditColumns();
                        }}
                        onCancel={hideEditColumns}
                        onError={hideCreate}                     
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TransportOrderContainer;
