import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { BankAccountDataForm } from '../../models/BankAccountType';

const useBankAccountService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBankAccountByReason, setFetchingGetBankAccountByReason] = useState(false);
    const [fetchingGetBankAccountByWorker, setFetchingGetBankAccountByWorker] = useState(false);
    const [fetchingDeleteBankAccount, setFetchingDeleteBankAccount] = useState(false);
    const [fetchingCreateBankAccount, setFetchingCreateBankAccount] = useState(false);
    const [fetchingStoreBankAccount, setFetchingStoreBankAccount] = useState(false);
    const [fetchingEditBankAccount, setFetchingEditBankAccount] = useState(false);
    const [fetchingUpdateBankAccount, setFetchingUpdateBankAccount] = useState(false);

    const getBankAccountByReason = (
        reason_id: number,
        worker_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT.BANK_ACCOUNT_BY_REASON.replace(
                ':reason_id',
                reason_id.toString()
            ).replace(':worker_id', worker_id.toString()),
            setFetching: setFetchingGetBankAccountByReason
        });
    };

    const getBankAccountByWorker = (
        worker_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT.BANK_ACCOUNT_BY_WORKER.replace(':worker_id', worker_id.toString()),
            setFetching: setFetchingGetBankAccountByWorker
        });
    };

    const createWorkerBankAccount = (
        worker_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT.CREATE_BANK_ACCOUNT_BY_WORKER.replace(':worker_id', worker_id.toString()),
            setFetching: setFetchingCreateBankAccount
        });
    };

    const editWorkerBankAccount = (
        bank_account_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT.EDIT_BANK_ACCOUNT.replace(':bank_account', bank_account_id.toString()),
            setFetching: setFetchingEditBankAccount
        });
    };

    const storeBankAccount = (bankAccount: BankAccountDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BANK_ACCOUNT.STORE_BANK_ACCOUNT,
            body: bankAccount,
            setFetching: setFetchingStoreBankAccount
        });
    };

    const updateBankAccount = (
        bankAccount: BankAccountDataForm, 
        bankAccountId: number, 
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.BANK_ACCOUNT.UPDATE_BANK_ACCOUNT.replace(':bank_account', bankAccountId.toString()),
            body: bankAccount,
            setFetching: setFetchingUpdateBankAccount
        });
    };

    const deleteBankAccount = (
        id: number,
        events: ServiceEvents = {}
    ) => {
        doDelete({
            ...events,
            url: EndPoints.BANK_ACCOUNT.DELETE_BANK_ACCOUNT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBankAccount
        });
    }

    return {
        fetchingGetBankAccountByReason,
        fetchingGetBankAccountByWorker,
        fetchingDeleteBankAccount,
        fetchingCreateBankAccount,
        fetchingStoreBankAccount,
        fetchingEditBankAccount,
        fetchingUpdateBankAccount,
        getBankAccountByReason,
        getBankAccountByWorker,
        deleteBankAccount,
        createWorkerBankAccount,
        storeBankAccount,
        editWorkerBankAccount,
        updateBankAccount,
    };
};

export default useBankAccountService;
