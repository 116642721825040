import React from 'react';
import {  QuestionDataForm, LinkInspectionOption } from '../../../../../../app/models/Question';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../../../components/SelectTwo';

type Props = {
    input: QuestionDataForm; // Asumiendo que InputQuestion incluye las opciones para los checkboxes
    handleChange: (optionValues: LinkInspectionOption[]) => void;
    disabled?: boolean
};

const QuestionMultipleSelectionField = ({ input, handleChange, disabled=false }: Props) => {
    return (
        <div className="mb-5">
           
            <SelectTwo
                name={(input.id)?.toString()}
                id={(input.id)?.toString()}
                inputValue={input.options?.filter((option: LinkInspectionOption) => option.is_response === true)}
                options={SelectTwoMapperOptions(input.options!)}
                onChange={(optionValues: any) => handleChange(optionValues)}
                isMulti={true}
                placeholder={'Seleccione opciones'}
                disabled={disabled}
            />
        </div>
    );
};

export default QuestionMultipleSelectionField;