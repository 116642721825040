import React from 'react';
import { QuestionDataForm } from '../../../../../../app/models/Question';

type Props = {
    input: QuestionDataForm;
    handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    disabled?: boolean
};

const QuestionTextAreaField = ({ input, handleChange, disabled=false }: Props) => {
    return (
        <>
            <div className="mb-5">
                <textarea
                    className="form-control"
                    name={`${input.id}`}
                    onChange={handleChange}
                    value={`${input.text}`}
                    rows={10}
                    disabled={disabled}
                />
            </div>
        </>
    );
};

export default QuestionTextAreaField;
