import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useAITolls = () => {
    const { doGet } = useFetch();

    const [fetchingPendingTollExpenses, setFetchingPendingTollExpenses] = useState(false);
    const [fetchingCountPendingTollExpenses, setFetchingCountPendingTollExpenses] = useState(false);

    const getPendingTollExpenses = (
        events: ServiceEvents = {},
        start_date?: string,
        end_date?: string
    ) => {
        const url = `${EndPoints.AI_TOLLS.PENDING_TOLL_EXPENSES}?${start_date ? `start_date=${start_date}` : ''}${
            end_date ? `&end_date=${end_date}` : ''
        }`;
        doGet({
            ...events,
            url,
            setFetching: setFetchingPendingTollExpenses,
        });
    };

    const getCountPendingTollExpenses = (
        events: ServiceEvents = {},
        start_date?: string,
        end_date?: string
    ) => {
        const url = `${EndPoints.AI_TOLLS.COUNT_PENDING_TOLL_EXPENSES}?${start_date ? `start_date=${start_date}` : ''}${
            end_date ? `&end_date=${end_date}` : ''
        }`;
        doGet({
            ...events,
            url,
            setFetching: setFetchingCountPendingTollExpenses,
        });
    };

    return {
        fetchingPendingTollExpenses,
        fetchingCountPendingTollExpenses,
        getPendingTollExpenses,
        getCountPendingTollExpenses
    };
};

export default useAITolls;
