import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';
import { TollExpenseDataForm, TollExpenseFilters } from '../../models/TollExpense';
import { AppContext } from '../../../contexts/AppContext';
import { TollExpenseHistoryDataForm } from '../../models/TollExpenseHistory';

const useTollExpenseService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [fetchingGetTollExpensesByTransportOrder, setFetchingGetTollExpensesByTransportOrder] =
        useState(false);

    const [fetchingStoreTollExpense, setFetchingStoreTollExpense] = useState(false);
    const [fetchingEditTollExpense, setFetchingEditTollExpense] = useState(false);

    const [fetchingGetUrlDocumentOnTransportOrder, setFetchingGetUrlDocumentOnTransportOrder] =
        useState(false);
    const [fetchingUpdateTollExpense, setFetchingUpdateTollExpense] = useState(false);
    const [fetchingDeleteTollExpense, setFetchingDeleteTollExpense] = useState(false);
    const [fetchingCreateTollExpense, setFetchingCreateTollExpense] = useState(false);
    const [fetchingShowTollExpense, setFetchingShowTollExpense] = useState(false);
    const [fetchingApprovedTollExpense, setFetchingApprovedTollExpense] = useState(false);
    const [fetchingCancelIsApprovedTollExpense, setFetchingCancelIsApprovedTollExpense] = useState(false);
    const [fetchingshowFileTollExpense, setFetchingshowFileTollExpense] = useState(false);

    const getAllTollExpensesByTransportOrder = (
        filter: TollExpenseFilters,
        transport_order_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.TOLL_EXPENSES.GET_TOLL_EXPENSES_BY_TRANSPORT_ORDER.replace(
            ':transport_order_id',
            transport_order_id.toString()
        )}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTollExpensesByTransportOrder
        });
    };

    const createTollExpense = (transportOrderId: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLL_EXPENSES.CREATE_TOLL_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ),
            setFetching: setFetchingCreateTollExpense
        });
    };

    const storeTollExpense = (
        transportOrderId: number,
        TollExpenseDataForm: TollExpenseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.TOLL_EXPENSES.STORE_TOLL_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ),
            body: TollExpenseDataForm,
            setFetching: setFetchingStoreTollExpense
        });
    };

    const editTollExpense = (transportOrderId: number, id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLL_EXPENSES.EDIT_TOLL_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingEditTollExpense
        });
    };

    const updateTollExpense = (
        transportOrderId: number,
        id: number,
        TollExpenseDataForm: TollExpenseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TOLL_EXPENSES.UPDATE_TOLL_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            body: TollExpenseDataForm,
            setFetching: setFetchingUpdateTollExpense
        });
    };

    const getUrlDocumentTollExpenseOnTransportOrder = (
        transportOrderId: number,
        id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.TOLL_EXPENSES.DOCUMENT_TOLL_EXPENSE_ON_TRANSPORT_ORDER.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingGetUrlDocumentOnTransportOrder
        });
    };

    const downloadDocument = (url_file: string) => {
        const link = document.createElement('a');
        link.href = url_file;
        link.download = 'documento gasto';
        link.target = '_blank';
        document.body.appendChild(link);

        // Simular un clic en el enlace para iniciar la descarga
        link.click();
        if (changeAnimation)
            changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
    };

    // const showTollExpense = (id: number, events: ServiceEvents = {}) => {
    //     doGet({
    //         ...events,
    //         url: EndPointsPegasus.TRANSPORT_ORDERS.SHOW_TRANSPORT_ORDER.replace(
    //             ':id',
    //             id.toString()
    //         ),
    //         setFetching: setFetchingShowTollExpense
    //     });
    // };

    const deleteTollExpense = (
        transportOrderId: number,
        id: number,
        events: ServiceEvents = {}
    ) => {
        doDelete({
            ...events,
            url: EndPoints.TOLL_EXPENSES.DELETE_TOLL_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingDeleteTollExpense
        });
    };

    const approvedTollExpense = (
        transportOrderId: number,
        id: number,
        formData: TollExpenseHistoryDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TOLL_EXPENSES.APPROVED_TOLL_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            body: formData,
            setFetching: setFetchingApprovedTollExpense
        });
    };

    const cancelIsApprovedTollExpense = (
        id: number,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TOLL_EXPENSES.CANCEL_APPROVED_TOLL_EXPENSE.replace(':id', id.toString()),
            setFetching: setFetchingCancelIsApprovedTollExpense
        });
    };

    const showFileTollExpense = (transportOrderId: number, id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLL_EXPENSES.SHOW_FILE_TOLL_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingshowFileTollExpense
        });
    };

    return {
        getAllTollExpensesByTransportOrder,

        createTollExpense,
        storeTollExpense,
        editTollExpense,
        updateTollExpense,
        // showTollExpense,
        deleteTollExpense,
        getUrlDocumentTollExpenseOnTransportOrder,
        downloadDocument,
        approvedTollExpense,
        cancelIsApprovedTollExpense,
        showFileTollExpense,
        fetchingApprovedTollExpense,
        fetchingGetTollExpensesByTransportOrder,
        fetchingStoreTollExpense,
        fetchingEditTollExpense,
        fetchingUpdateTollExpense,
        fetchingShowTollExpense,
        fetchingDeleteTollExpense,
        fetchingCreateTollExpense,
        fetchingGetUrlDocumentOnTransportOrder,
        fetchingCancelIsApprovedTollExpense,
        fetchingshowFileTollExpense
    };
};

export default useTollExpenseService;
