import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents, ServiceEventsDocuments } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { MonthlyClosureDetailHistoryFilters } from '../../models/MonthlyClosureDetailHistory';

const useMonthlyClosureDetailHistoryService = () => {

    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [
        fetchingGetMonthlyClosureDetailHistoriesByMonthlyClosureDetail, 
        setFetchingGetMonthlyClosureDetailHistoriesByMonthlyClosureDetail
    ] = useState(false);
    

    const getMonthlyClosureDetailHistoriesByMonthlyClosureDetail = (
        monthlyClosureDetailId: number, 
        filter: MonthlyClosureDetailHistoryFilters, 
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.MONTHLY_CLOSURE_DETAIL_HISTORIES.ALL_MONTHLY_CLOSURE_DETAIL_HISTORIES_BY_MONTHLY_CLOSURE_DETAIL.replace(
            ':monthly_closure_detail_id',
            monthlyClosureDetailId.toString()
        )}?${queryString}`;
    
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetMonthlyClosureDetailHistoriesByMonthlyClosureDetail
        });
    };

   

    return {
        fetchingGetMonthlyClosureDetailHistoriesByMonthlyClosureDetail,
        getMonthlyClosureDetailHistoriesByMonthlyClosureDetail
    };
};

export default useMonthlyClosureDetailHistoryService;
