import React from 'react';
import { UserResponseFilters } from '../../../../app/models/UserResponse';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: UserResponseFilters;
    setFilter: (filter: UserResponseFilters) => void;
    onSearch: () => void;
};

const UserResponseFilter = ({ filter, setFilter, onSearch }: Props) => {

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="origin">
                            Rut
                        </label>
                        <input
                            title="Rut"
                            type="text"
                            id="rut"
                            name="rut"
                            className="form-control form-control-sm"
                            value={filter.rut ? filter.rut : ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="origin">
                            Nombre
                        </label>
                        <input
                            title="Nombre"
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="form-control form-control-sm"
                            value={filter.first_name ? filter.first_name : ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="origin">
                            Apellido Paterno
                        </label>
                        <input
                            title="Nombre"
                            type="text"
                            id="last_name"
                            name="last_name"
                            className="form-control form-control-sm"
                            value={filter.last_name ? filter.last_name : ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="complete_date_from">
                            Fecha completado (desde)
                        </label>
                        <input
                            title="Fecha carga (desde)"
                            type="date"
                            id="complete_date_from"
                            name="complete_date_from"
                            className="form-control form-control-sm"
                            value={filter.complete_date_from}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="complete_date_until">
                            Fecha completado (hasta)
                        </label>
                        <input
                            title="Fecha carga (hasta)"
                            type="date"
                            id="complete_date_until"
                            name="complete_date_until"
                            className="form-control form-control-sm"
                            value={filter.complete_date_until}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default UserResponseFilter
