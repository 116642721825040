import React, { useContext, useEffect, useState } from 'react';

import useCarrierService from '../../../app/services/hooks/useCarrierService';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import CarrierForm from './forms/CarrierForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import {
    defaultCarrierDataForm,
    CarrierDataForm,
    CarrierDocument
} from '../../../app/models/Carrier';
import { User } from '../../../app/models/User';
import { Society } from '../../../app/models/Society';
import { Provider } from '../../../app/models/Provider';
import { useHistory } from 'react-router-dom';
import GeneralDocumentTransporterForm from '../../../components/GeneralDocumentTransporterForm';
import useCarrierDocumentService from '../../../app/services/hooks/useCarrierDocumentService';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    carrierId: number;
    typeDocument: string;
}

const CarrierDocumentCreate = ({ onSaved, onCancel, onError, typeDocument, carrierId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [carrierDocument, setCarrierDocument] = useState<CarrierDocument>({
        carrier_id: carrierId,
        type: typeDocument,
        is_expire_date_indefinite: false
    });

    const [errorFields, setErrorFields] = useState<any>();
    const { fetchingStoreCarrierDocument, storeCarrierDocument } = useCarrierDocumentService();

    const store = () => {
        if (!carrierDocument) {
            return;
        }
        if (showLoading) showLoading('loading', 'Guardando documento...');
        storeCarrierDocument(carrierDocument, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <GeneralDocumentTransporterForm
                        document={carrierDocument}
                        setDocument={setCarrierDocument}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreCarrierDocument}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreCarrierDocument} />
                </div>
            </div>
        </>
    );
};

export default CarrierDocumentCreate;
