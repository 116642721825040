import React from 'react';
import { IconProps } from '../../types';

export const MonthlyReconciliation: React.FC<IconProps> = ({ size = 24, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            data-name="Layer 1"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <path
                    className="cls-1"
                    d="M12,12H22.5a10.45,10.45,0,0,1-3.07,7.42L12,12V1.48A10.5,10.5,0,1,0,19.43,19.4"
                />
                <path
                    className="cls-1"
                    d="M15.82,8.16h3.34a1.43,1.43,0,0,0,1.43-1.43h0A1.43,1.43,0,0,0,19.16,5.3h-1a1.44,1.44,0,0,1-1.43-1.44h0A1.43,1.43,0,0,1,18.2,2.43h3.35"
                />
                <line className="cls-1" x1="18.68" y1="0.52" x2="18.68" y2="2.43" />
                <line className="cls-1" x1="18.68" y1="8.16" x2="18.68" y2="10.07" />
            </g>
        </svg>
    );
};
