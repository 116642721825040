import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';

import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AccountDataForm, defaultAccountDataForm } from '../../../app/models/Account';
import useAccountService from '../../../app/services/hooks/useAccountService';
import AccountForm from './forms/AccountForm';
import { Clasification } from '../../../app/models/Clasification';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const AccountCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [account, setAccount] = useState<AccountDataForm>(defaultAccountDataForm);

    const [clasifs, setClasifs] = useState<Clasification[]>([]);
    const [clasifsTwo, setClasifsTwo] = useState<Clasification[]>([]);
    const [clasifsThree, setClasifsThree] = useState<Clasification[]>([]);
    const [clasifications, setClasifications] = useState<Clasification[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const { createAccount, fetchingStoreAccount, storeAccount } = useAccountService();

    useEffect(() => {
        create();
    }, []);
    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createAccount({
            onSuccess: (response: ServiceResponse) => {
                setClasifs(response.data.clasifs);
                setClasifsTwo(response.data.clasifs_two);
                setClasifsThree(response.data.clasifs_three);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando cuenta contable...');
        storeAccount(account, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <AccountForm
                        accountDataForm={account}
                        setAccountDataForm={setAccount}
                        clasifs={clasifs}
                        clasifsTwo={clasifsTwo}
                        clasifsThree={clasifsThree}
                        clasifications={clasifications}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreAccount} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreAccount} />
                </div>
            </div>
        </>
    );
};

export default AccountCreate;
