import React, { useState } from 'react';
import useUserResponseDetailService from '../../../app/services/hooks/useUserResponseDetail';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { UserResponseDetail } from '../../../app/models/UserResponseDetail';
import { UserResponseOption } from '../../../app/models/UserResponseOption';
import { UserResponseFile } from '../../../app/models/UserResponseFile';
import { InputResponseType } from '../../../app/shared/enums';
import { momentParseDate, momentParseDateTime } from '../../../helpers';
import { formatNumber } from '../../../utils/utils';

interface Props {
    responses: any[];
    isConfimation?: boolean;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UserResponseconfirm = ({ 
    responses,
    isConfimation=false, 
    onSaved, 
    onCancel, 
    onError 
}: Props) => {

    const [ responseDetail, setResponseDetail ] = useState<UserResponseDetail[]>([])
    const [ responseOptions, setResponseOptions ] = useState<UserResponseOption[]>([])
    const [ responseFiles, setResponseFiles ] = useState<UserResponseFile[]>([])
  
    const getOptionsForResponseDetail = (detailId: number) => {
        return responseOptions.filter(option => option.user_response_detail_id === detailId);
    };

    return (
        <>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Pregunta</th>
                            <th scope="col">Respuesta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {responses.map((res, index) => (
                            <tr key={res.id}>
                                <th scope="row" className="align-top">{index + 1}</th>
                                <td className="align-top">{res.question_text}</td>
                                <td className="align-top">
                                    {res.text ? (
                                        <p>
                                           {res.response_type === InputResponseType.DATETIME
                                            ? momentParseDateTime(res.text)
                                            : res.response_type === InputResponseType.DATE
                                            ? momentParseDate(res.text)
                                            : res.response_type === InputResponseType.NUMBER
                                            ? !isNaN(Number(res.text))  
                                                ? formatNumber(Number(res.text))
                                                : ''
                                            : res.text
                                        }
                                          
                                        </p>
                                    ) : (
                                        <p>
                                            {isConfimation 
                                                ? res.options
                                                    .filter((option: { is_response: any; }) => option.is_response) 
                                                    .map((option: { text: any; }) => option.text) 
                                                    .join(', ')
                                                : getOptionsForResponseDetail(res.id)
                                                    .filter(option => option.is_response) 
                                                    .map(option => option.text) 
                                                    .join(', ')
                                            }
                                        </p>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default UserResponseconfirm;
