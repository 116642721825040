import React, { useEffect } from 'react';

import { LinkInspectionOption, QuestionDataForm } from '../../../../../app/models/Question';
import ButtonCreate from '../../../../../components/buttons/ButtonCreate';
import useHandleErrorFields from '../../../../../hooks/useHandleErrorFields';
import ButtonTableAction from '../../../../../components/buttons/ButtonTableAction';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../../components/SelectTwo';

type Props = {
    questionForm: QuestionDataForm;
    setQuestionForm: (question: QuestionDataForm) => void;
    nextQuestionOptions?: QuestionDataForm[];
    errorFields?: any;
};

const FormCheckBox = ({ questionForm, setQuestionForm, nextQuestionOptions, errorFields }: Props) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();
    

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleCreateOption = () => {
        
        const maxIndex = questionForm.options
                ? Math.max(...questionForm.options.map((item) => item.position), 0)
                : 0;

        setQuestionForm({
            ...questionForm,
            options: [
                ...(questionForm.options as LinkInspectionOption[]),
                { 
                    id: maxIndex + 1,
                    position: maxIndex + 1,
                    label: (maxIndex + 1).toString(), 
                    value: false, 
                    next_question: '0',
                    is_response: false 
                }
            ]
        });

        setErrors({
            ...errors,
            options: undefined
        })

    };


    const handleRemoveOption = (index: number) => {
        const options = questionForm.options?.filter((item) => item.position !== index);

        setQuestionForm({
            ...questionForm,
            options: options
        });

        setErrors({
            ...errors,
            options: undefined
        })

    };


    const handleChangeItemData = (event: React.ChangeEvent<HTMLInputElement>, position: number) => {

        const { name, value } = event.target;
        
        const updatedFinalOptions = questionForm.options?.map((item) => {
            if (position === item.position) {
                
                return {
                    ...item,
                    [name]: value
                };
            }
            return item;
        });
        
        setQuestionForm({
            ...questionForm,
            options: updatedFinalOptions
        });

    };

    const handleChangeSelectTwo = (name: string, index: number, value: number | string | null | undefined) => {
        
        const obj = { [name]: value };
        const indexToUpdate = questionForm.options?.findIndex(item => item.position === index);

        if (indexToUpdate !== -1 && indexToUpdate !== undefined && questionForm.options){
            
            const currentItem = questionForm.options[indexToUpdate];
            const updatedItem = { 
                ...currentItem, 
                ...obj 
            };

            const updatedFinalOptions = [
                ...questionForm.options.slice(0, indexToUpdate),
                updatedItem,                                     
                ...questionForm.options.slice(indexToUpdate + 1) 
            ];

            setQuestionForm({
                ...questionForm,
                options: updatedFinalOptions
            });
        }
    };


    return (
        <>
            
            <div className="row">
                <div className="col-md-12 mb-3">
                    <label className="col-form-label">Pregunta</label>
                    <input
                        className="form-control"
                        type="text"
                        name="question_text"
                        id="question_text"
                        value={questionForm.question_text}
                        readOnly={true}
                    />
                </div>
                
            </div>
            <div className="row">
               
                    <div className="col-md-12 mb-3">
                        <ButtonCreate
                            title='Agregar Opción'
                            callbackCreate={handleCreateOption}
                        />

                        {questionForm.options &&
                            questionForm.options.map((option, index) => (

                                
                                <div key={index} className="row mt-2 mb-2">

                                <div className="col-md">
                                    <input
                                        className={`form-control ${fieldHasError(
                                            `options.[${index}].label`,
                                            true
                                        )}`}
                                        type="text"
                                        name="label"
                                        id="label"
                                        value={option.label}
                                        onChange={(e) => handleChangeItemData(e, option.position)}
                                        placeholder="Ingrese texto de opción"
                                        onFocus={() => onFocusRemove(`options.[${index}].label`, true)}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage(`options.[${index}].label`, true)}
                                    </div>
                                </div>

                                { nextQuestionOptions && <div className="col-md">
                                    <SelectTwo
                                        name="next_question_id"
                                        id="next_question_id"
                                        inputValue={option.next_question_id}
                                        options={SelectTwoMapperOptions(nextQuestionOptions, 'id', ['name'])}
                                        hasError={fieldHasError(`options.[${index}].next_question_id`, true) !== ''}
                                        onChange={(value: any) => handleChangeSelectTwo('next_question_id', option.position, value?.value)}
                                        placeholder={'Siguiente Pregunta'}
                                        onFocus={() => onFocusRemove(`options.[${index}].next_question_id`, true)}
                                        isClearable={true}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage(`options.[${index}].next_question_id`, true)}
                                    </div>
                                </div> }

                                <div className="col-auto d-flex">
                                        <div className="my-auto">
                                            <ButtonTableAction
                                                callbackFunction={() => handleRemoveOption(option.position)}
                                                classIcon={'mdi mdi-delete'}
                                                colorIcon={'text-danger'}
                                                errorMessage={'No se puede eliminar este registro.'}
                                                title={'Eliminar'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
               
            </div>
        </>
    );
};

export default FormCheckBox;
