import React, { useEffect } from 'react';
import { LinkInspectionOption, QuestionDataForm } from '../../../app/models/Question';
import { InputResponseType } from '../../../app/shared/enums';
import QuestionMultipleCheckField from './forms/Inputs/mobile/QuestionMultipleCheckField';
import QuestionSelectionField from './forms/Inputs/mobile/QuestionSelectionField';
import QuestionMultipleSelectionField from './forms/Inputs/mobile/QuestionMultipleSelectionField';
import QuestionSwitchField from './forms/Inputs/mobile/QuestionSwitchField';
import QuestionNumberField from './forms/Inputs/mobile/QuestionNumberField';
import QuestionRadioField from './forms/Inputs/mobile/QuestionRadioField';
import QuestionTextAreaField from './forms/Inputs/mobile/QuestionTextAreaField';
import QuestionTimeField from './forms/Inputs/mobile/QuestionTimeField';
import QuestionDateTimeField from './forms/Inputs/mobile/QuestionDateTimeField';
import QuestionTextField from './forms/Inputs/mobile/QuestionTextField';
import QuestionDateField from './forms/Inputs/mobile/QuestionDateField';

// import QuestionImageField from './forms/Inputs/mobile/QuestionImageField';

type Props = {
    currentQuestion: QuestionDataForm;
    setCurrentQuestion: (question: QuestionDataForm) => void;
    isSurveyResponse?: boolean;
    showConfirm?: boolean;
};

const QuestionCard = ({ 
    currentQuestion, 
    setCurrentQuestion, 
    isSurveyResponse=false,
    showConfirm=false
}: Props) => {

    //El name es la id de la opción
    const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>)=> {

        const { checked, name } = event.target;

        setCurrentQuestion({
            ...currentQuestion,
            options: currentQuestion.options?.map((option: LinkInspectionOption) => {
                
                if(option.id == name){
                    if (option.value){
                        return {
                            ...option,
                            value: false,
                            is_response: false
                        }
                    }
                    return {
                        ...option,
                        value: true,
                        is_response: true
                    
                    }
                }
                return option;
            })
        })
    }

    //El name es la id de la opción
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>)=> {

        const { checked, name } = event.target;

        setCurrentQuestion({
            ...currentQuestion,
            options: currentQuestion.options?.map((option: LinkInspectionOption) => {
                
                if(option.id == name){

                    return {
                        ...option,
                        value: true,
                        is_response: true
                    }
                    
                }
                return {
                    ...option,
                    value: false,
                    is_response: false
                };
            })
        })
    }

    //El name es la id de la opción
    const handleNumber = (name: string, value: string) => {

        setCurrentQuestion({
            ...currentQuestion,
            text: value.replace(/[^0-9]/g, '')
        }) 

    }

    //El name es la id de la opción
    const handleText = (name: string, value: string) => {

       setCurrentQuestion({
            ...currentQuestion,
            text: value
        }) 

    }

    // useEffect(() => {
    // console.log(currentQuestion.options)
    // }, [currentQuestion.options])

    const handleSelect = (fieldName: string, value: number | string | null) => {

        setCurrentQuestion({ 
            ...currentQuestion, 
            [fieldName]: currentQuestion.options?.map((option: LinkInspectionOption) => {
                
                const isResponse =  value === option.id;
                
                return {
                    ...option,
                    is_response: isResponse ? true : false, 
                };
            }),
        
        });
    
    }

    const handleMultiSelect = (optionValues: LinkInspectionOption[]) => {

        setCurrentQuestion({
            ...currentQuestion,
            options: currentQuestion.options?.map((option: LinkInspectionOption) => {
           
                const isResponse = optionValues.some(
                    (selectedOption) => selectedOption.value === option.value
                );

                return {
                    ...option,
                    is_response: isResponse ? true : false, 
                };
            }),
        });

    }


    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        setCurrentQuestion(
            { 
                ...currentQuestion, 
                switch_value: checked,
                text: checked? 'Si' : 'No' 
            }
        );
    };


    const handleChange = (

        event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        //
        const { name, value, type } = event.target;
        
        if (type === 'number') {

            handleNumber(name, value)

        } else {

            handleText(name, value)
       
        }
     
    };

    // const handleChangeImage = (uuid: string, value: string[]) => {
    //     setCurrentQuestion(
    //         questions.map((itemQuestion) => {
    //             if (question.id === itemQuestion.id) {
    //                 question.inputs.forEach((_input) => {
    //                     if (_input.uuid === uuid) {
    //                         _input.images = value;
    //                     }
    //                 });
    //                 return itemQuestion;
    //             }
    //             return itemQuestion;
    //         })
    //     );
    // };


    return (
        <>
        <div style={isSurveyResponse ? { minHeight: '60vh' } : {}}>
         

                {
                    showConfirm ?
                    <>
                        <div className="question-label">
                            <h1 className="text-center px-5">Felicitaciones</h1>
                        </div>
                        <div className="mt-5">
                            <p>Ha respondido la encuesta.</p>
                        </div>
                    </>
                    :
                    <>
                    <div className="question-label">
                        <h1 className="text-center px-5">{currentQuestion.question_text}</h1>
                    </div>
                    <div className="mt-5">
                     {
                    (currentQuestion.response_type === InputResponseType.CHECKBOX) &&
                            (
                                <QuestionMultipleCheckField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleCheckboxChange={handleCheckBox}
                                    disabled={showConfirm}
                                />
                            )
                    }
                    {
                        (currentQuestion.response_type === InputResponseType.SELECT) &&
                            (
                                <QuestionSelectionField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleChange={handleSelect}
                                    disabled={showConfirm}
                                />
                            )
                    }
                    {
                        (currentQuestion.response_type === InputResponseType.MULTIPLESELECT) &&
                            (
                                <QuestionMultipleSelectionField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleChange={handleMultiSelect}
                                    disabled={showConfirm}
                                />
                            )
                    }
                    {
                        (currentQuestion.response_type === InputResponseType.TEXT) &&
                           (
                                <QuestionTextField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleChange={handleChange}
                                    disabled={showConfirm}
                                />
                            )
                    } 
                    {
                       (currentQuestion.response_type === InputResponseType.NUMBER) &&
                            (
                                <QuestionNumberField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleChange={handleChange}
                                    disabled={showConfirm}
                                />
                            )
                    }

                    {
                        (currentQuestion.response_type === InputResponseType.SWITCH) &&
                            (
                                <QuestionSwitchField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleChange={handleSwitchChange}
                                    disabled={showConfirm}
                                />
                            )
                    }
                    {
                        (currentQuestion.response_type === InputResponseType.RADIOBUTTON) &&
                            (
                                <QuestionRadioField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleRadioChange={handleRadioChange}
                                    disabled={showConfirm}
                                />
                            )
                    }
                   
                    {
                        (currentQuestion.response_type === InputResponseType.TEXTAREA) &&
                            (
                                <QuestionTextAreaField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleChange={handleChange}
                                    disabled={showConfirm}
                                />
                            )
                    }
                    {
                        (currentQuestion.response_type === InputResponseType.DATE) &&
                            (
                                <QuestionDateField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleChange={handleChange}
                                    disabled={showConfirm}
                                />
                            )
                    }
                    {
                        (currentQuestion.response_type === InputResponseType.TIME) &&
                            (
                                <QuestionTimeField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleChange={handleChange}
                                    disabled={showConfirm}
                                />
                            )
                    }
                    {
                        (currentQuestion.response_type === InputResponseType.DATETIME) &&
                            (
                                <QuestionDateTimeField
                                    input={currentQuestion}
                                    key={currentQuestion.id}
                                    handleChange={handleChange}
                                    disabled={showConfirm}
                                />
                            )
                    }
             
                    {/* {
                        (currentQuestion.response_type === InputResponseType.FILE) &&
                            (
                                <QuestionImageField
                                input={currentQuestion}
                                key={currentQuestion.id}
                                    handleChangeImage={handleChangeImage}
                                />
                            )
                    }  */}
                    </div>
                    </>

                }

                                  
            </div>
   
        </>
    );
};

export default QuestionCard;
