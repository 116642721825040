import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { AppContext } from '../../../contexts/AppContext';
import useAuthService from '../../../app/services/hooks/useAuthService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import LazyLoading from '../../../components/LazyLoading';
import useSweetAlert from '../../../hooks/useSweetAlert';
import logo_pegasus from '../../../assets/images/logo_pegasus.png';
import { useHistory } from 'react-router-dom';
import useNavigationPage from '../../../hooks/useNavigationPage';

const Login = () => {
    const history = useHistory();
    const { login } = useContext(AuthContext);
    const { showLoading, hideLoading } = useContext(AppContext);

    const { fetchingLoginAuth, loginAuth } = useAuthService();

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [errorMessage, setErrorMessage] = useState('');

    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
        haveSesion: false
    });
    const { navigationPage } = useNavigationPage();

    useEffect(() => {
        setErrorMessage('');
    }, [credentials]);

    const handleSubmit = () => {
        if (showLoading) showLoading('loading', 'Conectando...');
        loginAuth(credentials, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                const { data } = response;

                if (data.sesion && data.sesion === true) {
                    confirmLogin(data.sesion);
                    return;
                }

                if (login) {
                    login({
                        id: data.auth.id,
                        firstName: data.auth.first_name,
                        lastName: data.auth.last_name,
                        email: data.auth.email,
                        authToken: data.auth.auth_token,
                        avatar: data.auth.avatar,
                        roles: data.auth.roles,
                        permissions: data.auth.permissions
                    });

                    // window.location.href = '/';
                    navigationPage('/', false);
                }
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorMessage(response.message);
            },
            onFieldError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrors(response.data);
            }
        });
    };

    const confirmLogin = (sesion = false) => {
        const _html = '<p>¿Desea cerrar la sesión para iniciar una nueva?</p>';

        useSweetAlert().requestConfirmation({
            title: 'Hay otra sesión iniciada con esta cuenta en estos momentos',
            html: _html,
            text: '',
            confirmButtonText: 'Si, iniciar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                credentials.haveSesion = true;

                useSweetAlert().spinnerAlert('Iniciando nueva sesión...', 400);

                handleSubmit();
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'No se ha iniciado una nueva sesión.'
                });
                credentials.haveSesion = false;
            }
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCredentials({ ...credentials, [name]: value });
    };

    return (
        <div className="vh-100 d-flex bg-primary">
            <div className="m-auto px-3 w-100" style={{ maxWidth: '380px' }}>
                <div className="card">
                    <h5 className="card-header bg-transparent border-bottom text-uppercase text-center">
                        Acceso Usuarios
                    </h5>
                    {!fetchingLoginAuth ? (
                        <div className="card-body">
                            <img src={logo_pegasus} className="img-fluid" />

                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                <div className="mb-2 row">
                                    {/*<label className="col-md-3 col-form-label">Email</label>*/}
                                    <div className="col-md-12">
                                        <input
                                            name="username"
                                            id="username"
                                            type="email"
                                            className={`form-control ${fieldHasError('username')}`}
                                            value={credentials.username}
                                            onChange={handleChange}
                                            onFocus={() => onFocusRemove('username')}
                                            placeholder="Ingrese un correo electrónico"
                                        />
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: 'flex' }}
                                        >
                                            {fieldErrorMessage('username')}
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2 row">
                                    {/*<label className="col-md-3 col-form-label">Contraseña</label>*/}
                                    <div className="col-md-12">
                                        <input
                                            name="password"
                                            id="password"
                                            type="password"
                                            className={`form-control ${fieldHasError('password')}`}
                                            value={credentials.password}
                                            onChange={handleChange}
                                            onFocus={() => onFocusRemove('password')}
                                            placeholder="Ingrese su contraseña"
                                        />
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: 'flex' }}
                                        >
                                            {fieldErrorMessage('password')}
                                        </div>
                                    </div>
                                </div>

                                {errorMessage ? (
                                    <div className="alert alert-danger my-4" role="alert">
                                        {errorMessage}
                                        <button
                                            type="button"
                                            className="close"
                                            onClick={() => setErrorMessage('')}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                ) : null}

                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            type="submit"
                                            className="btn btn-primary waves-effect waves-light w-100"
                                        >
                                            INICIAR SESIÓN
                                        </button>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button
                                            type="button"
                                            className="btn btn-link waves-effect waves-light"
                                            onClick={() =>
                                                (window.location.href = '/recovery-password')
                                            }
                                        >
                                            Recuperar Contraseña
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="card-body">
                            <LazyLoading height={200} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Login;
