import React, { ChangeEvent, useEffect, useState } from 'react';
import { User, defaultUserDataForm } from '../../../../app/models/User';
import { WorkerDataForm, Worker, defaultWorkeDataForm } from '../../../../app/models/Workers';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Provision } from '../../../../app/models/Provision';
import { Afp } from '../../../../app/models/Afp';
import useRut from '../../../../hooks/useRut';
import { Society } from '../../../../app/models/Society';
import { Provider } from '../../../../app/models/Provider';
import useWorkerService from '../../../../app/services/hooks/useWorkerService';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { DocumentSet } from '../../../../app/models/DocumentSet';
import { momentParseDate } from '../../../../helpers';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import ButtonCreate from '../../../../components/buttons/ButtonCreate';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

interface GeneralWorkerProps {
    workerForm: WorkerDataForm;
    setWorkerForm: (worker: WorkerDataForm) => void;
    users: User[];
    afps: Afp[];
    provisions: Provision[];
    societies: Society[];
    providers: Provider[];
    documentSets: DocumentSet[];
    errorFields?: any;
    isCreate?: boolean;
    isEdit?: boolean;
    isProfile?: boolean;
    workers?: Worker[];
    isCarrier?: boolean;
}

const WorkersForm = ({
    workerForm,
    afps,
    provisions,
    societies,
    providers,
    users,
    documentSets,
    setWorkerForm,
    errorFields,
    isCreate,
    isEdit,
    isProfile = false,
    workers,
    isCarrier
}: GeneralWorkerProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { findUser, fetchingFindUser } = useWorkerService();
    const [user, setUser] = React.useState<User>();

    let filteredUsers: User[];
    if (workers) {
        filteredUsers = users.filter(
            (user) => !workers.some((worker) => worker?.user?.id === user.id)
        );
    } else {
        filteredUsers = users;
    }

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        setterUserData(Number(workerForm.user_id));
    }, []);

    const searchUser = (user_id: number) => {
        findUser(user_id, {
            onSuccess: (response: ServiceResponse) => {
                setUser(response.data.user);
                if (response.data.deleted_worker) {
                    const _worker = response.data.deleted_worker;
                    if (response.data.deleted_worker.society_id) {
                        _worker.is_internal = true;
                    } else if (response.data.deleted_worker.provider_id) {
                        _worker.is_internal = false;
                    }
                    _worker.document_sets = response.data.deleted_worker.document_sets.map(
                        (docSet: any) => {
                            return {
                                value: docSet.id,
                                label: docSet.name
                            };
                        }
                    );
                    setWorkerForm({ ..._worker });
                } else {
                    setWorkerForm({ ...workerForm, user_id: response.data.user.id });
                }
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const handleCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name } = event.target;

        setWorkerForm({ ...workerForm, is_internal: !workerForm.is_internal });
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setWorkerForm({ ...workerForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if (name === 'user_id') {
            setterUserData(Number(value));
        } else if (name === 'society_id') {
            setWorkerForm({ ...workerForm, provider_id: 0, is_internal: true, ...obj });
        } else if (name === 'provider_id') {
            setWorkerForm({ ...workerForm, society_id: 0, is_internal: false, ...obj });
        } else {
            setWorkerForm({ ...workerForm, ...obj });
        }
    };

    if (!workerForm) return null;

    const setterUserData = (user_id: number) => {
        const user = filteredUsers.find((user) => user.id === user_id);

        if (user) {
            if (isCreate) {
                setUser(defaultUserDataForm);
                searchUser(user_id);
            } else {
                setUser(user);
            }
        }
    };


    const handleFileChange = (event: FileList | null, type: string) => {
        if (event) {
            const file = event[0];
            setWorkerForm({
                ...workerForm,
                FUN_document: file
            });
        }
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="mb-2 row needs-validation">
                    <div className="col-6">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="internal"
                                value="internal"
                                checked={workerForm.is_internal}
                                id="it_internal"
                                onChange={handleCheckChange}
                            />
                            <label className="form-check-label" htmlFor="it_internal">
                                Interno
                            </label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="external"
                                value="external"
                                checked={!workerForm.is_internal}
                                id="it_external"
                                onChange={handleCheckChange}
                            />
                            <label className="form-check-label" htmlFor="it_external">
                                Externo
                            </label>
                        </div>
                    </div>
                </div>

                {workerForm.is_internal ? (
                    <div className="col-md-12">
                        <label className="col-form-label">Sociedades</label>
                        <SelectTwo
                            name="society_id"
                            id="society_id"
                            inputValue={workerForm.society_id}
                            options={SelectTwoMapperOptions(societies, 'id', ['name'])}
                            hasError={fieldHasError('society_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('society_id', value.value)
                            }
                            placeholder={'Seleccione Sociedad'}
                            onFocus={() => onFocusRemove('society_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('society_id')}
                        </div>
                    </div>
                ) : (
                    <div className="col-md-12">
                        <label className="col-form-label">Proveedores</label>
                        <SelectTwo
                            name="provider_id"
                            id="provider_id"
                            inputValue={workerForm.provider_id}
                            options={SelectTwoMapperOptions(providers, 'id', ['name'])}
                            hasError={fieldHasError('provider_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('provider_id', value.value)
                            }
                            placeholder={'Seleccione Proveedor'}
                            onFocus={() => onFocusRemove('provider_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('provider_id')}
                        </div>
                    </div>
                )}
                {isCreate ? (
                    <div className="col-md-12">
                        <label className="col-form-label">Usuario</label>
                        <SelectTwo
                            name="user_id"
                            id="user_id"
                            inputValue={workerForm.user_id}
                            disabled={!isCreate}
                            options={SelectTwoMapperOptions(filteredUsers, 'id', ['name'])}
                            hasError={fieldHasError('user_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('user_id', value?.value)
                            }
                            placeholder={'Seleccione Usuario'}
                            onFocus={() => onFocusRemove('user_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('user_id')}
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">RUT</label>
                    <input
                        name="rut"
                        id="rut"
                        type="text"
                        className="form-control"
                        disabled={true}
                        value={user?.rut}
                        onFocus={() => onFocusRemove('rut')}
                        placeholder="Ingrese el RUT"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('rut')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="first_name"
                        id="first_name"
                        type="text"
                        disabled={true}
                        className={`form-control ${fieldHasError('first_name')}`}
                        value={user?.first_name}
                        onFocus={() => onFocusRemove('first_name')}
                        placeholder="Ingrese el nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('first_name')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Email</label>

                    <input
                        name="email"
                        id="email"
                        type="email"
                        disabled={true}
                        className={`form-control ${fieldHasError('email')}`}
                        value={user?.email}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese el email"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-6">
                    <label className="col-form-label">Apellido Paterno</label>
                    <input
                        name="last_name"
                        id="last_name"
                        type="text"
                        disabled={true}
                        className={`form-control ${fieldHasError('last_name')}`}
                        value={user?.last_name}
                        onFocus={() => onFocusRemove('last_name')}
                        placeholder="Ingrese el apellido paterno"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('last_name')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Apellido Materno</label>
                    <input
                        name="second_last_name"
                        id="second_last_name"
                        type="text"
                        disabled={true}
                        className={`form-control ${fieldHasError('second_last_name')}`}
                        value={user?.second_last_name}
                        onFocus={() => onFocusRemove('second_last_name')}
                        placeholder="Ingrese el apellido materno"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('second_last_name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-12 ">
                    <label className=" col-form-label">Teléfono</label>
                    <div className="row">
                        <div className="col-auto">
                            <select
                                className="form-control form-control-custom pl-2"
                                id="phone_code"
                                name="phone_code"
                                disabled={true}
                                value={user?.phone_code}
                                onFocus={() => onFocusRemove('phone_code')}
                            >
                                <option defaultValue={'+56'} value="+56">
                                    +56
                                </option>
                            </select>
                        </div>

                        <div className="col">
                            <input
                                name="phone"
                                id="phone"
                                type="number"
                                disabled={true}
                                className="form-control"
                                value={user?.phone}
                                onFocus={() => onFocusRemove('phone')}
                                placeholder="Teléfono"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label"> Dirección </label>
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className={`form-control ${fieldHasError('address')}`}
                        value={workerForm.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        placeholder="Ingrese la dirección"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">AFP</label>
                    <SelectTwo
                        name="afp_id"
                        id="afp_id"
                        inputValue={workerForm.afp_id}
                        options={SelectTwoMapperOptions(afps)}
                        hasError={fieldHasError('afp_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('afp_id', value?.value)}
                        placeholder={'Seleccione afp'}
                        onFocus={() => onFocusRemove('afp_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('afp_id')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Previsiones</label>
                    <SelectTwo
                        name="provision_id"
                        id="provision_id"
                        inputValue={workerForm.provision_id}
                        options={SelectTwoMapperOptions(provisions)}
                        hasError={fieldHasError('provision_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('provision_id', value?.value)
                        }
                        placeholder={'Seleccione prevision'}
                        onFocus={() => onFocusRemove('provision_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provision_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Valor UF Previsión</label>
                    <input
                        type="number"
                        name="provision_value"
                        id="provision_value"
                        onFocus={() => onFocusRemove('value')}
                        className={`form-control ${fieldHasError('value')}`}
                        value={workerForm.provision_value}
                        onChange={handleChange}
                        placeholder="Ingrese valor en UF"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provision_value')}
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="col-form-label">Documento FUN</label>
                    <div className="col-md-12 ">
                        <InputButtonFile
                            name={'file'}
                            handleChangeDocumentFile={handleFileChange}
                            objectFile={workerForm?.FUN_document}
                        />
                        <div className="invalid-feedback mb-1" style={{ display: 'flex' }}>
                            {fieldErrorMessage('FUN_document')}
                        </div>
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('job_title_id')}
                    </div>
                </div>
            </div>

            {workerForm.contract && (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Contrato</label>
                        <input
                            name="contract"
                            id="contract"
                            type="text"
                            className={'form-control'}
                            disabled={true}
                            value={
                                workerForm.contract.is_indefinite
                                    ? `${workerForm?.contract.id}.- ${workerForm?.contract.job_title?.name} - Indefinido`
                                    : `${workerForm?.contract.id}.- ${workerForm?.contract.job_title?.name} - Temporal`
                            }
                            placeholder="Ingrese el número de cuenta"
                        />
                    </div>
                </div>
            )}

            {workerForm.contract && (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Jornada laboral</label>
                        <input
                            name="shift_work"
                            id="shift_work"
                            type="text"
                            className={'form-control'}
                            disabled={true}
                            value={
                                workerForm.contract?.shift_work?.name
                            }
                            placeholder="Ingrese el número de cuenta"
                        />
                    </div>
                </div>
            )}

            {isEdit && (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Set de documentos</label>
                        <SelectTwo
                            name="document_sets"
                            id="document_sets"
                            inputValue={workerForm.document_sets}
                            options={SelectTwoMapperOptions(documentSets)}
                            disabled={isEdit}
                            hasError={fieldHasError('documents') !== ''}
                            onChange={(value: any) =>
                                setWorkerForm({
                                    ...workerForm,
                                    document_sets: value
                                })
                            }
                            isSortable={true}
                            isMulti={true}
                            placeholder={'Seleccione los set de documentos'}
                            onFocus={() => onFocusRemove('document_sets')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('document_sets')}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default WorkersForm;
