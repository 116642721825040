import { useHistory } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import { useContext, useEffect, useState } from 'react';

import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { ServiceResponse } from '../../app/services/shared/interfaces';

import DefaultModal from '../../components/default/DefaultModal';

import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import {
    WorkOrder,
    WorkOrderDataForm,
    WorkOrderFilters,
    WorkOrderStatusRequestDataForm
} from '../../app/models/WorkOrder';
import useWorkOrderService from '../../app/services/hooks/useWorkOrderService';
import WorkOrderDataTable from './components/tables/WorkOrderDataTable';
import WorkOrderCreate from './components/WorkOrderCreate';
import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';
import { use } from 'echarts';
import ButtonEditColumns from '../../components/buttons/ButtonEditColumns';
import ColumnSetCreate from '../Columns/subcomponents/ColumnSetCreate';
import { AuthContext } from '../../contexts/AuthContext';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Ordenes de Trabajo',
        url: '/work-orders',
        isActive: true
    }
];

const WorkOrders = () => {
    const { auth } = useContext(AuthContext);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetWorkOrders, getWorkOrders, deleteWorkOrder } = useWorkOrderService();
    const history = useHistory();
    const [workOrderIdEdit, setWorkOrderIdEdit] = useState<number>(-1);
    const [workOrders, setWorkOrders] = useState<WorkOrderDataForm[]>([]);
    const [showingEdit, setShowingEdit] = useState(false);
    const [editColumns, setEditColumns] = useState(false);
    const [statuses, setStatuses] = useState<WorkOrderStatusRequestDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [columnAlias, setColumnAlias] = useState<string[]>([]);

    const [showingCreate, setShowingCreate] = useState(false);
    const { navigationPage } = useNavigationPage();

    const [filter, setFilter] = useState<WorkOrderFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        table_name: 'work_order',
        work_order_id: null,
        society_name: '',
        truck_patent: '',
        truck_brand_name: '',
        truck_model_vehicle_name: '',
        status: ''
    });

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const reloadTable = () => {
        getWorkOrders(filter, {
            onSuccess: (response: ServiceResponse) => {
                setWorkOrders(response.data.work_orders);
                setTotalRows(response.data.total_rows);
                setStatuses(response.data.statuses);
                setColumnAlias(response.data.alias_list)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (id: number) => {
        navigationPage(`/work-orders/${id}/edit`);
    };

    const showShow = (id: number) => {
        navigationPage(`/work-orders/${id}/show`);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setWorkOrderIdEdit(-1);
    };

    const onError = () => {
        setShowingEdit(false);
        setWorkOrderIdEdit(-1);
    };

    const showEditColumns = () => {
        setEditColumns(true);
    }

    const hideEditColumns = () => {
        setEditColumns(false);
    }

    const destroy = (societyId: number) => {
        const _text = 'Está a punto de eliminar la orden de trabajo #' + societyId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando orden de trabajo...');
                        deleteWorkOrder(societyId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La orden de trabajo no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Ordenes de trabajo" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate
                                    callbackCreate={showCreate}
                                    title="Nueva orden de trabajo"
                                />
                            </div>
                            {auth &&
                            auth?.roles &&
                            auth?.roles.includes('Administrador') &&
                                (<div className="col d-md-flex justify-content-end d-none">
                                    <ButtonEditColumns
                                        callbackCreate={showEditColumns}
                                        title="Editar Columnas"
                                    />
                                </div>)
                            }
                        </div>
                    </div>
                    <div className="col-12">
                        <WorkOrderDataTable
                            workOrders={workOrders}
                            totalRows={totalRows}
                            loading={fetchingGetWorkOrders}
                            destroy={destroy}
                            edit={showEdit}
                            filter={filter}
                            setFilter={setFilter}
                            show={showShow}
                            statuses={statuses}
                            onSearch={reloadTable}
                            columnDict={columnAlias}
                            // active={active}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear orden de trabajo"
                    backdrop={true}
                    showFooter={false}
                >
                    <WorkOrderCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
            {editColumns ? (
                <DefaultModal
                    show={editColumns}
                    handleClose={hideEditColumns}
                    title="Editar columnas de la tabla"
                    backdrop={true}
                    showFooter={false}
                >
                    <ColumnSetCreate
                        tableName={'work_order'}
                        onSaved={() => {
                            reloadTable();
                            hideEditColumns();
                        }}
                        onCancel={hideEditColumns}
                        onError={hideCreate}                     
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default WorkOrders;
