import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import useAITolls from '../../app/services/hooks/useAITolls';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'APIs',
        url: '/ai-tolls',
        isActive: true
    }
];

const AITolls = () => {
    const { getCountPendingTollExpenses, getPendingTollExpenses } = useAITolls();
    const { successAlert, errorAlert } = useReactConfirmAlert();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();

    const [pendingCount, setPendingCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [errorFields, setErrorFields] = useState<any>(null); // Inicializa errorFields como null o un objeto vacío

    useEffect(() => {
        loadPendingCount();
    }, [startDate, endDate]);

    const loadPendingCount = () => {
        getCountPendingTollExpenses(
            {
                onSuccess: ({ data }: ServiceResponse) => {
                    setPendingCount(data?.count_pending_expenses || 0);
                },
                onError: (response: ServiceResponse) => {
                    setIsLoading(false);
                    toast.error("Error al procesar la revisión de peajes.");
                    if (response?.data?.to_dashboard) {
                        navigationPage('/');
                    }
                },
            },
            startDate || undefined,
            endDate || undefined
        );
    };

    const handleReviewTolls = () => {
        // Inicia la animación de carga
        setIsLoading(true);
        if (showLoading) showLoading('loading', 'Revisando Peajes...');
        
        getPendingTollExpenses(
            {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setIsLoading(false);
                    loadPendingCount();
                    successAlert({
                        title: 'Éxito',
                        message: response.message || 'Gasto de peaje actualizado exitosamente.'
                    });
                    toast.success(response.message || 'Gasto de peaje actualizado exitosamente.', { autoClose: 2500 });
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setIsLoading(false);

                    errorAlert({
                        title: 'Error',
                        message: response.message || 'Error al procesar la revisión de peajes.'
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setIsLoading(false);
                    
                    setErrorFields(errorFields.data);
                }
            },
            startDate || undefined,
            endDate || undefined
        );
    };

    return (
        <>
            <Breadcrumbs pageSection="APIS" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <h3 style={{ marginBottom: '40px' }}>API de Revisión de Peajes Pendientes</h3>
                
                <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                    <div>
                        <label>Fecha Desde:</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div>
                        <label>Fecha Hasta:</label>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                </div>

                <button
                    style={{
                        padding: '10px 20px',
                        backgroundColor: isLoading ? '#e8f5f2' : '#7CE2C3',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: '16px',
                        cursor: isLoading ? 'not-allowed' : 'pointer',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        transition: '0.3s ease',
                        marginBottom: '10px',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                    onMouseOver={(e) => {
                        if (!isLoading) {
                            (e.target as HTMLButtonElement).style.backgroundColor = '#6CD9A8';
                        }
                    }}
                    onMouseOut={(e) => {
                        if (!isLoading) {
                            (e.target as HTMLButtonElement).style.backgroundColor = '#7CE2C3';
                        }
                    }}
                    onClick={isLoading ? undefined : handleReviewTolls}
                    disabled={isLoading}
                >
                    {isLoading ? 'Cargando...' : 'Revisar Peajes'}
                    {isLoading && (
                        <span
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#7CE2C3',
                                transformOrigin: 'left',
                                animation: 'loadingBar 3s ease-in-out infinite',
                                opacity: 0.5
                            }}
                        ></span>
                    )}
                </button>

                <p style={{ marginTop: '1px', fontSize: '13px', fontWeight: 600 }}>
                    {`Peajes en Estado Pendientes: ${pendingCount}`}
                </p>
                
                {/* Muestra los errores de campo, si existen */}
                {errorFields && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                        {Object.entries(errorFields).map(([field, error], index) => (
                            <p key={index}>{`${field}: ${error}`}</p>
                        ))}
                    </div>
                )}
            </DefaultCard>

            <style>
                {`
                    @keyframes loadingBar {
                        0% { transform: scaleX(0); }
                        50% { transform: scaleX(1); }
                        100% { transform: scaleX(0); }
                    }
                `}
            </style>
        </>
    );
};

export default AITolls;
