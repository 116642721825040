import React from 'react';
import { IconProps } from '../types';

export const Sale: React.FC<IconProps> = ({ size = 24, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 1024 1024"
            fill="currentColor"
            version="1.1"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <path d="M585.1 640.1V823l164.6 91.4L914.3 823V640.1l-164.6-91.4-164.6 91.4z m256 139.9l-91.4 50.8-91.4-50.8v-96.8l91.4-50.8 91.4 50.8V780z" />
                <path d="M713.600676 737.454004a36.6 36.6 0 1 0 72.255718-11.719697 36.6 36.6 0 1 0-72.255718 11.719697Z" />
                <path d="M109.7 109.7v804.6h438.9v-73.2H182.9V182.9h658.3V512h73.1V109.7z"></path>
                <path d="M694.9 380.9v58H768V256H585.2v73.1h58L507.3 464.9l-109.7-73.1-167.4 167.5 51.7 51.7L407 485.9l109.7 73.2zM256 694.9h256V768H256z" />
            </g>
        </svg>
    );
};
