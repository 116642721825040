import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';

const useTransportOrderStatusHistoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [
        fetchingGetTransportOrderStatusHistoriesByTransportOrder,
        setFetchingGetTransportOrderStatusHistoriesByTransportOrder
    ] = useState(false);

    const getAllTransportOrderStatusHistoriesByTransportOrder = (
        transport_order_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORT_ORDER_STATUS_HISTORIES.GET_TRANSPORT_ORDER_STATUS_HISTORIES_BY_TRANSPORT_ORDER.replace(
                ':transport_order_id',
                transport_order_id.toString()
            ),
            setFetching: setFetchingGetTransportOrderStatusHistoriesByTransportOrder
        });
    };

    return {
        getAllTransportOrderStatusHistoriesByTransportOrder,
        fetchingGetTransportOrderStatusHistoriesByTransportOrder
    };
};

export default useTransportOrderStatusHistoryService;
