import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ProviderType } from '../../../app/models/ProviderType';
import ProviderTypeForm from './forms/ProviderTypeForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    providerType: ProviderType;
    setProviderType: (providerType: ProviderType) => void;
    errorFields?: any;
}

const ProviderTypeFormContainer = ({
    fetching,
    action,
    cancel,
    providerType,
    setProviderType,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ProviderTypeForm
                        providerTypeDataForm={providerType}
                        setProviderTypeDataForm={setProviderType}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ProviderTypeFormContainer;
