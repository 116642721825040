import React, { useContext, useEffect, useState }  from 'react'
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import DefaultModal from '../../components/default/DefaultModal';
import MonthlyClosureCreate from './components/MonthlyClosureCreate';
import { MonthlyClosureDataForm, MonthlyClosureFilters } from '../../app/models/MonthlyClosure';
import useMonthlyClosureService from '../../app/services/hooks/useMonthlyClosureService';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import MonthlyClosureDataTable from './components/tables/MonthlyClosureDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';


const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Cierre mensual',
        url: '/monthly-closures',
        isActive: true
    }
];

const MonthlyClosure = () => {

    const { 
        getMonthlyClosures, 
        fetchingGetMonthlyClosures
    } = useMonthlyClosureService();
    const { navigationPage } = useNavigationPage();

    const [totalRows, setTotalRows] = useState<number>(0);
    const [showingCreate, setShowingCreate] = useState(false);
    const [monthlyClosures, setMonthlyClosures] = useState<MonthlyClosureDataForm[]>([])
    const [monthlyClosureFilter, setMonthlyClosureFilter] = useState<MonthlyClosureFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [
        monthlyClosureFilter.page, 
        monthlyClosureFilter.per_page, 
        monthlyClosureFilter.sort, 
        monthlyClosureFilter.order
    ]);

    const getAllmonthlyClosures = () => {
        getMonthlyClosures(monthlyClosureFilter, {
            onSuccess: (response: ServiceResponse) => {
                setMonthlyClosures(response.data.monthly_closures);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (closureId: number) => {
        navigationPage('/monthly-closures/' + closureId + '/edit');
    };

    const reloadTable = () => {
        getAllmonthlyClosures();
    };


    return (
        <>
            <Breadcrumbs pageSection="Cierre mensual" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate callbackCreate={showCreate} title="Nuevo Cierre de Mes" />
                    }
                />

                <MonthlyClosureDataTable
                    monthlyClosures={monthlyClosures} 
                    totalRows={totalRows} 
                    loading={fetchingGetMonthlyClosures}
                    filter={monthlyClosureFilter} 
                    setFilter={setMonthlyClosureFilter} 
                    onSearch={reloadTable}
                    edit={showEdit}
                />
            </DefaultCard>
                

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Generar Cierre de Mes"
                    backdrop={true}
                    showFooter={false}
                >
                    <MonthlyClosureCreate
                        onSaved={() => {
                            reloadTable();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    )
}

export default MonthlyClosure
