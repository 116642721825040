import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import LazyLoading from '../../../components/LazyLoading';
import DefaultModal from '../../../components/default/DefaultModal';

import ExpenseCreate from './ExpenseCreate';
import ExpenseEdit from './ExpenseEdit';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import { TollExpenseDataForm, TollExpenseFilters } from '../../../app/models/TollExpense';
import useTollExpenseService from '../../../app/services/hooks/useExpenseTollService';
import TollExpenseDataTable from './tables/TollExpenseDataTable';
import TollExpenseEdit from './TollExpenseEdit';
import { PaymentMethod } from '../../../app/models/PaymentMethod';
import { TollExpenseHistoryDataForm } from '../../../app/models/TollExpenseHistory';
import { ExpenseHistoryDataForm } from '../../../app/models/ExpenseHistory';
import TollExpenseHistoryCreate from './TollExpenseHistoryCreate';
import TollExpenseHistoryContainer from './TollExpenseHistoryContainer';
import TollExpenseCreate from './TollExpenseCreate';
import not_image_default from '../../../assets/images/not-image.png';

interface Props {
    transportOrderId: number;
    canTollExpense: boolean;
    refreshKey?: boolean
}

const TollExpenseContainer = ({ transportOrderId, canTollExpense = false, refreshKey }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllTollExpensesByTransportOrder,
        fetchingGetTollExpensesByTransportOrder,
        deleteTollExpense,
        fetchingDeleteTollExpense,
        getUrlDocumentTollExpenseOnTransportOrder,
        downloadDocument,
        approvedTollExpense,
        cancelIsApprovedTollExpense,
        showFileTollExpense,
        fetchingshowFileTollExpense
    } = useTollExpenseService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingGet, setShowingGet] = useState(false);

    const [tollExpenseIdEdit, setTollExpenseIdEdit] = useState<number>(-1);

    const [tollExpenses, setTollexpenses] = useState<TollExpenseDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

    const [filter, setFilter] = useState<TollExpenseFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const [showingCreateTollExpenseHistory, setShowingCreateTollExpenseHistory] = useState(false);
    const [tollExpenseHistoryIdCreate, setTollExpenseHistoryIdCreate] = useState<number>(-1);

    const [showingGetTollExpenseHistories, setShowingGetTollExpenseHistories] = useState(false);
    const [tollExpenseHistoryIdGet, setTollExpenseHistoryIdGet] = useState<number>(-1);
    const [tollExpenseHistory, setTollExpenseHistory] = useState<TollExpenseHistoryDataForm>({
        comment: '',
        is_approved: true
    });

    const [trollExpenseFile, setTollExpenseFile] = useState<string | undefined>(undefined);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (canTollExpense) {
            reloadTable();
        }
    }, [filter.page, filter.per_page, filter.sort, filter.order,refreshKey]);

    const reloadTable = () => {
        getAllTollExpensesByTransportOrder(filter, transportOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setTollexpenses(response.data.toll_expenses);
                setDocumentTypes(response.data.expense_document_types);
                setPaymentMethods(response.data.payment_methods);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const handleRowClick = (row: TollExpenseDataForm) => {
        if (showFile) {
            showFile(Number(row.id));  // Muestra el archivo relacionado al hacer clic en la fila
        }
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (expenseId: number) => {
        setShowingEdit(true);
        setTollExpenseIdEdit(expenseId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setTollExpenseIdEdit(-1);
    };

    const showCreateTollExpenseHistory = (tollExpenseId: number) => {
        setShowingCreateTollExpenseHistory(true);
        setTollExpenseHistoryIdCreate(tollExpenseId);
    };

    const hideCreateTollExpenseHistory = () => {
        setShowingCreateTollExpenseHistory(false);
        setTollExpenseHistoryIdCreate(-1);
        useReactConfirmAlert().infoAlert({
            title: 'Cancelado',
            message: `El gasto de peaje no ha cambiado de estado.`
        });
    };

    const hideSuccessCreateTollExpenseHistory = () => {
        setShowingCreateTollExpenseHistory(false);
        setTollExpenseHistoryIdCreate(-1);
    };

    const showGetTollExpenseHistories = (tollExpenseId: number) => {
        setShowingGetTollExpenseHistories(true);
        setTollExpenseHistoryIdGet(tollExpenseId);
    };

    const hideGetTollExpenseHistories = () => {
        setShowingGetTollExpenseHistories(false);
        setTollExpenseHistoryIdGet(-1);
    };

    const document = (expenseId: number) => {
        if (showLoading) showLoading('download', 'descargando archivo...');
        getUrlDocumentTollExpenseOnTransportOrder(transportOrderId, expenseId, {
            onSuccess: (response: ServiceResponse) => {
                window.open(response.data.url_file);
                if (changeAnimation)
                    changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) {
                    hideLoading();
                }
                toast.error(response.message);
            }
        });
    };
    const destroy = (expenseId: number) => {
        const _text = 'Está a punto de eliminar el gasto de peaje #' + expenseId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando gasto de peaje...');
                        deleteTollExpense(transportOrderId, expenseId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El gasto de peaje no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const cancel = (expenseId: number, approved: boolean) => {
        const _text = 'Está a punto de pasar el gasto #' + expenseId + ` de ${approved ? 'aprobado' : 'rechazado'} a nulo`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando gasto de viaje...');
                        cancelIsApprovedTollExpense(expenseId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El gasto de viaje no ha cambiado de estado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const approved = (tollExpenseId: number, value: boolean, message: string) => {
        console.log(value)
        const _text = `El gasto de peaje # ${tollExpenseId} está apunto de ser ${message},${value ? " recuerde rendir el gasto antes de intentar aprobarlo": ""}`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (value) {
                            getApprovedTollExpense(tollExpenseId, tollExpenseHistory);
                        } else {
                            showCreateTollExpenseHistory(tollExpenseId);
                        }
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: `El gasto de peaje no se ha ${message}.`
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const getApprovedTollExpense = (
        tollExpenseId: number,
        tollExpenseHistory: ExpenseHistoryDataForm
    ) => {
        if (showLoading) showLoading('loading', `Cambiando estado de gasto peaje...`);
        approvedTollExpense(transportOrderId, tollExpenseId, tollExpenseHistory, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });

                reloadTable();
                hideSuccessCreateTollExpenseHistory();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };


    const showFile = (tollExpenseId: number) => {
        // if (showLoading) showLoading('loading', 'Obteniendo documento...');
        setTollExpenseFile(undefined);
        showFileTollExpense(transportOrderId, tollExpenseId, {
            onSuccess: (response: ServiceResponse) => {
                // if (hideLoading) hideLoading();
                setTollExpenseFile(response.data.toll_expense_file);
              
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
        });
    };

    return (
        <>
            {canTollExpense && (
                <DefaultCard>
                    <div className="row">
                        <h5>Peajes</h5>
                        <div className="col-12 mb-2 mt-2">
                            <ButtonCreate title="Nuevo gasto peaje" callbackCreate={showCreate} />
                        </div>
                        <div className="col-12">
                            <TollExpenseDataTable
                                transportOrderId={transportOrderId}
                                loading={fetchingGetTollExpensesByTransportOrder}
                                tollExpenses={tollExpenses}
                                totalRows={totalRows}
                                documentTypes={documentTypes}
                                paymentMethods={paymentMethods}
                                edit={showEdit}
                                // show={showGet}
                                destroy={destroy}
                                approved={approved}
                                cancel={cancel}
                                showTollExpenseHistory={showGetTollExpenseHistories}
                                filter={filter}
                                setFilter={setFilter}
                                documentTollExpense={document}
                                callbackSuccess={reloadTable}
                                canBeEdited={true}
                                onSearch={reloadTable}
                                // showFile={showFile}
                                // trollExpenseUrlFile={trollExpenseFile}
                                // setTollExpenseFile={setTollExpenseFile}
                                // se sacaron por ser incesarios ya que esos datos ya estan en el get

                            />
                        </div>
                    </div>
                </DefaultCard>
            )}
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear gasto de peaje"
                    backdrop={true}
                    showFooter={false}
                >
                    <TollExpenseCreate
                        transportOrderId={transportOrderId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Gasto de peaje"
                    backdrop={true}
                    showFooter={false}
                >
                    <TollExpenseEdit
                        tollExpenseId={tollExpenseIdEdit}
                        transportOrderId={transportOrderId}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}

            {showingCreateTollExpenseHistory ? (
                <DefaultModal
                    show={showingCreateTollExpenseHistory}
                    handleClose={hideCreateTollExpenseHistory}
                    title="Añadir información"
                    backdrop={true}
                    showFooter={false}
                >
                    <TollExpenseHistoryCreate
                        tollExpenseId={tollExpenseHistoryIdCreate}
                        getApprovedTollExpense={getApprovedTollExpense}
                        errorFields={errorFields}
                        onSaved={() => {
                            reloadTable();
                            hideSuccessCreateTollExpenseHistory();
                        }}
                        onCancel={hideCreateTollExpenseHistory}
                        onError={hideCreateTollExpenseHistory}
                    />
                </DefaultModal>
            ) : null}

            {showingGetTollExpenseHistories ? (
                <DefaultModal
                    show={showingGetTollExpenseHistories}
                    handleClose={hideGetTollExpenseHistories}
                    title="Historial gastos peaje"
                    backdrop={true}
                    showFooter={false}
                    size="lg"
                >
                    <TollExpenseHistoryContainer
                        tollExpenseId={tollExpenseHistoryIdGet}
                        onCancel={hideGetTollExpenseHistories}
                        onError={hideGetTollExpenseHistories}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TollExpenseContainer;
