import React from 'react';
import { IconProps } from '../types';

export const Unit: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            width={size}
            height={size}
            viewBox="0 0 512 512"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path d="M511.173,264.814l-53.646-158.703c-1.237-7.68-7.876-13.55-15.904-13.55H300.478 c-4.777-13.133-15.215-23.571-28.348-28.348V30.62c0-8.279-6.066-15.543-14.294-16.451c-9.729-1.073-17.963,6.515-17.963,16.028 v34.017c-13.133,4.777-23.571,15.215-28.348,28.348H70.377c-8.028,0-14.667,5.871-15.904,13.55L0.827,264.814 c-0.752,2.221-0.972,4.542-0.739,6.812v35.705c0,22.722,18.42,41.141,41.141,41.141h58.576c22.722,0,41.141-18.42,41.141-41.141 v-35.705c0.233-2.269,0.013-4.59-0.739-6.812L92.887,124.819H211.92c4.502,12.377,14.03,22.362,26.106,27.47l-49.974,313.387 h-63.884c-8.279,0-15.543,6.066-16.451,14.294c-1.073,9.729,6.515,17.963,16.028,17.963h263.014 c8.279,0,15.543-6.066,16.451-14.294c1.073-9.729-6.515-17.963-16.028-17.963h-63.579l-49.954-313.258 c12.229-5.066,21.89-15.111,26.432-27.599h119.033l-47.322,139.995c-0.752,2.221-0.972,4.542-0.739,6.812v35.705 c0,22.722,18.42,41.141,41.141,41.141h58.576c22.722,0,41.142-18.42,41.142-41.141v-35.705 C512.145,269.357,511.924,267.036,511.173,264.814z M108.691,307.331c-0.001,4.906-3.978,8.884-8.885,8.884H41.231 c-4.906,0-8.884-3.977-8.884-8.884v-21.223h76.343V307.331z M102.056,253.85H38.188l31.934-94.471L102.056,253.85z M256,93.636 c8.301,0,15.054,6.753,15.054,15.054c0,8.301-6.753,15.054-15.054,15.054c-8.301,0-15.054-6.753-15.054-15.054 C240.946,100.389,247.699,93.636,256,93.636z M291.333,465.676h-71.012l4.801-30.107h61.41L291.333,465.676z M281.388,403.312 h-51.123l25.562-160.295L281.388,403.312z M441.879,159.379l31.934,94.471h-63.867L441.879,159.379z M479.654,307.331 c0,4.906-3.977,8.884-8.884,8.884h-58.576c-4.906,0-8.884-3.977-8.884-8.884v-21.223h76.343V307.331z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
