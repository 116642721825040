import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Login from './pages/Auth/Login';
import RecoveryPassword from './pages/Auth/RecoveryPassword';
import MainTheme from './template/MainTheme';
import AppProvider from './contexts/AppContext';
import AuthProvider, { AuthContext } from './contexts/AuthContext';
import NotificationProvider from './contexts/NotificationContext';
import PrivateMiddleware from './routes/middleware/PrivateMiddleware';
import Pusher from 'pusher-js';
import Notifications from './pages/Notifications';
import useAuthService from './app/services/hooks/useAuthService';
import ForeignExchange from './pages/ForeignExchange';
import Client from './pages/Clients';
import Society from './pages/Societies';
import Place from './pages/Places';
import User from './pages/Users';
import Truck from './pages/Trucks';
import Provider from './pages/Providers';
import Carrier from './pages/Carriers';
import Hopper from './pages/Hoppers';
import WorkOrder from './pages/WorkOrders';
import Quotes from './pages/Quotes';
import QuoteEdit from './pages/Quotes/subpages/QuoteEdit';
import QuoteShow from './pages/Quotes/subpages/QuoteShow';
import Dashboard from './pages/Dashboard';
import CarrierEdit from './pages/Carriers/subpages/CarrierEdit';
import TruckEdit from './pages/Trucks/subpages/TruckEdit';
import HopperEdit from './pages/Hoppers/subpages/HopperEdit';
import WorkOrderEdit from './pages/WorkOrders/subpages/WorkOrderEdit';
import WorkOrderShow from './pages/WorkOrders/subpages/WorkOrderShow';
import TransportOrderEdit from './pages/WorkOrders/subpages/TransportOrderEdit';
import TransportationOrder from './pages/TransportationOrders';
import TransportationOrderEdit from './pages/TransportationOrders/subpages/TransportationOrderEdit';
import TransportationOrderShow from './pages/TransportationOrders/subpages/TransportationOrderShow';
import RouteSegment from './pages/RouteSegment';
import TransportOrder from './pages/TransportOrder';
import Tolls from './pages/tolls';
import TollEdit from './pages/tolls/subpages/TollEdit';
import Guide from './pages/Guides';
import Expense from './pages/Expenses';
import LoadTicket from './pages/LoadTickets';
import Afps from './pages/Afps';
import Provisions from './pages/Provisions';
import Banks from './pages/Banks';
import BankAccountTypes from './pages/BankAccountTypes';
import ProviderTypes from './pages/ProviderTypes';
import CarrierTypes from './pages/CarrierTypes';
import Units from './pages/Units';
import Products from './pages/Products';
import Brands from './pages/Brand';
import VehicleModels from './pages/VehicleModels';
import Currencies from './pages/Currencies';
import FuelConsumptions from './pages/FuelConsumptions';
import Groups from './pages/Groups';
import PublicMiddleware from './routes/middleware/PublicMiddleware';
import CheckingAccount from './pages/CheckingAccount';
import InternalSettlements from './pages/InternalSettlements';
import MonthlyReconciliations from './pages/MonthlyReconciliation';
import Profile from './pages/Profile/EditProfile';
import PasswordProfile from './pages/Profile/EditPassword';
import ExpenseTypes from './pages/ExpenseType';
import Calendar from './pages/Calendar';
import Holidays from './pages/Holidays';
import EventTypes from './pages/EventTypes';
import Workers from './pages/Workers';
import Shifts from './pages/Shifts';
import WorkersEdit from './pages/Workers/subpages/WorkersEdit';
import JobTitles from './pages/JobTitles';
import WorkersCalendar from './pages/Workers/subpages/WorkersCalendar';
import WorkersRequests from './pages/WorkersRequests';
import CalendarWorkerShow from './pages/Calendar/components/subpages/CalendarWorkerShow';
import TravelExpenses from './pages/TravelExpenses';
import TransferReasons from './pages/TransferReasons';
import SocietySignatures from './pages/SocietySignature';
import DispatchPrograms from './pages/DispatchProgram';
import DispatchProgramEdit from './pages/DispatchProgram/subpages/DispatchProgramEdit';
import DispatchProgramShow from './pages/DispatchProgram/subpages/DispatchProgramShow';
import DailyCarriers from './pages/DailyCarriers';
import Document from './pages/Documents';
import DocumentSet from './pages/DocumentSets';
//import SocietiesEdit from './pages/Societies/subpages/SocietiesEdit';
import SocietyEdit from './pages/Societies/components/SocietyEdit';
import Accounts from './pages/Accounts';
import StatusHistories from './pages/StatusHistories';
import Clasifications from './pages/Clasifications';
import UnitEquivalence from './pages/UnitEquivalence';
import Contract from './pages/Contract';
import Liquidations from './pages/Liquidations';
import Liquidation from './pages/Liquidations';
import Bonuses from './pages/Bonus';
import Deductions from './pages/Deductions';
import AfpEdit from './pages/Afps/components/subpages/AfpEdit';
import ProvisionEdit from './pages/Provisions/components/subpages/ProvisionEdit';
import JobTitleLiquidationSet from './pages/JobTitleLiquidationSets';
import DeductionEdit from './pages/Deductions/subpages/DeductionEdit';
import BonusEdit from './pages/Bonus/subpages/BonusEdit';
import LiquidationShow from './pages/Liquidations/subpages/LiquidationShow';
import SurveyEdit from './pages/Surveys/subpages/SurveyEdit';
import Surveys from './pages/Surveys';
import QuestionCreate from './pages/Surveys/subpages/QuestionCreate';
import SurveyResponses from './pages/Surveys/subpages/SurveyResponses';
import QuestionEdit from './pages/Surveys/subpages/QuestionEdit';
import RespondSurvey from './pages/Surveys/subpages/RespondSurvey';
import IncidentReasons from './pages/IncidentReasons';
import MonthlyClosures from './pages/MonthlyClosures';
import MonthlyClosureEdit from './pages/MonthlyClosures/subpages/MonthlyClosureEdit';
import AITolls from './pages/AITolls';


// import { EndPoints } from './app/services/EndPoints';

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || '', {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER
    // userAuthentication: {
    //     endpoint: EndPoints.AUTH.PUSHER_AUTH,
    //     transport: "ajax",
    //     params: {},
    //     headers: {},
    //   },
});

export const channel = pusher.subscribe('notifications');

function App() {
    // const { getRoles } = useAuthService();

    // const [roles, setRoles] = useState<string[]>([]);
    // const [company, setCompany] = useState<string>('');

    // useEffect(() => {
    //     const path = window.location.pathname;
    //     if (path != '/login' && path != '/recovery-password') {
    //         getRoles({
    //             onSuccess: (response) => {
    //                 setRoles(response.data.roles);
    //                 setCompany(response.data.company);
    //             }
    //         });
    //     }
    // }, []);

    return (
        <AuthProvider>
            <AppProvider>
                <NotificationProvider>
                    <Router>
                        <Switch>
                            <Route path="/login">
                                <PublicMiddleware>
                                    <MainTheme showLayouts={false}>
                                        <Login />
                                    </MainTheme>
                                </PublicMiddleware>
                            </Route>
                            <Route path="/recovery-password">
                                <PublicMiddleware>
                                    <MainTheme showLayouts={false}>
                                        <RecoveryPassword />
                                    </MainTheme>
                                </PublicMiddleware>
                            </Route>
                            <PrivateMiddleware>
                                <Switch>
                                    <Route path="/carriers/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <CarrierEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/workers/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <WorkersEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/societies/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <SocietyEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/trucks/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <TruckEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/hoppers/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <HopperEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/quotes/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <QuoteEdit />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/transport-orders/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <TransportOrderEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/incident-reasons">
                                        <MainTheme showLayouts={true}>
                                            <IncidentReasons />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/bonus/:id/edit" exact>
                                        <MainTheme showLayouts={true}>
                                            <BonusEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/surveys/:id/edit" exact>
                                        <MainTheme showLayouts={true}>
                                            <SurveyEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/surveys/:id/sections/:section_id/questions/create">
                                        <MainTheme showLayouts={true}>
                                            <QuestionCreate />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/surveys/:id/sections/:section_id/questions/:question_id/edit">
                                        <MainTheme showLayouts={true}>
                                            <QuestionEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/surveys/:id/respond" exact>
                                        <MainTheme showLayouts={true}>
                                            <RespondSurvey />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/surveys/:id/responses" exact>
                                        <MainTheme showLayouts={true}>
                                            <SurveyResponses />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/bonus" exact>
                                        <MainTheme showLayouts={true}>
                                            <Bonuses />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/surveys" exact>
                                        <MainTheme showLayouts={true}>
                                            <Surveys />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/transport-orders">
                                        <MainTheme showLayouts={true}>
                                            <TransportOrder />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/transportation-orders/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <TransportationOrderEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/transportation-orders/:id/show">
                                        <MainTheme showLayouts={true}>
                                            <TransportationOrderShow />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/transportation-orders">
                                        <MainTheme showLayouts={true}>
                                            <TransportationOrder />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/monthly-closures/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <MonthlyClosureEdit/>
                                        </MainTheme>
                                    </Route>
                                    <Route path="/monthly-closures">
                                        <MainTheme showLayouts={true}>
                                            <MonthlyClosures/>
                                        </MainTheme>
                                    </Route>

                                    <Route path="/monthly-reconciliations">
                                        <MainTheme showLayouts={true}>
                                            <MonthlyReconciliations />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/guides">
                                        <MainTheme showLayouts={true}>
                                            <Guide />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/expenses">
                                        <MainTheme showLayouts={true}>
                                            <Expense />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/load-tickets">
                                        <MainTheme showLayouts={true}>
                                            <LoadTicket />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/users">
                                        <MainTheme showLayouts={true}>
                                            <User />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/places">
                                        <MainTheme showLayouts={true}>
                                            <Place />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/fuel-consumptions">
                                        <MainTheme showLayouts={true}>
                                            <FuelConsumptions />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/societies-signatures">
                                        <MainTheme showLayouts={true}>
                                            <SocietySignatures />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/societies">
                                        <MainTheme showLayouts={true}>
                                            <Society />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/clients">
                                        <MainTheme showLayouts={true}>
                                            <Client />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/documents">
                                        <MainTheme showLayouts={true}>
                                            <Document />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/document-sets">
                                        <MainTheme showLayouts={true}>
                                            <DocumentSet />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/quotes/:id/show">
                                        <MainTheme showLayouts={true}>
                                            <QuoteShow />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/quotes">
                                        <MainTheme showLayouts={true}>
                                            <Quotes />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/quotes/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <QuoteEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/contracts">
                                        <MainTheme showLayouts={true}>
                                            <Contract />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/liquidations/:id/show">
                                        <MainTheme showLayouts={true}>
                                            <LiquidationShow />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/liquidations">
                                        <MainTheme showLayouts={true}>
                                            <Liquidation />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/documents">
                                        <MainTheme showLayouts={true}>
                                            <Document />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/document-sets">
                                        <MainTheme showLayouts={true}>
                                            <DocumentSet />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/quotes/:id/show">
                                        <MainTheme showLayouts={true}>
                                            <QuoteShow />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/quotes">
                                        <MainTheme showLayouts={true}>
                                            <Quotes />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/quotes/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <QuoteEdit />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/providers">
                                        <MainTheme showLayouts={true}>
                                            <Provider />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/provider-types">
                                        <MainTheme showLayouts={true}>
                                            <ProviderTypes />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/trucks">
                                        <MainTheme showLayouts={true}>
                                            <Truck />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/carriers">
                                        <MainTheme showLayouts={true}>
                                            <Workers key="carriers" is_carrier={true} />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/hoppers">
                                        <MainTheme showLayouts={true}>
                                            <Hopper />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/work-orders/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <WorkOrderEdit />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/work-orders/:id/show">
                                        <MainTheme showLayouts={true}>
                                            <WorkOrderShow />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/work-orders">
                                        <MainTheme showLayouts={true}>
                                            <WorkOrder />
                                        </MainTheme>
                                    </Route>

                                    <Route path="/notifications">
                                        <MainTheme showLayouts={true}>
                                            <Notifications />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/route-segments">
                                        <MainTheme showLayouts={true}>
                                            <RouteSegment />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/dispatch-programs/:id/edit">
                                        <MainTheme showLayouts={true}>
                                            <DispatchProgramEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/dispatch-programs/:id/show">
                                        <MainTheme showLayouts={true}>
                                            <DispatchProgramShow />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/daily-carriers">
                                        <MainTheme showLayouts={true}>
                                            <DailyCarriers />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/dispatch-programs">
                                        <MainTheme showLayouts={true}>
                                            <DispatchPrograms />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/tolls" exact>
                                        <MainTheme showLayouts={true}>
                                            <Tolls />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/tolls/:id/edit" exact>
                                        <MainTheme showLayouts={true}>
                                            <TollEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/ai-tolls">
                                        <MainTheme showLayouts={true}>
                                            <AITolls />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/afps" exact>
                                        <MainTheme showLayouts={true}>
                                            <Afps />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/afps/:id/edit" exact>
                                        <MainTheme showLayouts={true}>
                                            <AfpEdit />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/calendar">
                                        <MainTheme showLayouts={true}>
                                            <Calendar />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/calendar-by-worker/:id/event">
                                        <MainTheme showLayouts={true}>
                                            <CalendarWorkerShow />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/event-types">
                                        <MainTheme showLayouts={true}>
                                            <EventTypes />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/job-titles">
                                        <MainTheme showLayouts={true}>
                                            <JobTitles />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/holidays">
                                        <MainTheme showLayouts={true}>
                                            <Holidays />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/workers">
                                        <MainTheme showLayouts={true}>
                                            <Workers key="workers" />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/calendar-requests">
                                        <MainTheme showLayouts={true}>
                                            <WorkersRequests />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/travel-expenses">
                                        <MainTheme showLayouts={true}>
                                            <TravelExpenses />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/shifts">
                                        <MainTheme showLayouts={true}>
                                            <Shifts />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/provisions" exact>
                                        <MainTheme showLayouts={true}>
                                            <Provisions />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/checking-accounts">
                                        <MainTheme showLayouts={true}>
                                            <CheckingAccount />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/internal-settlements">
                                        <MainTheme showLayouts={true}>
                                            <InternalSettlements />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/banks">
                                        <MainTheme showLayouts={true}>
                                            <Banks />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/job_title_liquidation_set">
                                        <MainTheme showLayouts={true}>
                                            <JobTitleLiquidationSet />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/deductions" exact>
                                        <MainTheme showLayouts={true}>
                                            <Deductions />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/deductions/:id/edit" exact>
                                        <MainTheme showLayouts={true}>
                                            <DeductionEdit />
                                        </MainTheme>
                                    </Route>
                                    
                                    <Route path="/transfer_reasons">
                                        <MainTheme showLayouts={true}>
                                            <TransferReasons />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/transfer_reasons">
                                        <MainTheme showLayouts={true}>
                                            <TransferReasons />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/bank-account-types">
                                        <MainTheme showLayouts={true}>
                                            <BankAccountTypes />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/expense-types">
                                        <MainTheme showLayouts={true}>
                                            <ExpenseTypes />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/accounts">
                                        <MainTheme showLayouts={true}>
                                            <Accounts />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/clasifications">
                                        <MainTheme showLayouts={true}>
                                            <Clasifications />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/products">
                                        <MainTheme showLayouts={true}>
                                            <Products />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/units">
                                        <MainTheme showLayouts={true}>
                                            <Units />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/unit-equivalence">
                                        <MainTheme showLayouts={true}>
                                            <UnitEquivalence />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/currencies">
                                        <MainTheme showLayouts={true}>
                                            <Currencies />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/foreign-exchanges">
                                        <MainTheme showLayouts={true}>
                                            <ForeignExchange />
                                        </MainTheme>
                                    </Route> 
                                    <Route path="/brands">
                                        <MainTheme showLayouts={true}>
                                            <Brands />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/vehicle-models">
                                        <MainTheme showLayouts={true}>
                                            <VehicleModels />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/roles">
                                        <MainTheme showLayouts={true}>
                                            <Groups />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/status-histories">
                                        <MainTheme showLayouts={true}>
                                            <StatusHistories />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/edit-profile">
                                        <MainTheme showLayouts={true}>
                                            <Profile />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/dashboard">
                                        <MainTheme showLayouts={true}>
                                            <Dashboard />
                                        </MainTheme>
                                    </Route>
                                    <Route path="/">
                                        <MainTheme showLayouts={true}>
                                            <Dashboard />
                                        </MainTheme>
                                    </Route>
                                </Switch>
                            </PrivateMiddleware>
                        </Switch>
                    </Router>
                </NotificationProvider>
            </AppProvider>
        </AuthProvider>
    );
}

export default App;
