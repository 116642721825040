import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';

import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { VehicleModelDataForm } from '../../../app/models/VehicleModel';
import { VehicleType } from '../../../app/models/VehicleType';
import useVehicleModelService from '../../../app/services/hooks/useVehicleModelService';
import VehicleModelForm from './forms/VehicleModelForm';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const VehicleModelCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [vehicleModel, setVehicleModel] = useState<VehicleModelDataForm>({
        name: '',
        vehicle_type: ''
    });
    const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingStoreVehicleModel, storeVehicleModel, createVehicleModel } =
        useVehicleModelService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createVehicleModel({
            onSuccess: (response: ServiceResponse) => {
                setVehicleTypes(response.data.vehicle_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando modelo...');
        storeVehicleModel(vehicleModel, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <VehicleModelForm
                        vehicleModelDataForm={vehicleModel}
                        setVehicleModelDataForm={setVehicleModel}
                        vehicleTypes={vehicleTypes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreVehicleModel} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreVehicleModel} />
                </div>
            </div>
        </>
    );
};

export default VehicleModelCreate;
