import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { IncidentReasonDataForm } from '../../../../app/models/IncidentReason';

interface GeneralProps {
    incidentReasonForm: IncidentReasonDataForm;
    setIncidentReasonForm: (incidentReasonForm: IncidentReasonDataForm) => void;
    errorFields?: any;
}

const IncidentReasonForm = ({
    incidentReasonForm,
    setIncidentReasonForm,
    errorFields
}: GeneralProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setIncidentReasonForm({ ...incidentReasonForm, [name]: value });
    };

    if (!incidentReasonForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre</label>
                    <input
                        className={`form-control ${fieldHasError('title')}`}
                        type="text"
                        name="name"
                        id="name"
                        value={incidentReasonForm.name}
                        onChange={handleChange}
                        placeholder="Ingrese Nombre"
                        onFocus={() => onFocusRemove('name')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                <label className="col-form-label">Descripción</label>
                <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={incidentReasonForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default IncidentReasonForm;
