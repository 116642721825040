import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { defaultIncidentDataForm, IncidentDataForm } from '../../../app/models/Incident';
import useIncidentService from '../../../app/services/hooks/useIncidentService';
import IncidentForm from './forms/IncidentForm';
import { IncidentReasonDataForm } from '../../../app/models/IncidentReason';

interface Props {
    transportOrderId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const IncidentCreate = ({ transportOrderId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);

    const { createIncident, storeIncident,fetchingStoreIncident, fetchingCreateIncident } = useIncidentService();

    const [incident, setIncident] = useState<IncidentDataForm>({
        ...defaultIncidentDataForm,
        transport_order_id: transportOrderId,
    });
    const [incidentReasons, setIncidentReasons] = useState<IncidentReasonDataForm[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createIncident({
            onSuccess: (response: ServiceResponse) => {
                
                setIncidentReasons(response.data.incident_reasons);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const save = () => {
        if (showLoading) showLoading('loading', 'Guardando incidente...');
        storeIncident(incident, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            },
        });
    };
    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <IncidentForm 
                incident={incident}
                incidentReasons={incidentReasons} 
                setIncident={setIncident} 
                errorFields={errorFields} 
            />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreIncident} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={save} locked={fetchingStoreIncident} />
                </div>
            </div>
        </>
    );
};

export default IncidentCreate;
