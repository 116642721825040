import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents, ServiceEventsDocuments } from '../shared/interfaces';
import { ExpenseDataForm, ExpenseFilters } from '../../models/Expense';
import { getFetchDefaultHeaders } from '../shared/vars';
import { AppContext } from '../../../contexts/AppContext';
import { MassiveApproveForm } from '../../models/Toll';
import { ExpenseHistoryDataForm } from '../../models/ExpenseHistory';

const useExpenseService = () => {
    const { doGet, doPost, doPut, doDelete, doGetDocument } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [fetchingGetExpensesByTransportOrder, setFetchingGetExpensesByTransportOrder] =
        useState(false);
    const [fetchingGetExpenses, setFetchingGetExpenses] = useState(false);
    const [fetchingGetExcelExpenses, setFetchingGetExcelExpenses] = useState(false);
    const [fetchingStoreExpense, setFetchingStoreExpense] = useState(false);
    const [fetchingStoreExpenseByEvent, setFetchingStoreExpenseByEvent] = useState(false);
    const [fetchingEditExpense, setFetchingEditExpense] = useState(false);
    const [fetchingEditCalendarExpense, setFetchingEditCalendarExpense] = useState(false);
    const [fetchingGetUrlDocument, setFetchingGetUrlDocument] = useState(false);
    const [fetchingGetUrlDocumentOnTransportOrder, setFetchingGetUrlDocumentOnTransportOrder] =
        useState(false);
    const [fetchingUpdateExpense, setFetchingUpdateExpense] = useState(false);
    const [fetchingUpdateCalendarExpense, setFetchingUpdateCalendarExpense] = useState(false);
    const [fetchingDeleteExpense, setFetchingDeleteExpense] = useState(false);
    const [fetchingDeleteCalendarExpense, setFetchingDeleteCalendarExpense] = useState(false);
    const [fetchingCreateExpense, setFetchingCreateExpense] = useState(false);
    const [fetchingCreateCalendarExpense, setFetchingCreateCalendarExpense] = useState(false);
    const [fetchingShowExpense, setFetchingShowExpense] = useState(false);
    const [fetchingGetExpensesByCarrier, setFetchingGetExpensesByCarrier] = useState(false);
    const [fetchingGetExpensesEditByCarrier, setFetchingGetExpensesEditByCarrier] = useState(false);
    const [fetchingGetExpensesByDates, setFetchingGetExpensesByDates] = useState(false);
    const [fetchingGetExpensesByEventId, setFetchingGetExpensesByEventId] = useState(false);
    const [fetchingApprovedExpense, setFetchingApprovedExpense] = useState(false);
    const [fetchingCancelIsApprovedExpense, setFetchingCancelIsApprovedExpense] = useState(false);
    const [fetchingApprovedCalendarExpense, setFetchingApprovedCalendarExpense] = useState(false);
    const [
        fetchingChangeApproveRowsFromTransportOrder,
        setFetchingChangeApproveRowsFromTransportOrder
    ] = useState(false);

    const getAllExpensesByTransportOrder = (
        filter: ExpenseFilters,
        transport_order_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.EXPENSES.GET_EXPENSES_BY_TRANSPORT_ORDER.replace(
            ':transport_order_id',
            transport_order_id.toString()
        )}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetExpensesByTransportOrder
        });
    };

    const getExpenses = (filter: ExpenseFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.EXPENSES.GET_EXPENSES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetExpenses
        });
    };

    const getExcelAllExpenses = (
        filter: ExpenseFilters,
        events: ServiceEventsDocuments = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.EXPENSES.GET_EXCEL_EXPENSES}?${queryString}`;

        doGetDocument({
            ...events,
            url: url,
            successData: {
                nameDocument: 'gastos.xlsx',
                message: 'Descarga exitosa.'
            },
            setFetching: setFetchingGetExcelExpenses
        });
    };

    const createExpense = (transportOrderId: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSES.CREATE_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ),
            setFetching: setFetchingCreateExpense
        });
    };

    const createCalendarExpense = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSES.CREATE_CALENDAR_EXPENSE,
            setFetching: setFetchingCreateCalendarExpense
        });
    };

    const storeExpense = (
        transportOrderId: number,
        ExpenseDataForm: ExpenseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.EXPENSES.STORE_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ),
            body: ExpenseDataForm,
            setFetching: setFetchingStoreExpense
        });
    };

    const storeExpenseByEvent = (
        eventId: number,
        ExpenseDataForm: ExpenseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.EXPENSES.STORE_EXPENSE_BY_EVENT.replace(':event_id', eventId.toString()),
            body: ExpenseDataForm,
            setFetching: setFetchingStoreExpenseByEvent
        });
    };

    const editExpense = (transportOrderId: number, id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSES.EDIT_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingEditExpense
        });
    };

    const updateExpense = (
        transportOrderId: number,
        id: number,
        ExpenseDataForm: ExpenseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.EXPENSES.UPDATE_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            body: ExpenseDataForm,
            setFetching: setFetchingUpdateExpense
        });
    };

    const editCalendarExpense = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSES.EDIT_CALENDAR_EXPENSE.replace(':id', id.toString()),
            setFetching: setFetchingEditCalendarExpense
        });
    };

    const updateCalendarExpense = (
        id: number,
        ExpenseDataForm: ExpenseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.EXPENSES.UPDATE_CALENDAR_EXPENSE.replace(':id', id.toString()),
            body: ExpenseDataForm,
            setFetching: setFetchingUpdateCalendarExpense
        });
    };

    const getExpensesByCarrier = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSES.GET_EXPENSE_ON_CHECKING_ACCOUNT_CREATE.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingGetExpensesByCarrier
        });
    };

    const getExpensesEditByCarrier = (
        id: number,
        checking_account_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSES.GET_EXPENSE_ON_CHECKING_ACCOUNT_EDIT.replace(
                ':id',
                id.toString()
            ).replace(':checking_account_id', checking_account_id.toString()),
            setFetching: setFetchingGetExpensesEditByCarrier
        });
    };

    const getExpensesByDates = (date: string, view: string, events: ServiceEvents = {}) => {
        const url = `${EndPoints.EXPENSES.GET_EXPENSES_BY_DATES}?date=${date}&view=${view}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetExpensesByDates
        });
    };

    const getExpensesByEventId = (eventId: number, events: ServiceEvents = {}) => {
        const url = `${EndPoints.EXPENSES.GET_EXPENSES_BY_EVENT_ID}`.replace(
            ':event_id',
            eventId.toString()
        );
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetExpensesByEventId
        });
    };

    const getAllExpensesByEventId = (
        filter: ExpenseFilters,
        eventId: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.EXPENSES.GET_EXPENSES_BY_EVENT_ID.replace(
            ':event_id',
            eventId.toString()
        )}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetExpensesByEventId
        });
    };

    const getUrlDocumentExpenseOnTransportOrder = (
        transportOrderId: number,
        id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSES.DOCUMENT_EXPENSE_ON_TRANSPORT_ORDER.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingGetUrlDocumentOnTransportOrder
        });
    };

    const getUrlDocumentCalendarExpense = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSES.DOCUMENT_EXPENSE_ON_CALENDAR.replace(':id', id.toString()),
            setFetching: setFetchingGetUrlDocumentOnTransportOrder
        });
    };

    const getUrlDocumentExpense = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSES.DOCUMENT_EXPENSE.replace(':id', id.toString()),
            setFetching: setFetchingGetUrlDocument
        });
    };

    const downloadDocument = (url_file: string) => {
        const link = document.createElement('a');
        link.href = url_file;
        link.download = 'documento gasto';
        link.target = '_blank';
        document.body.appendChild(link);

        // Simular un clic en el enlace para iniciar la descarga
        link.click();
        if (changeAnimation)
            changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
    };

    // const showExpense = (id: number, events: ServiceEvents = {}) => {
    //     doGet({
    //         ...events,
    //         url: EndPointsPegasus.TRANSPORT_ORDERS.SHOW_TRANSPORT_ORDER.replace(
    //             ':id',
    //             id.toString()
    //         ),
    //         setFetching: setFetchingShowExpense
    //     });
    // };

    const deleteExpense = (transportOrderId: number, id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.EXPENSES.DELETE_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingDeleteExpense
        });
    };

    const deleteCalendarExpense = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.EXPENSES.DELETE_CALENDAR_EXPENSE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteCalendarExpense
        });
    };

    const approvedCalendarExpense = (id: number, value: boolean, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.EXPENSES.APPROVED_CALENDAR_EXPENSE.replace(':id', id.toString()),
            body: { is_approved: value },
            setFetching: setFetchingApprovedCalendarExpense
        });
    };

    const approvedExpense = (
        transportOrderId: number,
        id: number,
        formData: ExpenseHistoryDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.EXPENSES.APPROVED_EXPENSE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            body: formData,
            setFetching: setFetchingApprovedExpense
        });
    };

    const cancelIsApprovedExpense = (
        id: number,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.EXPENSES.CANCEL_APPROVED_EXPENSE.replace(':id', id.toString()),
            setFetching: setFetchingCancelIsApprovedExpense
        });
    };

    const changeApproveRowsFromTransportOrder = (
        massiveApproveForm: MassiveApproveForm,
        events: ServiceEvents = {}
    ) => {
        const fetchDefaultHeaders = getFetchDefaultHeaders();
        return doPut({
            ...events,
            url: EndPoints.EXPENSES.CHANGE_APPROVE_ROWS_FROM_EXPENSE,
            body: massiveApproveForm,
            requestType: 'json',
            headers: {
                'Content-Type': 'application/json',
                ...fetchDefaultHeaders
            },
            setFetching: setFetchingChangeApproveRowsFromTransportOrder
        });
    };

    return {
        getAllExpensesByTransportOrder,
        getAllExpensesByEventId,
        getExpenses,
        createExpense,
        createCalendarExpense,
        storeExpense,
        editExpense,
        updateExpense,
        editCalendarExpense,
        updateCalendarExpense,
        getExpensesByEventId,
        // showExpense,
        deleteExpense,
        deleteCalendarExpense,
        getExpensesByCarrier,
        getExpensesEditByCarrier,
        getExpensesByDates,
        getUrlDocumentExpense,
        getUrlDocumentExpenseOnTransportOrder,
        getUrlDocumentCalendarExpense,
        downloadDocument,
        approvedExpense,
        approvedCalendarExpense,
        storeExpenseByEvent,
        changeApproveRowsFromTransportOrder,
        cancelIsApprovedExpense,
        getExcelAllExpenses,
        fetchingGetExpensesByTransportOrder,
        fetchingGetExpenses,
        fetchingStoreExpense,
        fetchingStoreExpenseByEvent,
        fetchingEditExpense,
        fetchingEditCalendarExpense,
        fetchingGetExpensesByEventId,
        fetchingUpdateExpense,
        fetchingUpdateCalendarExpense,
        fetchingShowExpense,
        fetchingDeleteExpense,
        fetchingDeleteCalendarExpense,
        fetchingCreateExpense,
        fetchingCreateCalendarExpense,
        fetchingGetUrlDocument,
        fetchingGetUrlDocumentOnTransportOrder,
        fetchingGetExpensesByCarrier,
        fetchingGetExpensesEditByCarrier,
        fetchingGetExpensesByDates,
        fetchingApprovedExpense,
        fetchingApprovedCalendarExpense,
        fetchingChangeApproveRowsFromTransportOrder,
        fetchingCancelIsApprovedExpense,
        fetchingGetExcelExpenses
    };
};

export default useExpenseService;
