import React from 'react';
import { QuestionDataForm, LinkInspectionOption } from '../../../../../../app/models/Question';

type Props = {
    input: QuestionDataForm;
    handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean
};

const QuestionRadioField = ({ input, handleRadioChange, disabled=false }: Props) => {
    return (
        <div className="mb-5">
            <div>
                {input &&
                    input.options &&
                    input.options.map((option: LinkInspectionOption, index: number) => (
                        <div key={index} className="form-check">
                            <input
                                type="radio"
                                className="form-check-input"
                                id={`${option.id}-${index}`}
                                name={`${option.id}`} 
                                checked={
                                    input.options && input.options.find((o: LinkInspectionOption) => o.id === option.id)!.value === true
                                } 
                                onChange={handleRadioChange}
                                disabled={disabled}
                            />
                            <label className="form-check-label" htmlFor={`${option.id}-${index}`}>
                                {option.label}
                            </label>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default QuestionRadioField;
