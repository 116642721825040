import React from 'react';
import { IconProps } from '../types';

export const Orders: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M0 6H5C5.55228 6 6 5.55228 6 5V0H16C17.1046 0 18 0.89543 18 2V22C18 23.1046 17.1046 24 16 24H2C0.89543 24 0 23.1046 0 22V6zM0.34141 4C0.94398 2.29517 2.29517 0.943981 4 0.341411V4H0.34141zM5 11H13C13.5523 11 14 10.5523 14 10C14 9.44771 13.5523 9 13 9H5C4.44772 9 4 9.44771 4 10C4 10.5523 4.44772 11 5 11zM5 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H5C4.44772 13 4 13.4477 4 14C4 14.5523 4.44772 15 5 15zM5 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 13.5523 17 13 17H5C4.44772 17 4 17.4477 4 18C4 18.5523 4.44772 19 5 19z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Orders;
