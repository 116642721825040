import React, { useEffect } from 'react'
import { Bank } from '../../../../app/models/Bank';
import { BankAccountReason, BankAccountType, BankAccountDataForm } from '../../../../app/models/BankAccountType';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import LazyLoading from '../../../../components/LazyLoading';

interface BankAccountFromtProps {
    workerBankAccountForm: BankAccountDataForm;
    setWorkerBankAccountForm: (worker: BankAccountDataForm) => void;
    banks: Bank[];
    bankAccountTypes: BankAccountType[];
    accountReasons: BankAccountReason[];
    errorFields?: any;
    fetchingBankAccounts?: boolean
}

const BankAccountForm = ({
    workerBankAccountForm,
    setWorkerBankAccountForm,
    banks,
    bankAccountTypes,
    accountReasons,
    errorFields,
    fetchingBankAccounts = false
}: BankAccountFromtProps) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        console.log(workerBankAccountForm);
    }, [workerBankAccountForm]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setWorkerBankAccountForm({ ...workerBankAccountForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setWorkerBankAccountForm({ ...workerBankAccountForm, [name]: value });
    };

    return (
        <>
        {fetchingBankAccounts ? (
            <LazyLoading height={300} />
        ) : (
        <>
            <div className="mb-4 mt-2 row needs-validation">
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Banco</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="bank_id"
                            id="bank_id"
                            disabled={false}
                            inputValue={workerBankAccountForm.bank_id}
                            options={SelectTwoMapperOptions(banks)}
                            hasError={fieldHasError('bank_id') !== ''}
                            onChange={(value: any) => handleChangeSelectTwo('bank_id', value.value)}
                            placeholder={'Seleccione banco'}
                            onFocus={() => onFocusRemove('bank_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('bank_id')}
                        </div>
                    </div>
                </div>
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Motivo de Cuenta</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="bank_account_reason_id"
                            id="bank_account_reason_id"
                            inputValue={workerBankAccountForm.bank_account_reason_id}
                            options={SelectTwoMapperOptions(accountReasons)}
                            hasError={fieldHasError('banck_account_reason_id') !== ''}
                            onChange={(value: any) => handleChangeSelectTwo('bank_account_reason_id', value.value)}
                            placeholder={'Seleccione motivo'}
                            onFocus={() => onFocusRemove('bank_account_reason_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('bank_account_reason_id')}
                        </div>
                    </div>
                </div>
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Número de Cuenta</label>
                    <div className="col-md-9">
                        <input
                            name="account_number"
                            id="account_number"
                            type="number"
                            className={`form-control ${fieldHasError('account_number')}`}
                            value={workerBankAccountForm.account_number}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('account_number')}
                            placeholder="Ingrese el número de cuenta"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('account_number')}
                        </div>
                    </div>
                </div>
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Tipo de Cuenta</label>
                    <div className="col-md-9">
                        <SelectTwo
                            name="account_type_id"
                            id="account_type_id"
                            disabled={false}
                            inputValue={workerBankAccountForm.account_type_id}
                            options={SelectTwoMapperOptions(bankAccountTypes)}
                            hasError={fieldHasError('account_type_id') !== ''}
                            onChange={(value: any) => handleChangeSelectTwo('account_type_id', value.value)}
                            placeholder={'Seleccione tipo de cuenta'}
                            onFocus={() => onFocusRemove('account_type_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('account_type_id')}
                        </div>
                    </div>
                </div>
            </div>
        </>)}
        </>
    )
}

export default BankAccountForm
