import React, { useContext } from 'react';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { AuthContext } from '../../../../contexts/AuthContext';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';
import { QuestionFilters } from '../../../../app/models/Question';

type Props = {
    filter: QuestionFilters;
    setFilter: (filter: QuestionFilters) => void;
    onSearch: () => void;
};

const QuestionFilter = ({ filter, setFilter, onSearch }: Props) => {

    const { auth } = useContext(AuthContext);

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="response_type">
                            Tipo de respuesta
                        </label>
                        <select
                            id="response_type"
                            name="expense_document_type"
                            className="form-control form-select-sm"
                            value={filter.response_type ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'TEXT'} value="TEXT">
                                Texto
                            </option>
                            <option key={'NUMBER'} value="NUMBER">
                                Número
                            </option>
                            <option key={'SELECT'} value="SELECT">
                                Selección
                            </option>
                            <option key={'CHECKBOX'} value="CHECKBOX">
                                Check
                            </option>
                            <option key={'MULTIPLESELECT'} value="MULTIPLESELECT">
                                Selección multiple
                            </option>
                            <option key={'RADIOBUTTON'} value="RADIOBUTTON">
                                botón radio
                            </option>
                            <option key={'TEXTAREA'} value="TEXTAREA">
                                Area de texto
                            </option>
                            <option key={'FILE'} value="FILE">
                                Archivo
                            </option>
                            <option key={'Date'} value="Date">
                                Fecha
                            </option>
                            <option key={'TIME'} value="TIME">
                                Hora
                            </option>
                            <option key={'DATETIME'} value="DATETIME">
                                Fecha y hora
                            </option>
                        </select>
                    </div>
                </div>
             
                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionFilter;
