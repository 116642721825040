import React, { useEffect, useState } from 'react';
import NotificationCard from './components/NotificationCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import useNotificationService from '../../app/services/hooks/useNotificationService';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
// import LazyLoading from "../../components/LazyLoading";

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Notificaciones',
        url: '/notifications',
        isActive: true
    }
];

const Notifications = () => {
    const { getAllNotificationsByUserRange } = useNotificationService();
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [idNotification, seIdNotification] = useState<number>(-1);
    const [quantity, setQuantity] = useState<number>(5);
    const [hasMore, setHasMore] = useState<boolean>(true);

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = () =>
        getAllNotificationsByUserRange(idNotification, quantity, {
            onSuccess: (response: ServiceResponse) => {
                const _notifications = response.data.notifications;
                if (_notifications.length == 0) {
                    setHasMore(false);
                } else {
                    setNotifications([...notifications, ..._notifications]);
                    seIdNotification(_notifications[_notifications.length - 1].id);
                }
            }
        });

    return (
        <>
            <Breadcrumbs pageSection="Notificaciones" breadcrumbs={breadcrumbs} />
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div id="scrollableDiv" className="scroll-notifications-box pe-2">
                        <InfiniteScroll
                            dataLength={notifications.length}
                            next={getNotifications}
                            hasMore={hasMore}
                            loader={
                                <p style={{ textAlign: 'center' }}>
                                    <b>Cargando...</b>
                                </p>
                            }
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>No hay más notificaciones</b>
                                </p>
                            }
                            scrollableTarget="scrollableDiv"
                        >
                            {notifications && notifications.length > 0 ? (
                                notifications.map((notification, index) => (
                                    <NotificationCard
                                        key={`notifications-${index}`}
                                        notification={notification}
                                    />
                                ))
                            ) : (
                                <DefaultCard>
                                    <div className="text-center">
                                        <h4 className="text-muted">No hay notificaciones</h4>
                                    </div>
                                </DefaultCard>
                            )}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Notifications;
