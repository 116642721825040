import React, { useEffect, useRef } from 'react';
import { Carrier, CarrierDocument } from '../app/models/Carrier';
import ButtonTableAction from './buttons/ButtonTableAction';
import { Truck, TruckDocument } from '../app/models/Truck';
import { Hopper, HopperDocument } from '../app/models/Hopper';

type Props = {
    data: any;

    create?: (type: string) => void;

    showHistory?: (type: string) => void;
    documentTypes: any[];
};

const GeneralDocumentsTransporterTable = ({ data, create, documentTypes, showHistory }: Props) => {
    return (
        <table className="table table-sm table-bordered">
            <thead>
                <tr>
                    <th>Documento</th>
                    <th>Nombre del archivo</th>
                    <th>Fecha de emisión </th>
                    <th>Fecha de vencimiento</th>
                    <th>Indefinido? </th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {documentTypes.map((doc) => (
                    <tr key={doc.value}>
                        <td>{doc.label}</td>
                        <td>
                            <a
                                target="_blank"
                                href={String(
                                    data.documents?.find(
                                        (item: CarrierDocument | HopperDocument | TruckDocument) =>
                                            item.type === doc.value
                                    )?.file
                                )}
                                rel="noreferrer"
                            >
                                {
                                    data.documents?.find(
                                        (item: CarrierDocument | HopperDocument | TruckDocument) =>
                                            item.type === doc.value
                                    )?.original_name
                                }
                            </a>
                        </td>
                        <td>
                            <strong>
                                {
                                    data.documents?.find(
                                        (item: CarrierDocument | HopperDocument | TruckDocument) =>
                                            item.type === doc.value
                                    )?.date_of_issue
                                }
                            </strong>
                        </td>
                        <td>
                            <strong>
                                {
                                    data.documents?.find(
                                        (item: CarrierDocument | HopperDocument | TruckDocument) =>
                                            item.type === doc.value
                                    )?.expire_date
                                }
                            </strong>
                        </td>
                        <td>
                            <strong>
                                {data.documents?.find(
                                    (item: CarrierDocument | HopperDocument | TruckDocument) =>
                                        item.type === doc.value
                                )?.is_expire_date_indefinite
                                    ? ' Si'
                                    : ' No'}
                            </strong>
                        </td>
                        <td>
                            <ButtonTableAction
                                callbackFunction={() => (create ? create(doc.value) : null)}
                                classIcon={'mdi mdi-file-plus'}
                                colorIcon={'text-success'}
                                errorMessage={'No se puede mostrar este registro.'}
                                title={'Ver Registros anteriores'}
                            />
                            <ButtonTableAction
                                callbackFunction={() =>
                                    showHistory ? showHistory(doc.value) : null
                                }
                                classIcon={'mdi mdi-eye'}
                                colorIcon={'text-info'}
                                errorMessage={'No se puede mostrar este registro.'}
                                title={'Ver Registros anteriores'}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default GeneralDocumentsTransporterTable;
