import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { AuthContext } from '../../../contexts/AuthContext';
import useQuestionService from '../../../app/services/hooks/useQuestionService';
import { QuestionDataForm, QuestionFilters } from '../../../app/models/Question';
import QuestionDataTable from './tables/QuestionDataTable';
import ButtonDeleteForm from '../../../components/buttons/ButtonDeleteForm';
import ButtonTableAction from '../../../components/buttons/ButtonTableAction';
import { SectionDataForm } from '../../../app/models/Section';

interface Props {
    section: SectionDataForm;
    survey_id: number;
    isShow?: boolean;
    deleteSection?: (sectionId: number, position: number) => void;
}

const QuestionContainer = ({ section, survey_id, deleteSection, isShow = false }: Props) => {
    const { auth } = useContext(AuthContext);
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllQuestionsBySection,
        fetchingGetQuestionsBySection,
        deleteQuestion,
        fetchingDeleteQuestion,
        activeQuestion, 
        fetchingActiveQuestion,
        requiredQuestion,
        fetchingRequiredQuestion
    } = useQuestionService();

    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);
 
    const [questions, setQuestions] = useState<QuestionDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
  
    const [filter, setFilter] = useState<QuestionFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
    });

    const showCreate = () => {
        navigationPage('/surveys/'+ survey_id + '/sections/'+ section.id + '/questions/create');
    };

    const showEdit = (questionId: number) => {
        navigationPage('/surveys/'+ survey_id + '/sections/'+ section.id  + '/questions/' + questionId + '/edit');
    };

    const showShow = (questionId: number) => {
        navigationPage('/surveys/'+ survey_id + '/sections/'+ section.id  + '/questions/' + questionId + '/show');
    };

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);
    

    const destroy = (questionId: number) => {
        const _text = 'Está a punto de eliminar la pregunta #' + questionId + 'de la sección #'+section.id;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading)
                            showLoading('loading', 'Eliminando orden de transporte...');
                        deleteQuestion(questionId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La pregunta no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const active = (id: number, value: boolean, setChecked: (value:boolean) => void) => {
        setChecked(value);

        activeQuestion(id, value, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setChecked(!value);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const required = (id: number, value: boolean, setChecked: (value:boolean) => void) => {
        setChecked(value);

        requiredQuestion(id, value, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setChecked(!value);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const reloadTable = () => {
        getAllQuestionsBySection(section.id!, filter, {
            onSuccess: (response: ServiceResponse) => {

                setQuestions(response.data.questions);
                setTotalRows(response.data.total_rows);
 
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12 mb-2">
                        {!isShow && (
                            <div className="d-flex justify-content-between">
                            <ButtonCreate
                                title="Nueva pregunta"
                                callbackCreate={showCreate}
                            />
                            {
                                deleteSection && 

                                <ButtonTableAction
                                    callbackFunction={() => deleteSection(section.id!, section.position!)}
                                    classIcon={'mdi mdi-delete'}
                                    colorIcon={'text-danger'}
                                    errorMessage={'No se puede eliminar este registro.'}
                                    title={'Eliminar sección'}
                                />
                            }
                            
                            </div>
                            
                        )}
                       
                    </div>
                    <div className="col-12">
                        {isShow ? (
                            <QuestionDataTable
                                section={section}
                                questions={questions}
                                loading={fetchingGetQuestionsBySection}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                                callbackSuccess={reloadTable}
                                onSearch={reloadTable}
                            />
                        ) : (
                            <QuestionDataTable
                                section={section}
                                questions={questions}
                                edit={showEdit}
                                destroy={destroy}
                                loading={fetchingGetQuestionsBySection}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                                callbackSuccess={reloadTable}
                                onSearch={reloadTable}
                                active={active}
                                required={required}
                            />
                        )}
                    </div>
                </div>
            </DefaultCard>
            {/* {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear pregunta"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuestionCreate
                        sectionId={sectionId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null} */}
        </>
    );
};

export default QuestionContainer;
