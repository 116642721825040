import React from 'react';
import { QuestionDataForm } from '../../../../../../app/models/Question';

type Props = {
    input: QuestionDataForm;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean
};

const QuestionDateTimeField = ({ input, handleChange, disabled=false }: Props) => {
    return (
        <>
            <div className="mb-5">
                <input
                    name={`${input.id}`}
                    type="datetime-local"
                    className="form-control"
                    value={ typeof input.text === 'string'
                        ?
                        input.text &&
                        input.text.length > 20
                            ? input.text.substring(
                                  0,
                                  input.text.length - 6
                              )
                            : input.text
                        : ''
                    }
                    onChange={(e) => handleChange(e)}
                    disabled={disabled}
                />
            </div>
        </>
    );
};

export default QuestionDateTimeField;
