import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import FormText from './forms/Inputs/FormText';
import FormSwitch from './forms/Inputs/FormSwitch';

import FormCheckBox from './forms/Inputs/FormCheckBox';
import { ResponseType } from '../../../app/models/ResponseType';
import { InputResponseType } from '../../../app/shared/enums';
import { QuestionDataForm } from '../../../app/models/Question';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import FormNumber from './forms/Inputs/FormNumber';
import FormSelect from './forms/Inputs/FormSelect';
import FormTime from './forms/Inputs/FormTime';


type Props = {
    questionForm: QuestionDataForm;
    setQuestionForm: (question: QuestionDataForm) => void;
    nextQuestionOptions?: QuestionDataForm[];
    errorFields?: any;
};

const InputCard = ({ questionForm, setQuestionForm, nextQuestionOptions, errorFields }: Props) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);
  
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setQuestionForm({ ...questionForm, [name]: value });
    };

    return (
       
            
                <div className="row">
                 
                    <div className="col">
                        {
                            (
                                questionForm?.response_type === InputResponseType.TEXT || 
                                questionForm?.response_type === InputResponseType.TEXTAREA
                            ) && (
                                <FormText questionForm={questionForm} setQuestionForm={setQuestionForm} errorFields={errorFields}/>
                        )}
                        {
                            (
                                questionForm?.response_type === InputResponseType.NUMBER
                            ) && (
                                <FormNumber questionForm={questionForm} setQuestionForm={setQuestionForm} errorFields={errorFields}/>
                        )}
                        {
                            (
                                questionForm?.response_type === InputResponseType.SELECT || 
                                questionForm?.response_type === InputResponseType.RADIOBUTTON ||
                                questionForm?.response_type === InputResponseType.MULTIPLESELECT
                            ) && (
                                <FormSelect 
                                    questionForm={questionForm} 
                                    setQuestionForm={setQuestionForm} 
                                    errorFields={errorFields}
                                    nextQuestionOptions={nextQuestionOptions}
                                />
                        )}
                        
                        {
                            (questionForm?.response_type === InputResponseType.CHECKBOX) && (
                                <FormCheckBox
                                    questionForm={questionForm} 
                                    setQuestionForm={setQuestionForm} 
                                    errorFields={errorFields}
                                    nextQuestionOptions={nextQuestionOptions}
                                />
                        )} 
                        {
                            (questionForm?.response_type === InputResponseType.TIME) && (
                                <FormTime
                                    questionForm={questionForm} 
                                    setQuestionForm={setQuestionForm} 
                                    errorFields={errorFields}
                                />
                        )} 
                        {
                            (
                                questionForm?.response_type === InputResponseType.FILE ||
                                questionForm?.response_type === InputResponseType.DATE ||
                                questionForm?.response_type === InputResponseType.DATETIME ||
                                questionForm?.response_type === InputResponseType.SWITCH
                            ) && (

                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        No necesita datos iniciales
                                    </div>
                                </div>

                                
                        )} 
                    </div>
                   
                </div>
 
    );
};

export default InputCard;
