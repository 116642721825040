import React from 'react';
import MonthYearPicker from '../../../../components/inputs/MonthYearPicker';
import { momentParseDate } from '../../../../helpers';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';
import { MonthlyClosureDetailFilters } from '../../../../app/models/MonthlyClosureDetail';

type Props = {
    filter: MonthlyClosureDetailFilters;
    setFilter: (filter: MonthlyClosureDetailFilters) => void;
    onSearch: () => void;
};

const MonthlyClosureDetailFilter = ({ filter, setFilter, onSearch }: Props) => {
    
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-auto mb-3">
                    <label className="font-size-10 mb-1" htmlFor="society">
                        Sociedad
                    </label>
                    <input
                        title="Sociedad"
                        type="text"
                        id="society"
                        name="society"
                        className="form-control form-control-sm"
                        value={filter.society ?? ''}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="status">
                            Estado
                        </label>
                        <select
                            name="status"
                            className="form-control form-select-sm"
                            value={filter.status || ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option value="">TODOS</option>
                            <option value="ABIERTO">Abierto</option>
                            <option value="CERRADO">Cerrado</option>
                            <option value="ANULADO">Anulado</option>
                        </select>
                    </div>
                </div>
                
                
                {/* <div className="col-auto mb-3">
                    <label className="font-size-10 mb-1" htmlFor="period_from">
                        Creado(desde)
                    </label>
                    <MonthYearPicker
                        className="form-control form-control-sm input-datepicker"
                        onMonthYearChange={(date) =>
                            handlerInputFilter({
                                target: {
                                    name: 'period_from',
                                    value: String(momentParseDate(date, 'YYYY-MM-DD'))
                                }
                            })
                        }
                    />
                </div>
                
               
                <div className="col-auto mb-3">
                    <label className="font-size-10 mb-1" htmlFor="period_until">
                        Creado(hasta)
                    </label>
                    <MonthYearPicker
                        className="form-control form-control-sm input-datepicker"
                        onMonthYearChange={(date) =>
                            handlerInputFilter({
                                target: {
                                    name: 'period_until',
                                    value: String(momentParseDate(date, 'YYYY-MM-DD'))
                                }
                            })
                        }
                    />
                </div> */}

            </div>
            <div className='col-md-auto'>
                <div style={{marginTop: '22px'}}>
                    <ButtonSearchFilter callbackSearch={onSearch}/>
                </div>
            </div>
        </div>
    );
};

export default MonthlyClosureDetailFilter;
