import { useEffect } from "react";
import { Column, UserColumnSetDataForm } from "../../../app/models/Column";
import useHandleErrorFields from "../../../hooks/useHandleErrorFields";
import SelectTwo from "../../../components/SelectTwo";


interface GeneralUserColumnSetProps {
    userColumnSetForm: UserColumnSetDataForm;
    setUserColumnSetForm: (columnSet: UserColumnSetDataForm) => void;
    columns: Column[];
    errorFields?: any;
}

const ColumnSetForm = ({
    userColumnSetForm,
    setUserColumnSetForm,
    columns,
    errorFields
}: GeneralUserColumnSetProps) => {

    const { setErrors, fieldErrorMessage, fieldHasError, onFocusRemove } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const MapperOptions = (items: any[]) => {
        return items.map((item) => ({
            value: item.id,
            label: item.alias
        }));
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                <label className="col-form-label">Columnas</label>
                    <SelectTwo
                        name="columns"
                        id="columns"
                        inputValue={userColumnSetForm.columns}
                        options={MapperOptions(columns)}
                        hasError={fieldHasError('columns') !== ''}
                        onChange={(value: any) =>
                            setUserColumnSetForm({
                                ...userColumnSetForm,
                                columns: value
                            })
                        }
                        isSortable={true}
                        isMulti={true}
                        placeholder={'Seleccione las columnas'}
                        onFocus={() => onFocusRemove('columns')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('columns')}
                    </div>
                </div>
            </div>
        </>
    )

}

export default ColumnSetForm;
