import React from 'react';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { TransportationOrderItemDataForm } from '../../../../app/models/TransportationOrderItem';
import { momentParseDate } from '../../../../helpers';
import { formatNumber, formatMoney } from '../../../../utils/utils';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

interface Props {
    transportationSubOrderItems: TransportationOrderItemDataForm[];
    callbackSuccess?: () => void;
    split?: (id: number) => void;
    generatePaymentStatus?: (id: number) => void;
    isShow?: boolean;
}

const TransportationSubOrderItemsDataTable = ({
    transportationSubOrderItems,
    split,
    generatePaymentStatus,
    isShow = false
}: Props) => {
    const columns = [
        {
            name: 'Producto',
            selector: (row: any) => row?.product_name,
            sortable: true,
            sortField: 'product_name'
        },
        {
            name: 'Servicio',
            selector: (row: any) => row?.service_name,
            sortable: true,
            sortField: 'service_name'
        },
        {
            name: 'Origen',
            selector: (row: any) => row?.origin_name,
            sortable: true,
            sortField: 'origin_name'
        },
        {
            name: 'Destino',
            selector: (row: any) => row?.destiny_name,
            sortable: true,
            sortField: 'destiny_name'
        },
        {
            name: 'Cantidad',
            selector: (row: any) => row?.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: any, index: any, column: any) => {
                return formatNumber(row.quantity ?? 0) + ' ' + row.product_unit;
            }
        },
        // {
        //     name: 'Tarifa Transportista Externo',
        //     selector: (row: any) => row?.rate,
        //     sortable: true,
        //     sortField: 'rate',
        //     cell: (row: any, index: any, column: any) => {
        //         return (
        //             formatMoney(row.rate, row.currency_name) +
        //             ' ' +
        //             row.currency_name +
        //             '/' +
        //             row.product_unit
        //         );
        //     }
        // },
        {
            name: 'Cantidad por programar',
            selector: (row: any) => row?.missing_dispatch_quantity,
            sortable: true,
            sortField: 'missing_dispatch_quantity',
            cell: (row: any, index: any, column: any) => {
                return formatNumber(row.missing_dispatch_quantity) + ' ' + row.product_unit;
            }
        },
        {
            name: 'F. Despacho',
            sortField: 'dispatch_date',
            sortable: true,
            selector: (row: any) => row?.dispatch_date,
            cell: (row: any, index: any, column: any) => {
                return momentParseDate(row.dispatch_date);
            }
        },
        {
            name: 'Cantidad guia',
            selector: (row: any) => row?.guide,
            sortField: 'guide',
            sortable: true,
            cell: (row: any, index: any, column: any) => {
                return row?.guide ? formatNumber(row?.guide) + ' ' + row.product_unit : null;
            }
        },
        {
            name: 'Acciones',
            dataField: 'edit',
            sortable: false,
            cell: (row: any, index: any, column: any) => {
                if (isShow) {
                    return '';
                }
                return (
                    <div>
                        <ButtonTableAction
                            callbackFunction={() => {
                                if (generatePaymentStatus) {
                                    generatePaymentStatus(row.id!);
                                }
                            }}
                            classIcon={'mdi mdi-file'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede crear despacho.'}
                            title={'Generar Estados de pago'}
                        />
                        <ButtonTableAction
                            callbackFunction={() => {
                                if (split) {
                                    split(row.id!);
                                }
                            }}
                            classIcon={'mdi mdi-plus'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede crear despacho.'}
                            title={'Crear Despacho'}
                        />
                    </div>
                );
            }
        }
    ];

    return (
        <DefaultDataTable
            data={transportationSubOrderItems}
            columns={columns}
            paginationTotalRows={10}
            filter={undefined}
            setFilter={function (filter: any): void {
                throw new Error('Function not implemented.');
            }}
            pagination={false}
        />
    );
};

export default TransportationSubOrderItemsDataTable;
