import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { JobTitle, JobTitleDataForm, JobTitleFilters } from '../../models/JobTitle';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useJobTitleService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetJobTitles, setFetchingGetJobTitle] = useState(false);
    const [fetchingDeleteJobTitle, setFetchingDeleteJobTitle] = useState(false);
    const [fetchingActiveJobTitle, setFetchingActiveJobTitle] = useState(false);
    const [fetchingStoreJobTitle, setFetchingStoreJobTitle] = useState(false);
    const [fetchingCreateJobTitle, setFetchingCreateJobTitle] = useState(false);
    const [fetchingEditJobTitle, setFetchingEditJobTitle] = useState(false);
    const [fetchingUpdateJobTitle, setFetchingUpdateJobTitle] = useState(false);

    const getJobTitles = (JobTitleFilters: JobTitleFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(JobTitleFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.JOB_TITLES.GET_JOB_TITLES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetJobTitle
        });
    };

    const storeJobTitle = (JobTitle: JobTitleDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.JOB_TITLES.STORE_JOB_TITLES,
            body: JobTitle,
            setFetching: setFetchingStoreJobTitle
        });
    };

    const createJobTitle = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.JOB_TITLES.CREATE_JOB_TITLES,
            setFetching: setFetchingCreateJobTitle
        });
    };

    const deleteJobTitle = (jobTitleId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.JOB_TITLES.DELETE_JOB_TITLES.replace(':id', jobTitleId.toString()),
            setFetching: setFetchingDeleteJobTitle
        });
    };

    const activeJobTitle = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.JOB_TITLES.ACTIVE_JOB_TITLES.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveJobTitle
        });
    };

    const editJobTitle = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.JOB_TITLES.EDIT_JOB_TITLES.replace(':id', id.toString()),
            setFetching: setFetchingEditJobTitle
        });
    };

    const updateJobTitle = (
        id: number,
        jobTitles: JobTitleDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.JOB_TITLES.UPDATE_JOB_TITLES.replace(':id', id.toString()),
            body: jobTitles,
            setFetching: setFetchingUpdateJobTitle
        });
    };

    return {
        fetchingGetJobTitles,
        fetchingActiveJobTitle,
        fetchingDeleteJobTitle,
        fetchingCreateJobTitle,
        fetchingStoreJobTitle,
        fetchingEditJobTitle,
        fetchingUpdateJobTitle,
        storeJobTitle,
        createJobTitle,
        activeJobTitle,
        getJobTitles,
        deleteJobTitle,
        editJobTitle,
        updateJobTitle
    };
};

export default useJobTitleService;
