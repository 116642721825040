

export interface IncidentReasonDataForm {
    id?: number;
    name?: string; 
    description?: string;
    is_active?: boolean; 
    created_at?: string;
}

export const defaultIncidentReasonDataForm: IncidentReasonDataForm = {
    id: 0,
    name: '',
};

export interface IncidentReasonFilters {
    name?: string; 
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
