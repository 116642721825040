import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';

import { VehicleType } from '../../../app/models/VehicleType';
import { VehicleModelDataForm } from '../../../app/models/VehicleModel';
import VehicleModelForm from './forms/VehicleModelForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    vehicleModel: VehicleModelDataForm;
    setVehicleModel: (vehicleModel: VehicleModelDataForm) => void;
    vehicleTypes: VehicleType[];
    errorFields?: any;
}

const VehicleModelFormContainer = ({
    fetching,
    action,
    cancel,
    vehicleModel,
    setVehicleModel,
    vehicleTypes,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <VehicleModelForm
                        vehicleModelDataForm={vehicleModel}
                        setVehicleModelDataForm={setVehicleModel}
                        vehicleTypes={vehicleTypes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default VehicleModelFormContainer;
