import React, { useContext, useEffect, useState } from 'react';
import useWorkerService from '../../../../app/services/hooks/useWorkerService';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../../components/LazyLoading';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import WorkersForm from './WorkersForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../../contexts/AppContext';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { User } from '../../../../app/models/User';
import { Afp } from '../../../../app/models/Afp';
import { Provision } from '../../../../app/models/Provision';
import { WorkerDataForm, defaultWorkeDataForm, Worker } from '../../../../app/models/Workers';
import { Society } from '../../../../app/models/Society';
import { Provider } from '../../../../app/models/Provider';
import { JobTitle } from '../../../../app/models/JobTitle';
import { DocumentSet } from '../../../../app/models/DocumentSet';

interface Props {
    isCarrier?: boolean;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const WorkersCreate = ({ onSaved, onCancel, onError, isCarrier }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);

    const [users, setUsers] = useState<User[]>([]);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [afps, setAfps] = useState<Afp[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [provisions, setProvisions] = useState<Provision[]>([]);
    const [documentSets, setDocumentSets] = useState<DocumentSet[]>([]);
    const [worker, setWorker] = useState<WorkerDataForm>(defaultWorkeDataForm);

    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingStoreWorker, storeWorker, fetchingCreateWorker, createWorker } =
        useWorkerService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createWorker({
            onSuccess: (response: ServiceResponse) => {
                setUsers(response.data.users);
                setWorkers(response.data.workers);
                setSocieties(response.data.societies);
                setProviders(response.data.providers);
                setAfps(response.data.afps);
                setProvisions(response.data.provisions);
                setDocumentSets(response.data.document_sets);

                if (isCarrier) {
                    const titles: JobTitle[] = response.data.job_titles;
                    const CTitle = titles.find((title) => title.name.toLowerCase() === 'conductor');
                    setWorker({
                        ...worker,
                        job_title_id: CTitle?.id
                    });
                }
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando conductor...');
        storeWorker(worker, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateWorker ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <WorkersForm
                        workerForm={worker}
                        setWorkerForm={setWorker}
                        users={users}
                        afps={afps}
                        provisions={provisions}
                        societies={societies}
                        providers={providers}
                        documentSets={documentSets}
                        errorFields={errorFields}
                        isCreate={true}
                        workers={workers}
                        isCarrier={isCarrier}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreWorker} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreWorker} />
                </div>
            </div>
        </>
    );
};

export default WorkersCreate;
