import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

import { getFetchDefaultHeaders } from '../shared/vars';
import { SurveyDataForm, SurveyFilters } from '../../models/Survey';

const useSurveyService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetSurveys, setFetchingGetSurveys] = useState(false);
    const [fetchingGetSurvey, setFetchingGetSurvey] = useState(false);
    const [fetchingGetSurveyByEventType, setFetchingGetSurveyByEventType] = useState(false);
    const [fetchingShowSurvey, setFetchingShowSurvey] = useState(false);
    const [fetchingCreateSurvey, setFetchingCreateSurvey] = useState(false);
    const [fetchingStoreSurvey, setFetchingStoreSurvey] = useState(false);
    const [fetchingEditSurvey, setFetchingEditSurvey] = useState(false);
    const [fetchingUpdateSurvey, setFetchingUpdateSurvey] = useState(false);
    const [fetchingDeleteSurvey, setFetchingDeleteSurvey] = useState(false);
    const [fetchingActiveSurvey, setFetchingActiveSurvey] = useState(false);

    const getSurveys = (filter: SurveyFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SURVEYS.GET_SURVEYS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetSurveys
        });
    };

    const getSurveyById = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SURVEYS.GET_SURVEY.replace(':id', id.toString()),
            setFetching: setFetchingGetSurvey
        });
    };

    const getSurveyByEventTypeId = (eventTypeId: number,filter: SurveyFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SURVEYS.GET_SURVEY_BY_EVENT_TYPE.replace(':event_type_id', eventTypeId.toString())}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetSurveyByEventType
        });
    };

    const showSurvey = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SURVEYS.SHOW_SURVEY.replace(':id', id.toString()),
            setFetching: setFetchingShowSurvey
        });
    };

    const createSurvey = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SURVEYS.CREATE_SURVEY,
            setFetching: setFetchingCreateSurvey
        });
    };

    const storeSurvey = (survey: SurveyDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SURVEYS.STORE_SURVEY,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            requestType: 'json',
            body: survey,
            setFetching: setFetchingStoreSurvey
        });
    };

    const editSurvey = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SURVEYS.EDIT_SURVEY.replace(':id', id.toString()),
            setFetching: setFetchingEditSurvey
        });
    };

    const updateSurvey = (id: number, survey: SurveyDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.SURVEYS.UPDATE_SURVEY.replace(':id', id.toString()),
            body: survey,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            requestType: 'json',
            setFetching: setFetchingUpdateSurvey
        });
    };

    const deleteSurvey = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SURVEYS.DELETE_SURVEY.replace(':id', id.toString()),
            setFetching: setFetchingDeleteSurvey
        });
    };


    const activeSurvey = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SURVEYS.ACTIVE_SURVEY.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveSurvey
        });
    };

    return {
        fetchingGetSurveys,
        fetchingShowSurvey,
        fetchingCreateSurvey,
        fetchingStoreSurvey,
        fetchingEditSurvey,
        fetchingUpdateSurvey,
        fetchingDeleteSurvey,
        fetchingActiveSurvey,
        fetchingGetSurvey,
        fetchingGetSurveyByEventType,
        getSurveys,
        getSurveyById,
        getSurveyByEventTypeId,
        showSurvey,
        createSurvey,
        storeSurvey,
        editSurvey,
        updateSurvey,
        deleteSurvey,
        activeSurvey
    };
};

export default useSurveyService;
