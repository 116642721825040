import React, { useContext, useEffect, useRef, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useSectionService from '../../../app/services/hooks/useSectionService';
import { defaultSectionDataForm, SectionDataForm } from '../../../app/models/Section';
import SectionForm from './forms/SectionForm';

interface Props {
    surveyId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SectionCreate = ({ onSaved, onCancel, onError, surveyId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const {
        createSection,
        storeSection,
        fetchingCreateSection,
        fetchingStoreSection
    } = useSectionService();


    const [section, setSection] = useState<SectionDataForm>(
        {
            survey_id:surveyId,
            ...defaultSectionDataForm
        }
    );

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createSection({
            onSuccess: (response: ServiceResponse) => {
               
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando sección...');
        storeSection(section, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                
                    if (onSaved) onSaved();
    
                },
                onError: (response: ServiceResponse) => {
                
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                  
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            }
        );
    };


    const cancel = () => {
        if (onCancel) onCancel();
    };


    return fetchingCreateSection ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <SectionForm
                        sectionForm={section}
                        setSectionForm={setSection}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreSection}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreSection} />
                </div>
            </div>
        </>
    );
};

export default SectionCreate;
