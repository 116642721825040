import React from 'react';
import { IconProps } from '../../types';

export const RouteSegment: React.FC<IconProps> = ({ size = 24, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            width={size}
            height={size}
            viewBox="0 0 467.246 467.246"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <path d="M407.246,201.174c4.796-1.239,9.822-1.899,15-1.899c5.178,0,10.204,0.66,15,1.899V6.123h-137.96v101.256h107.96V201.174z" />
                    <path d="M155.799,234.678c11.766-14.877,18.798-33.661,18.798-54.058c0-48.136-39.162-87.298-87.298-87.298S0,132.484,0,180.621 c0,20.397,7.032,39.18,18.798,54.058l68.5,86.124L155.799,234.678z M67.298,180.621c0-11.028,8.972-20,20-20s20,8.972,20,20 s-8.972,20-20,20S67.298,191.649,67.298,180.621z" />
                    <path d="M87.298,401.123c-16.542,0-30,13.458-30,30c0,16.542,13.458,30,30,30s30-13.458,30-30 C117.298,414.581,103.84,401.123,87.298,401.123z" />
                    <path d="M409.284,330.199H242.659c-15.418,0-27.962-12.544-27.962-27.962s12.544-27.962,27.962-27.962h121.485 c-1.239-4.796-1.899-9.822-1.899-15s0.66-10.204,1.899-15H242.659c-31.96,0-57.962,26.001-57.962,57.962 s26.002,57.962,57.962,57.962h166.625c15.418,0,27.962,12.543,27.962,27.962s-12.544,27.962-27.962,27.962H145.4 c1.239,4.796,1.899,9.822,1.899,15s-0.66,10.204-1.899,15h263.884c31.96,0,57.962-26.001,57.962-57.962 S441.244,330.199,409.284,330.199z" />
                    <path d="M392.246,259.275c0,16.542,13.458,30,30,30c16.542,0,30-13.458,30-30s-13.458-30-30-30 C405.704,229.275,392.246,242.733,392.246,259.275z" />
                </g>
            </g>
        </svg>
    );
};
