import React from 'react';
import { TransportOrderFilters } from '../../../../app/models/TransportOrder';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: TransportOrderFilters;
    setFilter: (filter: TransportOrderFilters) => void;
    folios: any[];
    onSearch: () => void;
};

const TransportOrderReadyToDispatchProgramFilter = ({ filter, setFilter, folios, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };
    console.log(folios);
    return (
        <div className="row">
            <div className="row">
            <div className="col-md-auto">
            <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="issue_date_guide_from">
                        Desde
                    </label>
                    <input
                        type="date"
                        id="issue_date_guide_from"
                        name="issue_date_guide_from"
                        className="form-control form-control-sm"
                        value={filter.issue_date_guide_from}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
                </div>
                <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="issue_date_guide_until">
                        Hasta
                    </label>
                    <input
                        type="date"
                        id="issue_date_guide_until"
                        name="issue_date_guide_until"
                        className="form-control form-control-sm"
                        value={filter.issue_date_guide_until}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
                </div>
                <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="carrier">
                        Conductor
                    </label>
                    <input
                        title="Conductor"
                        type="text"
                        id="carrier"
                        name="carrier"
                        className="form-control form-control-sm"
                        value={filter.carrier}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
                </div>
                <div className="col-md-auto">
                <div className="mb-3 ">
                    <label className="font-size-10 mb-1" htmlFor="folios">
                        Folios
                    </label>
                    <SelectTwo
                        id="folios"
                        name="folios"
                        inputValue={filter.folios}
                        options={SelectTwoMapperOptions(folios!)}
                        onChange={(value: any) =>
                            setFilter({
                                ...filter,
                                folios: value,
                                folio_list: value.map((item: any) => item.label)
                            })
                        }
                        isMulti={true}
                        placeholder="Seleccione folio(s)"
                        size="small"
                    />
                </div>
                </div>
            
            </div>
            <div className='row'>
            <div className='col-md-auto'>
                <div style={{marginTop: '22px'}}>
                    <ButtonSearchFilter callbackSearch={onSearch}/>
                </div>
            </div>
            </div>
        </div>
   );
};

export default TransportOrderReadyToDispatchProgramFilter;
