import React, { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import ActiveControl from '../../../components/ActiveControl';

interface Props {
    id: number;
    checkValue: boolean;
    useFunction: (id: number, value: boolean, setChecked: (value:boolean) => void) => void;
}

const QuestionSwitchChange = ({ id, checkValue, useFunction }: Props) => {

    const [checked, setChecked] = React.useState<boolean>(checkValue);

    const changeSwitch = (value: boolean) => {

        useFunction(id, value, setChecked);

    };

    return <ActiveControl status={checked} id={id} callback={changeSwitch} />;
};

export default QuestionSwitchChange;
