import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { toast } from 'react-toastify';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import useSurveyService from '../../../app/services/hooks/useSurveyService';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { defaultSurveyDataForm, SurveyDataForm } from '../../../app/models/Survey';
import SurveyForm from '../components/forms/SurveyForm';
import SectionContainer from '../components/SectionContainer';
import { EventTypeListItem } from '../../../app/models/EventType';


type RouterParams = {
    id: string;
};

const SurveyEdit = () => {

    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Encuesta',
            url: '/surveys',
            isActive: false
        },
        {
            name: 'Editar #' + id,
            url: '/surveys/' + id + '/edit',
            isActive: true
        }
    ];

    const history = useHistory();
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    

    const [survey, setSurvey] = useState<SurveyDataForm>(defaultSurveyDataForm);
    const [eventTypes, setEventTypes] = useState<EventTypeListItem[]>([])
   
    const [errorFields, setErrorFields] = useState<any>();
    const { editSurvey, updateSurvey, fetchingEditSurvey, fetchingUpdateSurvey } =
        useSurveyService();

    useEffect(() => {
        edit(parseInt(id));
    }, [id]);

    const edit = (id: number) => {
        editSurvey(id, {
            onSuccess: (response: ServiceResponse) => {
                const _survey = response.data.survey
                _survey.event_types = response.data.survey.event_types.map((evnt_type: any) => {
                    return {
                        value: evnt_type.id,
                        label: evnt_type.name
                    };
                })
                setSurvey(_survey);
                setEventTypes(response.data.event_types);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/surveys');
                }
            }
        }
    );
    };

    const update = () => {
        if (survey) {
            if (showLoading) showLoading('loading', 'Actualizando encuesta...');

            updateSurvey(Number(survey?.id), survey, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    setErrorFields(undefined);
                    edit(parseInt(id));
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    

    return (
        <>
            <Breadcrumbs pageSection={`Editar Encuesta #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditSurvey ? (
                            <>
                                <SurveyForm
                                    surveyForm={survey}
                                    setSurveyForm={setSurvey}
                                    errorFields={errorFields}
                                    eventTypes={eventTypes}
                                />
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateSurvey}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                             
                            <LazyLoading />
                        )}
                     
                    </DefaultCard>
                </div>
            </div>

            <SectionContainer
                surveyId={parseInt(id)}
            />
        </>
    );
};

export default SurveyEdit;
